import { TQRCodeData } from '@models/index';
import { TConnectionStatusData } from '@redux/connection/models';
import { connectionInitialData } from '@redux/connection/mockData';

export interface IInitialState {
  qrData: TQRCodeData | undefined;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  isFetching: boolean;
  isFetchingQR: boolean;
  isGetQRError: boolean;
  isFetchingCleanQuery: boolean;
  isCleanQueryError: boolean;
  isBotStoppingQuery: boolean;
  isBotStoppingError: boolean;
  isRestartingBot: boolean;
  isBotRestartError: boolean;
  isAccessToConnectionDenied: boolean;
  isRetries: boolean;
  retries: number;
  data: TConnectionStatusData;
  errorMessage: string;
  successMessage: string;
}

export const initialState: IInitialState = {
  qrData: undefined,
  isError: false,
  isLoading: false,
  isSuccess: false,
  isFetching: false,
  isFetchingQR: false,
  isGetQRError: false,
  isFetchingCleanQuery: false,
  isCleanQueryError: false,
  isBotStoppingQuery: false,
  isBotStoppingError: false,
  isRestartingBot: false,
  isBotRestartError: false,
  isAccessToConnectionDenied: false,
  isRetries: false,
  retries: 1,
  errorMessage: '',
  successMessage: '',
  data: connectionInitialData,
};
