import { apiService } from '@api/ApiService';
import { ConfigENV } from '@const/httpConst';
import { AMPLITUDE_END_POINT_URL, TAmplitudeEventData } from '@helpers/amplitude';

export const amplitudeApi = {
  /**
   * Генерит событие обновления данных шаблона
   */
  templateUpdateEvent(data: TAmplitudeEventData) {
    return apiService.post(
      `${ConfigENV.AUTO_CONNECT_BASE_URL}${AMPLITUDE_END_POINT_URL.TEMPLATE_UPDATE}`,
      data.eventProperties,
    );
  },
  /**
   * Генерит событие создания шаблона
   */
  templateCreateEvent(data: TAmplitudeEventData) {
    return apiService.post(
      `${ConfigENV.AUTO_CONNECT_BASE_URL}${AMPLITUDE_END_POINT_URL.TEMPLATE_CREATE}`,
      data.eventProperties,
    );
  },
  /**
   * Генерит событие запуска рассылки
   */
  sendoutStartEvent(data: TAmplitudeEventData) {
    return apiService.post(
      `${ConfigENV.AUTO_CONNECT_BASE_URL}${AMPLITUDE_END_POINT_URL.SENDOUT_START}`,
      data.eventProperties,
    );
  },
  /**
   * Генерит событие авторизации в ЛК
   */
  lkLoginEvent(data: TAmplitudeEventData) {
    return apiService.post(
      `${ConfigENV.AUTO_CONNECT_BASE_URL}${AMPLITUDE_END_POINT_URL.LK_LOGIN}`,
      data.eventProperties,
    );
  },
  /**
   * Генерит событие когда клиент начал заполнение формы подключения ввел любой символ в поля
   * Ваш номер для связи или имя. Либо поменял чекбокс Где с вами лучше связаться?
   */
  startConnectRequestEvent(data: TAmplitudeEventData) {
    return apiService.post(
      `${ConfigENV.AUTO_CONNECT_BASE_URL}${AMPLITUDE_END_POINT_URL.START_CONNECT_REQUEST}`,
      data.eventProperties,
    );
  },
  /**
   * Генерит событие когда клиент нажал на кнопку подключить самостоятельно
   */
  autoconnectButtonEvent(data: TAmplitudeEventData) {
    return apiService.post(
      `${ConfigENV.AUTO_CONNECT_BASE_URL}${AMPLITUDE_END_POINT_URL.AUTOCONNECT_BUTTON}`,
      data.eventProperties,
    );
  },
  /**
   * Генерит событие открытия страницы автоподключения
   */
  autoconnectPageOpened(data: TAmplitudeEventData) {
    return apiService.post(
      `${ConfigENV.AUTO_CONNECT_BASE_URL}${AMPLITUDE_END_POINT_URL.AUTOCONNECT_PAGE_OPEN}`,
      data.eventProperties,
    );
  },
};
