import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
//
import { PATH_NAMES, templateKeysDict } from '@data/dict';
import { TAppLanguage } from '@models/index';
import { TStartMailingData } from '@api/types';
import { MailingStep1 } from '@components/mailingStep1';
import { MailingStep2 } from '@components/mailingStep2';
import { MailingStep3 } from '@components/mailingStep3';
import { getMailingData } from '@redux/mailing/selectors';
import { MailingDrawer } from '@components/mailingDrawer';
import { useAppDispatch, useAppSelector } from '@store/store';
import { ACTIVE_STEP_NUMBER, FORM_DATA_FIELD_NAME } from '@const/mailing';
import { clearCurrentRefValue, isFileSizeIncorrect, mailingTextValidate } from '@helpers/index';
import { postXLSXFileToParse, sendTestMessage, startSendOut } from '@redux/mailing/mailingThunks';
import {
  TAddFile,
  TClickHandler,
  TOnChangeHandler,
  TClearClientFile,
  TClearMediaFileHandler,
} from '@shared/types';
import {
  clearForm,
  setMediaFile,
  setActiveStep,
  clearMediaFile,
  setClientListFile,
  clearClientFileName,
  hideMailingExamplesDrawer,
  showMailingExamplesDrawer,
} from '@redux/mailing/mailingSlice';

type TMailingStepsProps = {
  /**
   * Callback для добавления ключа в текст
   * @param {(key: string) => () => void}
   */
  setKeyInTextarea: (key: string) => () => void;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Callback для оборачивания выделенного текста в символы форматирования
   * @param {TClickHandler}
   */
  wrapSelectedTextInTextarea: TClickHandler;
  /**
   * Сохраненный элемент для операций с innerHtml
   * при работе с редактированием текста и добавления ключей
   * @param {React.MutableRefObject<(EventTarget & HTMLDivElement) | null>}
   */
  div: React.MutableRefObject<(EventTarget & HTMLDivElement) | null>;
  /**
   * Опциональный параметр отключает возможность использования страницы рассылок
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
};

export const MailingSteps = memo(
  ({
    div,
    disabled,
    appLanguage,
    setKeyInTextarea,
    wrapSelectedTextInTextarea,
  }: TMailingStepsProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
      return () => {
        dispatch(clearForm());
      };
    }, [dispatch]);

    const {
      text,
      sendOuts,
      mediaFile,
      clientFile,
      activeStep,
      showDrawer,
      customerKeys,
      selectedYear,
      mediaFileURL,
      isShowWarning,
      mediaFileName,
      mediaFileType,
      selectedMonth,
      clientFileName,
      showParsedInfo,
      isShowCalendar,
      newSendOutData,
      daySendOutLimit,
      mailingStartDate,
      mailingStartTime,
      textareaKeysData,
      isParsingXLSXFile,
      isImmediateLaunch,
      isStartingSendOut,
      isScheduledLaunch,
      sendOutDetailData,
      parsedXSLXFileData,
      dateForGetDetailData,
      blurFromTextareaName,
      isTestMessageSending,
      mediaFileTooBigError,
      unsupportedFileError,
      clientFileTooBigError,
      isParsingXLSXFileError,
      isImmediateStartAllowed,
      isGettingSendOutDetailData,
      minimalRecommendedStartTime,
      isShowSendOutDetailDataModal,
      isCheckingNewSendOutFitToDate,
    } = useAppSelector(getMailingData);

    // флаг отключения кнопки начала рассылки
    const isDisableStartSendOutButton = isScheduledLaunch && !newSendOutData.startDate;

    // Обработчик добавления медиафайлов
    const setMediaFileHandler: TOnChangeHandler = useCallback(
      event => {
        const { files } = event.currentTarget;
        if (files) {
          dispatch(setMediaFile({ files }));
        }
      },
      [dispatch],
    );

    // Создаем рефы для инпутов в которые загружают файлы с изображениями и списком контактов для рассылке
    // рефы нужны для обнуления значений инпутов
    const mediaFileRef = useRef<HTMLInputElement>(null);
    const clientFileRef = useRef<HTMLInputElement>(null);

    // Создает formData, записывает в нее переданный файл и отправляет запрос
    // на получение информации о рассылке по этому файлу
    const parseXLSXFile = useCallback(
      (file: File) => {
        if (!file) return;
        const formData2 = new FormData();
        formData2.append(FORM_DATA_FIELD_NAME.CLIENT_XLSX_FILE, file, file.name);
        dispatch(postXLSXFileToParse({ formData: formData2 }));
      },
      [dispatch],
    );

    // Обрабатывает onChange на инпуте, проверяет загруженный файл на размер
    // если размер меньше 20 mb, записывает данные файла в store и вызывает parseXLSXFile()
    // если файл больше 20 mb, сбрасывает значение инпута
    const setClientListFileHandler: TOnChangeHandler = useCallback(
      event => {
        const { files } = event.currentTarget;
        if (files) {
          dispatch(setClientListFile({ files }));
          if (!isFileSizeIncorrect(files[0])) {
            parseXLSXFile(files[0]);
          }
        } else {
          event.currentTarget.value = '';
        }
      },
      [parseXLSXFile, dispatch],
    );

    // Очищает данные о загруженном файле с контактами в store и очищает инпут от загруженного файла
    const clearClientFile: TClearClientFile = useCallback(
      ref => {
        clearCurrentRefValue(ref);
        dispatch(clearClientFileName());
      },
      [dispatch],
    );

    // Очищает данные о загруженном медиафайле в store и очищает инпут от загруженного файла
    const clearMediaFileHandler: TClearMediaFileHandler = useCallback(
      ref => () => {
        clearCurrentRefValue(ref);
        dispatch(clearMediaFile());
      },
      [dispatch],
    );

    // Отправляет запрос к бэку для отправки тестового сообщения, перед отправкой запускается функция
    // валидации и если валидация прошла запрос отправляется
    const sendTestMessageHandler = useCallback(() => {
      if (mailingTextValidate()) {
        dispatch(sendTestMessage({ file: mediaFile, text, keys: customerKeys }));
      }
    }, [customerKeys, mediaFile, text, dispatch]);

    // Запускает рассылку, сначала проверяется форма на заполнения обязательных полей и если все ок
    // формируется FormData и отправляется в запросе
    const startMailing = useCallback(() => {
      if (mailingTextValidate()) {
        if (clientFileName) {
          const formData = new FormData();

          if (clientFile) {
            formData.append(FORM_DATA_FIELD_NAME.CLIENT_XLSX_FILE, clientFile, clientFileName);
          }
          if (mediaFile) {
            formData.append(FORM_DATA_FIELD_NAME.MEDIA_FILE, mediaFile, mediaFileName);
          }
          formData.append(FORM_DATA_FIELD_NAME.MESSAGE_TEXT, text);
          const data: TStartMailingData = {
            formData,
          };

          if (isScheduledLaunch || !isImmediateStartAllowed) {
            const [hours, minutes] = mailingStartTime.split(':');

            const year = mailingStartDate.getFullYear();
            const month = String(mailingStartDate.getMonth() + 1).padStart(2, '0');
            const day = mailingStartDate.getDate();

            data.startTime = `${year}-${month}-${day}T${hours}:${minutes}:00`;
          }

          const navigateToStartedSendOuts = () => {
            navigate(PATH_NAMES.STARTED_MAILING);
          };

          dispatch(startSendOut({ ...data, callback: navigateToStartedSendOuts }));

          clearCurrentRefValue(mediaFileRef);
          clearCurrentRefValue(clientFileRef);
        }
      }
    }, [
      text,
      navigate,
      mediaFile,
      clientFile,
      mediaFileName,
      clientFileName,
      mailingStartDate,
      mailingStartTime,
      isScheduledLaunch,
      isImmediateStartAllowed,
      dispatch,
    ]);

    // Callback открывает диалоговое окно добавления файла
    const addFile: TAddFile = useCallback(
      ref => () => {
        ref.current?.click();
      },
      [],
    );

    // Переключает шаг формы рассылок
    const setNextStepHandler = useCallback(
      (step: number) => () => {
        dispatch(setActiveStep({ step }));
      },
      [dispatch],
    );

    // Открывает drawer с примерами текстов и картинок для рассылок
    const openMailingExamplesDrawer = useCallback(() => {
      dispatch(showMailingExamplesDrawer());
    }, [dispatch]);

    // Закрывает drawer с примерами текстов и картинок для рассылок
    const hideMailingExamplesDrawerHandler = useCallback(() => {
      dispatch(hideMailingExamplesDrawer());
    }, [dispatch]);

    const keys = { ...customerKeys };

    const keysToRender = keys ? Object.assign(keys, templateKeysDict[appLanguage].mailing) : keys;

    return (
      <div className='lg:max-w-[47rem]'>
        {activeStep === ACTIVE_STEP_NUMBER.FIRST_STEP ? (
          <MailingStep1
            addFile={addFile}
            disabled={disabled}
            appLanguage={appLanguage}
            customerKeys={keysToRender}
            clientFileName={clientFileName}
            showParsedInfo={showParsedInfo}
            clearClientFile={clearClientFile}
            setKeyInTextarea={setKeyInTextarea}
            isParsingXLSXFile={isParsingXLSXFile}
            openDrawer={openMailingExamplesDrawer}
            parsedXSLXFileData={parsedXSLXFileData}
            clientFileTooBigError={clientFileTooBigError}
            isParsingXLSXFileError={isParsingXLSXFileError}
            setClientListFileHandler={setClientListFileHandler}
            wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
            setNextStepHandler={setNextStepHandler(ACTIVE_STEP_NUMBER.SECOND_STEP)}
            className='mb-8'
          />
        ) : null}
        {activeStep === ACTIVE_STEP_NUMBER.SECOND_STEP ? (
          <MailingStep2
            div={div}
            text={text}
            addFile={addFile}
            disabled={disabled}
            appLanguage={appLanguage}
            customerKeys={keysToRender}
            mediaFileURL={mediaFileURL}
            mediaFileName={mediaFileName}
            mediaFileType={mediaFileType}
            setKeyInTextarea={setKeyInTextarea}
            textareaKeysData={textareaKeysData}
            openDrawer={openMailingExamplesDrawer}
            setMediaFileHandler={setMediaFileHandler}
            blurFromTextareaName={blurFromTextareaName}
            mediaFileTooBigError={mediaFileTooBigError}
            unsupportedFileError={unsupportedFileError}
            clearMediaFileHandler={clearMediaFileHandler}
            wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
            setNextStepHandler={setNextStepHandler(ACTIVE_STEP_NUMBER.THIRD_STEP)}
            className='mb-8'
          />
        ) : null}
        {activeStep === ACTIVE_STEP_NUMBER.THIRD_STEP ? (
          <MailingStep3
            disabled={disabled}
            sendOuts={sendOuts}
            appLanguage={appLanguage}
            customerKeys={keysToRender}
            selectedYear={selectedYear}
            startMailing={startMailing}
            isShowWarning={isShowWarning}
            selectedMonth={selectedMonth}
            isShowCalendar={isShowCalendar}
            daySendOutLimit={daySendOutLimit}
            mailingStartTime={mailingStartTime}
            setKeyInTextarea={setKeyInTextarea}
            mailingStartDate={mailingStartDate}
            isImmediateLaunch={isImmediateLaunch}
            isScheduledLaunch={isScheduledLaunch}
            isStartingSendOut={isStartingSendOut}
            sendOutDetailData={sendOutDetailData}
            openDrawer={openMailingExamplesDrawer}
            parsedXSLXFileData={parsedXSLXFileData}
            isChecking={isCheckingNewSendOutFitToDate}
            dateForGetDetailData={dateForGetDetailData}
            isTestMessageSending={isTestMessageSending}
            sendTestMessageHandler={sendTestMessageHandler}
            isImmediateStartAllowed={isImmediateStartAllowed}
            isGettingSendOutDetailData={isGettingSendOutDetailData}
            wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
            isDisableStartSendOutButton={isDisableStartSendOutButton}
            minimalRecommendedStartTime={minimalRecommendedStartTime}
            isShowSendOutDetailDataModal={isShowSendOutDetailDataModal}
          />
        ) : null}
        <MailingDrawer
          isShowWithTabs={false}
          showDrawer={showDrawer}
          onCloseHandler={hideMailingExamplesDrawerHandler}
        />
      </div>
    );
  },
);

MailingSteps.displayName = 'MailingSteps';
