import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { TOnChangeHandler } from '@shared/types';
import { PayPromoCode } from '@components/payPromoCode';

type TPayButtons = {
  /**
   * Флаг подключения автоплатежа
   * @param {boolean}
   */
  isAutoPayEnable: boolean;
  /**
   * Флаг применения промокода
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Скрывает/показывает блок с промокодом
   * @param {boolean}
   */
  isHidePromoCodeBlock: boolean;
  /**
   * Промокод
   * @param {string}
   */
  promoCode: string;
  /**
   * Обработчик поля ввода промокода
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Callback в кнопку для применения промокода
   * @param {() => void}
   */
  applyPromoCode: () => void;
  /**
   * Опциональный параметр - строка классов
   * @param {string}
   */
  /**
   * Флаг показывает или скрывает кнопку скачать счет
   * @param {boolean}
   */
  createBill: boolean;
  /**
   * Флаг показывает состояние запроса данных для получения счета
   * @param {boolean}
   */
  isFetchingInvoiceLink: boolean;
  /**
   * Общая сумма выбранного периода оплаты
   * @param {number}
   */
  selectedCardTotalAmount: number;
  /**
   * Номер выбранной карточки оплаты
   * @param {number}
   */
  selectedCardNumber: number;
  /**
   * Callback для открытия страницы платежной системы
   * @param {() => void}
   */
  changePageOnClick: () => void;
  /**
   * Callback для подключения автоплатежа
   * @param {() => void}
   */
  getAutoPayLinkHandler: () => void;
  /**
   * Callback для открытия модалки получения счета на оплату
   * @param {() => void}
   */
  getBillModalOpen: () => void;
};

export const PayButtons = memo(
  ({
    isLoading,
    promoCode,
    createBill,
    applyPromoCode,
    isAutoPayEnable,
    onChangeHandler,
    getBillModalOpen,
    changePageOnClick,
    selectedCardNumber,
    isHidePromoCodeBlock,
    getAutoPayLinkHandler,
    isFetchingInvoiceLink,
    selectedCardTotalAmount,
  }: TPayButtons) => {
    const { t } = useTranslation();

    const [isButtonPressed, setIsButtonPressed] = useState(false);

    useEffect(() => {
      if (!isLoading && isButtonPressed) {
        setIsButtonPressed(false);
      }
    }, [isLoading, isButtonPressed]);

    const selectedCardTotalAmountText = t('PAY_PAGE_TEXT.selectedCardTotalAmountText', {
      selectedCardTotalAmount,
      paymentMethodText:
        selectedCardNumber === 1 ? t('PAY_PAGE_TEXT.cardPay') : t('PAY_PAGE_TEXT.linkPay'),
    });

    const payButtonText = t('PAY_PAGE_TEXT.payButtonText', {
      selectedCardTotalAmount,
      selectedCardTotalAmountText,
    });

    const isPayButtonDisabled = selectedCardTotalAmount === 0;

    const isEnableAutoPayButton = selectedCardNumber === 1 && !isAutoPayEnable;

    const hidePayButton = selectedCardNumber === 1 && isAutoPayEnable;

    const payButtonClickHandler = useCallback(() => {
      setIsButtonPressed(true);

      if (isEnableAutoPayButton) {
        getAutoPayLinkHandler();
      } else {
        changePageOnClick();
      }
    }, [changePageOnClick, getAutoPayLinkHandler, isEnableAutoPayButton]);

    return (
      <div className='flex flex-wrap items-end justify-between'>
        <div className='flex flex-col sm:flex-row flex-wrap w-full sm:w-fit'>
          {!hidePayButton ? (
            <Button
              dense
              type='action'
              view='filled'
              color='success'
              disabled={isPayButtonDisabled}
              onClick={payButtonClickHandler}
              loading={isButtonPressed && isLoading}
              text={
                isPayButtonDisabled
                  ? payButtonText
                  : isEnableAutoPayButton
                  ? t('PAY_PAGE_TEXT.enableAutoPayment')
                  : payButtonText
              }
              icon={
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M1.98347 2.40039H22.0165C23.112 2.40039 24 3.40775 24 4.65039V18.1504C24 19.393 23.112 20.4004 22.0165 20.4004H1.98347C0.88803 20.4004 0 19.393 0 18.1504V4.65039C0 3.40775 0.88803 2.40039 1.98347 2.40039ZM2.4 10.8004V18.0004H21.6V10.8004H2.4ZM2.4 8.40039H21.6V4.80039H2.4V8.40039Z'
                    fill={selectedCardTotalAmount === 0 ? '#87C289FF' : '#FFFFFF'}
                  />
                </svg>
              }
              className='mb-6 sm:!min-w-[18.725rem] mr-2 w-full sm:w-fit !h-fit'
            />
          ) : null}
          {!hidePayButton && createBill && !isEnableAutoPayButton ? (
            <Button
              dense
              type='action'
              view='outlined'
              onClick={getBillModalOpen}
              loading={isFetchingInvoiceLink}
              text={t('PAY_PAGE_TEXT.downloadBill')}
              disabled={selectedCardTotalAmount === 0}
              icon={
                <svg
                  width='15'
                  height='21'
                  viewBox='0 0 15 21'
                  fill={selectedCardTotalAmount === 0 ? '#9696A0FF' : '#4A4B57'}
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M1 0.5H9L15 6.5V19.5C15 20.05 14.55 20.5 14 20.5H13H10.9202H3.8075H2.5H1C0.45 20.5 0 20.05 0 19.5V1.5C0 0.95 0.45 0.5 1 0.5ZM2 18.5H13V7.5H8V2.5H2V18.5ZM4.76983 12.364C4.38093 11.9751 3.74453 11.9751 3.35562 12.364C2.96671 12.7529 2.96671 13.3893 3.35562 13.7782L6.89116 17.3137C7.28006 17.7026 7.91646 17.7026 8.30537 17.3137L11.8409 13.7782C12.2298 13.3893 12.2298 12.7529 11.8409 12.364C11.452 11.9751 10.8156 11.9751 10.4267 12.364L8.59528 14.1954L8.59528 9.71418C8.60235 9.45255 8.50336 9.19799 8.30537 9C7.91646 8.61109 7.28006 8.61109 6.89116 9C6.69317 9.19799 6.59417 9.45255 6.59417 9.70711L6.60124 14.1954L4.76983 12.364Z'
                  />
                </svg>
              }
              className='mb-6 w-full sm:w-fit'
            />
          ) : (
            <div className='h-[4rem]' />
          )}
        </div>
        {!isHidePromoCodeBlock ? (
          <PayPromoCode
            promoCode={promoCode}
            isLoading={isLoading}
            applyPromoCode={applyPromoCode}
            onChangeHandler={onChangeHandler}
            className='mb-6'
          />
        ) : null}
      </div>
    );
  },
);

PayButtons.displayName = 'PayButtons';
