import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Chips } from '@uikit/Chips';
import { Table } from '@uikit/Table/Table';
import { useAppDispatch } from '@store/store';
import { TPayHistoryConsultantTableData } from '@redux/payHistoryConsultantTable/models';
import { setSortTypeDataHCT } from '@redux/payHistoryConsultantTable/payHistoryConsultantTableSlice';

interface IPartnersPayHistoryConsultantTableProps {
  /**
   * Данные таблицы
   * @param {TPayHistoryConsultantTableData[]}
   */
  data: TPayHistoryConsultantTableData[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const PartnersPayHistoryConsultantTable = memo(
  ({ data, className = '' }: IPartnersPayHistoryConsultantTableProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const getStatusChips = useCallback(
      (status: boolean) => (
        <Chips
          round
          noClick
          type='dense'
          icon={status ? 'small-tick' : 'time'}
          color={status ? 'success' : 'warning'}
          tag={
            status
              ? t('PARTNER_PAGE_TEXT.partnersPayHistoryConsultantTable.paid')
              : t('PARTNER_PAGE_TEXT.partnersPayHistoryConsultantTable.notPaid')
          }
        />
      ),
      [t],
    );

    const Head = useMemo(
      () => [
        { id: 'period', label: t('PARTNER_PAGE_TEXT.partnersPayHistoryConsultantTable.period') },
        { id: 'sum', label: t('PARTNER_PAGE_TEXT.partnersPayHistoryConsultantTable.sum') },
        {
          id: 'status',
          label: t('PARTNER_PAGE_TEXT.partnersPayHistoryConsultantTable.status'),
          noSort: true,
        },
      ],
      [t],
    );

    const getTableData = useCallback(
      (tableData: TPayHistoryConsultantTableData[]) => {
        const emptyData = [
          {
            period: ' ',
            sum: ' ',
            status: ' ',
          },
        ];

        if (!tableData.length) {
          return emptyData;
        }

        return tableData.map(item => {
          return {
            period: item.period,
            sum: item.sum,
            status: getStatusChips(item.status),
          };
        });
      },
      [getStatusChips],
    );

    // функция сортировки, получает название столбца отправляет в редакс
    const setSortTypeHandler = useCallback(
      (collumName: string, order: string) => {
        dispatch(setSortTypeDataHCT({ collumName, order }));
      },
      [dispatch],
    );

    return (
      <div className={className}>
        <p className='leading-6 mb-5 text-blackText tracking-[0.0275em]'>
          {t('PARTNER_PAGE_TEXT.partnersPayHistoryConsultantTable.header')}
        </p>
        <Table
          name={Head}
          data={getTableData(data)}
          position={['left', 'right', 'right']}
          type='def'
          view='flat'
          color='default'
          pages
          labelRowsPerPage={t('PARTNER_PAGE_TEXT.partnersPayHistoryConsultantTable.pagination')}
          rowsPerPageOptions={[5, 8, 20]}
          defRows={8}
          checkboxes={false}
          paperClassName='no_shadow'
          onSortCallback={setSortTypeHandler}
          style={{ boxShadow: 'none', marginLeft: '6px' }}
        />
      </div>
    );
  },
);

PartnersPayHistoryConsultantTable.displayName = 'PartnersPayHistoryConsultantTable';
