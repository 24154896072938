import { payPageEmptyData } from '@redux/pay/mockData';
import { TCreateInvoiceResponse, TPayData, TPaymentData } from '@redux/pay/models';

export interface IInitialState {
  isLoading: boolean;
  isError: boolean;
  isChangeModalOpen: boolean;
  isConnectAutoPayModalOpen: boolean;
  isAutoPayDisableModalOpen: boolean;
  isBillDownloadModalOpen: boolean;
  autoPayLink: string;
  isFetchingAutoPayLink: boolean;
  isFetchingAutoPayLinkError: boolean;
  isFetchingAutoPayLinkSuccess: boolean;
  changeCardDataLink: string;
  changeCardIsLoading: boolean;
  changeCardIsError: boolean;
  changeCardIsSuccess: boolean;
  turnOffAutoPaymentIsLoading: boolean;
  turnOffAutoPaymentIsError: boolean;
  turnOffAutoPaymentIsSuccess: boolean;
  isAccessOnPayPageDenied: boolean;
  retries: number;
  isRetries: boolean;
  errorMessage: string;
  selectedCardNumber: number;
  selectedCardTotalAmount: number;
  selectedCardMonthAmount: number;
  isFetchingInvoiceLink: boolean;
  isFetchingInvoiceLinkError: boolean;
  isSendingInvoiceToTgError: boolean;
  isSendingInvoiceToTg: boolean;
  invoiceData: TCreateInvoiceResponse | null;
  filteredData: TPaymentData[];
  successMessage: string;
  promoCode: string;
  data: TPayData;
}

export const initialState: IInitialState = {
  isLoading: false,
  isError: false,
  isChangeModalOpen: false,
  isConnectAutoPayModalOpen: false,
  isAutoPayDisableModalOpen: false,
  isBillDownloadModalOpen: false,
  autoPayLink: '',
  isFetchingAutoPayLink: false,
  isFetchingAutoPayLinkError: false,
  isFetchingAutoPayLinkSuccess: false,
  changeCardDataLink: '',
  changeCardIsLoading: false,
  changeCardIsError: false,
  changeCardIsSuccess: false,
  turnOffAutoPaymentIsLoading: false,
  turnOffAutoPaymentIsError: false,
  turnOffAutoPaymentIsSuccess: false,
  isAccessOnPayPageDenied: false,
  isRetries: false,
  retries: 1,
  errorMessage: '',
  filteredData: [],
  selectedCardNumber: 0,
  selectedCardTotalAmount: 0,
  selectedCardMonthAmount: 0,
  isFetchingInvoiceLink: false,
  isFetchingInvoiceLinkError: false,
  isSendingInvoiceToTg: false,
  isSendingInvoiceToTgError: false,
  successMessage: '',
  invoiceData: null,
  promoCode: '',
  data: payPageEmptyData,
};
