import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@atoms/icon';
import { CONFIG } from '@const/config';
import { Callout } from '@uikit/Callout';
import { KeyText } from '@blocks/keyText';
import { TClickHandler } from '@shared/types';
import { TSimpleStringObj } from '@models/index';

type TTemplateAdditionalInfoProps = {
  /**
   * Опциональный параметр изменяет внешний вид компонента.
   * Если true отображается список ключей для шаблонов потеряшек
   * @param {boolean}
   */
  isWr?: boolean;
  /**
   * Параметр изменяет внешний вид компонента, включает отображение для мобилок
   * @param {boolean}
   */
  forMobile: boolean;
  /**
   * Callback для добавления ключа в текст
   * @param {(key: string) => () => void}
   */
  setKeyInTextarea: (key: string) => () => void;
  /**
   * Callback для оборачивания выделенного текста в символы форматирования
   * @param {TClickHandler}
   */
  wrapSelectedTextInTextarea: TClickHandler;
  /**
   * Флаг изменяет стили отображения блока на странице рассылок
   * @param {boolean}
   */
  forMailingTemplate?: boolean;
  /**
   * Словарь для формирования списка ключей
   * @param {TSimpleStringObj}
   */
  specialKeyDict: TSimpleStringObj;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const TemplateAdditionalInfo = memo(
  ({
    isWr,
    forMobile,
    specialKeyDict,
    setKeyInTextarea,
    forMailingTemplate,
    wrapSelectedTextInTextarea,
    className = '',
  }: TTemplateAdditionalInfoProps) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    // Тоггл для открытия и закрытия компонента в мобильном виде
    const isOpenToggle = useCallback(() => setIsOpen(prevState => !prevState), []);

    // Формируем доп стили
    const addStyle = forMobile || forMailingTemplate ? 'hidden lg:flex' : 'flex';

    // Формируем массив ключей для отображения
    const keysToRender = useMemo(
      () =>
        Object.entries(specialKeyDict).map(([key, value]) => (
          <span
            key={key}
            className='addText flex mb-[0.3125rem]'
            title={t('TEMPLATE_PAGE_TEXT.templateAdditionalInfoTitle')}>
            <KeyText keyName={`{${key}}`} callBack={setKeyInTextarea(`{${key}}`)} />
            <span className='list-disc whitespace-pre-line ml-2'>{value}</span>
          </span>
        )),
      [t, setKeyInTextarea, specialKeyDict],
    );

    const textFormattingData = useMemo(
      () => [
        {
          keyName: `*${t('TEMPLATE_PAGE_TEXT.text')}*`,
          example: t('TEMPLATE_PAGE_TEXT.templateAdditionalInfoSpecialSymbolsBold'),
          tailWindClassName: 'font-bold',
        },
        {
          keyName: `_${t('TEMPLATE_PAGE_TEXT.text')}_`,
          example: t('TEMPLATE_PAGE_TEXT.templateAdditionalInfoSpecialSymbolsItalic'),
          tailWindClassName: 'italic',
        },
        {
          keyName: `~${t('TEMPLATE_PAGE_TEXT.text')}~`,
          example: t('TEMPLATE_PAGE_TEXT.templateAdditionalInfoSpecialSymbolsStrikethrough'),
          tailWindClassName: 'line-through',
        },
        {
          keyName: `${'```'}${t('TEMPLATE_PAGE_TEXT.text')}${'```'}`,
          example: t('TEMPLATE_PAGE_TEXT.templateAdditionalInfoSpecialSymbolsMonospace'),
          tailWindClassName: 'font-mono',
        },
      ],
      [t],
    );

    // Формируем массив кнопок для форматирования текста
    const textFormattingBlockToRender = useMemo(
      () =>
        textFormattingData.map(({ example, keyName, tailWindClassName }) => (
          <span key={keyName} className='addText'>
            <KeyText keyName={keyName} callBack={wrapSelectedTextInTextarea} />
            {tailWindClassName === 'font-mono' ? (
              <code className={`ml-1 ${tailWindClassName}`}>{example}</code>
            ) : (
              <span className={`ml-1 ${tailWindClassName}`}>{example}</span>
            )}
          </span>
        )),
      [textFormattingData, wrapSelectedTextInTextarea],
    );

    return (
      <div className={className}>
        <div className='border border-quartz rounded-lg px-4 py-3'>
          <div className={`${forMailingTemplate ? 'flex' : ''} ${isOpen ? 'mb-2' : ''}`}>
            <Icon
              variant='formatting'
              className={`${forMobile || !forMailingTemplate ? 'hidden' : 'mr-2'} h-10 w-10`}
            />
            <h3
              className={`relative cursor-pointer flex justify-between font-bold text-blackText text-h6Mobile xl:mb-2 ${
                forMailingTemplate ? 'pr-8' : 'pr-6'
              } translate-all ease-out duration-300 w-full`}>
              {t('TEMPLATE_PAGE_TEXT.templateAdditionalInfoFullHeader')}
              <span
                onClick={isOpenToggle}
                className={`absolute top-0 right-0 bottom-0 left-0 flex items-center justify-end ${
                  !forMobile && !forMailingTemplate ? 'hidden' : ''
                }`}>
                <Icon
                  variant='caretDown'
                  className={`${
                    isOpen ? 'rotate-180' : ''
                  } translate-all ease-out duration-300 h-6 w-6 `}
                />
              </span>
            </h3>
          </div>
          <div
            className={`overflow-hidden ${
              forMobile ? (isOpen ? '!max-h-max' : 'max-h-0') : 'xl:max-h-max'
            } translate-all ease-out duration-300 leading-6 tracking-[0.0275em] text-gray2`}>
            {keysToRender.length ? (
              <p className={`text-black ${addStyle} mb-1`}>
                {t('TEMPLATE_PAGE_TEXT.templateAdditionalInfoKeyDescription')}
              </p>
            ) : null}
            <p className='flex flex-col mb-5 whitespace-pre-line'>
              {keysToRender}
              <span className='block bg-gray1 py-4 px-3 rounded-lg mt-4 leading-6 tracking-[0.0275em]'>
                {t('TEMPLATE_PAGE_TEXT.templateAdditionalInfoSynonymsDescription')}
                <span className='block leading-6 tracking-[0.0275em]'>
                  {t('TEMPLATE_PAGE_TEXT.templateAdditionalInfoSynonymsDescription2')}
                </span>
                <span className='block leading-6 tracking-[0.0275em]'>
                  <a
                    target='_blank'
                    rel='nofollow noreferrer'
                    className='underline text-green1'
                    href={CONFIG.ARTICLE_SYNONYMS_IN_TEMPLATES}>
                    {t('TEMPLATE_PAGE_TEXT.templateAdditionalInfoSynonymsDescriptionLink')}
                  </a>
                </span>
              </span>
            </p>
            <p className='flex items-center mb-2'>
              {t('TEMPLATE_PAGE_TEXT.templateAdditionalInfoSpecialSymbolsDescription')}
            </p>
            <p className='flex flex-col'>{textFormattingBlockToRender}</p>
            <div className={`${addStyle} mt-8`}>
              <Callout type='def' view='smooth' color='primary' icon='info-sign'>
                {!isWr
                  ? t('TEMPLATE_PAGE_TEXT.templateAdditionalInfoCallout')
                  : t('TEMPLATE_PAGE_TEXT.templateAdditionalInfoCalloutWR')}
              </Callout>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

TemplateAdditionalInfo.displayName = 'TemplateAdditionalInfo';
