import React, { memo, useCallback, useEffect } from 'react';
//
import { NoAccess } from '@components/noAccess';
import { ErrorMessage } from '@blocks/errorMessage';
import { ConnectionTemplate } from '@templates/connection';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getConnectionPageData } from '@redux/connection/selectors';
import {
  stopBot,
  getQrData,
  restartBot,
  clearMessages,
  getConnectionPageStatus,
} from '@redux/connection/connectionThunks';

const ConnectionPage = memo(() => {
  const dispatch = useAppDispatch();

  const {
    data,
    qrData,
    isError,
    isLoading,
    isSuccess,
    isFetchingQR,
    isGetQRError,
    errorMessage,
    isRestartingBot,
    isBotRestartError,
    isCleanQueryError,
    isBotStoppingError,
    isBotStoppingQuery,
    isFetchingCleanQuery,
    isAccessToConnectionDenied,
  } = useAppSelector(getConnectionPageData);

  const { selectedFilial, isError: isAccountsError } = useAppSelector(getAccountsData);

  const selectedFilialAccId = selectedFilial?.accId || '';

  useEffect(() => {
    if (selectedFilialAccId) {
      dispatch(getConnectionPageStatus());
    }
  }, [dispatch, selectedFilialAccId]);

  // Создаем callback для получения QR кода
  const getQrHandler = useCallback(() => {
    dispatch(getQrData());
  }, [dispatch]);

  // Создаем callback для очистки очереди сообщений
  const clearMessagesHandler = useCallback(() => {
    dispatch(clearMessages());
  }, [dispatch]);

  // Создаем callback для остановки бота
  const stopTelegramBot = useCallback(() => {
    dispatch(stopBot());
  }, [dispatch]);

  // Создаем callback для перезапуска бота
  const restartTelegramBot = useCallback(() => {
    dispatch(restartBot());
  }, [dispatch]);

  const isLoadingState = (isLoading || !isSuccess) && !isError;

  const isShowNoAccess = isAccessToConnectionDenied && !isLoading;

  const isShowError = !isAccessToConnectionDenied && (isError || isAccountsError);

  const isShowConnectionTemplate =
    !isLoading && !isAccessToConnectionDenied && !(isError || isAccountsError);

  return (
    <>
      {isShowNoAccess ? <NoAccess /> : null}
      {isShowError ? <ErrorMessage /> : null}
      {isShowConnectionTemplate ? (
        <ConnectionTemplate
          data={data}
          qrData={qrData}
          getQr={getQrHandler}
          isLoading={isLoadingState}
          errorMessage={errorMessage}
          isFetchingQR={isFetchingQR}
          isGetQRError={isGetQRError}
          clearQuery={clearMessagesHandler}
          stopTelegramBot={stopTelegramBot}
          isRestartingBot={isRestartingBot}
          isBotRestartError={isBotRestartError}
          isCleanQueryError={isCleanQueryError}
          isBotStoppingQuery={isBotStoppingQuery}
          isBotStoppingError={isBotStoppingError}
          restartTelegramBot={restartTelegramBot}
          isFetchingCleanQuery={isFetchingCleanQuery}
        />
      ) : null}
    </>
  );
});

ConnectionPage.displayName = 'ConnectionPage';

export default ConnectionPage;
