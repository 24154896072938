import { apiService } from '@api/ApiService';
import { END_POINTS_URL } from '@const/apiConstants';
import { TRequestStatus, TGetSettingResponseData } from '@api/types';

/**
 * API страницы настроек
 * @module settingsApi
 */

export const settingsApi = {
  /**
   * Метод выполняет запрос за данными страницы
   * @method getSettings
   * @return {Promise<AxiosResponse<TGetSettingResponseData | TRequestStatus, any>>}
   */
  getSettings() {
    return apiService.get<TGetSettingResponseData | TRequestStatus>(END_POINTS_URL.SETTINGS);
  },
  /**
   * Метод выполняет запрос для сохранения данных страницы
   * @method postSettings
   * @param data {IPostSettingsRequestData} данные страницы настроек
   * @return {Promise<AxiosResponse<TGetSettingResponseData | TRequestStatus, any>>}
   */
  postSettings(data: TGetSettingResponseData) {
    return apiService.post<TGetSettingResponseData | TRequestStatus>(END_POINTS_URL.SETTINGS, data);
  },
};
