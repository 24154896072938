import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
//
import { TNameList } from '@shared/types';
import { COMMON_TOAST, REG_EXP } from '@const/common';
import { TRejectResponseData } from '@models/index';
import { validatePhoneNumber } from '@helpers/index';
import { emptySettingsData } from '@redux/settings/mockData';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { IInitialState, initialState } from '@redux/settings/initialState';
import { SETTINGS_DATA_KEY_NAMES, TSettingsData } from '@redux/settings/models';
import { TStaffListItem, REJECT_RESPONSE_KEY, REJECT_RESPONSE_2_KEY } from '@api/types';
import { getListOfCategoriesSet, getSettings, postSettings } from '@redux/settings/settingsThunks';
import {
  Midnight,
  TOKEN_REQUIRED,
  LOGIN_REQUIRED,
  PASSWORD_REQUIRED,
  SETTINGS_MESSAGES,
  SMS_OPERATOR_NAMES,
  SENDER_NAME_REQUIRED,
  SMS_OPERATOR_NAMES_ENG,
  SETTINGS_BILL_DATA_KEY_NAMES,
  PROVIDER_NAME_FOR_SHOW_ADDITIONAL_INFO,
} from '@const/settings';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettingsData(state: IInitialState, action: PayloadAction<TSettingsData>) {
      state.data = action.payload;
    },
    changeSettingsInputValue(
      state: IInitialState,
      action: PayloadAction<{ name: string; value: string }>,
    ) {
      const { name, value } = action.payload;

      if (SETTINGS_DATA_KEY_NAMES.PHONE === name) {
        state.data[name] = value.replace(REG_EXP.onlyNumbers, '');
      } else {
        state.data[name] = value;
      }
    },
    changeSettingsCheckboxChecked(
      state: IInitialState,
      action: PayloadAction<{ name: string; checked: boolean }>,
    ) {
      const { name, checked } = action.payload;
      if (
        name === SETTINGS_DATA_KEY_NAMES.SMS_CONFIRM &&
        (state.data.smsProvider === SMS_OPERATOR_NAMES.TURN_OFF ||
          state.data.smsProvider === SMS_OPERATOR_NAMES_ENG.TURN_OFF ||
          state.data.smsProvider === '')
      ) {
        state.isPostError = true;
        state.errorMessage = SETTINGS_MESSAGES.SMS_CONFIRM_TURN_ON_ERROR;
        return;
      }
      if (name === SETTINGS_DATA_KEY_NAMES.ENABLE_CHAT_YCLIENTS) {
        if (state.data.disableChat) {
          state.isShowChatEnableModal = true;
          return;
        }
        state.data.disableChat = !checked;
        return;
      }
      state.data[name] = checked;
    },
    setDisableChatYclients(state: IInitialState, action: PayloadAction<boolean>) {
      state.isShowChatEnableModal = false;
      state.data.disableChat = action.payload;
    },
    closeChatEnableModal(state: IInitialState) {
      state.isShowChatEnableModal = false;
    },
    changeSettingsBillData(
      state: IInitialState,
      action: PayloadAction<{ name: string; value: string }>,
    ) {
      const { name, value } = action.payload;
      if (name === SETTINGS_BILL_DATA_KEY_NAMES.INN) {
        state.data.billData[name] = value.replace(REG_EXP.onlyNumbers, '');
      } else {
        state.data.billData[name] = value;
      }
    },
    changeSMSProviderName(
      state: IInitialState,
      action: PayloadAction<{ name: string; value: string }>,
    ) {
      const { name, value } = action.payload;

      state.data.smsProvider = value;
      state.isShowToken = TOKEN_REQUIRED.includes(value);
      state.isShowLogin = LOGIN_REQUIRED.includes(value);
      state.isShowPass = PASSWORD_REQUIRED.includes(value);
      state.isShowSenderName = SENDER_NAME_REQUIRED.includes(value);
      state.isShowAdditionalInfo = Object.keys(PROVIDER_NAME_FOR_SHOW_ADDITIONAL_INFO).includes(
        value,
      );

      if (value === SMS_OPERATOR_NAMES.TURN_OFF) {
        state.data[name] = '';
        if (state.data.smsConfirm) {
          state.data.smsConfirm = false;
          state.isWarningMessage = true;
          state.warningMessage = SETTINGS_MESSAGES.SMS_CONFIRM_TURN_OFF_WARNING;
        }
      } else {
        state.data[name] = value;
      }
    },
    setNewBalloonValue(
      state: IInitialState,
      action: PayloadAction<{ name: string; value: string }>,
    ) {
      const { name, value } = action.payload;
      state[`${name}NewValue`] = value;
    },
    clearNewBalloonValue(state: IInitialState, action: PayloadAction<{ name: string }>) {
      const { name } = action.payload;
      state[`${name}NewValue`] = '';
    },
    addBalloonSP(
      state: IInitialState,
      action: PayloadAction<{ keyName: TNameList; value: string }>,
    ) {
      const { keyName, value } = action.payload;
      if (!value) return;

      if (keyName === SETTINGS_DATA_KEY_NAMES.EXCEPTION_MASTER_NAMES) {
        state.data[keyName] = state.data[keyName].concat(value);
      } else {
        state.data[keyName] = state.data[keyName].concat(validatePhoneNumber(value));
      }

      state[`${keyName}NewValue`] = '';
    },
    deleteBalloonSP(
      state: IInitialState,
      action: PayloadAction<{ keyName: TNameList; value: string }>,
    ) {
      const { keyName, value } = action.payload;
      state.data[keyName] = state.data[keyName].filter(item => item !== value);
    },
    setFromTimeLimit(state: IInitialState, action: PayloadAction<{ time: string }>) {
      state.data.sendFromTime = action.payload.time;
    },
    setToTimeLimit(state: IInitialState, action: PayloadAction<{ time: string }>) {
      state.data.sendToTime = action.payload.time;
    },
    clearSendTimeLimit(state: IInitialState) {
      state.data.sendFromTime = Midnight;
      state.data.sendToTime = Midnight;
    },
    showSaveSettingsSuccessMessage(state: IInitialState) {
      state.isSaveSettingSuccess = true;
    },
    hideSaveSettingsSuccessMessage(state: IInitialState) {
      state.isSaveSettingSuccess = false;
      state.isWarningMessage = false;
    },
    hideSaveErrorMessage(state: IInitialState) {
      state.isPostError = false;
    },
    clearAccessDeniedErrorSP(state: IInitialState) {
      state.isAccessDenied = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getSettings.pending, (state: IInitialState) => {
      state.isLoading = true;
      state.isError = false;
      state.isPosting = false;
      state.isPostError = false;
      state.isAccessDenied = false;
      state.isSaveSettingSuccess = false;
    });
    builder.addCase(
      getSettings.fulfilled,
      (state: IInitialState, action: PayloadAction<TSettingsData | void>) => {
        if (action.payload) {
          const smsProvider = action.payload.smsProvider
            ? action.payload.smsProvider
            : i18n.t('SETTINGS_PAGE_TEXT.SMS_OPERATOR_NAMES.TURN_OFF');

          state.data = { ...action.payload, smsProvider };
          state.oldData = { ...action.payload, smsProvider };
        } else {
          state.data = emptySettingsData;
          state.oldData = emptySettingsData;
        }
        if (state.isRetries) {
          state.retries = 1;
          state.isRetries = false;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(
      getSettings.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
              state.isRetries = true;
              state.retries -= 1;
            } else {
              state.isError = true;
              state.isLoading = false;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.FAIL_RIGHT
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
            state.isLoading = false;
          }
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      },
    );
    builder.addCase(postSettings.pending, (state: IInitialState) => {
      state.isPosting = true;
      state.isPostError = false;
      state.isSaveSettingSuccess = false;
    });
    builder.addCase(
      postSettings.fulfilled,
      (state: IInitialState, action: PayloadAction<TSettingsData | void>) => {
        if (action.payload) {
          const smsProvider = action.payload.smsProvider
            ? action.payload.smsProvider
            : i18n.t('SETTINGS_PAGE_TEXT.SMS_OPERATOR_NAMES.TURN_OFF');

          state.data = { ...action.payload, smsProvider };
          state.oldData = { ...action.payload, smsProvider };

          state.isSaveSettingSuccess = true;
          state.successMessage = COMMON_TOAST.SAVE_SUCCESS;
        }
        state.isPosting = false;
      },
    );
    builder.addCase(
      postSettings.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.isPostError = true;
          }
          state.isPosting = false;
        }
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isPostError = true;
            state.isPosting = false;
          }
        }
        if (!action.payload) {
          state.isPostError = true;
          state.isPosting = false;
        }
        state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
      },
    );
    builder.addCase(
      getListOfCategoriesSet.fulfilled,
      (
        state: IInitialState,
        action: PayloadAction<{ data: TStaffListItem[]; categoryName: string } | void>,
      ) => {
        if (!action.payload) return;
        const { data } = action.payload;
        const newStaffList = data.map(item => [String(item.id), item.name]);
        if (newStaffList.length) {
          state.categoriesDataList.exceptionMastersNames = Object.fromEntries(newStaffList);
        } else {
          state.categoriesDataList.exceptionMastersNames = {
            '-': i18n.t('SETTINGS_PAGE_TEXT.categoriesDataListNoEntries'),
          };
        }
      },
    );
    builder.addCase(
      getListOfCategoriesSet.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.isGettingStaffListError = true;
          }
        }
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isGettingStaffListError = true;
          }
        }
      },
    );
  },
});

export const {
  addBalloonSP,
  setToTimeLimit,
  setSettingsData,
  deleteBalloonSP,
  setFromTimeLimit,
  setNewBalloonValue,
  clearSendTimeLimit,
  clearNewBalloonValue,
  closeChatEnableModal,
  hideSaveErrorMessage,
  changeSMSProviderName,
  changeSettingsBillData,
  setDisableChatYclients,
  changeSettingsInputValue,
  clearAccessDeniedErrorSP,
  changeSettingsCheckboxChecked,
  showSaveSettingsSuccessMessage,
  hideSaveSettingsSuccessMessage,
} = settingsSlice.actions;

export const settingsReducer = settingsSlice.reducer;
