import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
//
import { templateKeysDict } from '@data/dict';
import { TKeyData } from '@redux/template/models';
import { getKeysData } from '@helpers/contentMaker';
import { TParseDivValueToSlice } from '@shared/types';
import { COMMON_TOAST, ONE_DAY_IN_MS } from '@const/common';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { REJECT_RESPONSE_2_KEY, REJECT_RESPONSE_KEY } from '@api/types';
import { emptyParsedXSLXFileData, emptySendOut } from '@redux/mailing/mockData';
import { IInitialState, initialState } from '@redux/mailing/mailingInitialState';
import { ACTIVE_STEP_NUMBER, MAILING_MESSAGES, NEW_SEND_OUT_ID } from '@const/mailing';
import { EVENT_KEY_NAMES, FILE_TYPES, TAppLanguage, TRejectResponseData } from '@models/index';
import {
  TSendOutsData,
  TMailingTableData,
  TParsedXlsxFileData,
  TSendOutsDetailData,
} from '@redux/mailing/models';
import {
  stopSendOut,
  startSendOut,
  getAllowedToRun,
  sendTestMessage,
  postXLSXFileToParse,
  getCalendarDateThunk,
  getPaymentStatusThunk,
  getDetailSendOudDayData,
  getStartedMailingTableData,
} from '@redux/mailing/mailingThunks';
import {
  addNewLines,
  getWrapperText,
  getRoundedTime,
  getMediaFileType,
  sortSendOutsData,
  isFileSizeIncorrect,
  createParsedFileInfo,
  getIncrementedCurrentTime,
} from '@helpers/index';

export const mailingSlice = createSlice({
  name: 'mailing',
  initialState,
  reducers: {
    moveToSendOutStart(state: IInitialState) {
      state.activeStep = ACTIVE_STEP_NUMBER.FIRST_STEP;
    },
    setText(state: IInitialState, action: PayloadAction<{ value: string }>) {
      const { value } = action.payload;
      state.text = value;
    },
    setActiveStep(state: IInitialState, action: PayloadAction<{ step: number }>) {
      state.activeStep = action.payload.step;
    },
    setError(state: IInitialState) {
      state.error = true;
    },
    clearAllErrors(state: IInitialState) {
      state.isError = false;
      state.errorMessage = '';
      state.isShowCalendarError = false;
      state.mediaFileTooBigError = false;
      state.unsupportedFileError = false;
      state.clientFileTooBigError = false;
      state.isParsingXLSXFileError = false;
      state.isStartingSendOutError = false;
      state.isStoppingSendOutError = false;
      state.isTestMessageSendError = false;
    },
    clearAllSuccessStatuses(state: IInitialState) {
      state.successMessage = '';
      state.isParsingXLSXFileSuccess = false;
      state.isTestMessageSendSuccess = false;
      state.isStartingSendOutSuccess = false;
      state.isStoppingSendOutSuccess = false;
    },
    setMediaFile(state: IInitialState, action: PayloadAction<{ files: FileList }>) {
      const file = action.payload.files[0];
      if (!file) return;

      if (isFileSizeIncorrect(file)) {
        state.mediaFileTooBigError = true;
        state.errorMessage = COMMON_TOAST.FILE_TO_BIG;
        return;
      }

      const fileType = getMediaFileType(file);

      if (fileType === FILE_TYPES.UNKNOWN) {
        state.unsupportedFileError = true;
        state.errorMessage = COMMON_TOAST.UNSUPPORTED_FILE;
        return;
      }

      if (fileType === FILE_TYPES.IMAGE || fileType === FILE_TYPES.VIDEO) {
        if (!isFileSizeIncorrect(file)) {
          state.mediaFileTooBigError = false;
          state.mediaFileURL = URL.createObjectURL(file);
        } else {
          state.mediaFileTooBigError = true;
          return;
        }
      } else {
        state.mediaFileURL = null;
      }
      state.mediaFileName = file.name;
      state.mediaFileType = fileType;
      state.mediaFile = file;
      state.error = false;
    },
    clearMediaFile(state: IInitialState) {
      state.error = false;
      state.mediaFile = null;
      state.mediaFileURL = '';
      state.mediaFileName = '';
      state.mediaFileType = null;
    },
    setClientListFile(state: IInitialState, action: PayloadAction<{ files: FileList }>) {
      const file = action.payload.files[0];
      if (!file) return;
      if (!isFileSizeIncorrect(file)) {
        const fileName = file.name;
        state.error = false;
        state.clientFile = file;
        state.clientFileName = fileName;
        state.clientFileTooBigError = false;
      } else {
        state.clientFileTooBigError = true;
        state.errorMessage = COMMON_TOAST.FILE_TO_BIG;
      }
    },
    clearClientFileName(state: IInitialState) {
      state.clientFile = null;
      state.clientFileName = '';
      state.showParsedInfo = false;
      state.xlsxFileInfoMessages = [];
      state.isParsingXLSXFileSuccess = false;
    },
    setMailingAccessDenied(state: IInitialState) {
      state.isAccessDenied = true;
    },
    clearForm(state: IInitialState) {
      state.parsedXSLXFileData = emptyParsedXSLXFileData;
      state.text = '';
      state.mediaFileType = null;
      state.mediaFileName = '';
      state.mediaFile = null;
      state.clientFile = null;
      state.clientFileName = '';
      state.mediaFileURL = null;
      state.showParsedInfo = false;
      state.isParsingXLSXFileError = false;
      state.isStartingSendOutSuccess = false;
      state.isStoppingSendOutSuccess = false;
      state.isParsingXLSXFileSuccess = false;
      state.isTestMessageSendSuccess = false;
      state.xlsxFileInfoMessages = [];
      state.newSendOutData = emptySendOut;
      state.isScheduledLaunch = false;
      state.isImmediateLaunch = true;
      state.mailingStartTime = getIncrementedCurrentTime(1);
      state.selectedDay = new Date().getDate();
      state.selectedMonth = new Date().getMonth();
      state.selectedYear = new Date().getFullYear();
      state.activeStep = ACTIVE_STEP_NUMBER.FIRST_STEP;
    },
    setStoppingSendOutId(state: IInitialState, action: PayloadAction<{ sendOutId: number }>) {
      state.stoppedSendOutId = action.payload.sendOutId;
    },
    showStoppingMailingSuccessPopup(state: IInitialState) {
      state.isStoppingSendOutSuccess = true;
    },
    hideStoppingMailingSuccessPopup(state: IInitialState) {
      state.isStoppingSendOutSuccess = false;
    },
    showTestMessageSendPopup(state: IInitialState) {
      state.isTestMessageSendSuccess = true;
      state.successMessage = MAILING_MESSAGES.MESSAGE_SEND;
    },
    hideStartMailingSuccessMessages(state: IInitialState) {
      state.isStartingSendOutSuccess = false;
    },
    hideTestMessageSendPopup(state: IInitialState) {
      state.isTestMessageSendSuccess = false;
      state.successMessage = '';
    },
    setAbandonedTextareaDataMP(
      state: IInitialState,
      action: PayloadAction<{
        name: string;
        selectionStart: number;
        selectionEnd: number;
      }>,
    ) {
      const { name, selectionStart, selectionEnd } = action.payload;
      const { customerKeys, text } = state;

      state.blurFromTextareaName = name;
      state.selectionEnd = selectionEnd;
      state.selectionStart = selectionStart;

      state.textareaKeysData = getKeysData(text, customerKeys);
    },
    addKeyInTextareaMP(
      state: IInitialState,
      action: PayloadAction<{ innerText: string; callback: TParseDivValueToSlice }>,
    ) {
      const { customerKeys } = state;
      const { innerText, callback } = action.payload;
      const { selectionEnd, selectionStart } = state;

      const innerTextLength = innerText.length;

      const selectionStartOffset = selectionStart + innerTextLength;

      const selectionEndOffset = selectionEnd + innerTextLength;

      state.selectionStart = selectionStartOffset;
      state.selectionEnd = selectionEndOffset;

      const textAreaValue = String(state.text);

      const newTextAreaValue =
        textAreaValue.substring(0, selectionStart) +
        innerText +
        textAreaValue.substring(selectionEnd);

      state.text = newTextAreaValue;

      state.textareaKeysData = getKeysData(newTextAreaValue, customerKeys);

      callback({
        text: newTextAreaValue,
        selectionStart: selectionStartOffset,
        selectionEnd: selectionEndOffset,
        specialKeyDict: customerKeys,
      });
    },
    wrapTextMP(
      state: IInitialState,
      action: PayloadAction<{ innerText: string; callback: TParseDivValueToSlice }>,
    ) {
      const { customerKeys } = state;
      const { innerText, callback } = action.payload;
      const { selectionEnd, selectionStart } = state;

      const textAreaValue = String(state.text);

      const wrapSymbol = innerText.slice(0, 1);
      let wrapTextSymbols = '';

      if (wrapSymbol === '`') {
        wrapTextSymbols = '```';
      } else {
        wrapTextSymbols = wrapSymbol;
      }

      const newValueWithWrappedText = getWrapperText(
        textAreaValue,
        wrapTextSymbols,
        selectionStart,
        selectionEnd,
      );

      const delta = newValueWithWrappedText.length - textAreaValue.length;

      callback({
        text: newValueWithWrappedText,
        selectionStart: selectionEnd + delta,
        selectionEnd: selectionEnd + delta,
        specialKeyDict: customerKeys,
      });

      state.text = newValueWithWrappedText;
    },
    setSendOutErrorMessage(state: IInitialState, action: PayloadAction<string>) {
      state.sendOutErrorMessage = action.payload;
    },
    showMailingExamplesDrawer(state: IInitialState) {
      state.showDrawer = true;
    },
    hideMailingExamplesDrawer(state: IInitialState) {
      state.showDrawer = false;
    },
    setTextareaValueMP(
      state: IInitialState,
      action: PayloadAction<{
        value: string;
        selectionEnd: number;
        selectionStart: number;
        newTextareaKeysData: TKeyData[];
      }>,
    ) {
      const { newTextareaKeysData, selectionStart, selectionEnd, value } = action.payload;

      state.selectionStart = selectionStart;
      state.selectionEnd = selectionEnd;

      state.textareaKeysData = newTextareaKeysData;

      state.text = value;
    },
    textareaOnKeyUpHandlerMP(
      state: IInitialState,
      action: PayloadAction<{
        key: string;
        name: string;
        selectionEnd: number;
        selectionStart: number;
        callback: TParseDivValueToSlice;
      }>,
    ) {
      const { customerKeys } = state;
      const { key, callback, selectionStart, selectionEnd } = action.payload;

      if (key === EVENT_KEY_NAMES.ARROW_LEFT) {
        if (selectionStart === selectionEnd) {
          state.textareaKeysData.forEach(item => {
            if (selectionStart > item.start && selectionEnd <= item.end) {
              if (callback) {
                const offsetIndex = item.start - 1;

                state.selectionStart = offsetIndex;
                state.selectionEnd = offsetIndex;

                callback({
                  text: String(state.text),
                  selectionStart: offsetIndex,
                  selectionEnd: offsetIndex,
                  specialKeyDict: customerKeys,
                });
              }
            }
          });
        }
      }
      if (
        key === EVENT_KEY_NAMES.ARROW_RIGHT ||
        key === EVENT_KEY_NAMES.ARROW_UP ||
        key === EVENT_KEY_NAMES.ARROW_DOWN
      ) {
        if (selectionStart === selectionEnd) {
          state.textareaKeysData.forEach(item => {
            if (selectionStart + 1 > item.start && selectionEnd <= item.end) {
              if (callback) {
                const offsetIndex = item.end + 1;

                state.selectionStart = offsetIndex;
                state.selectionEnd = offsetIndex;

                callback({
                  text: String(state.text),
                  selectionStart: offsetIndex,
                  selectionEnd: offsetIndex,
                  specialKeyDict: customerKeys,
                });
              }
            }
          });
        }
      }
    },
    cancelSelectedStartDate(state: IInitialState) {
      const newSendOut = state.oldSendOuts.filter(sendOut => sendOut.isNew)[0];
      state.isShowCalendar = false;

      state.sendOuts = state.oldSendOuts;
      state.mailingStartDate = state.oldMailingStartDate;
      state.mailingStartTime = state.oldMailingStartTime;

      if (!newSendOut && state.newSendOutData.startDate) {
        state.newSendOutData = { ...state.newSendOutData, startDate: null, endDate: null };
      }
      if (newSendOut) {
        state.newSendOutData = newSendOut;
      }
    },
    showSendOutDetailDataModal(state: IInitialState) {
      state.isShowSendOutDetailDataModal = true;
    },
    hideSendOutDetailDataModal(state: IInitialState) {
      state.sendOutDetailData = [];
      state.isShowSendOutDetailDataModal = false;
    },
    setImmediateLaunch(state: IInitialState) {
      state.isImmediateLaunch = true;
      state.isScheduledLaunch = false;
    },
    setScheduledLaunch(state: IInitialState) {
      state.isImmediateLaunch = false;
      state.isScheduledLaunch = true;
    },
    setMailingStartTime(state: IInitialState, action: PayloadAction<string>) {
      const startDate = state.mailingStartDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });

      const { duration } = state.newSendOutData;

      const newTime = action.payload;

      const newStartDate = new Date(`${startDate} ${newTime}`);

      const newStartTimeStamp = newStartDate.getTime();

      const newEndDate = new Date(newStartTimeStamp + duration);

      const newSendOutWithNewStartTime = {
        ...state.newSendOutData,
        startDate: newStartDate,
        endDate: newEndDate,
      };

      state.mailingStartTime = newTime;
      state.oldMailingStartTime = newTime;

      if (state.newSendOutData.startDate) {
        state.newSendOutData = newSendOutWithNewStartTime;
      }
    },
    setSelectedMonth(state: IInitialState, action: PayloadAction<number>) {
      state.selectedMonth = action.payload;
    },
    setSelectedDay(state: IInitialState, action: PayloadAction<number>) {
      state.selectedDay = action.payload;
    },
    setSelectedYear(state: IInitialState, action: PayloadAction<number>) {
      const newYear = action.payload;

      const todayDate = new Date();

      const currentMonth = todayDate.getMonth();

      const currentYear = todayDate.getFullYear();

      if (newYear === currentYear && state.selectedMonth < currentMonth) {
        state.selectedMonth = currentMonth;
      }

      state.selectedYear = newYear;
    },
    setMailingStartDate(
      state: IInitialState,
      action: PayloadAction<{
        mailingStartDate: Date;
        remainDayLimit: number;
        isSetTimeFromState: boolean;
      }>,
    ) {
      const { mailingStartDate, remainDayLimit, isSetTimeFromState } = action.payload;

      const day = mailingStartDate.getDate();
      const month = mailingStartDate.getMonth();
      const year = mailingStartDate.getFullYear();

      const [hour, minutes] = state.mailingStartTime.split(':');

      state.selectedDay = day;
      state.selectedMonth = month;
      state.selectedYear = year;

      const fullMailingStartDate = isSetTimeFromState
        ? new Date(year, month, day, +hour, +minutes)
        : mailingStartDate;

      const newTimeStamp = isSetTimeFromState
        ? fullMailingStartDate.getTime()
        : mailingStartDate.getTime();

      if (!isSetTimeFromState) {
        const minimalRecommendedStartDate = new Date(newTimeStamp);
        state.mailingStartTime = getRoundedTime(minimalRecommendedStartDate, false, 5, true);
      }

      state.mailingStartDate = fullMailingStartDate;

      const { duration, totalCount, daySendOutLimit, remainder } = state.newSendOutData;

      let newRemainder = remainder;

      let newEndDate = new Date(fullMailingStartDate.getTime() + duration);

      if (remainDayLimit) {
        newRemainder = (totalCount - remainDayLimit) % daySendOutLimit;

        if (duration <= ONE_DAY_IN_MS && newRemainder > 0) {
          newEndDate = new Date(fullMailingStartDate.getTime() + duration + ONE_DAY_IN_MS);
        }
      }

      const newSendOut: TSendOutsData = {
        ...state.newSendOutData,
        startDate: fullMailingStartDate,
        endDate: newEndDate,
        remainder: newRemainder < 0 ? daySendOutLimit + newRemainder : newRemainder,
      };

      let isNewSendOutAdded = false;

      state.sendOuts = state.sendOuts.map(sendOut => {
        if (sendOut.isNew) {
          isNewSendOutAdded = true;
          return newSendOut;
        }
        return sendOut;
      });

      if (!isNewSendOutAdded) {
        state.sendOuts.push(newSendOut);
      }

      state.newSendOutData = newSendOut;

      state.sendOuts.sort((a, b) => {
        return (a.startDate?.getTime() || 0) - (b.startDate?.getTime() || 0);
      });
    },
    confirmSelectedStartDate(state: IInitialState) {
      state.isShowCalendar = false;
      state.oldSendOuts = state.sendOuts;
      state.oldMailingStartDate = state.mailingStartDate;
      state.oldMailingStartTime = state.mailingStartTime;
    },
    deleteNewSendOut(state: IInitialState) {
      const [newSendOut] = state.sendOuts.filter(sendOut => sendOut.isNew);

      if (newSendOut) {
        const { daySendOutLimit, totalCount } = newSendOut;

        const remainder = totalCount <= daySendOutLimit ? totalCount : totalCount % daySendOutLimit;

        state.newSendOutData = { ...newSendOut, startDate: null, endDate: null, remainder };

        const date = new Date(state.minimalRecommendedStartTime);

        const roundedTime = getRoundedTime(date, false, 5, true);

        state.mailingStartDate = date;
        state.oldMailingStartDate = date;

        state.mailingStartTime = roundedTime;
        state.oldMailingStartTime = roundedTime;

        const filteredSendOuts = state.sendOuts.filter(sendOut => !sendOut.isNew);

        state.sendOuts = filteredSendOuts;
        state.oldSendOuts = filteredSendOuts;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getStartedMailingTableData.pending, (state: IInitialState) => {
      if (state.isRefresh) {
        state.isRefresh = false;
      } else {
        state.isLoading = true;
      }
      state.isError = false;
      state.isAccessDenied = false;
    });
    builder.addCase(
      getStartedMailingTableData.fulfilled,
      (state: IInitialState, action: PayloadAction<TMailingTableData[] | void>) => {
        if (action.payload) {
          state.data = action.payload;
        } else {
          state.data = [];
        }
        if (state.isRetries) {
          state.retries = 1;
          state.isRetries = false;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(
      getStartedMailingTableData.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
            state.isLoading = false;
          }
        } else {
          state.isError = true;
          state.isLoading = false;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(sendTestMessage.pending, (state: IInitialState) => {
      state.isTestMessageSending = true;
      state.isTestMessageSendError = false;
      state.isTestMessageSendSuccess = false;
    });
    builder.addCase(sendTestMessage.fulfilled, (state: IInitialState) => {
      state.isTestMessageSending = false;
    });
    builder.addCase(
      sendTestMessage.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isTestMessageSendError = true;
            }
          } else if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            state.isTestMessageSending = false;
            state.isTestMessageSendError = true;
            state.errorMessage = action.payload.detail;
            return;
          } else {
            state.isTestMessageSendError = true;
          }
        } else {
          state.isTestMessageSendError = true;
        }
        state.isTestMessageSending = false;
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
    builder.addCase(postXLSXFileToParse.pending, (state: IInitialState) => {
      state.isParsingXLSXFile = true;
      state.isParsingXLSXFileError = false;
      state.isParsingXLSXFileSuccess = false;
    });
    builder.addCase(
      postXLSXFileToParse.fulfilled,
      (state: IInitialState, action: PayloadAction<TParsedXlsxFileData | void>) => {
        if (action.payload) {
          const { sendOutBatchLen, count, duration, allowedStart, immediateStart, keys } =
            action.payload;

          const appLanguage = i18n.language as TAppLanguage;

          if (keys) {
            state.customerKeys = keys;
          } else {
            state.customerKeys = templateKeysDict[appLanguage].mailing;
          }

          state.isImmediateStartAllowed = immediateStart;

          const minimalRecommendedStartDate = new Date(allowedStart);

          const month = minimalRecommendedStartDate.getMonth();
          const year = minimalRecommendedStartDate.getFullYear();

          state.selectedMonth = month;
          state.selectedYear = year;

          state.mailingStartDate = minimalRecommendedStartDate;
          state.oldMailingStartDate = minimalRecommendedStartDate;

          state.minimalRecommendedStartTime = minimalRecommendedStartDate.getTime();

          const mailingStartTime = getRoundedTime(minimalRecommendedStartDate, false, 5, true);

          state.mailingStartTime = mailingStartTime;
          state.oldMailingStartTime = mailingStartTime;

          const remainder = count <= sendOutBatchLen ? count : count % sendOutBatchLen;

          state.daySendOutLimit = sendOutBatchLen;

          state.newSendOutData = {
            isNew: true,
            startDate: null,
            endDate: null,
            duration: duration || 1,
            isActiveSendOut: true,
            sendOutId: NEW_SEND_OUT_ID,
            totalCount: count,
            daySendOutLimit: sendOutBatchLen,
            remainder,
          };

          const xlsxFileInfoMessages = createParsedFileInfo(action.payload);
          if (xlsxFileInfoMessages.length) {
            state.showParsedInfo = true;
            state.isParsingXLSXFileSuccess = true;
            state.parsedXSLXFileData = action.payload;
            state.xlsxFileInfoMessages = xlsxFileInfoMessages;
          } else {
            state.isParsingXLSXFileError = true;
            state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
          }
        }
        state.isParsingXLSXFile = false;
      },
    );
    builder.addCase(
      postXLSXFileToParse.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
              state.isStoppingSendOut = false;
            } else {
              state.isParsingXLSXFileError = true;
            }
          }
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            if (action.payload.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              return;
            }
            state.clientFile = null;
            state.clientFileName = '';
            state.showParsedInfo = false;
            state.isParsingXLSXFile = false;
            state.xlsxFileInfoMessages = [];
            state.isParsingXLSXFileError = true;
            state.isParsingXLSXFileSuccess = false;
            state.errorMessage = action.payload.detail || COMMON_TOAST.COMMON_ERROR_MESSAGE;
            return;
          }
          state.isParsingXLSXFile = false;
          state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
        } else {
          state.isParsingXLSXFileError = true;
          state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
        }
        state.isParsingXLSXFileError = true;
        state.isParsingXLSXFile = false;
        state.clientFile = null;
        state.clientFileName = '';
        state.showParsedInfo = false;
        state.xlsxFileInfoMessages = [];
        state.isParsingXLSXFileSuccess = false;
      },
    );
    builder.addCase(startSendOut.pending, (state: IInitialState) => {
      state.isStartingSendOut = true;
      state.isStartingSendOutError = false;
      state.isStartingSendOutSuccess = false;
    });
    builder.addCase(
      startSendOut.fulfilled,
      (state: IInitialState, action: PayloadAction<boolean>) => {
        const isScheduledLaunch = action.payload;
        state.isStartingSendOut = false;
        state.isStartingSendOutSuccess = true;

        state.successMessage = isScheduledLaunch
          ? MAILING_MESSAGES.MAILING_SCHEDULED
          : MAILING_MESSAGES.MAILING_SEND;
      },
    );
    builder.addCase(
      startSendOut.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (action.payload && REJECT_RESPONSE_KEY.ERROR in action.payload) {
            state.sendOutErrorMessage = addNewLines(action.payload.error);
            state.isStartingSendOutError = true;
            state.errorMessage = '';
            state.isStartingSendOut = false;
            return;
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else if (status === REQUEST_TEXT_ERROR_STATUS.KEY_ERROR) {
              state.isStartingSendOutError = true;
              state.sendOutErrorMessage = addNewLines(status);
            } else {
              state.isStartingSendOutError = true;
            }
          }
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            state.isStartingSendOut = false;
            state.isStartingSendOutError = true;
            state.errorMessage = action.payload.detail;
            return;
          }
          if (REJECT_RESPONSE_KEY.MESSAGE in action.payload) {
            state.isError = true;
            state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
          }
          state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
          state.isStartingSendOut = false;
        } else {
          state.isStartingSendOut = false;
          state.isStartingSendOutError = true;
          state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
        }
      },
    );
    builder.addCase(stopSendOut.pending, (state: IInitialState) => {
      state.isFetching = true;
      state.isStoppingSendOut = true;
      state.isStoppingSendOutError = false;
      state.isStoppingSendOutSuccess = false;
    });
    builder.addCase(stopSendOut.fulfilled, (state: IInitialState) => {
      state.isRefresh = true;
      state.isFetching = false;
      state.isStoppingSendOut = false;
      state.isStoppingSendOutSuccess = true;
      state.successMessage = i18n.t('MAILING_TOAST_MESSAGES.MAILING_STOP', {
        stoppedSendOutId: state.stoppedSendOutId,
      });
    });
    builder.addCase(
      stopSendOut.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
              state.isStoppingSendOut = false;
            } else {
              state.isStoppingSendOutError = true;
              state.isStoppingSendOut = false;
            }
          } else {
            state.isStoppingSendOut = false;
            state.isStoppingSendOutError = true;
            state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
          }
        } else {
          state.isStoppingSendOut = false;
          state.isStoppingSendOutError = true;
          state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
        }
      },
    );
    builder.addCase(getCalendarDateThunk.pending, (state: IInitialState) => {
      state.sendOuts = [];
      state.isGetsCalendarData = true;
      state.isShowSendOutDetailDataModal = false;
      state.sendOutDetailData = [];
    });
    builder.addCase(
      getCalendarDateThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<TSendOutsData[] | void>) => {
        state.isGetsCalendarData = false;
        if (action.payload) {
          if (state.newSendOutData.startDate) {
            const newSendOuts = sortSendOutsData([...action.payload, state.newSendOutData]);
            state.sendOuts = newSendOuts;
            state.oldSendOuts = newSendOuts;
          } else {
            state.sendOuts = action.payload;
          }
          state.isShowCalendar = true;
        }
      },
    );
    builder.addCase(
      getCalendarDateThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
              state.isGetsCalendarData = false;
            }
          } else if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            if (action.payload.detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isGetsCalendarData = false;
              state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
            }
          } else {
            state.isError = true;
            state.isGetsCalendarData = false;
            state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
          }
        } else {
          state.isError = true;
          state.isGetsCalendarData = false;
          state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
        }
        state.isShowCalendar = false;
      },
    );
    builder.addCase(getDetailSendOudDayData.pending, (state: IInitialState) => {
      state.isGettingSendOutDetailData = true;
      state.isShowSendOutDetailDataModal = false;
    });
    builder.addCase(
      getDetailSendOudDayData.fulfilled,
      (
        state: IInitialState,
        action: PayloadAction<{
          date: Date;
          data: TSendOutsDetailData[];
          isShowWarning: boolean;
        } | void>,
      ) => {
        if (action.payload) {
          const { data, date, isShowWarning } = action.payload;
          state.sendOutDetailData = data;
          state.dateForGetDetailData = date;
          state.isShowWarning = isShowWarning;
          state.isShowSendOutDetailDataModal = !!data.length;

          if (!data.length) {
            state.isParsingXLSXFileError = true;
            state.errorMessage = i18n.t('MAILING_PAGE_TEXT.mailingCalendar.sendOutCrossing');
          }
        }
        state.isGettingSendOutDetailData = false;
      },
    );
    builder.addCase(
      getDetailSendOudDayData.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        state.isShowSendOutDetailDataModal = false;
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
              state.isShowCalendar = false;
              state.isGettingSendOutDetailData = false;
            }
          } else if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            if (action.payload.detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isShowCalendar = false;
              state.isGettingSendOutDetailData = false;
              state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
            }
          } else {
            state.isError = true;
            state.isShowCalendar = false;
            state.isGettingSendOutDetailData = false;
            state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
          }
        } else {
          state.isError = true;
          state.isShowCalendar = false;
          state.isGettingSendOutDetailData = false;
          state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
        }
      },
    );
    builder.addCase(getAllowedToRun.pending, (state: IInitialState) => {
      state.isCheckingNewSendOutFitToDate = true;
    });
    builder.addCase(
      getAllowedToRun.fulfilled,
      (
        state: IInitialState,
        action: PayloadAction<{ isTimeCheck: boolean; isNewSendOutFit: boolean } | void>,
      ) => {
        if (action.payload) {
          const { isTimeCheck, isNewSendOutFit } = action.payload;
          state.isCheckingNewSendOutFitToDate = false;
          if (!isNewSendOutFit && isTimeCheck) {
            state.isParsingXLSXFileError = true;
            state.errorMessage = i18n.t('MAILING_PAGE_TEXT.mailingCalendar.sendOutCrossing');
          }
        }
      },
    );
    builder.addCase(
      getAllowedToRun.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
              state.isShowCalendar = false;
              state.isCheckingNewSendOutFitToDate = false;
            }
          } else if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            if (action.payload.detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isShowCalendar = false;
              state.isCheckingNewSendOutFitToDate = false;
              state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
            }
          } else {
            state.isError = true;
            state.isShowCalendar = false;
            state.isCheckingNewSendOutFitToDate = false;
            state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
          }
        } else {
          state.isError = true;
          state.isShowCalendar = false;
          state.isCheckingNewSendOutFitToDate = false;
          state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
        }
      },
    );
    builder.addCase(getPaymentStatusThunk.pending, (state: IInitialState) => {
      state.isLoading = true;
    });
    builder.addCase(
      getPaymentStatusThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<boolean>) => {
        state.isLoading = false;
        state.isPayedAccount = action.payload;

        if (!action.payload) {
          state.isShowCalendarError = true;
          state.errorMessage = COMMON_TOAST.MAILING_ACCOUNT_NOT_PAYED;
        }
      },
    );
    builder.addCase(
      getPaymentStatusThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
          } else if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            if (action.payload.detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
            }
          } else {
            state.isError = true;
            state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
          }
        } else {
          state.isError = true;
          state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
        }
      },
    );
  },
});

export const {
  setText,
  setError,
  clearForm,
  wrapTextMP,
  setMediaFile,
  setActiveStep,
  clearAllErrors,
  clearMediaFile,
  setSelectedDay,
  setSelectedYear,
  deleteNewSendOut,
  setSelectedMonth,
  setClientListFile,
  addKeyInTextareaMP,
  moveToSendOutStart,
  setImmediateLaunch,
  setScheduledLaunch,
  setTextareaValueMP,
  setMailingStartDate,
  clearClientFileName,
  setMailingStartTime,
  setStoppingSendOutId,
  setMailingAccessDenied,
  setSendOutErrorMessage,
  clearAllSuccessStatuses,
  cancelSelectedStartDate,
  confirmSelectedStartDate,
  hideTestMessageSendPopup,
  textareaOnKeyUpHandlerMP,
  showTestMessageSendPopup,
  hideMailingExamplesDrawer,
  showMailingExamplesDrawer,
  setAbandonedTextareaDataMP,
  hideSendOutDetailDataModal,
  showSendOutDetailDataModal,
  hideStartMailingSuccessMessages,
  showStoppingMailingSuccessPopup,
  hideStoppingMailingSuccessPopup,
} = mailingSlice.actions;

export const mailingReducer = mailingSlice.reducer;
