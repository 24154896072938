import React, { memo, useCallback } from 'react';
//
import { PATH_NAMES } from '@data/dict';
import { TAppLanguage } from '@models/index';
import { QUERY_PARAMS } from '@const/apiConstants';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { InviteFriendCard } from '@components/inviteFriendCard';
import { getInviteFriendsActionData } from '@redux/inviteFriendsAction/selectors';
import { InviteFriendActivateModal } from '@components/inviteFriendActivateModal';
import { TInviteFriendListData, TSwitchActionData } from '@redux/inviteFriendList/types';
import { hideWarningModal, showWarningModal } from '@redux/inviteFriendsAction/inviteFriendSlice';
import {
  deleteActionThunk,
  switchActionThunk,
  copyActionToFilialThunk,
} from '@redux/inviteFriendList/inviteFriendsThunk';

type TInviteFriedActionListProps = {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Флаг состояния запроса на удаление
   * @param {boolean}
   */
  isDeleting: boolean;
  /**
   * Флаг успешного удаления шаблона
   * @param {boolean}
   */
  isDeletingSuccess: boolean;
  /**
   * Флаг ошибки при удалении шаблона
   * @param {boolean}
   */
  isDeletingError: boolean;
  /**
   * Флаг переключения активности шаблона
   * @param {boolean}
   */
  isSwitching: boolean;
  /**
   * Данные для отображения карточек
   * @param {TInviteFriendListData[]}
   */
  data: TInviteFriendListData[];
};

export const InviteFriedActionList = memo(
  ({
    isDeleting,
    isSwitching,
    appLanguage,
    isDeletingError,
    isDeletingSuccess,
    data,
  }: TInviteFriedActionListProps) => {
    const dispatch = useAppDispatch();

    // получаем объект selectedFilial
    const { selectedFilial } = useAppSelector(getAccountsData);

    // получаем данные для отображения предупруждения при активации акции
    const { isShowWarningModal, errorInGetLoyaltyCard } = useAppSelector(
      getInviteFriendsActionData,
    );

    // id выбранного филиала
    const accId = selectedFilial?.accId || '';

    // переключает активность шаблона
    const switchAction = useCallback(
      (actionData: TSwitchActionData) => {
        const { id, status } = actionData;

        if (errorInGetLoyaltyCard && !status) {
          dispatch(showWarningModal());
        } else {
          dispatch(switchActionThunk({ id, status }));
        }
      },
      [errorInGetLoyaltyCard, dispatch],
    );

    // удаляет шаблон
    const deleteAction = useCallback(
      (id: string) => {
        dispatch(deleteActionThunk(id));
      },
      [dispatch],
    );

    // создает копию шаблона в текущем филиале
    const duplicateActionHandler = useCallback(
      (id: string) => {
        if (selectedFilial) {
          dispatch(
            copyActionToFilialThunk({ templateId: id, branchList: [selectedFilial.branchId] }),
          );
        }
      },
      [selectedFilial, dispatch],
    );

    // скрывает модалку с предупреждением
    const hideWarningModalHandler = useCallback(() => {
      dispatch(hideWarningModal());
    }, [dispatch]);

    // массив карточек для отображения
    const cartsToTender = data.map(card => {
      const linkToActionTemplate = `${PATH_NAMES.INVITE_FRIEND_TEMPLATE}?${QUERY_PARAMS.ID}=${card.id}&${QUERY_PARAMS.ACC_ID}=${accId}`;

      return (
        <InviteFriendCard
          key={card.id}
          data={card}
          isEditTemplatesAccess
          isDeleting={isDeleting}
          appLanguage={appLanguage}
          isSwitching={isSwitching}
          link={linkToActionTemplate}
          deleteAction={deleteAction}
          isDeletingError={isDeletingError}
          switchActionActivity={switchAction}
          isDeletingSuccess={isDeletingSuccess}
          duplicateCation={duplicateActionHandler}
          className='w-full'
        />
      );
    });

    return (
      <div className='grid grid-cols-3temp xxl:grid-cols-2tempIF gap-[1.5rem] w-full'>
        {cartsToTender}
        <InviteFriendActivateModal
          isOpen={isShowWarningModal}
          hideModal={hideWarningModalHandler}
        />
      </div>
    );
  },
);

InviteFriedActionList.displayName = 'InviteFriedActionList';
