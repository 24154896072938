import * as amplitude from '@amplitude/analytics-browser';

export enum AMPLITUDE_END_POINT_URL {
  TEMPLATE_UPDATE = '/template_update',
  TEMPLATE_CREATE = '/template_create',
  SENDOUT_START = '/sendout_start',
  LK_LOGIN = '/lk_login',
  START_CONNECT_REQUEST = '/start_connect_request',
  AUTOCONNECT_BUTTON = '/autoconnect_button',
  AUTOCONNECT_PAGE_OPEN = '/autoconnect_page_opened',
}

export type TAmplitudeEventData = {
  eventName: string;
  eventProperties: {
    interface: string;
    branch_id?: string;
    template_id?: string;
    total?: number;
    media_file?: boolean;
    visits_median?: number;
    last_visit_time_median?: number;
  };
};

export enum AMPLITUDE_CUSTOM_EVENTS {
  TEMPLATE_UPDATE = 'template_update',
  TEMPLATE_CREATE = 'template_create',
  SENDOUT_START = 'sendout_start',
  LK_LOGIN = 'lk_login',
  START_CONNECT_REQUEST = 'start_connect_request',
  MANUAL_CONNECTION = 'autoconnect_button',
  AUTOCONNECT_PAGE_OPENED = 'autoconnect_page_openned',
}

export const AMPLITUDE_INTERFACE = 'lk';

const aipKey = process.env.REACT_APP_AMPLITUDE_API_KEY || '';

export const amplitudeInit = () =>
  amplitude.init(aipKey, undefined, {
    serverZone: 'EU',
    defaultTracking: {
      pageViews: false,
    },
  });

export const amplitudeTrack = (eventProperties: TAmplitudeEventData) => {
  amplitude.track(eventProperties.eventName, {
    ...eventProperties.eventProperties,
    interface: AMPLITUDE_INTERFACE,
  });
};

export const amplitudeTrackWithPromise = (eventProperties: TAmplitudeEventData) => {
  return amplitude.track(eventProperties.eventName, {
    ...eventProperties.eventProperties,
    interface: AMPLITUDE_INTERFACE,
  }).promise;
};

export const amplitudeSetUserId = (branchId: string) => {
  amplitude.setUserId(branchId);
};
