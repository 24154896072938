import { FILIALS } from '@const/templates';
import { TKeyData } from '@redux/template/models';
import { getKeysData } from '@helpers/contentMaker';
import { TParseDivValueToSlice } from '@shared/types';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sendFeedbackData } from '@redux/feedback/feedbackThunk';
import { TAppLanguage, TRejectResponseData } from '@models/index';
import { COMMON_TOAST, COMMON_TOAST_MESSAGES, REG_EXP } from '@const/common';
import { IInitialState, initialState } from '@redux/inviteFriendsAction/initialState';
import {
  TRejectResponse,
  TRejectResponse2,
  REJECT_RESPONSE_KEY,
  REJECT_RESPONSE_2_KEY,
} from '@api/types';
import {
  log,
  numWord,
  validateValue,
  getWrapperText,
  getRoundedMinutes,
  validatePhoneNumber,
} from '@helpers/index';
import {
  getCardTypesThunk,
  updateActionThunk,
  createNewActionThunk,
  getMinimalPriceThunk,
  copyActionToBranchThunk,
  getInviteFriendActionThunk,
} from '@redux/inviteFriendsAction/inviteFriendsActionThunk';
import {
  BONUSES,
  REQUIRED_KEY,
  CARD_NOT_FIND,
  NOT_SELECTED_CARD,
  NOT_SELECTED_CARD_ID,
  WHEN_SEND_VALUE_INVITE_FRIENDS,
  INVITE_FRIENDS_NEW_BALLOON_VALUE_POSTFIX,
} from '@const/inviteFriend';
import {
  TCardType,
  TActionData,
  TIFTextareaNames,
  TBonusValueInputs,
  TIFBalloonsKeyNames,
  ACTION_DATA_KEY_NAMES,
} from '@redux/inviteFriendsAction/type';

import i18n from 'i18n';

const inviteFriendActionSlice = createSlice({
  name: 'inviteFriendSlice',
  initialState,
  reducers: {
    setInviteFriendsActionPageData(state: IInitialState, action: PayloadAction<TActionData>) {
      state.data = action.payload;
    },
    setTitle(state: IInitialState, action: PayloadAction<{ title: string }>) {
      state.data.title = action.payload.title;
    },
    setTitleInHeader(state: IInitialState, action: PayloadAction<{ title: string }>) {
      state.data.title = action.payload.title;
      state.data.oldTitle = action.payload.title;
    },
    setTemplateActivity(state: IInitialState, action: PayloadAction<{ checked: boolean }>) {
      const cardId = state.data.cardType.id;
      const { firstMessage, secondMessage } = state.data;
      const isRequiredKeyAdded = state.data.secondMessage.includes(REQUIRED_KEY);

      if (state.errorInGetLoyaltyCard && !state.data.active) {
        state.isShowWarningModal = true;
        return;
      }

      if (!cardId) {
        state.errorMessage = i18n.t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.cardTypeNotSelected');
        return;
      }

      if (!firstMessage) {
        state.errorMessage = i18n.t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.noFirstMessage');
        return;
      }

      if (!secondMessage) {
        state.errorMessage = i18n.t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.noSecondMessage');
        return;
      }

      if (!isRequiredKeyAdded) {
        state.errorMessage = i18n.t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.noRequiredKeyError', {
          key: REQUIRED_KEY,
        });
        return;
      }

      state.data.active = action.payload.checked;
    },
    setInputValues(state: IInitialState, action: PayloadAction<{ name: string; value: string }>) {
      const { name, value } = action.payload;

      switch (name) {
        case ACTION_DATA_KEY_NAMES.TITLE: {
          state.data.title = value;
          break;
        }
        default: {
          log(value);
        }
      }
    },
    setSelectedLoyaltyCard(state: IInitialState, action: PayloadAction<TCardType>) {
      if (action.payload.id === NOT_SELECTED_CARD_ID) {
        state.data.active = false;
      }
      state.data.cardType = action.payload;
    },
    setBonusType(state: IInitialState, action: PayloadAction<string>) {
      const newBonusType = action.payload;

      if (newBonusType === BONUSES.FIRST_VISITS) {
        state.data.visits = [''];
      }
      state.data.bonusType = action.payload;
    },
    setBonusesValues(
      state: IInitialState,
      action: PayloadAction<{ name: TBonusValueInputs; value: string }>,
    ) {
      const { name, value } = action.payload;

      const newValue = String(+value.replace(REG_EXP.onlyNumbers, ''));

      if (!newValue) {
        state.data[name] = '0';
      }

      if (name === 'interestRegularCustomer' && +newValue > 100) {
        state.data[name] = validateValue({
          value: newValue,
          upLimit: 100,
          lowLimit: 0,
          initialValue: '0',
        });
      } else if (newValue) {
        state.data[name] = newValue;
      }
    },
    setWhenSendIF(state: IInitialState, action: PayloadAction<string>) {
      const newWhenSend = action.payload;
      state.data.whenSend = action.payload;
      switch (newWhenSend) {
        case WHEN_SEND_VALUE_INVITE_FRIENDS.BEFORE_START: {
          state.data.whenSend2 = '';
          state.data.whenSend2Days = '0';
          state.data.whenSend2Min = '15';
          break;
        }
        case WHEN_SEND_VALUE_INVITE_FRIENDS.AFTER_END: {
          state.data.whenSend2 = '';
          state.data.whenSend2Days = '0';
          state.data.whenSend2Min = '15';
          break;
        }
        case WHEN_SEND_VALUE_INVITE_FRIENDS.ALL_DAY_RECS_NOTIFICATION: {
          state.data.whenSend2 = '1';
          state.data.whenSend2Days = '1';
          break;
        }
        default: {
          state.data.whenSend2 = '';
          state.data.whenSend2Days = '0';
          state.data.whenSend2Min = '15';
        }
      }
    },
    setWhenSend2IF(state: IInitialState, action: PayloadAction<string>) {
      const newWhenSend2 = action.payload;

      if (
        state.data.whenSend === WHEN_SEND_VALUE_INVITE_FRIENDS.ALL_DAY_RECS_NOTIFICATION &&
        newWhenSend2 !== '0'
      ) {
        state.data.whenSend2Days = '1';
      }
      state.data.whenSend2 = newWhenSend2;
    },
    setWhenSend2DaysIF(
      state: IInitialState,
      action: PayloadAction<{ value: string; maxValue: number; minValue: number }>,
    ) {
      const { value, maxValue, minValue } = action.payload;
      const newValue = +value.replace(REG_EXP.onlyNumbers, '');
      state.data.whenSend2Days = validateValue({
        value: String(newValue),
        upLimit: maxValue,
        lowLimit: minValue,
        initialValue: '',
      });
    },
    setWhenTimeIF(state: IInitialState, action: PayloadAction<string>) {
      state.data.whenTime = action.payload;
    },
    setWhenSend2MinIF(state: IInitialState, action: PayloadAction<string>) {
      const newValue = +action.payload.replace(REG_EXP.onlyNumbers, '');
      state.data.whenSend2Min = String(newValue);
    },
    roundedWhenSend2MinutesIF(state: IInitialState) {
      state.data.whenSend2Min = String(getRoundedMinutes(+state.data.whenSend2Min, 15));
    },
    setTextareaValueIF(
      state: IInitialState,
      action: PayloadAction<{
        value: string;
        selectionEnd: number;
        name: TIFTextareaNames;
        selectionStart: number;
        newTextareaKeysData: TKeyData[];
      }>,
    ) {
      const { name, selectionStart, selectionEnd, value, newTextareaKeysData } = action.payload;

      state.selectionStart = selectionStart;
      state.selectionEnd = selectionEnd;

      state.textareaKeysData = newTextareaKeysData;

      state.data[name] = value;
    },
    wrapTextIF(
      state: IInitialState,
      action: PayloadAction<{ innerText: string; callback: TParseDivValueToSlice }>,
    ) {
      const { inviteFriendsKeysDict } = state;
      const { innerText, callback } = action.payload;
      const { blurFromTextareaName, selectionEnd, selectionStart } = state;

      const textAreaValue = String(state.data[blurFromTextareaName]);

      const wrapSymbol = innerText.slice(0, 1);
      let wrapTextSymbols = '';

      if (wrapSymbol === '`') {
        wrapTextSymbols = '```';
      } else {
        wrapTextSymbols = wrapSymbol;
      }

      const newValueWithWrappedText = getWrapperText(
        textAreaValue,
        wrapTextSymbols,
        selectionStart,
        selectionEnd,
      );

      const delta = newValueWithWrappedText.length - textAreaValue.length;

      callback({
        text: newValueWithWrappedText,
        selectionStart: selectionStart + delta,
        selectionEnd: selectionEnd + delta,
        specialKeyDict: inviteFriendsKeysDict,
      });

      state.data[blurFromTextareaName] = newValueWithWrappedText;
    },
    addKeyInTextareaIF(
      state: IInitialState,
      action: PayloadAction<{
        innerText: string;
        callback: TParseDivValueToSlice;
      }>,
    ) {
      const { inviteFriendsKeysDict } = state;
      const { innerText, callback } = action.payload;
      const { blurFromTextareaName, selectionEnd, selectionStart } = state;
      const textAreaValue = String(state.data[blurFromTextareaName]);

      const innerTextLength = innerText.length;

      const selectionStartOffset = selectionStart + innerTextLength;

      const selectionEndOffset = selectionEnd + innerTextLength;

      state.selectionStart = selectionStartOffset;
      state.selectionEnd = selectionEndOffset;

      const newTextAreaValue = `${textAreaValue.substring(
        0,
        selectionStart,
      )}${innerText}${textAreaValue.substring(selectionEnd)}`;

      state.data[blurFromTextareaName] = newTextAreaValue;

      state.textareaKeysData = getKeysData(newTextAreaValue, inviteFriendsKeysDict);

      callback({
        text: newTextAreaValue,
        selectionStart: selectionStartOffset,
        selectionEnd: selectionEndOffset,
        specialKeyDict: inviteFriendsKeysDict,
      });
    },
    setAbandonedTextareaDataIF(
      state: IInitialState,
      action: PayloadAction<{
        name: TIFTextareaNames;
        selectionStart: number;
        selectionEnd: number;
      }>,
    ) {
      const { inviteFriendsKeysDict } = state;
      const { name, selectionStart, selectionEnd } = action.payload;

      state.blurFromTextareaName = name;
      state.selectionEnd = selectionEnd;
      state.selectionStart = selectionStart;

      state.textareaKeysData = getKeysData(state.data[name], inviteFriendsKeysDict);
    },
    setNewBalloonValueIF(
      state: IInitialState,
      action: PayloadAction<{ name: TIFBalloonsKeyNames; value: string }>,
    ) {
      const { name, value } = action.payload;

      if (name === ACTION_DATA_KEY_NAMES.BLACKLIST) {
        state[`${name}${INVITE_FRIENDS_NEW_BALLOON_VALUE_POSTFIX}`] = value.replace(
          REG_EXP.onlyNumbers,
          '',
        );
      } else {
        state[`${name}${INVITE_FRIENDS_NEW_BALLOON_VALUE_POSTFIX}`] = value;
      }
    },
    clearNewBalloonValueIF(
      state: IInitialState,
      action: PayloadAction<{ name: TIFBalloonsKeyNames }>,
    ) {
      const { name } = action.payload;
      state[`${name}${INVITE_FRIENDS_NEW_BALLOON_VALUE_POSTFIX}`] = '';
    },
    addBalloonIF(
      state: IInitialState,
      action: PayloadAction<{ keyName: TIFBalloonsKeyNames; value: string }>,
    ) {
      const { keyName, value } = action.payload;
      if (!value) return;

      if (keyName === ACTION_DATA_KEY_NAMES.BLACKLIST) {
        state.data[keyName] = state.data[keyName].concat(validatePhoneNumber(value));
        state[`${keyName}${INVITE_FRIENDS_NEW_BALLOON_VALUE_POSTFIX}`] = '';
      } else {
        state.data[keyName] = state.data[keyName].concat(value);
        state[`${keyName}${INVITE_FRIENDS_NEW_BALLOON_VALUE_POSTFIX}`] = '';
      }
    },
    deleteBalloonIF(
      state: IInitialState,
      action: PayloadAction<{ keyName: TIFBalloonsKeyNames; value: string }>,
    ) {
      const { keyName, value } = action.payload;
      state.data[keyName] = state.data[keyName].filter(item => item !== value);
    },
    setVisitQuantityIF(
      state: IInitialState,
      action: PayloadAction<{ quantityFrom: string; quantityTo: string }>,
    ) {
      const { quantityFrom, quantityTo } = action.payload;
      state.data.visits[1] = quantityTo;
      state.data.visits[0] = quantityFrom;
    },
    setRangeOfVisitsIF(state: IInitialState, action: PayloadAction<{ value: string }>) {
      const { value } = action.payload;
      if (value === '') {
        state.data.rangeOfVisits = '';
        return;
      }
      if (Number(value) < 1) {
        state.data.rangeOfVisits = '';
      } else {
        state.data.rangeOfVisits = String(value);
      }
    },
    setOnlineRecord(state: IInitialState, action: PayloadAction<{ checked: boolean }>) {
      state.data.onlineRecord = action.payload.checked;
    },
    showNoRequiredKeyError(state: IInitialState) {
      state.errorMessage = i18n.t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.noRequiredKeyError', {
        key: REQUIRED_KEY,
      });
    },
    showWarningModal(state: IInitialState) {
      state.isShowWarningModal = true;
    },
    hideWarningModal(state: IInitialState) {
      state.isShowWarningModal = false;
    },
    clearAllSuccessStateIF(state: IInitialState) {
      state.successMessage = '';
      state.isSavingSuccess = false;
      state.isCopyTemplatesSuccess = false;
    },
    clearAllErrorStateIF(state: IInitialState) {
      state.errorMessage = '';
      state.isCopyTemplatesError = false;
    },
    clearAllErrorIF(state: IInitialState) {
      state.isError = false;
      state.isAccessDenied = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getInviteFriendActionThunk.pending, (state: IInitialState) => {
      state.isError = false;
      state.isLoading = true;
    });
    builder.addCase(
      getInviteFriendActionThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<TActionData | void>) => {
        state.isLoading = false;
        if (action.payload) {
          state.data = action.payload;
          state.oldData = action.payload;
        }
      },
    );
    builder.addCase(
      getInviteFriendActionThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isLoading = false;
            } else {
              return;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
              state.isLoading = false;
            } else {
              state.isError = true;
              state.isLoading = false;
            }
          }
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      },
    );
    builder.addCase(
      getCardTypesThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<TCardType[]>) => {
        const newLoyaltyCard = action.payload;
        const selectedLang = i18n.language as TAppLanguage;

        if (newLoyaltyCard.length) {
          newLoyaltyCard.unshift(NOT_SELECTED_CARD[selectedLang]);
        } else {
          newLoyaltyCard.unshift(CARD_NOT_FIND[selectedLang]);
          [state.data.cardType] = newLoyaltyCard;
          // state.isBlockTemplate = true;
        }

        state.loyaltyCards = action.payload;
      },
    );
    builder.addCase(
      getCardTypesThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
            } else {
              return;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
          }
        } else {
          state.isError = true;
        }
        state.errorInGetLoyaltyCard = true;
      },
    );
    builder.addCase(
      getMinimalPriceThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<number>) => {
        state.minimalPrice = String(action.payload);
      },
    );
    builder.addCase(
      getMinimalPriceThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
            } else {
              return;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
          }
        } else {
          state.isError = true;
        }
      },
    );
    builder.addCase(createNewActionThunk.pending, (state: IInitialState) => {
      state.isSaving = true;
    });
    builder.addCase(
      createNewActionThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<TActionData | void>) => {
        if (action.payload) {
          const data = action.payload;

          state.isSaving = false;

          if (!data.loyaltyPermissionStatus) {
            state.isShowWarningModal = true;
          }
          if (data.loyaltyPermissionStatus) {
            state.isSavingSuccess = true;
            state.successMessage = COMMON_TOAST_MESSAGES.SAVE_SUCCESS;
            state.data = action.payload;
            state.oldData = action.payload;
          }
        }
      },
    );
    builder.addCase(
      createNewActionThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        state.isSaving = false;
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isSaving = false;
            }
          } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
              state.isSaving = false;
            }
          } else {
            state.isError = true;
            state.isSaving = false;
          }
        } else {
          state.isError = true;
          state.isSaving = false;
        }
      },
    );
    builder.addCase(updateActionThunk.pending, (state: IInitialState) => {
      state.isSaving = true;
    });
    builder.addCase(
      updateActionThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<TActionData | void>) => {
        if (action.payload) {
          const data = action.payload;

          state.isSaving = false;

          if (!data.loyaltyPermissionStatus) {
            state.isShowWarningModal = true;
          }

          if (data.loyaltyPermissionStatus) {
            state.isSavingSuccess = true;
            state.successMessage = COMMON_TOAST_MESSAGES.SAVE_SUCCESS;
            state.data = action.payload;
            state.oldData = action.payload;
          }
        }
      },
    );
    builder.addCase(
      updateActionThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        state.isSaving = false;
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isSaving = false;
            }
          } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status, loyalty_permission_status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else if (status === REQUEST_TEXT_ERROR_STATUS.OBJECT_NOT_UPDATE) {
              state.isSaving = false;
              if (!loyalty_permission_status) {
                state.isShowWarningModal = true;
              } else {
                state.isSavingSuccess = true;
                state.successMessage = COMMON_TOAST_MESSAGES.SAVE_SUCCESS;
                state.oldData = state.data;
              }
            } else {
              state.isError = true;
              state.isSaving = false;
            }
          } else {
            state.isError = true;
            state.isSaving = false;
          }
        } else {
          state.isError = true;
          state.isSaving = false;
        }
      },
    );
    builder.addCase(
      sendFeedbackData.fulfilled,
      (state: IInitialState, action: PayloadAction<boolean>) => {
        if (action.payload) {
          state.successMessage = i18n.t('INVITE_FRIEND_PAGE_TEXT.feedback.feedbackSend');
        }
      },
    );
    builder.addCase(
      sendFeedbackData.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;

            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              return;
            }
          }
        }
        state.errorMessage = i18n.t('COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE');
      },
    );
    builder.addCase(copyActionToBranchThunk.pending, (state: IInitialState) => {
      state.isCopyTemplatesError = false;
      state.isCopyTemplatesSuccess = false;
      state.isCopyTemplatesLoading = true;
    });
    builder.addCase(
      copyActionToBranchThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<number>) => {
        const lang = i18n.language as TAppLanguage;
        const filialToCopyLength = action.payload;

        const filials = FILIALS[lang];

        const successMessage = i18n.t('TEMPLATE_PAGE_TEXT.templateCopy.templateCopied', {
          templateCopiedNumber: filialToCopyLength,
          filials: numWord(filialToCopyLength, filials),
        });

        state.isCopyTemplatesLoading = false;
        state.isCopyTemplatesSuccess = true;
        state.successMessage = successMessage;
      },
    );
    builder.addCase(
      copyActionToBranchThunk.rejected,
      (
        state: IInitialState,
        action: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              return;
            }
            state.isCopyTemplatesError = true;
            state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            } else {
              state.isCopyTemplatesError = true;
              state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
            }
          }
        } else {
          state.isCopyTemplatesError = true;
          state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
        }
        state.isCopyTemplatesLoading = false;
      },
    );
  },
});

export const inviteFriendActionReducer = inviteFriendActionSlice.reducer;

export const {
  setTitle,
  wrapTextIF,
  setBonusType,
  addBalloonIF,
  setWhenTimeIF,
  setWhenSendIF,
  setWhenSend2IF,
  setInputValues,
  clearAllErrorIF,
  deleteBalloonIF,
  setOnlineRecord,
  setBonusesValues,
  setTitleInHeader,
  hideWarningModal,
  showWarningModal,
  setWhenSend2MinIF,
  addKeyInTextareaIF,
  setRangeOfVisitsIF,
  setTextareaValueIF,
  setVisitQuantityIF,
  setWhenSend2DaysIF,
  setTemplateActivity,
  clearAllErrorStateIF,
  setNewBalloonValueIF,
  showNoRequiredKeyError,
  clearAllSuccessStateIF,
  clearNewBalloonValueIF,
  setSelectedLoyaltyCard,
  roundedWhenSend2MinutesIF,
  setAbandonedTextareaDataIF,
  setInviteFriendsActionPageData,
} = inviteFriendActionSlice.actions;
