import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { Loader } from '@blocks/loader';
import { EVENT_KEY_NAMES } from '@models/index';
import { getAppData } from '@redux/app/selectors';
import { ROUTES_ACCESS_RIGHTS } from '@const/access';
import { SendTimeLimit } from '@blocks/sendTimeLimit';
import { getAccountsData } from '@redux/accounts/selectors';
import { getSettingsData } from '@redux/settings/selectors';
import { REG_EXP, VIDEO_INSTRUCTIONS } from '@const/common';
import { ToastsMessages } from '@components/toastsMessages';
import { SettingsFormInput } from '@blocks/settingsFormInput';
import { useAppDispatch, useAppSelector } from '@store/store';
import { HeaderWithButton } from '@components/headerWithButton';
import { SettingsBillData } from '@components/settingsBillData';
import { TEMPLATE_DATA_KEY_NAMES } from '@redux/template/models';
import { SmsOperatorSelect } from '@components/smsOperatorSelect';
import { PROVIDER_NAMES, SETTINGS_MESSAGES } from '@const/settings';
import { SettingInputWithLabel } from '@blocks/settingInputWithLabel';
import { InputWithDropdownFromEPSP } from '@blocks/inputWithDropdownFromEPSP';
import { SETTINGS_DATA_KEY_NAMES, TSettingsData } from '@redux/settings/models';
import { SettingsCheckboxComponent } from '@components/settingsCheckboxComponent';
import {
  TNameList,
  TOnChangeHandler,
  TAddBalloonOnBlur,
  TDeleteBalloonSet,
  TSettingsPostHandler,
  TAddBalloonOnBlurHandler,
} from '@shared/types';
import {
  addBalloonSP,
  setToTimeLimit,
  deleteBalloonSP,
  setFromTimeLimit,
  setNewBalloonValue,
  clearSendTimeLimit,
  closeChatEnableModal,
  clearNewBalloonValue,
  hideSaveErrorMessage,
  changeSMSProviderName,
  changeSettingsBillData,
  setDisableChatYclients,
  changeSettingsInputValue,
  changeSettingsCheckboxChecked,
  hideSaveSettingsSuccessMessage,
} from '@redux/settings/settingsSlice';

type TSettingsProps = {
  /**
   * Состояние запроса на сохранение изменений
   * @param {boolean}
   */
  isPosting: boolean;
  /**
   * Флаг ошибки сохранения данных
   * @param {boolean}
   */
  isPostError: boolean;
  /**
   * ID выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Флаг успешного сохранения
   * @param {boolean}
   */
  isSaveSettingSuccess: boolean;
  /**
   * Callback для сохранения изменений на странице
   * @param {TSettingsPostHandler}
   */
  postCallback: TSettingsPostHandler;
  /**
   * Данные страницы настроек
   * @param {TSettingsData}
   */
  data: TSettingsData;
  /**
   * Флаг отключения кнопки сохранения
   * @param {boolean}
   */
  isSaveButtonDisable: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const SettingsTemplate = memo(
  ({
    data,
    isPosting,
    isPostError,
    postCallback,
    isSaveButtonDisable,
    selectedFilialAccId,
    isSaveSettingSuccess,
    className = '',
  }: TSettingsProps) => {
    const { t } = useTranslation();

    const {
      smsPass,
      billData,
      smsLogin,
      smsApikey,
      sendToTime,
      smsConfirm,
      smsProvider,
      sendContact,
      disableChat,
      sendFromTime,
      replyMsgCheck,
      smsSenderName,
      enableReplyInBot,
      phonesDontShowInBot,
      exceptionMastersNames,
    } = data;

    const dispatch = useAppDispatch();
    const {
      isLoading,
      isShowPass,
      isShowLogin,
      isShowToken,
      errorMessage,
      successMessage,
      warningMessage,
      isWarningMessage,
      isShowSenderName,
      categoriesDataList,
      isShowAdditionalInfo,
      isShowChatEnableModal,
      phonesDontShowInBotNewValue,
    } = useAppSelector(getSettingsData);

    const { appLanguage } = useAppSelector(getAppData);

    // Состояние для отображения сообщения об ошибки в имени отправителя,
    // выводится только в том случае если поле обязательно пи выбранном.
    const [isShowSmsSenderRequiredNameError, setIsShowSmsSenderRequiredNameError] = useState(false);

    const [isShowSmsSenderRequiredNameErrorMessage, setIsShowSmsSenderRequiredNameErrorMessage] =
      useState('');

    const { selectedFilial } = useAppSelector(getAccountsData);

    const isOwner = selectedFilial?.rights.includes(ROUTES_ACCESS_RIGHTS.OWNER);

    // Эффект убирает ошибку если есть значение в smsSenderName
    useEffect(() => {
      if (smsSenderName) {
        setIsShowSmsSenderRequiredNameError(false);
        setIsShowSmsSenderRequiredNameErrorMessage('');
      }
    }, [smsSenderName]);

    // Эффект запускает changeSMSProviderName для открытия/закрытия полей настройки провайдера
    useEffect(() => {
      dispatch(changeSMSProviderName({ name: smsProvider, value: smsProvider }));
    }, [dispatch, smsProvider]);

    // Обработчик события Change, обрабатывает инпуты.
    const onChangeHandler: TOnChangeHandler = useCallback(
      event => {
        const { name, value } = event.currentTarget;
        dispatch(changeSettingsInputValue({ name, value }));
      },
      [dispatch],
    );

    // Обработчик события Change, обрабатывает чекбоксы.
    const onChangeSelectHandler: TOnChangeHandler = useCallback(
      event => {
        const { name, checked } = event.currentTarget;
        dispatch(changeSettingsCheckboxChecked({ name, checked }));
      },
      [dispatch],
    );

    const enableChatCallback = useCallback(() => {
      dispatch(setDisableChatYclients(false));
    }, [dispatch]);

    const closeModalCallback = useCallback(() => {
      dispatch(closeChatEnableModal());
    }, [dispatch]);

    // Обработчик данных о счете
    const billDataOnChangeHandler: TOnChangeHandler = useCallback(
      event => {
        const { name, value } = event.currentTarget;
        dispatch(changeSettingsBillData({ name, value }));
      },
      [dispatch],
    );

    const newBalloonValueOnChangeHandler: TOnChangeHandler = useCallback(
      event => {
        const { name, value } = event.currentTarget;
        const keyName = name as TNameList;

        dispatch(
          setNewBalloonValue({ value: value.replace(REG_EXP.onlyNumbers, ''), name: keyName }),
        );
      },
      [dispatch],
    );

    const clearNewBalloonValueHandler = useCallback(
      (name: string) => {
        dispatch(clearNewBalloonValue({ name }));
      },
      [dispatch],
    );

    // Удаляет 'шарик' по его значению
    const deleteBalloon: TDeleteBalloonSet = useCallback(
      (value, keyName) => () => {
        const tKeyName = keyName as TNameList;
        dispatch(deleteBalloonSP({ keyName: tKeyName, value }));
      },
      [dispatch],
    );

    // Добавляет новый по событию Blur шарик и очищает инпут
    const addBalloonOnBlur: TAddBalloonOnBlur = useCallback(
      event => {
        const { name, value } = event.currentTarget;

        if (value) {
          const keyName = name as TNameList;
          dispatch(addBalloonSP({ keyName, value }));
        }
      },
      [dispatch],
    );

    // Добавляет новый по событию Blur шарик и очищает инпут
    const addBalloonOnBlurHandler: TAddBalloonOnBlurHandler = useCallback(
      (name, value) => {
        if (value) {
          const keyName = SETTINGS_DATA_KEY_NAMES.EXCEPTION_MASTER_NAMES as TNameList;
          dispatch(addBalloonSP({ keyName, value }));
        }
      },
      [dispatch],
    );

    // Добавляет новый по событию KeyUp шарик и очищает инпут
    const addBalloonByEnterKey = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === EVENT_KEY_NAMES.ENTER) {
          addBalloonOnBlur(event);
        }
      },
      [addBalloonOnBlur],
    );

    // Проверяет на валидность название провайдера
    const smsProviderSettingsIsValid = useCallback(() => {
      const valid = [true];

      if (smsProvider === PROVIDER_NAMES.SMS_PROSTO) {
        valid.push((!!smsPass && !!smsLogin) || !!smsApikey);
      }

      if (isShowToken && smsProvider !== PROVIDER_NAMES.SMS_PROSTO) {
        valid.push(!!smsApikey);
      }
      if (isShowPass && smsProvider !== PROVIDER_NAMES.SMS_PROSTO) {
        valid.push(!!smsPass);
      }
      if (isShowLogin && smsProvider !== PROVIDER_NAMES.SMS_PROSTO) {
        valid.push(!!smsLogin);
      }
      if (isShowSenderName) {
        valid.push(!!smsSenderName);
      }

      return valid.every(item => item);
    }, [
      smsPass,
      smsLogin,
      smsApikey,
      isShowPass,
      isShowToken,
      smsProvider,
      isShowLogin,
      smsSenderName,
      isShowSenderName,
    ]);

    const clearSendTimeLimitHandler = useCallback(() => {
      dispatch(clearSendTimeLimit());
    }, [dispatch]);

    const setFromTimeLimitHandler = useCallback(
      (time: string) => {
        dispatch(setFromTimeLimit({ time }));
      },
      [dispatch],
    );

    const setToTimeLimitHandler = useCallback(
      (time: string) => {
        dispatch(setToTimeLimit({ time }));
      },
      [dispatch],
    );

    // Проверяет перед отправкой настройки СМС оператора, если данные не валидны выставляет ошибку,
    // скролит окно к неверно заполненному инпуту и устанавливает на него фокус
    const sendDataValidate = useCallback(() => {
      if (smsProviderSettingsIsValid()) {
        postCallback(data, phonesDontShowInBotNewValue);
        setIsShowSmsSenderRequiredNameError(false);
      } else {
        setIsShowSmsSenderRequiredNameError(true);
        setIsShowSmsSenderRequiredNameErrorMessage(SETTINGS_MESSAGES.SMS_SENDER_FIELD_EMPTY_ERROR);

        setTimeout(() => {
          setIsShowSmsSenderRequiredNameError(false);
          setIsShowSmsSenderRequiredNameErrorMessage('');
        }, 200);
      }
    }, [data, phonesDontShowInBotNewValue, smsProviderSettingsIsValid, postCallback]);

    const hideErrorMessageHandler = useCallback(() => {
      dispatch(hideSaveErrorMessage());
    }, [dispatch]);

    const hideSuccessMessageHandler = useCallback(() => {
      dispatch(hideSaveSettingsSuccessMessage());
    }, [dispatch]);

    const isSaveButtonDisabled = isSaveButtonDisable || !isOwner;

    const isShowErrorToast = isPostError || isShowSmsSenderRequiredNameError;

    const errorMessageToToast = isShowSmsSenderRequiredNameErrorMessage || errorMessage;

    return (
      <div className={`flex flex-col ${className}`}>
        <HeaderWithButton
          headerText={t('SETTINGS_PAGE_TEXT.header')}
          instructionData={VIDEO_INSTRUCTIONS.settings}
          className='mb-10'
        />
        {isLoading ? (
          <Loader className='h-[50vh]' />
        ) : (
          <div className='likeForm max-w-[82.5rem]'>
            <SettingsCheckboxComponent
              smsConfirm={smsConfirm}
              enableChat={!disableChat}
              sendContact={sendContact}
              replyMsgCheck={replyMsgCheck}
              enableReplyInBot={enableReplyInBot}
              closeModalCallback={closeModalCallback}
              enableChatCallback={enableChatCallback}
              isShowChatEnableModal={isShowChatEnableModal}
              onChangeSelectHandler={onChangeSelectHandler}
              className='mb-10 w-full'
            />
            {data.sendContact ? (
              <SettingInputWithLabel
                value={data.phone}
                onChangeHandler={onChangeHandler}
                htmlFor={SETTINGS_DATA_KEY_NAMES.PHONE}
                className='mb-8'>
                {t('SETTINGS_PAGE_TEXT.phoneNumberLabel')}
              </SettingInputWithLabel>
            ) : null}
            <InputWithDropdownFromEPSP
              value={exceptionMastersNames}
              deleteBalloon={deleteBalloon}
              isFetchingSuccess={!isLoading}
              categoriesDataList={categoriesDataList}
              selectedFilialAccId={selectedFilialAccId}
              addBalloonOnBlur={addBalloonOnBlurHandler}
              categoryName={TEMPLATE_DATA_KEY_NAMES.STAFF}
              inputId={SETTINGS_DATA_KEY_NAMES.EXCEPTION_MASTER_NAMES}
              labelTextVariant={SETTINGS_DATA_KEY_NAMES.EXCEPTION_MASTER_NAMES}
              className='mb-8'
            />
            <SettingsFormInput
              noIcon
              typeOfValueIsNumber
              value={phonesDontShowInBot}
              deleteBalloon={deleteBalloon}
              addBalloonOnBlur={addBalloonOnBlur}
              newValue={phonesDontShowInBotNewValue}
              clearNewValue={clearNewBalloonValueHandler}
              addBalloonByEnterKey={addBalloonByEnterKey}
              setNewValue={newBalloonValueOnChangeHandler}
              inputId={SETTINGS_DATA_KEY_NAMES.PHONES_DONT_SHOW_IN_BOT}
              variant={SETTINGS_DATA_KEY_NAMES.PHONES_DONT_SHOW_IN_BOT}
              className='mb-8'
            />
            <SendTimeLimit
              sendToTime={sendToTime}
              sendFromTime={sendFromTime}
              setToTime={setToTimeLimitHandler}
              clearTime={clearSendTimeLimitHandler}
              setFromTime={setFromTimeLimitHandler}
              className='mb-8'
            />
            <SettingsBillData
              billData={billData}
              billDataOnChangeHandler={billDataOnChangeHandler}
              className='mb-10'
            />
            <SmsOperatorSelect
              smsPass={smsPass}
              smsLogin={smsLogin}
              smsApikey={smsApikey}
              isShowPass={isShowPass}
              appLanguage={appLanguage}
              isShowLogin={isShowLogin}
              smsProvider={smsProvider}
              isShowToken={isShowToken}
              smsSenderName={smsSenderName}
              onChangeHandler={onChangeHandler}
              isShowSenderName={isShowSenderName}
              isShowAdditionalInfo={isShowAdditionalInfo}
              className='max-w-[21.25rem] w-full'
            />
            <Button
              dense
              type='action'
              view='filled'
              color='success'
              loading={isPosting}
              onClick={sendDataValidate}
              disabled={isSaveButtonDisabled}
              text={t('SETTINGS_PAGE_TEXT.saveButton')}
              title={!isOwner ? t('SETTINGS_PAGE_TEXT.saveButtonTitle') : ''}
            />
            <ToastsMessages
              warningToastTimeout={7000}
              successMessage={successMessage}
              warningMessage={warningMessage}
              errorMessage={errorMessageToToast}
              isShowErrorToast={isShowErrorToast}
              isShowWarningToast={isWarningMessage}
              isShowSuccessToast={isSaveSettingSuccess}
              clearErrorCallback={hideErrorMessageHandler}
              clearSuccessStatusCallback={hideSuccessMessageHandler}
            />
          </div>
        )}
      </div>
    );
  },
);

SettingsTemplate.displayName = 'SettingsTemplate';
