import { storageDb } from '@api/storageApi';
import { reviewsApi } from '@api/reviewsApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRejectResponseData } from '@models/index';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { reviewsMappers } from '@redux/reviews/reviewsMappers';
import { setIdChainDeleted } from '@redux/reviews/reviewsSlice';
import {
  TStaffListItem,
  REJECT_RESPONSE_KEY,
  REJECT_RESPONSE_2_KEY,
  T_STAFF_LIST_RESPONSE_KEYS,
  GET_ONE_REVIEW_DATA_KEY_NAMES,
  GET_RESPONSE_WITH_DATA_KEY_NAMES,
} from '@api/types';

import { TReviewData, TReviewInListMapData } from './models';

/**
 * Thunk-экшен для получения списка цепочек отзывов.
 * @returns {TReviewInListMapData[] | void} - Список цепочек отзывов или void
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const getReviewsList = createAsyncThunk<
  TReviewInListMapData[] | void,
  void,
  { rejectValue: TRejectResponseData }
>('reviews/getReviewsList', (_, { rejectWithValue }) => {
  return reviewsApi
    .getReviewsListData()
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (GET_RESPONSE_WITH_DATA_KEY_NAMES.COUNT in response.data) {
        return reviewsMappers.mapReviewsDataToStore(response.data.data);
      }
    })
    .catch(error => {
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для получения отзыва по идентификатору шаблона.
 * @param {Object} data - Объект с данными для запроса.
 * @param {string} data.templateId - Идентификатор шаблона отзыва.
 * @returns {Promise<TReviewData | void>} - Данные отзыва или void в случае ошибки.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const getReviewById = createAsyncThunk<
  TReviewData | void,
  { templateId: string },
  { rejectValue: TRejectResponseData }
>('reviews/getReviewById', (data, { rejectWithValue }) => {
  return reviewsApi
    .getReviewData({ templateId: data.templateId })
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data) {
        throw new Error(response.data.status);
      }

      if (GET_ONE_REVIEW_DATA_KEY_NAMES.FIRST_STEP in response.data.data) {
        return reviewsMappers.mapResponseToStore(response.data.data);
      }
    })
    .catch(error => {
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для сохранения цепочки отзыва.
 * @param {TReviewData} data - Данные цепочки отзыва для сохранения.
 * @returns {{ id: string }} - Объект, содержащий идентификатор сохраненного отзыва.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const saveReview = createAsyncThunk<
  { id: string },
  TReviewData,
  { rejectValue: TRejectResponseData }
>('reviews/saveReview', (data, { rejectWithValue, dispatch }) => {
  const requestData = storageDb.getRequestData();
  return reviewsApi
    .saveReview({ ...requestData, data: reviewsMappers.mapReviewDataToSend(data) })
    .then(response => {
      const regex = /\d+/g;
      const id = response.data.status.match(regex);
      return { id: id ? id[0] : '' };
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(saveReview(data)), 100);
      }

      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для обновления цепочки отзыва.
 * @param {TReviewData} data - Данные отзыва для обновления.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const updateReview = createAsyncThunk<void, TReviewData, { rejectValue: TRejectResponseData }>(
  'reviews/updateReview',
  (data, { rejectWithValue, dispatch }) => {
    return reviewsApi
      .updateReview({
        templateId: String(data.id),
        data: reviewsMappers.mapReviewDataToSend(data),
      })
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(response.data.status);
        }
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(updateReview(data)), 100);
        }

        return rejectWithValue(error.response.data);
      });
  },
);

/**
 * Thunk-экшен для удаления цепочки отзыва.
 * @param {Object} data - Объект с данными для удаления цепочки отзыва.
 * @param {string} data.chainId - Идентификатор цепочки отзывов.
 * @returns {string | void} - Идентефикатор удаленной цепочки или void в случае ошибки.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const deleteReview = createAsyncThunk<
  string | void,
  { chainId: string },
  { rejectValue: TRejectResponseData }
>('reviews/deleteReview', (data, { dispatch, rejectWithValue }) => {
  const requestData = storageDb.getRequestData();
  return reviewsApi
    .deleteReviewById({ data: requestData, templateId: data.chainId })
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(response.data.status);
      }
      dispatch(setIdChainDeleted({ deleteChainId: data.chainId }));

      return data.chainId;
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(deleteReview({ chainId: data.chainId })), 100);
      }

      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для переключения активности цепочки отзывов.
 * @param {Object} data - Объект с данными для переключения активности цепочки отзывов.
 * @param {boolean} data.stepSwitchStatus - Статус переключения шага цепочки.
 * @param {string} data.templateId - Идентификатор шаблона отзыва.
 * @returns {{ templateId: string; stepSwitchStatus: boolean } | void} - Объект, содержащий идентификатор шаблона и статус переключения шага цепочки или void в случае ошибки.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const switchChainActivity = createAsyncThunk<
  { templateId: string; stepSwitchStatus: boolean } | void,
  { stepSwitchStatus: boolean; templateId: string },
  { rejectValue: TRejectResponseData }
>('reviews/switchChainActivity', (data, { dispatch, rejectWithValue }) => {
  const requestData = storageDb.getRequestData();
  return reviewsApi
    .switchReviewChain({
      data: requestData,
      templateId: data.templateId,
      stepSwitchStatus: data.stepSwitchStatus,
    })
    .then(response => {
      if (response.data.ok) {
        return {
          templateId: data.templateId,
          stepSwitchStatus: data.stepSwitchStatus,
        };
      }
      if (!response.data.ok) {
        throw new Error(response.data.status);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(
          () =>
            dispatch(
              switchChainActivity({
                templateId: data.templateId,
                stepSwitchStatus: data.stepSwitchStatus,
              }),
            ),
          100,
        );
      }

      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для переключения активности шага цепочки отзывов.
 * @param {Object} data - Объект с данными для переключения активности шага цепочки отзывов.
 * @param {boolean} data.switch - Флаг переключения активности шага цепочки.
 * @param {string} data.templateId - Идентификатор шаблона отзыва.
 * @param {string} data.stepId - Идентификатор шага цепочки.
 * @returns {{ templateId: string; stepNumber: string; switch: boolean } | void} - Объект, содержащий идентификатор шаблона, номер шага и флаг переключения активности шага цепочки или void в случае ошибки.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const switchChainStepActivity = createAsyncThunk<
  { templateId: string; stepNumber: string; switch: boolean } | void,
  { switch: boolean; templateId: string; stepId: string },
  { rejectValue: TRejectResponseData }
>('reviews/switchChainStepActivity', (data, { dispatch, rejectWithValue }) => {
  const requestData = storageDb.getRequestData();
  return reviewsApi
    .switchReviewChainStep({
      data: requestData,
      switch: data.switch,
      stepNumber: +data.stepId,
      templateId: data.templateId,
    })
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && response.data.ok) {
        return {
          templateId: data.templateId,
          stepNumber: data.stepId,
          switch: data.switch,
        };
      }

      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(response.data.status);
      }
    })
    .catch(error => {
      if (
        REJECT_RESPONSE_2_KEY.DETAIL in error.response.data &&
        error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED
      ) {
        setTimeout(
          () =>
            dispatch(
              switchChainStepActivity({
                switch: data.switch,
                templateId: data.templateId,
                stepId: data.stepId,
              }),
            ),
          100,
        );
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для удаления шага цепочки отзыва.
 * @param {Object} data - Объект с данными для удаления шага отзыва.
 * @param {string} data.templateId - Идентификатор шаблона отзыва.
 * @param {string} data.stepId - Идентификатор шага цепочки.
 * @returns {{ stepNumber: string; templateId: string } | void} - Объект, содержащий номер шага и идентификатор шаблона или void в случае ошибки.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const reviewStepDelete = createAsyncThunk<
  { stepNumber: string; templateId: string } | void,
  { templateId: string; stepId: string },
  { rejectValue: TRejectResponseData }
>('reviews/reviewStepDelete', (data, { dispatch, rejectWithValue }) => {
  const requestData = storageDb.getRequestData();
  return reviewsApi
    .deleteStepReview({
      data: requestData,
      stepNumber: +data.stepId,
      templateId: data.templateId,
    })
    .then(response => {
      if (response.data.ok) {
        return {
          templateId: data.templateId,
          stepNumber: data.stepId,
        };
      }

      if (!response.data.ok) {
        throw new Error(response.data.status);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(
          () => dispatch(reviewStepDelete({ stepId: data.stepId, templateId: data.templateId })),
          100,
        );
      }

      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для получения данных для выпадающего списка.
 * @param {Object} data - Объект с данными для получения данных для выпадающего списка.
 * @param {string} data.categoryName - Название категории.
 * @returns {{ data: TStaffListItem[]; categoryName: string } | void} - Объект, содержащий данные для выпадающего списка и название категории, или void в случае ошибки.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const getDropdownDataList = createAsyncThunk<
  { data: TStaffListItem[]; categoryName: string } | void,
  { categoryName: string },
  { rejectValue: TRejectResponseData }
>('reviews/getDropdownDataList', (data, { dispatch, rejectWithValue }) => {
  return reviewsApi
    .getDropdownList(data.categoryName)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (T_STAFF_LIST_RESPONSE_KEYS.COUNT in response.data) {
        return { data: response.data.data, categoryName: data.categoryName };
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getDropdownDataList(data)), 100);
      }
      return rejectWithValue(error.response.data);
    });
});

export {
  saveReview,
  deleteReview,
  updateReview,
  getReviewById,
  getReviewsList,
  reviewStepDelete,
  switchChainActivity,
  getDropdownDataList,
  switchChainStepActivity,
};
