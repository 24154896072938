import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//
import { createPortal } from 'react-dom';
import { TAppLanguage } from '@models/index';
import { useAppDispatch } from '@store/store';
import { ACTIVE_STEP_NUMBER } from '@const/mailing';
import { CalendarModal } from '@components/calendarModal';
import { SendOutDatePicker } from '@blocks/sendOutDatePicker';
import { ParsedClientFileInfo } from '@components/parsedClientFileInfo';
import { SetSendOutSendingType } from '@components/setSendOutSendingType';
import { getAllowedToRun, getCalendarDateThunk } from '@redux/mailing/mailingThunks';
import { TParsedXlsxFileData, TSendOutsData, TSendOutsDetailData } from '@redux/mailing/models';
import {
  setImmediateLaunch,
  setScheduledLaunch,
  setMailingStartTime,
  cancelSelectedStartDate,
  confirmSelectedStartDate,
} from '@redux/mailing/mailingSlice';

type TSetSendOutDateAndTimeProps = {
  /**
   * Флаг показывает возможно ли отправить рассылку немедленно
   * @param {boolean}
   */
  isImmediateStartAllowed: boolean;
  /**
   * Флаг отправки рассылки немедленно
   * @param {boolean}
   */
  isImmediateLaunch: boolean;
  /**
   * Флаг отправки рассылки по выбранной дате
   * @param {boolean}
   */
  isScheduledLaunch: boolean;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Данные о загруженном файле со списком клиентов
   * @param {TParsedXlsxFileData}
   */
  parsedXSLXFileData: TParsedXlsxFileData;
  /**
   * Дата начала отправки новой рассылки
   * @param {Date}
   */
  mailingStartDate: Date;
  /**
   * Флаг состояния модалки с календарем
   * @param {boolean}
   */
  isShowCalendar: boolean;
  /**
   * Время отправки рассылки
   * @param {string}
   */
  mailingStartTime: string;
  /**
   * Выбранный месяц
   * @param {number}
   */
  selectedMonth: number;
  /**
   * Выбранный год
   * @param {number}
   */
  selectedYear: number;
  /**
   * Массив рассылок для отображения в календаре
   * @param {TSendOutsData[]}
   */
  sendOuts: TSendOutsData[];
  /**
   * Отключает кнопку подтверждения выбранной даты
   * @param {boolean}
   */
  disableConfirmButton: boolean;
  /**
   * Максимальное количество сообщений в день
   * @param {number}
   */
  daySendOutLimit: number;
  /**
   * Минимально возможное время начала рассылки
   * @param {number}
   */
  minimalRecommendedStartTime: number;
  /**
   * Флаг показа модалки с данными о запущенных рассылках в выбранном дне
   * @param {boolean}
   */
  isShowSendOutDetailDataModal: boolean;
  /**
   * Выбранная в календаре дата для отображения данных рассылок в этот день
   * @param {Date}
   */
  dateForGetDetailData: Date;
  /**
   * Детальные данные рассылок за день
   * @param {TSendOutsDetailData[]}
   */
  sendOutDetailData: TSendOutsDetailData[];
  /**
   * Флаг состояния запроса за детальной информацией по рассылкам за день
   * @param {boolean}
   */
  isGettingSendOutDetailData: boolean;
  /**
   * Флаг проверки установленного времени
   * @param {boolean}
   */
  isChecking: boolean;
  /**
   * Флаг показа сообщения о невозможности запустить рассылку
   * @param {boolean}
   */
  isShowWarning: boolean;
  /**
   * Опциональный параметр отключает возможность изменения условий отправки
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const SetSendOutDateAndTime = memo(
  ({
    disabled,
    sendOuts,
    isChecking,
    appLanguage,
    selectedYear,
    selectedMonth,
    isShowWarning,
    isShowCalendar,
    daySendOutLimit,
    mailingStartTime,
    mailingStartDate,
    isImmediateLaunch,
    isScheduledLaunch,
    sendOutDetailData,
    parsedXSLXFileData,
    dateForGetDetailData,
    disableConfirmButton,
    isImmediateStartAllowed,
    isGettingSendOutDetailData,
    minimalRecommendedStartTime,
    isShowSendOutDetailDataModal,
    className = '',
  }: TSetSendOutDateAndTimeProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
      window.scroll({ top: 0, behavior: 'smooth' });
    }, []);

    // Обрабатывает выбор радио кнопки "Немедленный запуск"
    const setIsImmediateLaunchHandler = useCallback(() => {
      dispatch(setImmediateLaunch());
    }, [dispatch]);

    // Обрабатывает выбор радио кнопки "Запуск в запланированную дату"
    const setIsScheduledLaunchHandler = useCallback(() => {
      dispatch(setScheduledLaunch());
    }, [dispatch]);

    // Устанавливает время отправки рассылки
    const setMailingStartTimeHandler = useCallback(
      (newTime: string) => {
        const onSuccessAddCallback = () => {
          dispatch(setMailingStartTime(newTime));
        };

        const fallbackCallback = () => '';

        const startDate = mailingStartDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });

        const newStartDate = new Date(`${startDate} ${newTime}`);

        dispatch(
          getAllowedToRun({
            date: newStartDate,
            fallbackCallback,
            onSuccessAddCallback,
            isTimeCheck: true,
          }),
        );
      },
      [mailingStartDate, dispatch],
    );

    // Открывает календарь
    const showCalendarHandler = useCallback(() => {
      if (!disabled) {
        dispatch(getCalendarDateThunk());
      }
    }, [disabled, dispatch]);

    // Закрывает календарь
    const hideCalendarHandler = useCallback(() => {
      dispatch(cancelSelectedStartDate());
    }, [dispatch]);

    // "Подтверждает" выбранную дату и закрывает календарь
    const confirmSelectedStartDateHandler = useCallback(() => {
      dispatch(confirmSelectedStartDate());
    }, [dispatch]);

    return (
      <div className={className}>
        <ParsedClientFileInfo
          clientFileName=''
          appLanguage={appLanguage}
          clearClientFileHandler={() => ''}
          parsedXSLXFileData={parsedXSLXFileData}
          stepNumber={ACTIVE_STEP_NUMBER.THIRD_STEP}
          className='mb-4'
        />
        <h2 className='font-bold text-black text-h3Mobile tracking-[0.0075em] mb-4'>
          {t('MAILING_PAGE_TEXT.mailingStartTimeHeader')}
        </h2>
        <SetSendOutSendingType
          disabled={disabled}
          isImmediateLaunch={isImmediateLaunch}
          isScheduledLaunch={isScheduledLaunch}
          isImmediateStartAllowed={isImmediateStartAllowed}
          setIsImmediateLaunchHandler={setIsImmediateLaunchHandler}
          setIsScheduledLaunchHandler={setIsScheduledLaunchHandler}
          minimalRecommendedStartDate={new Date(minimalRecommendedStartTime)}
          className='mb-4'
        />
        {isScheduledLaunch ? (
          <SendOutDatePicker
            disabled={disabled}
            isChecking={isChecking}
            mailingStartTime={mailingStartTime}
            mailingStartDate={mailingStartDate}
            showCalendarHandler={showCalendarHandler}
            setMailingStartTimeHandler={setMailingStartTimeHandler}
            className='mb-4'
          />
        ) : null}
        {isShowCalendar
          ? createPortal(
              <CalendarModal
                sendOuts={sendOuts}
                appLanguage={appLanguage}
                selectedYear={selectedYear}
                isShowWarning={isShowWarning}
                selectedMonth={selectedMonth}
                daySendOutLimit={daySendOutLimit}
                hideCalendar={hideCalendarHandler}
                sendOutDetailData={sendOutDetailData}
                dateForGetDetailData={dateForGetDetailData}
                disableConfirmButton={disableConfirmButton}
                isGettingSendOutDetailData={isGettingSendOutDetailData}
                minimalRecommendedStartTime={minimalRecommendedStartTime}
                confirmSelectedStartDate={confirmSelectedStartDateHandler}
                isShowSendOutDetailDataModal={isShowSendOutDetailDataModal}
              />,
              document.body,
            )
          : null}
        {isShowCalendar
          ? createPortal(
              <div
                onClick={hideCalendarHandler}
                className='absolute top-0 right-0 bottom-0 left-0 bg-[#00000066] cursor-pointer z-[52]'
              />,
              document.body,
            )
          : null}
      </div>
    );
  },
);

SetSendOutDateAndTime.displayName = 'SetSendOutDateAndTime';
