import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@atoms/icon';
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';
import { numWord } from '@helpers/index';
import { TAppLanguage } from '@models/index';
import { EMPLOYEES, MONTH_WORDS } from '@const/pay';

type TGetBillModal = {
  /**
   * Номер филиала
   * @param {string}
   */
  branch: string;
  /**
   * Сумма за выбранный период
   * @param {number}
   */
  totalAmount: number;
  /**
   * Название филиала
   * @param {string}
   */
  filialName: string;
  /**
   * Сумма за месяц
   * @param {string}
   */
  monthAmount: string;
  /**
   * Количество сотрудников в филиале
   * @param {string}
   */
  branchEmployees: string;
  /**
   * Callback для закрытия модального окна
   * @param {() => void}
   */
  closeModalCallback: () => void;
  /**
   * Callback для обработки нажатия на кнопку "Скачать счет"
   * @param {() => void}
   */
  downloadBillCallback: () => void;
  /**
   * Callback для обработки нажатия на кнопку "Отправить в TG"
   * @param {() => void}
   */
  sendBillInTgCallback: () => void;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Флаг показывает состояние запроса для отправки счета в TG
   * @param {boolean}
   */
  isSendingInvoiceToTg: boolean;
};

export const GetBillModal = memo(
  ({
    branch,
    filialName,
    appLanguage,
    totalAmount,
    monthAmount,
    branchEmployees,
    closeModalCallback,
    downloadBillCallback,
    isSendingInvoiceToTg,
    sendBillInTgCallback,
  }: TGetBillModal) => {
    const { t } = useTranslation();

    const paymentPeriod = totalAmount / +monthAmount;

    // Формируем заголовок диалога
    const dialogTitle = t('PAY_PAGE_TEXT.billCreated', { totalAmount });

    // Формируем строку описания филиала
    const rate = t('PAY_PAGE_TEXT.billDownloadModalRate', {
      branchEmployees,
      employersText: numWord(+branchEmployees, EMPLOYEES[appLanguage]),
      monthAmount,
    });

    // Формируем строку описания сформированного счета
    const billDescription = t('PAY_PAGE_TEXT.billCreated2', {
      paymentPeriod,
      paymentPeriodText: numWord(paymentPeriod, MONTH_WORDS[appLanguage]),
      totalAmount,
    });

    return (
      <Dialog
        type='default'
        view='raised'
        color='default'
        title={dialogTitle}
        text={
          <div className='flex flex-col'>
            <span className='tracking-[0.0275em]'>
              {t('PAY_PAGE_TEXT.filial', { filialName, branch })}
            </span>
            <span className='tracking-[0.0275em]'>{rate}</span>
            <span className='font-medium tracking-[0.022em] mt-6'>{billDescription}</span>
          </div>
        }
        backdropOpacity={40}
        rightButton={
          <div className='flex flex-wrap'>
            <Button
              dense
              type='action'
              view='outlined'
              onClick={sendBillInTgCallback}
              loading={isSendingInvoiceToTg}
              icon={<Icon variant='smallTG' />}
              text={t('PAY_PAGE_TEXT.sendInTg')}
              className='mt-2 mr-6 w-[12rem]'
            />
            <Button
              dense
              type='action'
              view='filled'
              color='success'
              onClick={downloadBillCallback}
              text={t('PAY_PAGE_TEXT.downloadPdf')}
              icon={<Icon variant='downloadWhite' />}
              className='mt-2 w-[12rem]'
            />
          </div>
        }
        isOpen
        onClose={closeModalCallback}
        style={{ maxWidth: '42.5rem', width: '100%' }}
      />
    );
  },
);

GetBillModal.displayName = 'GetBillModal';
