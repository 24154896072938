import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { numWord } from '@helpers/index';
import { TAppLanguage } from '@models/index';
import { MONTH_WORDS, WEEK_WORDS } from '@const/pay';
import { TCardSelectCallbackToCard } from '@shared/types';

interface IPayCardProps {
  /**
   * Количество месяцев, по этому параметру рассчитывается цена
   * @param {string}
   */
  months: string;
  /**
   * Стоимость месяца с учетом бонусных недель
   * @param {TMonthData}
   */
  oneMonthCostWithDiscount: number;
  /**
   * Количество недель в в подарок
   * @param {string}
   */
  giftWeeks: string;
  /**
   * Флаг состояния автоплатежа
   * @param {boolean}
   */
  isAutoPayEnable: boolean;
  /**
   * Цена за один месяц
   * @param {number}
   */
  oneMonthCost: number;
  /**
   * Флаг состояния карточки
   * @param {boolean}
   */
  isSelected: boolean;
  /**
   * Callback для обработки onClick
   * @param {TCardSelectCallbackToCard}
   */
  onClickCallback: TCardSelectCallbackToCard;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const PayCard = memo(
  ({
    months,
    giftWeeks,
    isSelected,
    appLanguage,
    oneMonthCost,
    onClickCallback,
    isAutoPayEnable,
    oneMonthCostWithDiscount,
    className = '',
  }: IPayCardProps) => {
    const { t } = useTranslation();

    // Общая сумма к оплате
    const totalAmount = oneMonthCost * Number(months);

    const onClickHandler = useCallback(() => {
      onClickCallback(totalAmount, Number(months));
    }, [onClickCallback, months, totalAmount]);

    // Формируем строку с количеством месяцев
    const numberOfMonthText = `${months} ${numWord(Number(months), MONTH_WORDS[appLanguage])}`;

    // Формируем строку с общей суммой к оплате
    const totalAmountText = t('PAY_PAGE_TEXT.totalAmount', { oneMonthCostWithDiscount });

    const totalForAllMonth = t('PAY_PAGE_TEXT.totalForAllMonth', { totalAmount });

    // Формируем строку информации о количестве недель в подарок
    const giftText = t('PAY_PAGE_TEXT.giftText', {
      giftWeeks,
      weekText: numWord(Number(giftWeeks), WEEK_WORDS[appLanguage]),
    });

    const cardStyle = `flex flex-col justify-between hover:bg-aquaSpring rounded-lg h-[10.5rem] min-w-[12.875rem] w-full lg:w-fit ${
      isSelected && (isAutoPayEnable || +months !== 1)
        ? 'border border-green2 bg-aquaSpring px-[0.9375rem] py-[1.4375rem]'
        : 'bg-lightGray px-4 py-6'
    } cursor-pointer ${className}`;

    return (
      <div onClick={onClickHandler} className={cardStyle}>
        <div>
          <p className='font-medium text-small_text text-darkGray leading-5 mb-1'>
            {numberOfMonthText}
          </p>
          <p className='font-medium text-h6Mobile text-black leading-8 mb-1'>{totalAmountText}</p>
          {+months > 1 ? (
            <p className='font-medium text-small_text text-black leading-6'>{totalForAllMonth}</p>
          ) : null}
          {!isAutoPayEnable && +months === 1 ? (
            <p className='font-medium text-small_text text-red2 leading-6'>
              {t('PAY_PAGE_TEXT.withAutoPayments')}
            </p>
          ) : null}
        </div>
        {+giftWeeks > 0 ? (
          <p className='font-medium text-captionSmall text-green1'>{giftText}</p>
        ) : null}
      </div>
    );
  },
);

PayCard.displayName = 'PayCard';
