import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { storageDb } from '@api/storageApi';
import { NO_FILIAL_ERROR } from '@data/dict';
import { TRejectResponseData } from '@models/index';
import { TAccountsData } from '@redux/accounts/models';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { getAccounts } from '@redux/accounts/accountsThunks';
import { REJECT_RESPONSE_KEY, REJECT_RESPONSE_2_KEY } from '@api/types';
import { IInitialState, initialState } from '@redux/accounts/initialState';
import { consultantFilial, emptyBannerData } from '@redux/accounts/mockData';

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setSelectedFilial(
      state: IInitialState,
      action: PayloadAction<{
        accId: string;
        filialName: string;
        isSendOutAccount: boolean;
        rights: string[];
      }>,
    ) {
      const { accId, filialName, isSendOutAccount, rights } = action.payload;
      if (accId === NO_FILIAL_ERROR.TEXT && filialName === NO_FILIAL_ERROR.TEXT) {
        storageDb.setSelectedFilialData({ accId, filialName, isSendOutAccount });
      } else {
        storageDb.setSelectedFilialData({ accId, filialName, isSendOutAccount });
        storageDb.setRight(rights);
        [state.selectedFilial] = state.data.filter(item => item.accId === accId);
      }
    },
    setSelectedFilialInstanceStatus(state: IInitialState, action: PayloadAction<string>) {
      const selectedFilialAccId = state.selectedFilial?.accId;
      const selectedFilial = state.data.filter(item => item.accId === selectedFilialAccId)[0];
      const filialWithNewStatus = { ...selectedFilial, status: action.payload };
      state.data = state.data.map(item => {
        if (item.accId === selectedFilialAccId) {
          return filialWithNewStatus;
        }
        return item;
      });
      state.selectedFilial = filialWithNewStatus;
    },
    setIsAccessDenied(state: IInitialState) {
      state.isAccessDenied = true;
    },
    showNoFilialError(state: IInitialState) {
      state.isNoFilialError = true;
    },
    hideNoFilialError(state: IInitialState) {
      storageDb.setSelectedFilialData({
        accId: state.selectedFilial?.accId || '',
        filialName: state.selectedFilial?.address || '',
        isSendOutAccount: state.selectedFilial?.sendOutAccount || false,
      });
      state.isNoFilialError = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAccounts.pending, (state: IInitialState) => {
      state.isError = false;
      state.isLoading = true;
      state.selectedFilial = null;
    });
    builder.addCase(
      getAccounts.fulfilled,
      (state: IInitialState, action: PayloadAction<TAccountsData | void>) => {
        if (action.payload) {
          const { accountsData, consultantData, bannerData } = action.payload;

          if (accountsData.length) {
            state.data = accountsData;
          } else if (consultantData) {
            const newConsultantFilial = {
              ...consultantFilial,
              accId: consultantData?.promoCode,
              branch: consultantData?.promoCode,
            };

            state.selectedFilial = newConsultantFilial;
            state.data = [newConsultantFilial];
            state.consultantData = consultantData;
            state.isSalon = false;
          }

          state.consultantData = consultantData;
          state.bannerData = bannerData || emptyBannerData;

          if (accountsData.length && consultantData) {
            state.isSalon = false;
            state.isSalonConsultant = true;
          }

          if (accountsData.length > 1) {
            state.isLoading = false;
          }
        } else {
          state.isError = true;
        }
      },
    );
    builder.addCase(
      getAccounts.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
              state.isRetries = true;
              state.retries -= 1;
            } else {
              state.isError = true;
              state.isLoading = false;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
            state.isLoading = false;
          }
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      },
    );
  },
});

export const {
  hideNoFilialError,
  setIsAccessDenied,
  setSelectedFilial,
  showNoFilialError,
  setSelectedFilialInstanceStatus,
} = accountsSlice.actions;

export const accountsReducer = accountsSlice.reducer;
