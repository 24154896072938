import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { FormInput } from '@blocks/formInput';
import { EVENT_KEY_NAMES } from '@models/index';
import { VisitQuantity } from '@blocks/visitQuantity';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getTemplatePageData } from '@redux/template/selectors';
import { InputWithDropdownFromEP } from '@blocks/inputWithDropdownFromEP';
import { getInviteFriendsActionData } from '@redux/inviteFriendsAction/selectors';
import {
  TActionData,
  TIFBalloonsKeyNames,
  ACTION_DATA_KEY_NAMES,
} from '@redux/inviteFriendsAction/type';
import {
  addBalloonIF,
  deleteBalloonIF,
  setVisitQuantityIF,
  setNewBalloonValueIF,
  clearNewBalloonValueIF,
} from '@redux/inviteFriendsAction/inviteFriendSlice';
import {
  TOnChangeHandler,
  TAddBalloonOnBlur,
  TDeleteBalloonSetTP,
  TAddBalloonOnBlurHandler,
  TVisitQuantityOnChangeHandler,
} from '@shared/types';

type TInviteFriendsSendingConditionalsProps = {
  /**
   * Данные шаблона акции
   * @param {TActionData}
   */
  actionData: TActionData;
  /**
   * Флаг успешного получения данных
   * @param {boolean}
   */
  isFetchingSuccess: boolean;
  /**
   * Опциональны параметр доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const InviteFriendsSendingConditionals = memo(
  ({
    actionData,
    isFetchingSuccess,
    isEditTemplatesAccess,
    className = '',
  }: TInviteFriendsSendingConditionalsProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { visits, staff, services, blacklist, categories, clientCategory } = actionData;

    const [quantityFrom, quantityTo] = visits;

    const {
      staffNewValue,
      servicesNewValue,
      blacklistNewValue,
      categoriesNewValue,
      clientCategoryNewValue,
    } = useAppSelector(getInviteFriendsActionData);

    const { selectedFilial } = useAppSelector(getAccountsData);

    const { categoriesDataList } = useAppSelector(getTemplatePageData);

    // Обрабатывает изменение newBalloonValue
    const newBalloonValueHandler: TOnChangeHandler = useCallback(
      event => {
        const { name, value } = event.currentTarget;
        dispatch(setNewBalloonValueIF({ value, name: name as TIFBalloonsKeyNames }));
      },
      [dispatch],
    );

    // Очищает поле ввода newBalloonValue
    const clearNewBalloonValueHandler = useCallback(
      (name: string) => {
        dispatch(clearNewBalloonValueIF({ name: name as TIFBalloonsKeyNames }));
      },
      [dispatch],
    );

    // Удаляет "шарик" по его значению
    const deleteBalloon: TDeleteBalloonSetTP = useCallback(
      (value, keyName) => () => {
        const tKeyName = keyName as TIFBalloonsKeyNames;
        dispatch(deleteBalloonIF({ keyName: tKeyName, value }));
      },
      [dispatch],
    );

    // Добавляет новый по событию Blur шарик и очищает инпут
    const addBalloonOnBlurHandlerEvent: TAddBalloonOnBlur = useCallback(
      event => {
        const { name, value } = event.currentTarget;
        const keyName = name as TIFBalloonsKeyNames;
        dispatch(addBalloonIF({ keyName, value }));
      },
      [dispatch],
    );

    // Добавляет новый по событию Blur шарик и очищает инпут
    const addBalloonOnBlurHandler: TAddBalloonOnBlurHandler = useCallback(
      (name, value) => {
        const keyName = name as TIFBalloonsKeyNames;
        dispatch(addBalloonIF({ keyName, value }));
      },
      [dispatch],
    );

    // Добавляет новый по событию KeyUp шарик и очищает инпут
    const addBalloonByEnterKeyEvent = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === EVENT_KEY_NAMES.ENTER) {
          addBalloonOnBlurHandlerEvent(event);
        }
      },
      [addBalloonOnBlurHandlerEvent],
    );

    // Обрабатывает изменения количества визитов
    const visitQuantityOnChangeHandler: TVisitQuantityOnChangeHandler = useCallback(
      ({ newQuantityFromValue, newQuantityToValue }) => {
        dispatch(
          setVisitQuantityIF({
            quantityFrom: newQuantityFromValue,
            quantityTo: newQuantityToValue,
          }),
        );
      },
      [dispatch],
    );

    // // Обрабатывает клики на переключателе отправлять только при онлайн записи
    // const setOnlineRecordHandler: TSimpleOnchangeHandler = useCallback(
    //   event => {
    //     const { checked } = event.currentTarget;
    //
    //     dispatch(setOnlineRecord({ checked }));
    //   },
    //   [dispatch],
    // );

    return (
      <div className={className}>
        <p className='font-medium text-[1.25rem] text-black tracking-[0.004em] mb-4'>
          {t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.sendingConditionals')}
        </p>
        <InputWithDropdownFromEP
          addedValues={services}
          newValue={servicesNewValue}
          labelTextVariant='serviceIF'
          deleteBalloon={deleteBalloon}
          disabled={!isEditTemplatesAccess}
          isFetchingSuccess={isFetchingSuccess}
          categoriesDataList={categoriesDataList}
          inputId={ACTION_DATA_KEY_NAMES.SERVICES}
          addBalloonOnBlur={addBalloonOnBlurHandler}
          textColor='text-grayText tracking-[0.0275em]'
          selectedFilialAccId={selectedFilial?.accId || ''}
          className='mb-8'
        />
        <InputWithDropdownFromEP
          addedValues={categories}
          newValue={categoriesNewValue}
          deleteBalloon={deleteBalloon}
          labelTextVariant='categoriesIF'
          disabled={!isEditTemplatesAccess}
          isFetchingSuccess={isFetchingSuccess}
          categoriesDataList={categoriesDataList}
          addBalloonOnBlur={addBalloonOnBlurHandler}
          inputId={ACTION_DATA_KEY_NAMES.CATEGORIES}
          textColor='text-grayText tracking-[0.0275em]'
          selectedFilialAccId={selectedFilial?.accId || ''}
          className='mb-8'
        />
        <InputWithDropdownFromEP
          addedValues={staff}
          newValue={staffNewValue}
          labelTextVariant='staffIF'
          deleteBalloon={deleteBalloon}
          disabled={!isEditTemplatesAccess}
          isFetchingSuccess={isFetchingSuccess}
          inputId={ACTION_DATA_KEY_NAMES.STAFF}
          categoriesDataList={categoriesDataList}
          addBalloonOnBlur={addBalloonOnBlurHandler}
          textColor='text-grayText tracking-[0.0275em]'
          selectedFilialAccId={selectedFilial?.accId || ''}
          className='mb-8'
        />
        <InputWithDropdownFromEP
          addedValues={clientCategory}
          deleteBalloon={deleteBalloon}
          disabled={!isEditTemplatesAccess}
          newValue={clientCategoryNewValue}
          labelTextVariant='clientCategoryIF'
          isFetchingSuccess={isFetchingSuccess}
          categoriesDataList={categoriesDataList}
          addBalloonOnBlur={addBalloonOnBlurHandler}
          textColor='text-grayText tracking-[0.0275em]'
          inputId={ACTION_DATA_KEY_NAMES.CLIENT_CATEGORY}
          selectedFilialAccId={selectedFilial?.accId || ''}
          className='mb-8'
        />
        <VisitQuantity
          quantityFrom={quantityFrom}
          quantityTo={quantityTo}
          disabled={!isEditTemplatesAccess}
          textColor='text-grayText tracking-[0.0275em]'
          onChangeHandler={visitQuantityOnChangeHandler}
          className='mb-6'
        />
        <FormInput
          isShowInfoIcon
          value={blacklist}
          typeOfValueIsNumber
          variant='blacklistIF'
          newValue={blacklistNewValue}
          deleteBalloon={deleteBalloon}
          disabled={!isEditTemplatesAccess}
          setNewValue={newBalloonValueHandler}
          inputId={ACTION_DATA_KEY_NAMES.BLACKLIST}
          clearNewValue={clearNewBalloonValueHandler}
          textColor='text-grayText tracking-[0.004em]'
          addBalloonOnBlur={addBalloonOnBlurHandlerEvent}
          addBalloonByEnterKey={addBalloonByEnterKeyEvent}
          placeholder={t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.blacklistPlaceholder')}
          textSize='text-h3Mobile !text-black !mb-4'
          className='mt-10'
        />
        {/* <h2 className='font-medium text-blackText text-[1.25rem] leading-8 tracking-[0.004em] mb-4'>
        {t('TEMPLATE_PAGE_TEXT.additionalSendConditions')}
      </h2>
      <TemplateRadioSelect
        value={onlineRecord}
        disabled={!isEditTemplatesAccess}
        onChangeHandler={setOnlineRecordHandler}
        htmlFor={ACTION_DATA_KEY_NAMES.ONLINE_RECORD}
        headerText={t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.onlineRecordHeader')}
        descriptionText={t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.onlineRecordDescription')}
        className='mb-3'
      />
      <hr className='border-none bg-quartz1 h-[1px] mb-8' /> */}
      </div>
    );
  },
);

InviteFriendsSendingConditionals.displayName = 'InviteFriendsSendingConditionals';
