import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@atoms/icon';
import { Button } from '@uikit/Button';
import { Table } from '@uikit/Table/Table';
import { useAppDispatch } from '@store/store';
import { formatDateAndTime } from '@helpers/index';
import { SearchInputWithButton } from '@components/searchInputWithButton';
import { TTemplateDetailStatsData, TTemplatesDetailStatsTableFilter } from '@api/types';
import { sendTemplateDetailStatsToTGlThunk } from '@redux/templateStats/templateStatsThunks';
import { TemplatesDetailStatsSegmentSelect } from '@blocks/templatesDetailStatsSegmentSelect';
import {
  setTemplateDetailSort,
  setTemplateDetailFilter,
} from '@redux/templateStats/templateStatsSlice';

type TTemplatesDetailStatsTableProps = {
  /**
   * айди шаблона
   * @type {string}
   */
  id: string;
  /**
   * Данные таблицы по детальной статистике
   * @type {TTemplateDetailStatsData}
   */
  templateDetailStatsData: TTemplateDetailStatsData;
  /**
   * Данные фильтров таблицы детальной статистики
   * @type {TTemplatesDetailStatsTableFilter}
   */
  filter: TTemplatesDetailStatsTableFilter;
  /**
   * Массив версий для выпадающего меню фильтрации таблицы по версиям
   * @type {Record<string, string>[]}
   */
  versions: Record<string, string>[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};
export const TemplatesDetailStatsTable = memo(
  ({
    id,
    filter,
    versions,
    className = '',
    templateDetailStatsData,
  }: TTemplatesDetailStatsTableProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { value, filteredDetailTableDropdown } = filter;

    useEffect(() => {
      dispatch(
        setTemplateDetailFilter({
          filteredDetailTableDropdown: [
            ...templateDetailStatsData.map(({ version }) => String(version)),
            'all',
          ],
        }),
      );
    }, [dispatch, templateDetailStatsData]);

    // Массив заголовков таблицы
    const tableHeader = [
      {
        id: 'phone',
        label: t('TEMPLATE_PAGE_TEXT.statistics.table.templatesDetailStatsTable.phone'),
        noSort: true,
      },
      {
        id: 'name',
        label: t('TEMPLATE_PAGE_TEXT.statistics.table.templatesDetailStatsTable.name'),
      },
      {
        id: 'version',
        label: t('TEMPLATE_PAGE_TEXT.statistics.table.templatesDetailStatsTable.version'),
      },
      {
        id: 'createdAt',
        label: t('TEMPLATE_PAGE_TEXT.statistics.table.templatesDetailStatsTable.createdAt'),
      },
      {
        id: 'replyTime',
        label: t('TEMPLATE_PAGE_TEXT.statistics.table.templatesDetailStatsTable.replyTime'),
      },
      {
        id: 'recordTime',
        label: t('TEMPLATE_PAGE_TEXT.statistics.table.templatesDetailStatsTable.recordTime'),
      },
      {
        id: 'services',
        label: t('TEMPLATE_PAGE_TEXT.statistics.table.templatesDetailStatsTable.services'),
        noSort: true,
      },
      { id: 'sum', label: t('TEMPLATE_PAGE_TEXT.statistics.table.templatesDetailStatsTable.sum') },
    ];

    const renderTime = (data: [string, string]) => {
      const [date, time] = data;
      return (
        <>
          <span className='mr-2 text-stormGray'>{date}</span>
          <span className='text-blackText'>{time}</span>
        </>
      );
    };

    const renderServices = (services: string | string[]) => {
      if (Array.isArray(services)) {
        return (
          <div className='flex flex-col gap-5'>
            {services.map(service => (
              <div key={service}>{service}</div>
            ))}
          </div>
        );
      }

      return (
        <div className='flex flex-col gap-5'>
          <div key={services}>{services}</div>
        </div>
      );
    };

    const mapToTableData = useCallback((data: TTemplateDetailStatsData) => {
      if (data.length)
        return data.map(
          ({ phone, name, version, replyTime, createdAt, recordTime, services, sum }) => ({
            phone: phone || '-',
            name: name || '-',
            version: version || '-',
            createdAt: createdAt ? renderTime(formatDateAndTime(createdAt)) : '-', // TODO: fix send time
            replyTime: replyTime ? renderTime(formatDateAndTime(replyTime)) : '-',
            recordTime: recordTime ? renderTime(formatDateAndTime(recordTime)) : '-',
            services: services ? renderServices(services) : '-',
            sum: sum || '-',
          }),
        );
      return [
        {
          phone: '-',
          name: '-',
          version: '-',
          createdAt: '-',
          replyTime: '-',
          recordTime: '-',
          services: '-',
          sum: '-',
        },
      ];
    }, []);

    const sortHandler = useCallback(
      ({ columnName, order }: { columnName: string; order: string }) => {
        dispatch(setTemplateDetailSort({ columnName, order }));
      },
      [dispatch],
    );

    const downloadHandler = useCallback(
      () => dispatch(sendTemplateDetailStatsToTGlThunk(id)),
      [id, dispatch],
    );

    const setValueHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = event.target;
        if (/\d|\*/gm.test(inputValue)) {
          dispatch(setTemplateDetailFilter({ value: inputValue }));
        }
        if (!inputValue) {
          dispatch(setTemplateDetailFilter({ value: '' }));
        }
      },
      [dispatch],
    );

    const clearValueHandler = useCallback(
      () => dispatch(setTemplateDetailFilter({ value: '' })),
      [dispatch],
    );

    const getTableData = useMemo(
      () =>
        mapToTableData(templateDetailStatsData)
          .filter(versionFilter =>
            filteredDetailTableDropdown.includes(String(versionFilter.version)),
          )
          .filter(row => (value ? row.phone.includes(value) : true)),
      [templateDetailStatsData, value, mapToTableData, filteredDetailTableDropdown],
    );

    const onItemClickHandler = useCallback(
      (key: string) => {
        if (key === 'all') {
          if (filteredDetailTableDropdown.includes(key)) {
            dispatch(
              setTemplateDetailFilter({
                filteredDetailTableDropdown: [],
              }),
            );
            return;
          }
          dispatch(
            setTemplateDetailFilter({
              filteredDetailTableDropdown: [
                ...templateDetailStatsData.map(({ version }) => String(version)),
                'all',
              ],
            }),
          );
          return;
        }

        if (filteredDetailTableDropdown.includes(key)) {
          let newFilterData: string[] = [];

          if (filteredDetailTableDropdown.includes('all')) {
            newFilterData = filteredDetailTableDropdown.filter(
              item => item !== 'all' && item !== key,
            );
          } else {
            newFilterData = filteredDetailTableDropdown.filter(item => item !== key);
          }

          dispatch(
            setTemplateDetailFilter({
              filteredDetailTableDropdown: newFilterData,
            }),
          );
        } else {
          dispatch(
            setTemplateDetailFilter({
              filteredDetailTableDropdown: [...filteredDetailTableDropdown, key],
            }),
          );
        }
      },
      [dispatch, filteredDetailTableDropdown, templateDetailStatsData],
    );

    const totalSum = useMemo(
      () =>
        getTableData.reduce((previousValue, currentValue) => {
          return typeof currentValue.sum === 'number'
            ? previousValue + currentValue.sum
            : previousValue;
        }, 0),
      [getTableData],
    );
    return (
      <div className={`w-full ${className}`}>
        <h2 className='text-black text-h3Mobile font-medium mb-4'>
          {t('TEMPLATE_PAGE_TEXT.statistics.table.templatesDetailStatsTable.header')}
        </h2>
        <div className='hidden sm:flex flex-col md:flex-row justify-between px-1 mb-8'>
          <SearchInputWithButton
            hideSearchButton
            isChatHistoryPage
            searchString={value}
            disableSearchButton={false}
            startSearchHandler={() => ''}
            setSearchStringHandler={setValueHandler}
            clearSearchStringHandler={clearValueHandler}
            buttonText={t(
              'TEMPLATE_PAGE_TEXT.statistics.table.templatesDetailStatsTable.searchInput.searchButton',
            )}
            placeholder={t(
              'TEMPLATE_PAGE_TEXT.statistics.table.templatesDetailStatsTable.searchInput.placeholder',
            )}
            additionalInputDescription={t(
              'TEMPLATE_PAGE_TEXT.statistics.table.templatesDetailStatsTable.searchInput.description',
            )}
            className='pl-px mb-4 md:mb-0 md:max-w-[27rem]'
          />
          <Button
            fill
            dense
            type='action'
            view='outlined'
            color='default'
            onClick={downloadHandler}
            icon={<Icon variant='smallTG' />}
            text={t('TEMPLATE_PAGE_TEXT.statistics.table.templatesDetailStatsTable.sendToTG')}
            className='max-w-[12rem]'
          />
        </div>
        <div className='flex flex-col md:flex-row md:items-center justify-between mb-4'>
          <TemplatesDetailStatsSegmentSelect
            versions={versions}
            filtered={filteredDetailTableDropdown}
            onItemClickHandler={onItemClickHandler}
          />
          <h3 className='text-black text-h3Mobile font-medium mt-4 mb-6 sm:mb-0 md:mt-0'>
            {t('TEMPLATE_PAGE_TEXT.statistics.table.templatesDetailStatsTable.sumOfRecords', {
              totalSum,
            })}
          </h3>
        </div>
        <div className='hidden sm:block w-screen xl:max-w-7xl overflow-x-auto'>
          <Table
            name={tableHeader}
            data={getTableData}
            position={['left', 'left', 'left', 'right', 'right', 'right', 'right', 'right']}
            type='def'
            view='flat'
            color='default'
            pages
            rowsPerPageOptions={[5, 10, 20]}
            defRows={5}
            checkboxes={false}
            paperClassName='no_shadow'
            onSortCallback={sortHandler}
            style={{ boxShadow: 'none ', marginLeft: '6px' }}
            labelRowsPerPage={t('TEMPLATE_PAGE_TEXT.statistics.table.pagination')}
          />
        </div>
        <div className='sm:hidden'>
          <Button
            fill
            dense
            type='action'
            view='outlined'
            color='default'
            onClick={downloadHandler}
            icon={<Icon variant='download' />}
            text={t('TEMPLATE_PAGE_TEXT.statistics.table.templatesDetailStatsTable.sendToTG')}
          />
        </div>
      </div>
    );
  },
);
