import React, { memo } from 'react';
//
import { TSimpleStringObj } from '@models/index';
import { TMessages } from '@redux/connection/models';
import { TableTextBlock } from '@components/tableTextBlock';

interface IMobileConnectionTableCardProps {
  /**
   * Массив данных для отображение "шапки" карточки
   * @param {TSimpleStringObj[]}
   */
  head: TSimpleStringObj[];
  /**
   * Массив данных для отображение "тела" карточки
   * @param {TMessages[]}
   */
  data: TMessages[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const MobileConnectionTableCard = memo(
  ({ head, data, className = '' }: IMobileConnectionTableCardProps) => {
    const wrapperStyle = 'border-b border-quartz1 flex items-center py-2 min-h-[4rem]';
    const labelStyle = 'text-grayText px-2 w-[39%]';
    const dateTextStyle = 'px-2 w-[61%]';

    const [idHead, phoneHead, bodyHead] = head;

    const tableCardList = data.map((item, index) => {
      return (
        <div
          key={item.id}
          className={`border border-quartz flex flex-col p-4 rounded-lg ${
            index === data.length - 1 ? '' : 'mb-6'
          }`}>
          <div className={wrapperStyle}>
            <span className={labelStyle}>{idHead.label}</span>
            <span className={dateTextStyle}>{item.id}</span>
          </div>
          <div className={wrapperStyle}>
            <span className={labelStyle}>{phoneHead.label}</span>
            <span className={dateTextStyle}>{item.phone}</span>
          </div>
          <div className={wrapperStyle}>
            <span className={labelStyle}>{bodyHead.label}</span>
            <div className={dateTextStyle}>
              <TableTextBlock isMobile text={item.body} />
            </div>
          </div>
        </div>
      );
    });
    return <div className={`flex flex-col ${className}`}>{tableCardList}</div>;
  },
);

MobileConnectionTableCard.displayName = 'MobileConnectionTableCard';
