import { TBlackListData } from './models';

export interface IInitialState {
  data: TBlackListData[];
  isRefresh: boolean;
  isRetries: boolean;
  retries: number;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  isAddSuccess: boolean;
  isAddingNumber: boolean;
  isDeleteSuccess: boolean;
  isDeleteNumberError: boolean;
  isAddingNumberError: boolean;
  isAccessDenied: boolean;
  newNumber: string;
  numberToDelete: string;
  successMessage: string;
  errorMessage: string;
  filteredState: TBlackListData[];
  searchString: string;
}

export const initialState: IInitialState = {
  data: [],
  isRefresh: false,
  isRetries: false,
  retries: 1,
  isLoading: false,
  isFetching: false,
  isError: false,
  isAddSuccess: false,
  isAddingNumber: false,
  isDeleteSuccess: false,
  isDeleteNumberError: false,
  isAddingNumberError: false,
  isAccessDenied: false,
  errorMessage: '',
  successMessage: '',
  newNumber: '',
  numberToDelete: '',
  filteredState: [],
  searchString: '',
};
