import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
//
import isEqual from '@helpers/isEqual';
import { Button } from '@uikit/Button';
import { TAppLanguage } from '@models/index';
import { parseDivValue } from '@helpers/index';
import { EmojiClickData } from 'emoji-picker-react';
import { getSelectionOffset } from '@helpers/selection';
import { InputWithLabel } from '@blocks/inputWithLabel';
import { emojiPickerToggle } from '@redux/emoji/emojiSlice';
import { getAccountsData } from '@redux/accounts/selectors';
import { ToastsMessages } from '@components/toastsMessages';
import { GrayLabelWithInfo } from '@blocks/grayLabelWithInfo';
import { useAppDispatch, useAppSelector } from '@store/store';
import { sendFeedbackData } from '@redux/feedback/feedbackThunk';
import { InviteFriendsBonuses } from '@components/inviteFriendsBonuses';
import { TemplateAdditionalInfo } from '@blocks/templateAdditionalInfo';
import { InviteFriendsMessages } from '@components/inviteFriendsMessages';
import { InviteFriendsWhenSend } from '@components/inviteFriendsWhenSend';
import { InviteFriendsFeedback } from '@components/inviteFriendsFeedback';
import { getInviteFriendsActionData } from '@redux/inviteFriendsAction/selectors';
import { InviteFriendsActionHeader } from '@components/inviteFriendsActionHeader';
import { setFeedbackTextIF } from '@redux/inviteFriendList/inviteFriendListSlice';
import { BONUSES, FAST_FEEDBACK_BUTTON_TEXT, REQUIRED_KEY } from '@const/inviteFriend';
import { InviteFriendsSendingConditionals } from '@components/inviteFriendsSendingConditionals';
import {
  TActionData,
  TIFTextareaNames,
  ACTION_DATA_KEY_NAMES,
} from '@redux/inviteFriendsAction/type';
import {
  getCardTypesThunk,
  updateActionThunk,
  createNewActionThunk,
  getMinimalPriceThunk,
  copyActionToBranchThunk,
} from '@redux/inviteFriendsAction/inviteFriendsActionThunk';
import {
  wrapTextIF,
  setInputValues,
  addKeyInTextareaIF,
  clearAllErrorStateIF,
  clearAllSuccessStateIF,
  showNoRequiredKeyError,
  setAbandonedTextareaDataIF,
  hideWarningModal,
} from '@redux/inviteFriendsAction/inviteFriendSlice';
import {
  TClickHandler,
  TOnChangeHandler,
  TDivOnClickHandler,
  TDivOnKeyUpHandler,
  TOnEmojiViewChange,
  TSetEmojiInTextarea,
  TSetViewEmojiPicker,
  TOnChangeHandlerInputTextarea,
} from '@shared/types';
import { InviteFriendActivateModal } from '@components/inviteFriendActivateModal';

type TInviteFriendActionProps = {
  /**
   * Флаг отправки отзыва
   * @param {boolean}
   */
  isFeedbackSending: boolean;
  /**
   * Текст отзыва
   * @param {string}
   */
  feedbackText: string;
  /**
   * Флаг нового шаблона
   * @param {boolean}
   */
  isNewAction: boolean;
  /**
   * Опциональны параметр доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Данные шаблона акции
   * @param {TActionData}
   */
  actionData: TActionData;
  /**
   * Данные шаблона акции, эталонные до изменения, используются для отключения кнопки сохранения
   * @param {TActionData}
   */
  actionOldData: TActionData;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Выбранный вид быстрого отзыва
   * @param {string}
   */
  fastReview: string;
};

export const InviteFriendAction = memo(
  ({
    actionData,
    fastReview,
    isNewAction,
    appLanguage,
    feedbackText,
    actionOldData,
    isFeedbackSending,
    isEditTemplatesAccess = false,
  }: TInviteFriendActionProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(getCardTypesThunk());
      dispatch(getMinimalPriceThunk());
    }, [dispatch]);

    // Сохраняется выбранная пользователем div
    // для возвращения фокуса после добавления спец ключей или обрамления текста
    const div = useRef<(EventTarget & HTMLDivElement) | null>(null);

    const { data: accountsData, selectedFilial } = useAppSelector(getAccountsData);

    const {
      isSaving,
      isFetching,
      errorMessage,
      loyaltyCards,
      minimalPrice,
      successMessage,
      isShowWarningModal,
      blurFromTextareaName,
      inviteFriendsKeysDict,
      isCopyTemplatesSuccess,
    } = useAppSelector(getInviteFriendsActionData);

    const {
      id,
      title,
      active,
      cardType,
      oldTitle,
      whenSend,
      whenTime,
      bonusType,
      whenSend2,
      firstMessage,
      whenSend2Min,
      rangeOfVisits,
      loyaltyPoints,
      secondMessage,
      whenSend2Days,
      newCustomerPoints,
      interestRegularCustomer,
    } = actionData;

    const isFetchingSuccess = !isFetching && (!!id || isNewAction);

    // Обрабатывает изменения п инпуте
    const setInputValuesHandler: TOnChangeHandler = useCallback(
      event => {
        const { value, name } = event.currentTarget;
        dispatch(setInputValues({ name, value }));
      },
      [dispatch],
    );

    // Копирует шаблон акции в выбранные филиалы
    const copyActionTemplate = useCallback(
      (templateId: string) => (filials: string[]) => {
        dispatch(copyActionToBranchThunk({ templateId, branchList: filials }));
      },
      [dispatch],
    );

    // Устанавливает позицию курсора для добавления ключей или эмодзи
    const divOnClickHandler: TDivOnClickHandler = useCallback(
      event => {
        const {
          dataset: { name },
        } = event.currentTarget;

        const [start, end] = getSelectionOffset(event.currentTarget);

        div.current = event.currentTarget;

        dispatch(
          setAbandonedTextareaDataIF({
            selectionEnd: end,
            selectionStart: start,
            name: name as TIFTextareaNames,
          }),
        );
      },
      [dispatch],
    );

    // Устанавливает позицию курсора для добавления ключей или эмодзи
    const setCursorPositionHandler: TDivOnKeyUpHandler = useCallback(
      event => {
        const {
          dataset: { name },
        } = event.currentTarget;

        const [start, end] = getSelectionOffset(event.currentTarget);

        div.current = event.currentTarget;

        dispatch(
          setAbandonedTextareaDataIF({
            selectionEnd: end,
            selectionStart: start,
            name: name as TIFTextareaNames,
          }),
        );
      },
      [dispatch],
    );

    // Тоггл для открытия/закрытия блока с эмодзи
    const setViewEmojiPicker: TSetViewEmojiPicker = useCallback(
      ({ name, isOpenEmoji, divRef }) => {
        if (div.current) {
          div.current = divRef || null;
          dispatch(emojiPickerToggle({ isOpen: isOpenEmoji, name }));
        }
      },
      [dispatch],
    );

    // Добавляет эмодзи в выбранную textarea
    const setEmojiInTextarea: TSetEmojiInTextarea = useCallback(
      (emoji: EmojiClickData) => {
        if (blurFromTextareaName && div.current) {
          dispatch(
            addKeyInTextareaIF({ innerText: emoji.emoji, callback: parseDivValue(div.current) }),
          );
          div.current.focus();
        }
      },
      [blurFromTextareaName, dispatch],
    );

    // Callback для установки имени textarea в которой открыт EmojiPicker
    const onEmojiViewChange: TOnEmojiViewChange = useCallback(
      ({ textAreaName }) =>
        () => {
          if (div.current) {
            const [start, end] = getSelectionOffset(div.current);

            dispatch(
              setAbandonedTextareaDataIF({
                name: textAreaName as TIFTextareaNames,
                selectionStart: start,
                selectionEnd: end,
              }),
            );
          }
        },
      [dispatch],
    );

    // Callback передается в компонент <TemplateAdditionalInfo />
    // добавляет спец ключ в текс 'выбранной' textarea
    const setKeyInTextarea = useCallback(
      (key: string) => () => {
        if (blurFromTextareaName && div.current) {
          dispatch(addKeyInTextareaIF({ innerText: key, callback: parseDivValue(div.current) }));
        }
      },
      [blurFromTextareaName, dispatch],
    );

    // Оборачивает выделенный текст в выбранные спецсимволы
    const wrapSelectedTextInTextarea: TClickHandler = useCallback(
      event => {
        if (blurFromTextareaName && div.current) {
          // получаем выбранный спецсимвол вместе с примером текстом
          // и передаем в функцию для формирования новой строки передается первый символ
          const { innerText } = event.currentTarget;

          dispatch(wrapTextIF({ innerText, callback: parseDivValue(div.current) }));
        }
      },
      [blurFromTextareaName, dispatch],
    );

    const clearAllSuccessStateHandler = useCallback(() => {
      dispatch(clearAllSuccessStateIF());
    }, [dispatch]);

    const clearAllErrorStateHandler = useCallback(() => {
      dispatch(clearAllErrorStateIF());
    }, [dispatch]);

    // Создает новый шаблон или изменяет существующий
    const saveTemplateHandler = useCallback(
      (data: TActionData, actionId: string) => () => {
        if (!data.secondMessage.includes(REQUIRED_KEY)) {
          dispatch(showNoRequiredKeyError());
          return;
        }
        if (!actionId) {
          dispatch(createNewActionThunk(data));
        } else {
          dispatch(updateActionThunk(data));
        }
      },
      [dispatch],
    );

    // обрабатывает изменения значения в textarea
    const setFeedbackHandler: TOnChangeHandlerInputTextarea = useCallback(
      event => {
        dispatch(setFeedbackTextIF(event.currentTarget.value));
      },
      [dispatch],
    );

    // отправляет отзыв
    const sendFeedback = useCallback(
      (text: string) => (rate: number, showSuccessModalCallback: () => void) => {
        const textWithRate = `${text}\n\nRate: ${rate}`;

        dispatch(
          sendFeedbackData({
            file: null,
            topic: 'reference',
            filialName: selectedFilial?.address || '',
            text: textWithRate,
            showSuccessModalCallback,
          }),
        );
      },
      [selectedFilial?.address, dispatch],
    );

    const hideWarningModalHandler = useCallback(() => {
      dispatch(hideWarningModal());
    }, [dispatch]);

    const isTemplateNotChanged = isEqual(actionOldData, actionData);

    const isFormValid =
      !!title && !!firstMessage && !!secondMessage && bonusType !== BONUSES.NOT_SELECTED;

    const isSaveButtonDisabled = isTemplateNotChanged || !isEditTemplatesAccess || !isFormValid;

    const saveButtonText = id
      ? t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.saveTemplate')
      : t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.createTemplate');

    return (
      <div className='px-4 sm:px-0'>
        <InviteFriendsActionHeader
          id={id}
          active={active}
          oldTitle={oldTitle}
          accounts={accountsData}
          isTemplatesCopying={false}
          copyHandler={copyActionTemplate(id)}
          isTemplateNotChanged={isTemplateNotChanged}
          isEditTemplatesAccess={isEditTemplatesAccess}
          isCopyTemplatesSuccess={isCopyTemplatesSuccess}
        />
        <InviteFriendActivateModal
          isOpen={isShowWarningModal}
          hideModal={hideWarningModalHandler}
        />
        <div className='flex xl:flex-row-reverse'>
          <div className='hidden xl:flex flex-col pl-[3.7vw] w-[63%]'>
            <TemplateAdditionalInfo
              forMobile={false}
              setKeyInTextarea={setKeyInTextarea}
              specialKeyDict={inviteFriendsKeysDict}
              wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
              className='mb-6'
            />
            <InviteFriendsFeedback
              fastReview={fastReview}
              feedbackText={feedbackText}
              onChangeHandler={setFeedbackHandler}
              isFeedbackSending={isFeedbackSending}
              sendFeedback={sendFeedback(feedbackText)}
              fastFeedbackButtons={FAST_FEEDBACK_BUTTON_TEXT[appLanguage]}
            />
          </div>
          <div className='sm:pr-[7.5rem] xl:pr-0 sm:max-w-[54.5rem] w-full'>
            <div className='mb-10'>
              <InputWithLabel
                value={title}
                disabled={!isEditTemplatesAccess}
                name={ACTION_DATA_KEY_NAMES.TITLE}
                onChangeCallback={setInputValuesHandler}
                placeholderText={t(
                  'INVITE_FRIEND_PAGE_TEXT.actionTemplate.templateNamePlaceholder',
                )}
                labelComponent={
                  <GrayLabelWithInfo
                    isRequired
                    isFirstTooltip
                    text={t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.templateName')}
                  />
                }
              />
            </div>
            <InviteFriendsBonuses
              bonusType={bonusType}
              appLanguages={appLanguage}
              minimalPrice={minimalPrice}
              rangeOfVisits={rangeOfVisits}
              loyaltyPoints={loyaltyPoints}
              loyaltyCardData={loyaltyCards}
              selectedLoyaltyCard={cardType}
              newCustomerPoints={newCustomerPoints}
              isEditTemplatesAccess={isEditTemplatesAccess}
              interestRegularCustomer={interestRegularCustomer}
              className='mb-10'
            />
            <InviteFriendsWhenSend
              id={id}
              whenSend={whenSend}
              whenTime={whenTime}
              whenSend2={whenSend2}
              appLanguage={appLanguage}
              whenSend2Min={whenSend2Min}
              whenSend2Days={whenSend2Days}
              isEditTemplatesAccess={isEditTemplatesAccess}
              className='mb-10'
            />
            <InviteFriendsMessages
              appLanguage={appLanguage}
              firstMessage={firstMessage}
              secondMessage={secondMessage}
              onEmojiViewChange={onEmojiViewChange}
              divOnClickHandler={divOnClickHandler}
              setEmojiInTextarea={setEmojiInTextarea}
              setViewEmojiPicker={setViewEmojiPicker}
              isEditTemplatesAccess={isEditTemplatesAccess}
              setCursorPositionHandler={setCursorPositionHandler}
              className='mb-6'
            />
            <div className='flex xl:hidden flex-col mb-8'>
              <TemplateAdditionalInfo
                forMobile
                setKeyInTextarea={setKeyInTextarea}
                specialKeyDict={inviteFriendsKeysDict}
                wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
              />
            </div>
            <InviteFriendsSendingConditionals
              actionData={actionData}
              isFetchingSuccess={isFetchingSuccess}
              isEditTemplatesAccess={isEditTemplatesAccess}
              className='mb-10'
            />
            <div>
              <Button
                dense
                icon='tick'
                type='action'
                view='filled'
                color='success'
                loading={isSaving}
                text={saveButtonText}
                disabled={isSaveButtonDisabled}
                onClick={saveTemplateHandler(actionData, id)}
                className='w-fit'
              />
              <ToastsMessages
                errorMessage={errorMessage}
                successMessage={successMessage}
                isShowErrorToast={!!errorMessage}
                isShowSuccessToast={!!successMessage}
                callClearSuccessStatusCallbackTimeOut={2000}
                clearErrorCallback={clearAllErrorStateHandler}
                clearSuccessStatusCallback={clearAllSuccessStateHandler}
              />
            </div>
            <div className='lg:hidden my-10 w-full'>
              <InviteFriendsFeedback
                fastReview={fastReview}
                feedbackText={feedbackText}
                onChangeHandler={setFeedbackHandler}
                isFeedbackSending={isFeedbackSending}
                sendFeedback={sendFeedback(feedbackText)}
                fastFeedbackButtons={FAST_FEEDBACK_BUTTON_TEXT[appLanguage]}
                className='w-full'
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

InviteFriendAction.displayName = 'InviteFriendAction';
