import React, { memo } from 'react';
//
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { TKeyboardClickEvent, TOnChangeHandler } from '@shared/types';

type TBlackListInputProps = {
  /**
   * Значение для элемента input
   * @param {string}
   */
  value: string;
  /**
   * Значение placeholder в input
   * @param {string}
   */
  placeholder: string;
  /**
   * Callback для обработки события change для элемента input
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Опциональный callback для добавления номер по нажатию на кнопку Enter
   * @param {TKeyboardClickEvent}
   */
  onKeyDownHandler?: TKeyboardClickEvent;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const BlackListInput = memo(
  ({
    value,
    placeholder,
    onChangeHandler,
    onKeyDownHandler,
    className = '',
  }: TBlackListInputProps) => (
    <TextInput
      type='text'
      value={value}
      view='outlined'
      color='default'
      placeholder={placeholder}
      onChange={onChangeHandler}
      onKeyDown={onKeyDownHandler}
      className={`!h-12 !max-w-[21.25rem] !w-full ${className}`}
    />
  ),
);

BlackListInput.displayName = 'BlackListInput';
