import React, { memo } from 'react';
//
import { THelpKeys } from '@models/index';
import { TOnChangeHandler } from '@shared/types';
import { SettingsCheckbox } from '@blocks/settingsCheckbox';
import { SETTINGS_DATA_KEY_NAMES } from '@redux/settings/models';
import { YclientsChatEnableModal } from '@components/yclientsChatEnableModal';

type TSettingsCheckboxComponentProps = {
  /**
   * Флаг показа модалки для подтверждения включения чата
   * @param {boolean}
   */
  isShowChatEnableModal: boolean;
  /**
   * Состояние чекбокса Включить чат в журнале Yclients
   * @param {boolean}
   */
  enableChat: boolean;
  /**
   * Состояние чекбокса Проверка на ответ
   * @param {boolean}
   */
  replyMsgCheck: boolean;
  /**
   * Состояние чекбокса Отвечать на сообщения в телеграм
   * @param {boolean}
   */
  enableReplyInBot: boolean;
  /**
   * Состояние чекбокса Отправлять коды подтверждения в СМС
   * @param {boolean}
   */
  smsConfirm: boolean;
  /**
   * Состояние чекбокса Отправка контакта
   * @param {boolean}
   */
  sendContact: boolean;
  /**
   * Callback для обработки нажатий на чекбоксы
   * @param{TOnChangeHandler}
   */
  onChangeSelectHandler: TOnChangeHandler;
  /**
   * Callback для закрытия модалки
   * @param {() => void}
   */
  closeModalCallback: () => void;
  /**
   * Callback для подтверждения включения чата в журнале Yclients
   * @param {() => void}
   */
  enableChatCallback: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const SettingsCheckboxComponent = memo(
  ({
    enableChat,
    smsConfirm,
    sendContact,
    replyMsgCheck,
    enableReplyInBot,
    enableChatCallback,
    closeModalCallback,
    onChangeSelectHandler,
    isShowChatEnableModal,
    className = '',
  }: TSettingsCheckboxComponentProps) => {
    const checkBoxData = [
      {
        isFirstTooltip: true,
        checked: enableChat,
        variant: SETTINGS_DATA_KEY_NAMES.ENABLE_CHAT_YCLIENTS,
        classNameText: '!mr-1',
      },
      {
        isFirstTooltip: false,
        checked: replyMsgCheck,
        variant: SETTINGS_DATA_KEY_NAMES.REPLAY_MESSAGE_CHECK,
        classNameText: '!mr-1',
      },
      {
        isFirstTooltip: false,
        checked: enableReplyInBot,
        variant: SETTINGS_DATA_KEY_NAMES.ENABLE_REPLAY_IN_BOT,
        classNameText: '!mr-1',
      },
      {
        isFirstTooltip: false,
        checked: smsConfirm,
        variant: SETTINGS_DATA_KEY_NAMES.SMS_CONFIRM,
        classNameText: '!mr-1',
      },
      {
        isFirstTooltip: false,
        checked: sendContact,
        variant: SETTINGS_DATA_KEY_NAMES.SEND_CONTACT,
        classNameText: '!mr-1',
      },
    ];

    const checkBosToRender = checkBoxData.map(
      ({ checked, classNameText, isFirstTooltip, variant }, index) => {
        const isLastElement = index === checkBoxData.length - 1;
        return (
          <div key={variant} className={!isLastElement ? 'mb-2' : ''}>
            <SettingsCheckbox
              checked={checked}
              htmlFor={variant}
              variant={variant as THelpKeys}
              isFirstTooltip={isFirstTooltip}
              onChangeHandler={onChangeSelectHandler}
              className={classNameText}
            />
          </div>
        );
      },
    );

    return (
      <div className={`flex flex-col ${className}`}>
        {checkBosToRender}
        <YclientsChatEnableModal
          enableChatCallback={enableChatCallback}
          closeModalCallback={closeModalCallback}
          isShowChatEnableModal={isShowChatEnableModal}
        />
      </div>
    );
  },
);

SettingsCheckboxComponent.displayName = 'SettingsCheckboxComponent';
