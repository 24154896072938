import { numberOfMonth } from '@const/pay';
import { TPayData } from '@redux/pay/models';
import { TPayResponseData } from '@api/types';

/**
 * Преобразует данные ответа API в структуру данных для хранилища Redux.
 * @param {TPayResponseData} response - Данные ответа API.
 * @returns {TPayData} - Преобразованные данные для хранилища Redux.
 */
const mapResponseDateToStore = ({
  branch,
  branch_employees,
  amount,
  autopay,
  last_4,
  pay_status,
  pay_date,
  links,
  payments,
  create_bill,
  review_banner,
  month_cost,
  bonus_week,
  discount,
}: TPayResponseData): TPayData => {
  const payLinks = Object.values(links).filter(link => !!link);

  return {
    branch,
    branchEmployees: branch_employees,
    amount,
    isAutoPayEnable: autopay,
    cardLastFourDigits: last_4,
    payStatus: pay_status,
    payTillDate: pay_date,
    paymentData: payments.map(({ date, period, summ }) => ({
      date,
      period,
      sum: Number(summ),
    })),
    payLinks,
    numberOfMonth,
    numberOfGiftWeek: Object.values(bonus_week).map(item => String(item)),
    createBill: create_bill,
    reviewBanner: review_banner,
    bonusWeek: bonus_week,
    monthCost: month_cost,
    discount: discount * 100,
  };
};

export { mapResponseDateToStore };
