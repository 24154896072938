import React, { memo } from 'react';
//
import { TAppLanguage } from '@models/index';
import { TClickHandler } from '@shared/types';
import { SendSendOut } from '@components/sendSendOut';
import { ImportantMailingInfo } from '@blocks/importantMailingInfo';
import { SetSendOutDateAndTime } from '@components/setSendOutDateAndTime';
import {
  TCustomerKeys,
  TSendOutsData,
  TParsedXlsxFileData,
  TSendOutsDetailData,
} from '@redux/mailing/models';

interface IMailingStep3Props {
  /**
   * Callback для запуска рассылки
   * @param {() => void}
   */
  startMailing: () => void;
  /**
   * Флаг состояния запроса на запуск рассылки
   * @param {boolean}
   */
  isStartingSendOut: boolean;
  /**
   * Флаг состояния запроса на запуск рассылки
   * @param {boolean}
   */
  isTestMessageSending: boolean;
  /**
   * Callback для отправки тестового сообщения
   * @param {() => void}
   */
  sendTestMessageHandler: () => void;
  /**
   * Callback для добавления ключа в текст
   * @param {(key: string) => () => void}
   */
  setKeyInTextarea: (key: string) => () => void;
  /**
   * Callback для оборачивания выделенного текста в символы форматирования
   * @param {TClickHandler}
   */
  wrapSelectedTextInTextarea: TClickHandler;
  /**
   * Callback для открытия drawer с примерами шаблонов
   * @param {() => void}
   */
  openDrawer: () => void;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Данные о загруженном файле со списком клиентов
   * @param {TParsedXlsxFileData}
   */
  parsedXSLXFileData: TParsedXlsxFileData;
  /**
   * Флаг показывает возможно ли отправить рассылку немедленно
   * @param {boolean}
   */
  isImmediateStartAllowed: boolean;
  /**
   * Флаг отправки рассылки немедленно
   * @param {boolean}
   */
  isImmediateLaunch: boolean;
  /**
   * Флаг отправки рассылки по выбранной дате
   * @param {boolean}
   */
  isScheduledLaunch: boolean;
  /**
   * Дата начала отправки новой рассылки
   * @param {Date}
   */
  mailingStartDate: Date;
  /**
   * Флаг состояния модалки с календарем
   * @param {boolean}
   */
  isShowCalendar: boolean;
  /**
   * Время отправки рассылки
   * @param {string}
   */
  mailingStartTime: string;
  /**
   * Выбранный месяц
   * @param {number}
   */
  selectedMonth: number;
  /**
   * Выбранный год
   * @param {number}
   */
  selectedYear: number;
  /**
   * Массив рассылок для отображения в календаре
   * @param {TSendOutsData[]}
   */
  sendOuts: TSendOutsData[];
  /**
   * Флаг отключения кнопки начала рассылки
   * @param {boolean}
   */
  isDisableStartSendOutButton: boolean;
  /**
   * Максимальное количество сообщений в день
   * @param {number}
   */
  daySendOutLimit: number;
  /**
   * Минимально возможное время начала рассылки
   * @param {number}
   */
  minimalRecommendedStartTime: number;
  /**
   * Флаг показа модалки с данными о запущенных рассылках в выбранном дне
   * @param {boolean}
   */
  isShowSendOutDetailDataModal: boolean;
  /**
   * Выбранная в календаре дата для отображения данных рассылок в этот день
   * @param {Date}
   */
  dateForGetDetailData: Date;
  /**
   * Детальные данные рассылок за день
   * @param {TSendOutsDetailData[]}
   */
  sendOutDetailData: TSendOutsDetailData[];
  /**
   * Флаг состояния запроса за детальной информацией по рассылкам за день
   * @param {boolean}
   */
  isGettingSendOutDetailData: boolean;
  /**
   * Флаг проверки установленного времени
   * @param {boolean}
   */
  isChecking: boolean;
  /**
   * Флаг показа сообщения о невозможности запустить рассылку
   * @param {boolean}
   */
  isShowWarning: boolean;
  /**
   * Пользовательские ключи полученные после парсинга файла с данными клиентов
   * @param {TCustomerKeys}
   */
  customerKeys: TCustomerKeys;
  /**
   * Опциональный параметр отключает возможность запуска рассылок
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const MailingStep3 = memo(
  ({
    sendOuts,
    disabled,
    openDrawer,
    isChecking,
    appLanguage,
    selectedYear,
    customerKeys,
    startMailing,
    isShowWarning,
    selectedMonth,
    isShowCalendar,
    daySendOutLimit,
    mailingStartTime,
    mailingStartDate,
    setKeyInTextarea,
    isImmediateLaunch,
    isScheduledLaunch,
    isStartingSendOut,
    sendOutDetailData,
    parsedXSLXFileData,
    isTestMessageSending,
    dateForGetDetailData,
    sendTestMessageHandler,
    isImmediateStartAllowed,
    isGettingSendOutDetailData,
    wrapSelectedTextInTextarea,
    isDisableStartSendOutButton,
    minimalRecommendedStartTime,
    isShowSendOutDetailDataModal,
    className = '',
  }: IMailingStep3Props) => {
    return (
      <div className={className}>
        <SetSendOutDateAndTime
          sendOuts={sendOuts}
          disabled={disabled}
          isChecking={isChecking}
          appLanguage={appLanguage}
          selectedYear={selectedYear}
          isShowWarning={isShowWarning}
          selectedMonth={selectedMonth}
          isShowCalendar={isShowCalendar}
          daySendOutLimit={daySendOutLimit}
          mailingStartDate={mailingStartDate}
          mailingStartTime={mailingStartTime}
          isImmediateLaunch={isImmediateLaunch}
          isScheduledLaunch={isScheduledLaunch}
          sendOutDetailData={sendOutDetailData}
          parsedXSLXFileData={parsedXSLXFileData}
          dateForGetDetailData={dateForGetDetailData}
          isImmediateStartAllowed={isImmediateStartAllowed}
          disableConfirmButton={isDisableStartSendOutButton}
          isGettingSendOutDetailData={isGettingSendOutDetailData}
          minimalRecommendedStartTime={minimalRecommendedStartTime}
          isShowSendOutDetailDataModal={isShowSendOutDetailDataModal}
          className='mb-8'
        />
        <SendSendOut
          disabled={disabled}
          startMailing={startMailing}
          isScheduledLaunch={isScheduledLaunch}
          isStartingSendOut={isStartingSendOut}
          isTestMessageSending={isTestMessageSending}
          sendTestMessageHandler={sendTestMessageHandler}
          isDisableStartSendOutButton={isDisableStartSendOutButton}
        />
        <ImportantMailingInfo
          isMobileView
          disableOpenDrawerButton
          openDrawer={openDrawer}
          appLanguage={appLanguage}
          isShowExampleBlock={false}
          customerKeys={customerKeys}
          isShowAdditionalInfo={false}
          setKeyInTextarea={setKeyInTextarea}
          wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
          className='mt-8 xl:hidden'
        />
      </div>
    );
  },
);

MailingStep3.displayName = 'MailingStep3';
