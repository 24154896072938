import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Menu } from '@uikit/Menu';
import { Dropdown } from '@uikit/Dropdown';
import { FormLabel } from '@blocks/formLabel';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { SendTime } from '@components/sendTime';
import { DayAndMinutes } from '@components/dayAndMinutes';
import { BirthdayWhenSend2 } from '@blocks/birthdayWhenSend2';
import { AllDayRecWhenSend2 } from '@blocks/allDayRecWhenSend2';
import { TEMPLATE_DATA_KEY_NAMES } from '@redux/template/models';
import { TOnChangeHandler, TSetWhenSend2Days } from '@shared/types';
import { SEND_TIME_INCREASE_STEP, WHEN_SEND_VALUE } from '@const/templates';
import { THelpKeys, TSimpleStringObj, TSimpleStringObjLevel2 } from '@models/index';

interface IWhenSendProps {
  /**
   * Параметр показывает/скрывает знак обязательного параметра "*"
   * @param {boolean}
   */
  isRequired: boolean;
  /**
   * Флаг нового шаблона, влияет на список доступных опций в первом выпадающем списке
   * @param {boolean}
   */
  isNewTemplate: boolean;
  /**
   * Значение дропдауна whenSend
   * @param {string}
   */
  whenSend: string;
  /**
   * Значение дропдауна whenSend2
   * @param {string}
   */
  whenSend2: string;
  /**
   * Значение timePicker whenTime в формате HH:MM
   * @param {string}
   */
  whenTime: string;
  /**
   * Callback для изменения параметра whenSend
   * @param {(name: string) => void;}
   */
  setWhenSend: (name: string) => () => void;
  /**
   * Callback для изменения параметра whenSend2
   * @param {(name: string) => void;}
   */
  setWhenSend2: (name: string) => () => void;
  /**
   * Callback для изменения параметра whenTime
   * @param {(name: string) => void;}
   */
  setWhenTime: (name: string) => void;
  /**
   * Через сколько дней отправлять шаблон
   * @param {string}
   */
  whenSend2Days: string;
  /**
   * Callback для изменения whenSend2Days
   * @param {TSetWhenSend2Days}
   */
  setWhenSend2Days: TSetWhenSend2Days;
  /**
   * Через сколько минут отправлять шаблон
   * @param {string}
   */
  whenSend2Minutes: string;
  /**
   * Callback для изменения whenSend2Minutes, обрабатывает блок ввода
   * @param {TOnChangeHandler}
   */
  setWhenSend2MinutesOnChangeHandler: TOnChangeHandler;
  /**
   * Callback для изменения whenSend2Minutes, обрабатывает нажатия на элементы выпадающего списка
   * @param {(minutes: string) => () => void}
   */
  setWhenSend2MinutesHandler: (minutes: string) => () => void;
  /**
   * Объект с данными для формирования опций в выпадающем списке
   * @param {TSimpleStringObjLevel2}
   */
  whenSendSelectOption: TSimpleStringObj;
  /**
   * Объект с данными для формирования опций в выпадающем списке
   * @param {TSimpleStringObjLevel2}
   */
  whenSend2SelectOption: TSimpleStringObjLevel2;
  /**
   * Колбэк который срабатывает при закрытии выпадающего списка с минутами
   * @param {() => void}
   */
  onBlurCallback: () => void;
  /**
   * Опциональный параметр, если true при первом визите на страницу будет показан обучающий попап
   * @param {boolean}
   */
  isFirstTooltip?: boolean;
  /**
   * Опциональный параметр tailwind класс для изменения цвета текста label
   * @param {string}
   * @default
   */
  labelTextColor?: string;
  /**
   * Опциональный параметр tailwind класс для изменения цвет знака *
   * @param {string}
   * @default
   */
  requiredSignColor?: string;
  /**
   * Опциональный параметр включение disabled
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
  /**
   * Вариант текста в label, берется по ключу из словаря
   * @param {THelpKeys}
   */
  labelTextVariant?: THelpKeys;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const WhenSend = memo(
  ({
    whenSend,
    whenTime,
    whenSend2,
    isRequired,
    setWhenSend,
    setWhenTime,
    setWhenSend2,
    onBlurCallback,
    labelTextColor,
    labelTextVariant,
    whenSend2Minutes,
    // eslint-disable-next-line
    isNewTemplate,
    whenSend2Days,
    isFirstTooltip,
    setWhenSend2Days,
    disabled = false,
    requiredSignColor,
    whenSendSelectOption,
    whenSend2SelectOption,
    setWhenSend2MinutesHandler,
    setWhenSend2MinutesOnChangeHandler,
    className = '',
  }: IWhenSendProps) => {
    const { t } = useTranslation();

    // формируем список опций для дропдауна whenSend
    const whenSendOptionList = useMemo(
      () =>
        Object.entries(whenSendSelectOption).map(item => (
          <MenuItem
            key={item[0]}
            text={<span>{item[1]}</span>}
            onClick={setWhenSend(item[0])}
            className={whenSend === item[0] ? '!bg-ivory' : ''}
          />
        )),
      [whenSend, setWhenSend, whenSendSelectOption],
    );

    // Получаем текст выбранного пункта для dropdown
    const selectedWhenSendOptionText = useMemo(
      () => whenSendSelectOption[whenSend] || t('TEMPLATE_PAGE_TEXT.whenSendSelect'),
      [t, whenSend, whenSendSelectOption],
    );

    // Определяет, когда показать блок тем у кого запись
    const isShowAllDayRecWhenSend2 =
      whenSend === WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION ||
      whenSend === WHEN_SEND_VALUE.BIRTHDAY ||
      whenSend === WHEN_SEND_VALUE.EVENT;

    // Определяет, когда показать блок опций отправки поздравления на ДР
    const isShowBirthdayWhenSend2 = whenSend === WHEN_SEND_VALUE.BIRTHDAY && +whenSend2 !== 0;

    // Определяет, когда показать блок с днем и временем отправки шаблона
    const isShowDayAndMinutes =
      whenSend === WHEN_SEND_VALUE.AFTER_END || whenSend === WHEN_SEND_VALUE.BEFORE_START;

    // Определяет, когда показать блок настроек времени отправления
    const isShowSendTime = whenSend === WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION;

    return (
      <div className={`flex flex-col ${className}`}>
        <FormLabel
          isRequired={isRequired}
          textColor={labelTextColor}
          isFirstTooltip={isFirstTooltip}
          requiredSignColor={requiredSignColor}
          htmlFor={TEMPLATE_DATA_KEY_NAMES.WHEN_SEND}
          labelTextVariant={labelTextVariant || TEMPLATE_DATA_KEY_NAMES.WHEN_SEND}
          fontSize='text-h3Mobile leading-8'
        />
        <div
          className={`flex flex-wrap sm:flex-nowrap mt-2 ${
            isShowAllDayRecWhenSend2 ? 'justify-between' : 'justify-start'
          }`}>
          <div className='mt-2 sm:max-w-[15rem] w-full'>
            <Dropdown
              color='default'
              view='outlined'
              disabled={disabled}
              position='bottom-left'
              text={
                <span className={disabled ? 'cursor-not-allowed text-start w-full' : ''}>
                  {selectedWhenSendOptionText}
                </span>
              }
              content={
                <Menu
                  className={whenSendOptionList.length === 1 ? 'one_item_ul' : ''}
                  view='raised'>
                  {whenSendOptionList}
                </Menu>
              }
              className='when_send_dropdown min-w-[15rem] w-full'
            />
          </div>
          {isShowAllDayRecWhenSend2 ? (
            <AllDayRecWhenSend2
              disabled={disabled}
              whenSend={whenSend}
              whenSend2={whenSend2}
              setWhenSend2={setWhenSend2}
              whenSend2Days={whenSend2Days}
              setWhenSend2Days={setWhenSend2Days}
              whenSend2SelectOption={whenSend2SelectOption}
              className='sm:ml-2 sm:mt-0 sm:max-w-[33.5rem]'
            />
          ) : null}
          {isShowBirthdayWhenSend2 ? (
            <BirthdayWhenSend2
              disabled={disabled}
              whenSend2={whenSend2}
              whenSend2Days={whenSend2Days}
              setWhenSend2Days={setWhenSend2Days}
              className='sm:ml-[1.625rem] mt-2'
            />
          ) : null}
          {isShowDayAndMinutes ? (
            <DayAndMinutes
              disabled={disabled}
              whenSend={whenSend}
              whenSend2Days={whenSend2Days}
              onBlurCallback={onBlurCallback}
              whenSend2Minutes={whenSend2Minutes}
              setWhenSend2Days={setWhenSend2Days}
              setWhenSend2MinHandler={setWhenSend2MinutesHandler}
              setWhenSend2MinOnChangeHandler={setWhenSend2MinutesOnChangeHandler}
              className='mt-2 sm:ml-5'
            />
          ) : null}
        </div>
        {isShowSendTime ? (
          <div className='flex items-center mt-4'>
            <span className='mr-1'>{t('TEMPLATE_PAGE_TEXT.whenSendSend')}</span>
            <SendTime
              endMinutes={59}
              startMinutes={0}
              disabled={disabled}
              sendingTime={whenTime}
              setSendingTime={setWhenTime}
              minIncreaseStep={SEND_TIME_INCREASE_STEP[whenSend]}
            />
          </div>
        ) : null}
      </div>
    );
  },
);

WhenSend.displayName = 'WhenSend';
