import React from 'react';
//
import { monthsName } from '@const/partners';
import { TGetRequestData } from '@api/types';
import { QUERY_PARAMS } from '@const/apiConstants';
import { WHEN_SEND_VALUE } from '@const/templates';
import { DAYS, SORTING_TYPES } from '@const/common';
import { getInnerHtml } from '@helpers/contentMaker';
import { weekDaysGetDayFormat } from '@const/mailing';
import { setSelectionOffset } from '@helpers/selection';
import { WHEN_SEND_VALUE_REVIEWS } from '@const/reviews';
import { TMessageFileData } from '@redux/chatHistory/models';
import { TTemplateStatisticTableData } from '@redux/templateStats/models';
import { arrChatAudio, arrChatImage, arrChatVideo } from '@const/chatHistory';
import {
  TSendOutsData,
  TSendOutElement,
  TCreateParsedFileInfo,
  TGetParsedXlsxFileInfo,
} from '@redux/mailing/models';
import {
  TGetStatusText,
  TParseDivValue,
  TValidateValue,
  TGetCalendarElementData,
  TFocusEventOnInputElement,
  TCreateAWhenSend2ItemListNew,
  TCreateAWhenSend2ItemListOld,
} from '@shared/types';
import {
  TSort,
  TNumWord,
  THelpKeys,
  TGetNewRate,
  TAppLanguage,
  TCompareDate,
  TGetLeftOffset,
  TGetDateUSFormat,
  TSimpleStringObj,
  TGetMediaFileType,
} from '@models/index';

/**
 * Функция-обертка для console.log.
 *
 * @param {...unknown} args - Аргументы для вывода в консоль.
 */
export const log = (...args: unknown[]) => {
  // eslint-disable-next-line
  console.log(... args);
};

/**
 * Функция-обертка для prompt.
 *
 * @param {string} message - Сообщение для отображения в окне prompt.
 * @param {string} defaultValue - Значение по умолчанию для поля ввода.
 * @returns {string | null} - Введенное значение или null, если пользователь нажал "Отмена" или закрыл окно prompt.
 */
export const customPrompt = (message: string, defaultValue: string): string | null => {
  // eslint-disable-next-line
  return prompt(message, defaultValue);
};

/**
 * Функция добавляет/удаляет выбранный пункт из списка и возвращает отсортированный список.
 * Используется в ReviewsStep1 и Accesses.
 *
 * @param {Object} target - Объект с информацией о цели.
 * @param {string} target.name - Название пункта.
 * @param {boolean} target.checked - Флаг, указывающий, выбран ли пункт.
 * @param {Array} rights - Исходный список прав.
 * @returns {Array} - Отсортированный список прав.
 */
export const getNewRate: TGetNewRate = ({ target }, rights) => {
  const { name, checked } = target;

  return checked ? [...rights, name].sort() : rights.filter(item => item !== name);
};

/**
 * Функция возвращает тип медиафайла на основе его расширения.
 * используется в Template, Malling.
 *
 * @param {File} file - Объект файла.
 * @returns {string} - Тип медиафайла: 'image' для изображений, 'video' для видео, 'unknown' для неизвестных типов.
 */
export const getMediaFileType: TGetMediaFileType = file => {
  const arrImage = ['JPEG', 'JPG', 'PNG', 'GIF', 'APNG', 'SVG', 'BMP', 'ICO'];
  const arrVideo = ['MP4', 'MOV', 'WMV', 'AVI', 'AVCHD', 'FLV', 'F4V', 'SWF', 'MKV'];
  const arrDoc = ['PDF', 'DOC', 'DOCX', 'TXT'];

  const fileType = file.name.toUpperCase().split('.').pop();

  const isImage = fileType && arrImage.includes(fileType);
  const isVideo = fileType && arrVideo.includes(fileType);
  const isDoc = fileType && arrDoc.includes(fileType);

  return isImage ? 'image' : isVideo ? 'video' : isDoc ? 'doc' : 'unknown';
};

/**
 * Функция проверяет, является ли размер файла корректным.
 * используется в Template, Malling.
 *
 * @param {File} file - Объект файла.
 * @returns {boolean} - `true`, если размер файла превышает 20 МБ, иначе `false`.
 */
export const isFileSizeIncorrect = (file: File): boolean => file?.size > 20971520;

/**
 * Функция вычисляет смещение от левого края окна браузера на основе указанной позиции клиента.
 * функция высчитывает смещение всплывающего блока с подсказками, смещение зависит от того,
 * где первоначально пытается отрисоватся блок, если его границы выходят за края экрана, применяется смещение
 * используется IconWithTooltips.
 *
 * @param {number} clientX - Позиция клиента по оси X.
 * @returns {number} - Смещение от левого края окна браузера.
 */
export const getLeftOffset: TGetLeftOffset = clientX => {
  const windowInnerWidth = window.innerWidth;

  const maxWidthOfHint = windowInnerWidth * 0.95;
  const widthOfHintInRem = parseFloat(getComputedStyle(document.documentElement).fontSize) * 25;

  const currentWidthOfHint = maxWidthOfHint < widthOfHintInRem ? maxWidthOfHint : widthOfHintInRem;
  const halfOfCurrentWidthOfHint = currentWidthOfHint / 2;

  if (clientX - halfOfCurrentWidthOfHint < 20) {
    return clientX + halfOfCurrentWidthOfHint > windowInnerWidth
      ? windowInnerWidth / 2
      : clientX + halfOfCurrentWidthOfHint;
  }

  if (clientX + 200 > windowInnerWidth - 20) {
    return windowInnerWidth - currentWidthOfHint / 2 - windowInnerWidth * 0.025;
  }
  return clientX;
};

/**
 * Функция возвращает слово из массива `words` с правильным окончанием в зависимости от числового значения `value`.
 *
 * @example
 * // returns день
 * numWord(21, ['день', 'дня', 'дней'])
 * @param {number} value - Числовое значение.
 * @param {Array<string>} words - Массив слов.
 * @returns {string} - Слово из массива `words`.
 */
export const numWord: TNumWord = (value, words) => {
  value = Math.abs(value) % 100;
  const num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  return words[2];
};

/**
 * Функция возвращает объект с информацией о файле XLSX, полученной из указанных данных.
 *
 * @param {object} data - Данные файла XLSX.
 * @param appLanguage - Выбранный язык приложения
 * @returns {object} - Объект с информацией о файле XLSX.
 * @property {string} message1 - Сообщение о количестве получателей рассылки.
 * @property {string} message2 - Сообщение о количестве сообщений, которые можно отправить в день.
 * @property {string} message3 - Сообщение о количестве оставшихся сообщений и времени их отправки.
 * @property {string} message4 - Заголовок для информации о среднем значении.
 * @property {string} message5 - Информация о среднем количестве визитов.
 * @property {string} message6 - Информация о среднем количестве дней с последнего визита.
 */
export const getParsedXlsxFileInfo: TGetParsedXlsxFileInfo = (data, appLanguage) => {
  const { count, sendOutBatchLen, sendDays, visitsMedian, lastVisitTimeMedian } = data;
  const remainingMessages = count - sendOutBatchLen;

  return {
    message1: `Вы собираетесь отправить рассылку для ${count} ${numWord(count, [
      'получателя',
      'получателей',
      'получателей',
    ])}.`,
    message2: `Внимание, вы можете отправить в рассылке ${sendOutBatchLen} сообщений в день`,
    message3: `${remainingMessages > 1 ? 'остальные' : 'остальное'} ${remainingMessages} ${numWord(
      remainingMessages,
      ['сообщение', 'сообщения', 'сообщений'],
    )} ${remainingMessages > 1 ? 'будут' : 'будет'} ${
      remainingMessages > 1 ? 'отправлены' : 'отправлено'
    } в это же время ${sendDays > 1 ? 'в' : 'на'} ${numWord(sendDays, [
      'следующей',
      'следующие',
      'следующие',
    ])} ${sendDays === 1 ? '' : sendDays} ${numWord(sendDays, DAYS[appLanguage || 'rus'])}.`,
    message4: 'У которых в среднем:',
    message5: `${visitsMedian} визитов`,
    message6: `${lastVisitTimeMedian} дней с последнего визита`,
  };
};

/**
 * функция формирует массив для отображения в компоненте список сообщений зависит от количества номеров в рассылке
 * если дней рассылки больше 1 формируется 3 сообщения, если меньше, то 2
 *
 * @param {object} fileData - Данные файла.
 * @returns {Array<string>} - Массив с информацией о файле.
 */
export const createParsedFileInfo: TCreateParsedFileInfo = fileData => {
  if (!fileData) {
    return [];
  }
  const messages = getParsedXlsxFileInfo(fileData);
  const messagesToState = [];

  messagesToState[0] = messages.message1;
  messagesToState[1] = messages.message4;
  messagesToState[2] = messages.message5;
  messagesToState[3] = messages.message6;
  messagesToState[4] = messages.message2;
  messagesToState[5] = fileData.count > 500 ? messages.message3 : '';
  if (fileData.count > 500) {
    messagesToState[4] = messagesToState[4].concat(',');
  } else {
    messagesToState[4] = messagesToState[4].concat('.');
  }
  return messagesToState;
};

/**
 * Функция преобразует дату в формате EU (день/месяц/год) в формат US (месяц/день/год) и возвращает ее в виде временной метки.
 * функция является вспомогательной для dayInMonthComparator, compareDate, задача перевести европейский формат даты в
 * формат US и затем с помощью Date.parse получить дату в миллисекундах, для последующей сортировки.
 *
 * @param {string} date - Дата в формате EU.
 * @returns {number} - Временная метка в формате US.
 */
export const getDateUSFormat: TGetDateUSFormat = date => {
  // date = date.replace(' ', '');

  const regExp = /[.\-\s]/;

  const splittedString = date.split(regExp);

  const [day, month, year] = splittedString;

  const [, , , time] = splittedString;

  return Date.parse(
    `${month?.padStart(2, '0')}/${day?.padStart(2, '0')}/${year || '1970'}${
      time ? ` ${time}` : ''
    }`,
  );
};

/**
 * Функция для сравнения двух дат в формате `string` или `Date`.
 * Если даты не могут быть преобразованы в формат US, то считаются равными.
 *
 * @param {string | Date} v1 - Первая дата для сравнения.
 * @param {string | Date} v2 - Вторая дата для сравнения.
 * @returns {number} - Отрицательное число, если `v1` меньше `v2`; положительное число, если `v1` больше `v2`; 0, если даты равны или не могут быть преобразованы в формат US.
 */
export const compareDate: TCompareDate = (v1, v2) => {
  let date1;
  let date2;

  try {
    date1 = getDateUSFormat(v1);

    if (Number.isNaN(date1)) throw new Error();
  } catch (e) {
    date1 = 0;
  }

  try {
    date2 = getDateUSFormat(v2);

    if (Number.isNaN(date2)) throw new Error();
  } catch (e) {
    date2 = 0;
  }

  return date1 - date2;
};

/**
 * функция получает строку с двумя числами разделенными / выделяет из стоки эти числа и возвращает в массиве
 *
 * @param {string} process - Строка состояния процесса в формате "отправлено/общее количество".
 * @returns {number[]} - Массив с двумя числами: отправлено и общее количество.
 */
export const splitProcessState = (process: string): number[] => {
  const index = process.indexOf('/');
  const send = Number(process.slice(0, index));
  const total = Number(process.slice(index + 1));

  return [send, total];
};

/**
 * Функция для сравнения двух строк состояния процесса по количеству отправленных данных.
 * функция получает две строки с цифрами разделенными /, на каждой строке запускается функция splitProcessState()
 * результат записывается в переменные, затем первая пара делится из нее вычитается результат деления второй пары
 * полученное число возвращается
 *
 * @param {string} process1 - Первая строка состояния процесса в формате "отправлено/общее количество".
 * @param {string} process2 - Вторая строка состояния процесса в формате "отправлено/общее количество".
 * @returns {number} - Отрицательное число, если первый процесс имеет меньший процент отправленных данных;
 * положительное число, если второй процесс имеет меньший процент отправленных данных;
 * 0, если проценты отправленных данных равны.
 */
export const compareSendProcess = (process1: string, process2: string): number => {
  const [send1, total1] = splitProcessState(process1);
  const [send2, total2] = splitProcessState(process2);
  return send1 / total1 - send2 / total2;
};

/**
 * Функция для простой сортировки данных массива по заданному полю и порядку сортировки.
 *
 * @param {any[]} unsortedData - Неотсортированный массив данных.
 * @param {string} fieldName - Имя поля, по которому производится сортировка.
 * @param {'asc' | 'desc'} sortIndex - Порядок сортировки: 'asc' - по возрастанию, 'desc' - по убыванию.
 * @returns {any[]} - Отсортированный массив данных.
 */
// TODO fix types
export const simpleSort: TSort = (unsortedData, fieldName, sortIndex) => {
  const newArray = [...unsortedData];

  const sortHelper = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    a: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    b: any,
    order: SORTING_TYPES.ASCENDING | SORTING_TYPES.DESCENDING,
  ) => {
    if (typeof a[fieldName] === 'number' && typeof b[fieldName] === 'number') {
      return order === SORTING_TYPES.ASCENDING
        ? (a[fieldName] as number) - (b[fieldName] as number)
        : (b[fieldName] as number) - (a[fieldName] as number);
    }
    return order === SORTING_TYPES.ASCENDING
      ? String(a[fieldName]).localeCompare(String(b[fieldName]))
      : String(b[fieldName]).localeCompare(String(a[fieldName]));
  };

  if (sortIndex === SORTING_TYPES.ASCENDING || sortIndex === SORTING_TYPES.DESCENDING) {
    return newArray.sort((a, b) => sortHelper(a, b, sortIndex));
  }
  return unsortedData;
};

/**
 * Функция для преобразования текстового представления даты в формат "дд.мм.гггг".
 *
 * @param {string} date - Текстовое представление даты в формате "месяц год", например "январь 2022".
 * @returns {string} - Преобразованная дата в формате "дд.мм.гггг".
 */
export const parseTextMontInDate = (date: string): string => {
  const months = [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
  ];
  const splitDate = date.split(' ');
  const monthIndex = String(months.indexOf(splitDate[0].toLowerCase()) + 1);
  const month = monthIndex.padStart(2, '0');
  const year = splitDate[1];
  return `01.${month}.${year}`;
};

/**
 * функция сортирует массив дат! по возрастанию/убыванию, возвращает отсортированный массив
 *
 * @param {any[]} unsortedData - Неотсортированный массив дат.
 * @param {string} fieldName - Имя поля, по которому производится сортировка.
 * @param {'asc' | 'desc'} sortIndex - Порядок сортировки: 'asc' - по возрастанию, 'desc' - по убыванию.
 * @param {number} sliceStartIndex - Начальная позиция сортировки
 * @param {boolean} isTextPeriod - Если true, то сортировка будет производиться по текстовому представлению даты
 * @returns {any[]} - Отсортированный массив дат.
 */
// TODO fix types
export const dateSort: TSort = (
  unsortedData,
  fieldName,
  sortIndex,
  sliceStartIndex = 0,
  isTextPeriod = false,
) => {
  const newArray = [...unsortedData];

  const sortDateHelper = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    a: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    b: any,
    order: SORTING_TYPES.ASCENDING | SORTING_TYPES.DESCENDING,
  ) => {
    const firstKey = order === SORTING_TYPES.ASCENDING ? a : b;
    const secondKey = order === SORTING_TYPES.ASCENDING ? b : a;

    const firstSortDate = !isTextPeriod
      ? (firstKey[fieldName] as string)
      : parseTextMontInDate(firstKey[fieldName] as string);
    const secondSortDate = !isTextPeriod
      ? (secondKey[fieldName] as string)
      : parseTextMontInDate(secondKey[fieldName] as string);

    return compareDate(
      firstSortDate.slice(sliceStartIndex) as string,
      secondSortDate.slice(sliceStartIndex) as string,
    );
  };

  if (sortIndex === SORTING_TYPES.ASCENDING || sortIndex === SORTING_TYPES.DESCENDING) {
    return newArray.sort((a, b) => sortDateHelper(a, b, sortIndex));
  }

  return unsortedData;
};

/**
 * функция сортирует массив дат! по возрастанию/убыванию, возвращает отсортированный массив
 *
 * @param {any[]} unsortedData - Неотсортированный массив дат.
 * @param {string} fieldName - Имя поля, по которому производится сортировка.
 * @param {'asc' | 'desc'} sortIndex - Порядок сортировки: 'asc' - по возрастанию, 'desc' - по убыванию.
 * @returns {any[]} - Отсортированный массив дат.
 */
export const utcDateSort: TSort = (unsortedData, fieldName, sortIndex) => {
  const newArray = [...unsortedData];

  const sortDateHelper = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    a: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    b: any,
    order: SORTING_TYPES.ASCENDING | SORTING_TYPES.DESCENDING,
  ) => {
    const firstKey = order === SORTING_TYPES.ASCENDING ? a : b;
    const secondKey = order === SORTING_TYPES.ASCENDING ? b : a;

    const firstSortDate = new Date(firstKey[fieldName] as string).getDate();
    const secondSortDate = new Date(secondKey[fieldName] as string).getDate();

    return !Number.isNaN(firstSortDate - secondSortDate) ? firstSortDate - secondSortDate : 0;
  };

  if (sortIndex === SORTING_TYPES.ASCENDING || sortIndex === SORTING_TYPES.DESCENDING) {
    return newArray.sort((a, b) => sortDateHelper(a, b, sortIndex));
  }

  return unsortedData;
};

/**
 * Функция для сортировки данных массива "процессов" по заданному полю и порядку сортировки.
 * функция сортирует массив строк состоящих из двух чисел разделенных /, возвращает отсортированный массив
 *
 * @param {any[]} unsortedData - Неотсортированный массив данных
 * @param {string} fieldName - Имя поля, по которому производится сортировка.
 * @param {'asc' | 'desc'} sortIndex - Порядок сортировки: 'asc' - по возрастанию, 'desc' - по убыванию.
 * @returns {any[]} - Отсортированный массив данных.
 */
export const processSort: TSort = (unsortedData, fieldName, sortIndex) => {
  const newArray = [...unsortedData];

  if (sortIndex === 'asc') {
    return newArray.sort((a, b) => {
      return compareSendProcess(a[fieldName] as string, b[fieldName] as string);
    });
  }
  if (sortIndex === 'desc') {
    return newArray.sort((b, a) => {
      return compareSendProcess(a[fieldName] as string, b[fieldName] as string);
    });
  }
  return unsortedData;
};

/**
 * Функция для получения текстового представления статуса подключения на основе переданного ключа.
 *
 * @param {string} status - Ключ статуса подключения.
 * @param appLanguage
 * @param connectionStatusText
 * @param connectionStatusFallback
 * @returns {string} - Текстовое представление статуса подключения.
 */
export const getStatusText: TGetStatusText = ({
  status,
  appLanguage,
  connectionStatusText,
  connectionStatusFallback,
}): string => {
  return connectionStatusText[appLanguage][status]
    ? connectionStatusText[appLanguage][status]
    : connectionStatusFallback[appLanguage].fallbackStatus;
};

/**
 * Функция для получения расширенных данных для запроса из локального хранилища.
 *
 * @returns {TGetRequestData} - Объект с расширенными данными для запроса.
 */
export const getRequestData = (): TGetRequestData => {
  // получаем из LS id пользователя
  const id = localStorage.getItem('id');
  // читаем идентификатор филиала accId

  const accId = localStorage.getItem('accId');

  // получаем из LS JWT token
  const token = localStorage.getItem('token');

  // читаем идентификатор филиала isSendOutAccount этот ключ указывает
  // является ли выбранный аккаунт, аккаунтом для рассылок
  const isSendOutAccount = localStorage.getItem('isSendOutAccount');

  return {
    userId: Number(id),
    accId: accId || '',
    accessToken: token || '',
    sendOutAccount: !!isSendOutAccount,
  };
};

/**
 * Функция для валидации текстовых полей на странице.
 *
 * Функция находит textarea с классом ".error" на странице, проверяет их наличие и если они есть,
 * скролит страницу к этим textarea и возвращает значение false.
 * Если textarea не найдены, функция возвращает значение true.
 *
 * @returns {boolean} - Результат валидации: true, если textarea не найдены, false в противном случае.
 */
export const mailingTextValidate = (): boolean => {
  const textarea = document.querySelectorAll('textarea.error');
  if (textarea?.length) {
    textarea.forEach(item => {
      item.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
    return false;
  }
  return true;
};

/**
 * Функция для формирования строки с обрамленным участком текста и возврата этой строки.
 *
 * Функция получает выделенный текст из переданной строки oldText и оборачивает его в специальные символы,
 * указанные в параметре wrappedSymbol. Затем функция возвращает новую строку, в которой вместо выделенного
 * текста вставлен новый текст с обрамленным участком.
 *
 * @param {string} oldText - Исходная строка.
 * @param {string} wrappedSymbol - Символы для обрамления выделенного текста.
 * @param {number} startIndex - Индекс начала выделенного текста.
 * @param {number} endIndex - Индекс конца выделенного текста.
 * @returns {string} - Строка с обрамленным участком текста.
 */
export const getWrapperText = (
  oldText: string,
  wrappedSymbol: string,
  startIndex: number,
  endIndex: number,
): string => {
  // получаем выделенный текст из переданной строки
  const selectedText = oldText.substring(startIndex, endIndex);

  if (startIndex === endIndex || startIndex > endIndex) {
    return oldText;
  }
  // оборачиваем выделенный текст в спецсимволы
  const textWithTags = `${wrappedSymbol}${selectedText}${wrappedSymbol}`;
  // В переданную строку вставляем новый текст вместо выделенного
  return oldText.substring(0, startIndex) + textWithTags + oldText.substring(endIndex);
};

/**
 * Функция для получения названия филиала.
 *
 * Если переданное название филиала не является пустой строкой и его длина превышает 21 символ, то функция
 * обрезает название до 21 символа и добавляет многоточие в конце. В противном случае, функция возвращает
 * переданное название без изменений.
 *
 * @param {string} name - Название филиала.
 * @returns {string} - Обработанное название филиала.
 */
export const getFilialName = (name: string): string => {
  return name && name.length > 21 ? `${name.slice(0, 21)}...` : name;
};

/**
 * Функция получает время строкой в формате HH:MM создает объект Date
 * парсит переданную строку на часы и минуты
 * записывает эти данные в Date
 * возвращает Date с новым временем * @param time {string}
 * @function getTime
 * @param time {string} время в формате HH:MM
 * @return {Date} объект Date
 */
export const getTime = (time: string): Date => {
  const newDate = new Date(0);
  const hours = Number(time.slice(0, 2));
  const minutes = Number(time.slice(3));
  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  return newDate;
};

/**
 * Функция создает объект Date, используя предоставленное время в миллисекундах.
 * Затем она извлекает часы и минуты из этого объекта Date.
 * Эти значения форматируются таким образом, чтобы было две цифры для каждого компонента (часы и минуты),
 * дополняя их нулями, если необходимо.
 *
 * @param timeInMs {number}
 * @return string возвращает отформатированное время в виде строки.
 */
export const getTimeString = (timeInMs: number): string => {
  const date = new Date(timeInMs);
  return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(
    2,
    '0',
  )}`;
};

/**
 * Функция parseTime принимает строку timeString, которая должна быть в формате времени "HH:MM"
 * затем вызывает функцию getTime, передавая ей timeString.
 * функция getTime возвращает объект Date, из этого объекта получаем дату в миллисекундах
 * @param timeString {string}
 * @return number
 */
export const parseTime = (timeString: string): number => getTime(timeString).getTime();

/**
 * Функция принимает объект Data получает из него часы и минуты
 * минуты округляет до указанного значения, если значение не задано, округляет до 5 мин
 * если isSetOnlyHour true округляет минуты до 00
 * возвращает строку формата HH:MM
 * @function getRoundedTime
 * @param date {Date}
 * @param isSetOnlyHour {boolean}
 * @param isRoundCeil {boolean}
 * @param roundTo {number}
 * @return {string} время в формате HH:MM
 */
export const getRoundedTime = (
  date: Date,
  isSetOnlyHour: boolean,
  roundTo?: number,
  isRoundCeil?: boolean,
): string => {
  const min = roundTo || 5;
  const ms = 1000 * 60 * min;

  let roundedDate: Date;

  if (isRoundCeil) {
    roundedDate = new Date(Math.ceil(date.getTime() / ms) * ms);
  } else {
    roundedDate = new Date(Math.round(date.getTime() / ms) * ms);
  }

  const h = roundedDate.getHours();
  const m = roundedDate.getMinutes();

  const hours = String(h).padStart(2, '0');
  const minutes = String(m).padStart(2, '0');

  return `${hours}:${isSetOnlyHour ? '00' : minutes}`;
};

export const getRoundedMinutes = (minutes: number, roundTo?: number, isRoundCeil?: boolean) => {
  const round = roundTo || 5;

  let roundedTime: number;

  if (isRoundCeil) {
    roundedTime = Math.ceil(minutes / round) * round;
  } else {
    roundedTime = Math.round(minutes / round) * round;
  }

  return roundedTime;
};

/**
 * Функция для выделения текстового содержимого при получении фокуса на элементе ввода.
 *
 * Функция вызывается при получении фокуса на элементе ввода и выделяет текстовое содержимое этого элемента.
 *
 * @param {TFocusEventOnInputElement} event - Объект события фокуса.
 */
export const selectTextContentOnFocus: TFocusEventOnInputElement = event => {
  event.currentTarget.select();
};

/**
 * Функция для получения списка значений выпадающего списка.
 *
 * Функция возвращает объект с ключами 'add' и 'delete', содержащими соответствующие значения для указанного
 * выпадающего списка. Если переданный ключ не соответствует ни одному из предопределенных значений, функция
 * возвращает объект с ключами 'add' и 'delete', содержащими значения 'add' и 'delete' соответственно.
 *
 * @param {THelpKeys} dropDownName - Ключ, определяющий название выпадающего списка.
 * @param appLanguage
 * @returns {TSimpleStringObj} - Объект с значениями выпадающего списка.
 */
export const getDropdownList = (
  dropDownName: THelpKeys,
  appLanguage: TAppLanguage,
): TSimpleStringObj => {
  switch (dropDownName) {
    case 'services': {
      if (appLanguage === 'rus') {
        return {
          add: 'Добавить услугу',
          delete: 'Игнорировать услугу',
        };
      }
      return {
        add: 'Add service',
        delete: 'Ignore service',
      };
    }
    case 'serviceIF': {
      if (appLanguage === 'rus') {
        return {
          add: 'Добавить услугу',
          delete: 'Игнорировать услугу',
        };
      }
      return {
        add: 'Add service',
        delete: 'Ignore service',
      };
    }
    case 'categories': {
      if (appLanguage === 'rus') {
        return {
          add: 'Добавить категорию',
          delete: 'Игнорировать категорию',
        };
      }
      return {
        add: 'Add category',
        delete: 'Ignore category',
      };
    }
    case 'categoriesIF': {
      if (appLanguage === 'rus') {
        return {
          add: 'Добавить категорию',
          delete: 'Игнорировать категорию',
        };
      }
      return {
        add: 'Add category',
        delete: 'Ignore category',
      };
    }
    case 'products': {
      if (appLanguage === 'rus') {
        return {
          add: 'Добавить товар',
          delete: 'Игнорировать товар',
        };
      }
      return {
        add: 'Add product',
        delete: 'Ignore product',
      };
    }
    case 'staff': {
      if (appLanguage === 'rus') {
        return {
          add: 'Добавить сотрудника',
          delete: 'Игнорировать сотрудника',
        };
      }
      return {
        add: 'Add employee',
        delete: 'Ignore employee',
      };
    }
    case 'staffIF': {
      if (appLanguage === 'rus') {
        return {
          add: 'Добавить сотрудника',
          delete: 'Игнорировать сотрудника',
        };
      }
      return {
        add: 'Add employee',
        delete: 'Ignore employee',
      };
    }
    case 'staffs': {
      if (appLanguage === 'rus') {
        return {
          add: 'Добавить сотрудника',
          delete: 'Игнорировать сотрудника',
        };
      }
      return {
        add: 'Add employee',
        delete: 'Ignore employee',
      };
    }
    case 'clientCategory': {
      if (appLanguage === 'rus') {
        return {
          add: 'Добавить категорию',
          delete: 'Игнорировать категорию',
        };
      }
      return {
        add: 'Add category',
        delete: 'Ignore category',
      };
    }
    case 'clientCategoryIF': {
      if (appLanguage === 'rus') {
        return {
          add: 'Добавить категорию',
          delete: 'Игнорировать категорию',
        };
      }
      return {
        add: 'Add category',
        delete: 'Ignore category',
      };
    }
    case 'channels': {
      if (appLanguage === 'rus') {
        return {
          add: 'Добавить категорию',
          delete: 'Игнорировать категорию',
        };
      }
      return {
        add: 'Add category',
        delete: 'Ignore category',
      };
    }
    case 'recordTag': {
      if (appLanguage === 'rus') {
        return {
          add: 'Добавить категорию',
          delete: 'Игнорировать категорию',
        };
      }
      return {
        add: 'Add category',
        delete: 'Ignore category',
      };
    }
    case 'exceptionMastersNames': {
      if (appLanguage === 'rus') {
        return {
          delete: 'Игнорировать сотрудника',
        };
      }
      return {
        delete: 'Ignore employee',
      };
    }
    default: {
      return {
        add: 'add',
        delete: 'delete',
      };
    }
  }
};

/**
 * Функция для получения заголовка второго выпадающего списка.
 *
 * Функция возвращает строку - заголовок второго выпадающего списка в зависимости от переданного ключа. Если
 * переданный ключ не соответствует ни одному из предопределенных значений, функция возвращает строку 'add'.
 *
 * @param {THelpKeys} dropDownName - Ключ, определяющий название выпадающего списка.
 * @param {TAppLanguage} appLanguage - Язык приложения.
 * @returns {string} - Заголовок второго выпадающего списка.
 */
export const getSecondDropdownListTitle = (
  dropDownName: THelpKeys,
  appLanguage: TAppLanguage,
): string => {
  switch (dropDownName) {
    case 'services': {
      if (appLanguage === 'rus') {
        return 'Выберите услугу';
      }
      return 'Select service';
    }
    case 'categories': {
      if (appLanguage === 'rus') {
        return 'Выберите категорию';
      }
      return 'Select category';
    }
    case 'products': {
      if (appLanguage === 'rus') {
        return 'Выберите товар';
      }
      return 'Select product';
    }
    case 'staff': {
      if (appLanguage === 'rus') {
        return 'Выберите сотрудника';
      }
      return 'Select employee';
    }
    case 'staffs': {
      if (appLanguage === 'rus') {
        return 'Выберите сотрудника';
      }
      return 'Select employee';
    }
    case 'clientCategory': {
      if (appLanguage === 'rus') {
        return 'Выберите категорию';
      }
      return 'Select category';
    }
    case 'channels': {
      if (appLanguage === 'rus') {
        return 'Выберите категорию';
      }
      return 'Select category';
    }
    case 'exceptionMastersNames': {
      if (appLanguage === 'rus') {
        return 'Выберите сотрудника';
      }
      return 'Select employee';
    }
    case 'recordTag': {
      if (appLanguage === 'rus') {
        return 'Выберите категорию записей';
      }
      return 'Select post category';
    }
    default: {
      return 'add';
    }
  }
};
/**
 * Функция для получения заголовка второго выпадающего списка.
 *
 * Функция возвращает строку - заголовок второго выпадающего списка в зависимости от переданного ключа. Если
 * переданный ключ не соответствует ни одному из предопределенных значений, функция возвращает строку 'add'.
 *
 * @param {THelpKeys} dropDownName - Ключ, определяющий название выпадающего списка.
 * @param {TAppLanguage} appLanguage - Язык приложения.
 * @returns {string} - Заголовок второго выпадающего списка.
 */
export const getSecondDropdownListTitle2 = (
  dropDownName: THelpKeys,
  appLanguage: TAppLanguage,
): string => {
  switch (dropDownName) {
    case 'services': {
      if (appLanguage === 'rus') {
        return 'Название или код услуги';
      }
      return 'Select service';
    }
    case 'serviceIF': {
      if (appLanguage === 'rus') {
        return 'Название или код услуги';
      }
      return 'Select service';
    }
    case 'categories': {
      if (appLanguage === 'rus') {
        return 'Название или код категории';
      }
      return 'Select category';
    }
    case 'categoriesIF': {
      if (appLanguage === 'rus') {
        return 'Название или код категории';
      }
      return 'Select category';
    }
    case 'products': {
      if (appLanguage === 'rus') {
        return 'Название или код товара';
      }
      return 'Select product';
    }
    case 'staff': {
      if (appLanguage === 'rus') {
        return 'Имя или код сотрудника';
      }
      return 'Select employee';
    }
    case 'staffIF': {
      if (appLanguage === 'rus') {
        return 'Имя или код сотрудника';
      }
      return 'Select employee';
    }
    case 'staffs': {
      if (appLanguage === 'rus') {
        return 'Имя или код сотрудника';
      }
      return 'Select employee';
    }
    case 'clientCategory': {
      if (appLanguage === 'rus') {
        return 'Название или код категории';
      }
      return 'Select category';
    }
    case 'clientCategoryIF': {
      if (appLanguage === 'rus') {
        return 'Название или код категории';
      }
      return 'Select category';
    }
    case 'channels': {
      if (appLanguage === 'rus') {
        return 'Название или код категории';
      }
      return 'Select category';
    }
    case 'exceptionMastersNames': {
      if (appLanguage === 'rus') {
        return 'Имя или код сотрудника';
      }
      return 'Select employee';
    }
    case 'recordTag': {
      if (appLanguage === 'rus') {
        return 'Название или код категории записей';
      }
      return 'Select post category';
    }
    default: {
      return 'add';
    }
  }
};

/**
 * Функция проверяет переданный в React.Ref объект на наличие вертикального скрола, возвращает булево значения
 * @function getIsScroll
 * @param refLink {React.RefObject<HTMLDivElement>}
 * @return boolean
 */
export const getIsScroll = (refLink: React.RefObject<HTMLDivElement>): boolean => {
  if (refLink.current) {
    return refLink.current.scrollHeight > refLink.current.clientHeight;
  }
  return false;
};

/**
 * Функция преобразует дату в европейский формат
 * @function getEUDateFromString
 * @param date {string} дата в формате YYYY-MM-DD
 * @param isAddRest флаг скрывает или показывает оставшуюся строку после обработки
 * @return {string} дату в формате DD.MM.YYYY
 */
export const getEUDateFromString = (date: string, isAddRest?: boolean): string => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);
  const rest = date.slice(10);

  return `${day}.${month}.${year}${isAddRest ? rest : ''}`;
};

/**
 * Функция преобразует дату в европейский формат
 * @function getEUDateFromString
 * @param date {string} дата в формате DD-MM-YYYY HH:MM
 * @return {dateString: string, time: string} дату в формате DD.MM.YYYY и время HH:MM
 */
export const getLocaleData = (date: string | null): { dateString: string; time: string } => {
  if (!date) {
    return {
      dateString: '',
      time: '',
    };
  }

  const regExp = /[.\-\s]/;

  const [day, month, year, time] = date.split(regExp);

  return {
    dateString: `${day}.${month}.${year}`,
    time: `${time}`,
  };
};

/**
 * Функция получает строку с с датой и временем
 * находит индекс пробела в строке с этого индекса вырезает строке со временем
 * разделяет полученную строку на массив, 0 и 1 элементы массива конкатенируются
 * и возвращаются из функции в формате HH:MM
 * @function getTimeFromString
 * @param date {string} дата в формате YYYY-MM-DD HH:MM:SS
 * @return {string} время в формате HH:MM
 */
export const getTimeFromString = (date: string): string => {
  const indexOfSpace = date.indexOf(' ');
  const array = date.slice(indexOfSpace + 1).split(':');

  const hour = array[0];
  const minutes = array[1];

  return `${hour}:${minutes}`;
};

/**
 * Сортирует объект по ключам в алфавитном порядке.
 *
 * @param {TSimpleStringObj} obj - Исходный объект, который нужно отсортировать.
 * @returns {TSimpleStringObj} - Отсортированный объект.
 */
export const sortObj = (obj: TSimpleStringObj): TSimpleStringObj => {
  return Object.fromEntries(Object.entries(obj).sort());
};

/**
 * Возвращает строку, описывающую количество дней до или после дня рождения.
 *
 * @param {string} day - Количество дней до или после дня рождения.
 * @param appLanguage - Выбранный язык приложения
 * @returns {string} - Строка с описанием количества дней до или после дня рождения.
 */
export const getBirthdayString = (day: string, appLanguage?: 'eng' | 'rus'): string => {
  const absDay = Math.abs(+day);
  const numWords = numWord(absDay, DAYS[appLanguage || 'rus']);
  if (absDay === 0) {
    return 'В день рождения';
  }
  return `${+day > 0 ? 'За' : 'Через'} ${absDay} ${numWords} ${+day > 0 ? 'до' : 'после'} ДР`;
};

/**
 * Возвращает строку, описывающую количество дней до или после указанного дня.
 *
 * @param {string} day - Количество дней до или после указанного дня.
 * @param appLanguage - Выбранный язык приложения
 * @returns {string} - Строка с описанием количества дней до или после указанного дня.
 */
export const getAllDayRectString = (day: string, appLanguage?: 'eng' | 'rus'): string => {
  const absDay = Math.abs(+day);
  const numWords = numWord(absDay, DAYS[appLanguage || 'rus']);
  return `${+day > 0 ? 'Через ' : ''}${absDay} ${numWords}${+day < 0 ? ' назад' : ''}`;
};

/**
 * Возвращает строку, описывающую количество часов или минут до указанного дня.
 *
 * @param {string} day - Количество дней до указанного дня.
 * @returns {string} - Строка с описанием количества часов или минут до указанного дня.
 */
export const getAfterEndString = (day: string): string => {
  const absDay = Math.abs(+day);
  const roundedDay = Math.round(absDay);
  const min = Math.round(60 * absDay);
  const numWordsMin = numWord(min, ['минуту', 'минуты', 'минут']);
  const numWordsHour = numWord(absDay, ['час', 'часа', 'часов']);
  return `Через${absDay >= 1 ? ` ${roundedDay}` : ''} ${
    absDay >= 1 ? numWordsHour : `${min} ${numWordsMin}`
  }`;
};

/**
 * Возвращает строку, описывающую количество часов или минут, оставшихся до начала указанного дня.
 *
 * @param {string} day - Количество дней до начала указанного дня.
 * @returns {string} - Строка с описанием количества часов или минут до начала указанного дня.
 */
export const getBeforeStartString = (day: string): string => {
  const absDay = Math.abs(+day);
  const roundedDay = Math.round(absDay);
  const min = Math.round(60 * absDay);
  const numWordsMin = numWord(absDay, ['минуту', 'минуты', 'минут']);
  const numWordsHour = numWord(absDay, ['час', 'часа', 'часов']);
  return `За${absDay >= 1 ? ` ${roundedDay}` : ''} ${
    absDay >= 1 ? numWordsHour : `${min} ${numWordsMin}`
  }`;
};

/**
 * Создает новый список опций, добавляя строку, описывающую время отправки, в зависимости от значения whenSend.
 *
 * @param {object} options - Объект с параметрами для создания списка опций.
 * @param {string} options.whenSend - Значение времени отправки.
 * @param {string} options.whenSend2 - Значение времени отправки 2.
 * @param {object} options.optionList - Список опций.
 * @returns {object} - Новый список опций с добавленной строкой, описывающей время отправки.
 */
export const createAWhenSend2ItemList: TCreateAWhenSend2ItemListNew = ({
  whenSend,
  whenSend2,
  optionList,
}) => {
  const { eng, rus } = optionList;

  switch (whenSend) {
    case WHEN_SEND_VALUE.BEFORE_START: {
      const newOptionListEng = { ...eng };
      newOptionListEng[whenSend] = {
        ...newOptionListEng[whenSend],
        [whenSend2]: getBeforeStartString(whenSend2),
      };

      const newOptionListRus = { ...rus };
      newOptionListRus[whenSend] = {
        ...newOptionListRus[whenSend],
        [whenSend2]: getBeforeStartString(whenSend2),
      };

      return { eng: newOptionListEng, rus: newOptionListRus };
    }
    case WHEN_SEND_VALUE.AFTER_END: {
      const newOptionListEng = { ...eng };
      newOptionListEng[whenSend] = {
        ...newOptionListEng[whenSend],
        [whenSend2]: getAfterEndString(whenSend2),
      };

      const newOptionListRus = { ...rus };
      newOptionListRus[whenSend] = {
        ...newOptionListRus[whenSend],
        [whenSend2]: getAfterEndString(whenSend2),
      };

      return { eng: newOptionListEng, rus: newOptionListRus };
    }
    case WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION: {
      const newOptionListEng = { ...eng };
      newOptionListEng[whenSend] = {
        ...newOptionListEng[whenSend],
        [whenSend2]: getAllDayRectString(whenSend2),
      };

      const newOptionListRus = { ...rus };
      newOptionListRus[whenSend] = {
        ...newOptionListRus[whenSend],
        [whenSend2]: getAllDayRectString(whenSend2),
      };

      return { eng: newOptionListEng, rus: newOptionListRus };
    }
    case WHEN_SEND_VALUE.BIRTHDAY: {
      const newOptionListEng = { ...eng };
      newOptionListEng[whenSend] = {
        ...newOptionListEng[whenSend],
        [whenSend2]: getBirthdayString(whenSend2),
      };

      const newOptionListRus = { ...rus };
      newOptionListRus[whenSend] = {
        ...newOptionListRus[whenSend],
        [whenSend2]: getBirthdayString(whenSend2),
      };

      return { eng: newOptionListEng, rus: newOptionListRus };
    }
    default: {
      return optionList;
    }
  }
};

/**
 * Создает новый список опций для отзывов, добавляя строку, описывающую время отправки, в зависимости от значения whenSend.
 *
 * @param {object} options - Объект с параметрами для создания списка опций.
 * @param {string} options.whenSend - Значение времени отправки.
 * @param {string} options.whenSend2 - Значение времени отправки 2.
 * @param {object} options.optionList - Список опций.
 * @returns {object} - Новый список опций с добавленной строкой, описывающей время отправки.
 */
export const createAWhenSend2ReviewItemList: TCreateAWhenSend2ItemListOld = ({
  whenSend,
  whenSend2,
  optionList,
}) => {
  const { eng, rus } = optionList;

  switch (whenSend) {
    case WHEN_SEND_VALUE_REVIEWS.ALL_DAY_RECS_NOTIFICATION: {
      const newOptionListEng = { ...eng };
      newOptionListEng[whenSend] = {
        ...newOptionListEng[whenSend],
        [whenSend2]: getAllDayRectString(whenSend2),
      };

      const newOptionListRus = { ...rus };
      newOptionListRus[whenSend] = {
        ...newOptionListRus[whenSend],
        [whenSend2]: getAllDayRectString(whenSend2),
      };

      return { eng: newOptionListEng, rus: newOptionListRus };
    }
    case WHEN_SEND_VALUE_REVIEWS.AFTER_END: {
      const newOptionListEng = { ...eng };
      newOptionListEng[whenSend] = {
        ...newOptionListEng[whenSend],
        [whenSend2]: getAfterEndString(whenSend2),
      };

      const newOptionListRus = { ...rus };
      newOptionListRus[whenSend] = {
        ...newOptionListRus[whenSend],
        [whenSend2]: getAfterEndString(whenSend2),
      };

      return { eng: newOptionListEng, rus: newOptionListRus };
    }
    default: {
      return optionList;
    }
  }
};

/**
 * Возвращает отсортированный массив опций в зависимости от выбранного значения.
 * @param {string} whenSendValue - Значение выбранного варианта отправки.
 * @param {TSimpleStringObj} whenSend2SelectOption - Объект с опциями для выбора отправки.
 * @returns {Array} - Отсортированный массив опций.
 */
export const getSortedWhenSend2OptionArray = (
  whenSendValue: string,
  whenSend2SelectOption: TSimpleStringObj,
) => {
  if (
    whenSendValue === WHEN_SEND_VALUE.AFTER_END ||
    whenSendValue === WHEN_SEND_VALUE.BEFORE_START ||
    whenSendValue === WHEN_SEND_VALUE.BIRTHDAY
  ) {
    return Object.entries(whenSend2SelectOption).sort((a, b) => +b[0] - +a[0]);
  }

  if (whenSendValue === WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION) {
    return Object.entries(whenSend2SelectOption).sort((a, b) => +a[0] - +b[0]);
  }

  return Object.entries(whenSend2SelectOption);
};

/**
 * Возвращает отсортированный массив опций в зависимости от выбранного значения.
 * @param {string} whenSendValue - Значение выбранного варианта отправки отзывов.
 * @param {TSimpleStringObj} whenSend2SelectOption - Объект с опциями для выбора отправки отзывов.
 * @returns {Array} - Отсортированный массив опций.
 */
export const getSortedWhenSend2ReviewsOptionArray = (
  whenSendValue: string,
  whenSend2SelectOption: TSimpleStringObj,
) => {
  if (whenSendValue === WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION) {
    return Object.entries(whenSend2SelectOption).sort((a, b) => +a[0] - +b[0]);
  }
  return Object.entries(whenSend2SelectOption).sort((a, b) => +b[0] - +a[0]);
};

/**
 * Добавляет символы переноса строки в текст после каждых 25 символов.
 * Слова переносятся целиком.
 *
 * @param {string} text - Исходный текст.
 * @returns {string} Текст с символами переноса строки.
 */
export const addNewLines = (text: string): string => {
  const maxLength = 25;
  const words: string[] = text.split(' ');
  const lines: string[] = [];

  let line = '';

  words.forEach((word: string) => {
    if (line.length + word.length > maxLength) {
      lines.push(line.trim());
      line = '';
    }
    line += `${word} `;
  });

  lines.push(line.trim());

  return lines.join('\n');
};

/**
 * Преобразует строку даты в формат "Месяц Год".
 *
 * @param {string} date - Строка с датой в формате "ГГГГ-ММ".
 * @returns {string} - Строка в формате "Месяц Год".
 */
export const convertDateString = (date: string): string => {
  const [year, month] = date.split('-');

  const monthString = monthsName[+month - 1];

  return `${monthString || ''} ${year || ''}`;
};

/**
 * Преобразует число в строку с языкозависимым представлением даты.
 *
 * @param {number} afterEnd - Число для преобразования.
 * @returns {string} - Преобразованная строка.
 */
export const getAfterEndToString = (afterEnd: number) => {
  return String(
    Number(afterEnd).toLocaleString('en', { useGrouping: false, minimumFractionDigits: 1 }),
  );
};

/**
 * Добавляет строку в конец заданного текста.
 * Используется в шагах цепочек отзывов, кроме последнего
 *
 * @param {string} text - Текст, к которому нужно добавить строку.
 * @return {string} Измененный текст с добавленной строкой.
 */
export const addStringEndInPreviewReview = (text: string) => {
  return `${text}

Oтправьтe свoю оцeнку:
  5 - 😍 Понравилось
  3 - 😐 Сложно сказать
  1 - 🤬 He понравилось`;
};

/**
 * Формирует ссылку с query параметром acc_id для использования в navigate и Link
 * @param {string} route Строка пути для формирования ссылки
 * @param {string} accId Id филиала для добавления в query параметр
 */
export const getLinkHref = ({ route, accId }: { route: string; accId: string }): string => {
  return `${route}?${QUERY_PARAMS.ACC_ID}=${accId}`;
};
/**
 * Эта функция генерирует список строковых чисел, заполняет нулями числа до двух цифр.
 * Она включает возможность контролировать количество цифр в первом значении списка.
 *
 * @param start {number} - начальное число для списка
 * @param end {number} - конечное число для списка
 * @param step {number} - шаг для создания списка
 * @param isFirstOneDigit {boolean} - флаг, определяющий, должна ли первая цифра числа быть однозначной
 *
 * @returns массив строковых представлений чисел с префиксом '0', если необходимо
 */
export const listCreator = (
  start: number,
  end: number,
  step: number,
  isFirstOneDigit?: boolean,
): string[] => {
  const list: string[] = [];
  for (let i = start; i <= end; i += step) {
    const item = i > 0 ? String(i).padStart(2, '0') : isFirstOneDigit ? '0' : '00';
    list.push(item);
  }
  return list;
};

/**
 * Функция формируем массив часов и минут, с заданным шагом, для формирования впадающего списка
 * в компоненте NewWhenTime
 * @param minStep {number} шаг увеличения значения минут для добавления в массив
 * @param startMinutes {number} значение с которого начинается формирование списка минут
 * @param endMinutes {number} значение которым заканчивается формирование списка минут
 * @return { hour: string[]; minutes: string[] } возвращает массив часов и минут
 */
export const getTimeList = (
  minStep: number,
  startMinutes: number,
  endMinutes: number,
): { hour: string[]; minutes: string[] } => {
  const hour = listCreator(0, 23, 1);
  const minutes = listCreator(startMinutes, endMinutes, minStep);

  return { hour, minutes };
};

/**
 * Вспомогательная функция для преобразования массивов в number[].
 *
 * @param {string[]} array - Массив строк.
 * @returns {number[]} - Массив чисел.
 */
export const mapToNumberArray = (array: string[]): number[] =>
  array.length ? array.map(Number) : [];

/**
 * Функция преобразования в массив строк.
 *
 * @param {number[]} array - Массив чисел.
 * @returns {string[]} - Массив строк.
 */
export const mapToStringArray = (array: number[]): string[] => array.map(String);

/**
 * Функция getMessageSendTime принимает один аргумент dateInMS, представляющий дату и время в миллисекундах.
 * Она возвращает строку, представляющую время сообщения в заданном формате.
 * @param {number} dateInMS дата в миллисекундах.
 * @return {string} возвращает строку, представляющую время сообщения в заданном формате.
 */
export const getMessageSendTime = (dateInMS: number): string => {
  const messageSendDate = new Date(dateInMS);

  const hours = messageSendDate.getHours().toString().padStart(2, '0');
  const minutes = messageSendDate.getMinutes().toString().padStart(2, '0');
  const seconds = messageSendDate.getSeconds().toString().padStart(2, '0');

  const day = messageSendDate.getDate().toString().padStart(2, '0');
  const month = (messageSendDate.getMonth() + 1).toString().padStart(2, '0');
  const year = messageSendDate.getFullYear().toString();

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
};

/**
 * Извлекает расширение файла из URL и данных файла.
 *
 * @param {string} url - URL файла.
 * @param {TMessageFileData} fileData - Объект данных файла, содержащий тип содержимого.
 * @returns {object} Объект с деталями о файле, включая его имя, тип (изображение, видео, аудио или другой файл)
 * и расширение.
 *
 * Функция проверяет тип содержимого из fileData.contentType для определения типа файла.
 * Затем она категоризирует файл как изображение, видео, аудио или другой тип файла на основе его расширения.
 * Результат - объект со свойствами: fileName, isImage, isVideo, isAudio, isFile и extension.
 */
export const getFileExtensionFromURL = (
  url: string,
  fileData: TMessageFileData,
): {
  fileName: string;
  isImage: boolean;
  isVideo: boolean;
  isAudio: boolean;
  isFile: boolean;
  extension: string;
} => {
  if (!url || !fileData.contentType) {
    return {
      fileName: '',
      isImage: false,
      isVideo: false,
      isAudio: false,
      isFile: false,
      extension: '',
    };
  }

  const { fileName, contentType } = fileData;

  const extension = contentType?.split('/')[1];

  const isImage = extension ? arrChatImage.includes(extension.toUpperCase()) : false;
  const isVideo = extension ? arrChatVideo.includes(extension.toUpperCase()) : false;
  const isAudio = extension
    ? arrChatAudio.includes(extension?.split(';')[0]?.toUpperCase())
    : false;
  const isFile = extension ? !isImage && !isVideo && !isAudio : false;

  return { fileName, isImage, isVideo, isAudio, isFile, extension: extension || '' };
};

/**
 * Проверяет и форматирует российский номер телефона.
 * Если номер телефона начинается с '7', он возвращает номер как есть.
 * Если он начинается с '8', первый символ заменяется на '7'.
 * Если он начинается с любой другой цифры, номеру предшествует префикс '7'.
 *
 * @param {string} phoneNumber - Номер телефона для проверки.
 * @returns {string} Проверенный и отформатированный номер телефона.
 */
export const validatePhoneNumber = (phoneNumber: string): string => {
  const isSevenOnFirstChar = phoneNumber.startsWith('7');
  const isEightOnFirstChar = phoneNumber.startsWith('8');

  return isSevenOnFirstChar
    ? phoneNumber
    : isEightOnFirstChar
    ? `7${phoneNumber.slice(1)}`
    : `7${phoneNumber}`;
};

/**
 * Открывает новую страницу по клику на кнопку
 * @param isTargetBlank {boolean} флаг открытия страницы в новом окне
 * @param href {string} ссылка по которой откроется окно
 */
export const changePageOnClick = ({
  isTargetBlank,
  href,
}: {
  isTargetBlank: boolean;
  href: string;
}) => {
  const target = isTargetBlank ? '_blank' : '_self';
  const newWindow = window.open(href, target, 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

/**
 * Скачивает файл по ссылке не перезагружая страницу
 * @param href {string} путь для скачивания
 * @param isTargetSelf {boolean}
 */
export const downloadByLink = (href: string, isTargetSelf?: boolean) => {
  const link = document.createElement('a');
  link.download = 'file';
  link.target = `${isTargetSelf ? '_self' : '_blank'}`;

  link.href = href;

  link.click();
};

/**
 * Функция возвращает даты начала и конца текущего квартала
 * @function getCurrentQuarter
 * @return [Date, Date];
 */
export const getCurrentQuarter = () => {
  // Get Today's date()
  const today = new Date();
  // Get current quarter
  const currentQuarter = Math.floor(today.getMonth() / 3);
  // Get current year
  const year = today.getFullYear();
  // Get current quarter's start and end date
  const quarterStart = new Date(year, currentQuarter * 3, 1);
  const quarterEnd = new Date(year, (currentQuarter + 1) * 3, 0);
  return [quarterStart, quarterEnd];
};

/**
 * Функция создает объект кастомных ссылок для компонента DateRangeDashboardPicker
 * Возвращает массив объектов { label: текст ссылки, date: массив из двух дат(начало и конец периода) }[]
 * @function getCustomShortcuts
 */
export const getCustomShortcuts = () => {
  const now = new Date();
  const tenMinutesAgo = new Date();
  tenMinutesAgo.setMinutes(tenMinutesAgo.getMinutes() - 10);
  const tenHoursAgo = new Date();
  tenHoursAgo.setHours(tenHoursAgo.getHours() - 10);
  const oneDayAgo = new Date();
  oneDayAgo.setDate(oneDayAgo.getDate() - 1);
  const sevenDayAgo = new Date();
  sevenDayAgo.setDate(sevenDayAgo.getDate() - 7);
  const fourteenDaysAgo = new Date();
  fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);
  const currentMonth = new Date();
  currentMonth.setDate(1);
  const prevMonthStart = new Date();
  prevMonthStart.setMonth(prevMonthStart.getMonth() - 1);
  prevMonthStart.setDate(1);
  const prevMonthEnd = new Date();
  prevMonthEnd.setDate(1);
  prevMonthEnd.setDate(prevMonthEnd.getDate() - 1);
  const [currentQuarterStart] = getCurrentQuarter();
  const quarter = Math.floor(now.getMonth() / 3);
  const startFullQuarter = new Date(now.getFullYear(), quarter * 3 - 3, 1);
  const endFullQuarter = new Date(
    startFullQuarter.getFullYear(),
    startFullQuarter.getMonth() + 3,
    0,
  );
  const currentYear = new Date(now.getFullYear(), 0, 1);
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);

  return [
    // {
    //   dateRange: [now, now],
    //   label: 'Сегодня',
    // },
    // {
    //   dateRange: [oneDayAgo, now],
    //   label: 'Вчера',
    // },
    {
      dateRange: [sevenDayAgo, now],
      label: 'Последние 7 дней',
    },
    {
      dateRange: [fourteenDaysAgo, now],
      label: 'Последние 14 дней',
    },
    {
      dateRange: [currentMonth, now],
      label: 'Текущий месяц',
    },
    {
      dateRange: [prevMonthStart, prevMonthEnd],
      label: 'Прошлый месяц',
    },
    {
      dateRange: [currentQuarterStart, now],
      label: 'Текущий квартал',
    },
    {
      dateRange: [startFullQuarter, endFullQuarter],
      label: 'Прошлый квартал',
    },
    {
      dateRange: [currentYear, now],
      label: 'Текущий год',
    },
  ];
};

/**
 * Функция из переданного объекта Date выделяет день, месяц, год,
 * объединят все в строку с разделителем "." и возвращает ее
 * @function getDateString
 * @param date {Date} объект Date
 * @return {string} строка с датой в формате DD.MM.YYYY
 */
export const getDateString = (date: Date): string => {
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

/**
 * Форматирует строку даты и времени ISO 8601 в массив с датой и временем.
 * @param {string} dateString - Строка даты и времени в формате ISO 8601.
 * @returns {[string, string]} Массив, где первый элемент - дата в формате дд.мм.гг,
 * а второй элемент - время в формате чч:мм.
 */
export const formatDateAndTime = (dateString: string): [string, string] => {
  const [datePart, timePart] = dateString.split('T');
  const [year, month, day] = datePart.split('-');
  const [hours, minutes] = timePart.split(':');

  const formattedDate = `${day}.${month}.${year.slice(-2)}`;
  const formattedTime = `${hours}:${minutes}`;

  return [formattedDate, formattedTime];
};

export const getPastDate = (interval: 'days' | 'weeks' | 'months', n: number): string => {
  const today = new Date();

  switch (interval) {
    case 'days':
      today.setDate(today.getDate() - n);
      break;
    case 'weeks':
      today.setDate(today.getDate() - n * 7);
      break;
    case 'months':
      today.setMonth(today.getMonth() - n);
      break;
    default:
      break;
  }

  return today.toISOString();
};

export function groupDataByPeriod(
  data: TTemplateStatisticTableData[],
  periodType: 'week' | 'month' | 'custom',
  customPeriod?: {
    startDate: string;
    endDate: string;
  },
): Record<string, TTemplateStatisticTableData[]> {
  const groupedData: Record<string, TTemplateStatisticTableData[]> = {};

  // Функция для форматирования даты в строку
  function formatDate(date: Date): string {
    const day = `0${date.getDate()}`.slice(-2);
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  // Функция для получения ключа периода
  function getPeriodKey(date: Date): string {
    let start: Date;
    let end: Date;
    switch (periodType) {
      case 'week':
        start = new Date(date);
        start.setDate(start.getDate() - start.getDay() + 1);
        end = new Date(start);
        end.setDate(start.getDate() + 6);
        break;
      case 'month':
        start = new Date(date.getFullYear(), date.getMonth(), 1);
        end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        break;
      case 'custom':
        if (!customPeriod) {
          throw new Error('Custom period dates are required for custom period type');
        }
        start = new Date(customPeriod.startDate);
        end = new Date(customPeriod.endDate);
        if (Number.isNaN(start.getTime()) || Number.isNaN(end.getTime())) {
          throw new Error('Invalid custom period dates');
        }
        break;
      default:
        throw new Error('Invalid period type');
    }
    return `${formatDate(start)} - ${formatDate(end)}`;
  }

  // Группируем данные
  data.forEach(item => {
    const date = new Date(item.date);
    const periodKey = getPeriodKey(date);

    if (!groupedData[periodKey]) {
      groupedData[periodKey] = [];
    }

    groupedData[periodKey].push(item);
  });

  return groupedData;
}

/**
 * Очищает значение инпута по переданному реф
 * @param ref {React.RefObject<HTMLInputElement>} реа на input элемент
 */
export const clearCurrentRefValue = (ref: React.RefObject<HTMLInputElement>) => {
  if (ref.current) {
    ref.current.value = '';
    ref.current.files = null;
  }
};

/**
 * Формирует и вставляет HTML в переданный элемент
 * так же устанавливает курсор в элементе
 * @param element {HTMLDivElement} - div с редактируемым контентом
 */
export const parseDivValue: TParseDivValue =
  element =>
  ({ text, selectionStart, selectionEnd, specialKeyDict }) => {
    element.innerHTML = getInnerHtml(text, specialKeyDict);

    setSelectionOffset(element, selectionStart, selectionEnd);

    setTimeout(() => {
      element.focus();
    }, 10);

    return element.innerText;
  };

export const cut = ({
  text,
  cutStartIndex,
  cutEndIndex,
}: {
  text: string;
  cutStartIndex: number;
  cutEndIndex: number;
}): string => {
  const before = text.slice(0, cutStartIndex);
  const after = text.slice(cutEndIndex);

  return `${before}${after}`;
};

/**
 * Проводит фильтрацию объекта fullWhenSendSelectOption и в зависимости от значения whenSend,
 * возвращает новый объект
 * @param whenSend {string} тип шаблона (когда отправлять шаблон)
 * @param fullWhenSendSelectOption {TSimpleStringObj} объект для формирования выпадающего списка в компоненте WhenSend
 */
export const getWhenSendSelectOption = ({
  whenSend,
  fullWhenSendSelectOption,
}: {
  whenSend: string;
  fullWhenSendSelectOption: Record<TAppLanguage, TSimpleStringObj>;
}): Record<TAppLanguage, TSimpleStringObj> => {
  const { eng, rus } = fullWhenSendSelectOption;

  const newEng = Object.fromEntries(
    Object.entries(eng).filter(item => {
      if (whenSend === WHEN_SEND_VALUE.EVENT) {
        return item[0] === WHEN_SEND_VALUE.EVENT;
      }
      return item[0] !== WHEN_SEND_VALUE.EVENT;
    }),
  );

  const newRus = Object.fromEntries(
    Object.entries(rus).filter(item => {
      if (whenSend === WHEN_SEND_VALUE.EVENT) {
        return item[0] === WHEN_SEND_VALUE.EVENT;
      }
      return item[0] !== WHEN_SEND_VALUE.EVENT;
    }),
  );

  return { eng: newEng, rus: newRus };
};

/**
 * Валидирует массив удаляет из него пустые элементы и элементы состоящие из пробелов
 * @param words {string[]} массив запросов клиентов
 */
export const validateAutoResponseWords = (words: string[]): string[] => {
  return words.filter(word => !!word && !!word.trim());
};

/**
 * Добавляет переданное количество часов к текущему времени
 * @param incrementHour количество чесов которые нужно прибавить к текущему времени
 */
export const getIncrementedCurrentTime = (incrementHour: number): string => {
  const incrementInMs = incrementHour * 3600000;
  const incrementedTimeInMs = new Date().getTime() + incrementInMs;

  return new Date(incrementedTimeInMs).toLocaleTimeString('ru-RU').slice(0, -3);
};

/**
 * Возвращает timeStamp текущей даты в полночь
 * @return {number}
 */
export const getCurrentTimeStampAtStartOfDay = (date: Date): number => {
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();

  return new Date(currentYear, currentMonth, currentDay, 0, 0, 0).getTime();
};

/**
 * Возвращает данные для рендеринга дня в календаре
 * @param sendOuts массив с запланированными рассылками
 * @param calendarDay данные дня
 * @param appLanguage выбранный язык приложения
 * @param selectedMonth выбранный месяц
 */
export const getCalendarElementData: TGetCalendarElementData = ({
  sendOuts,
  calendarDay,
  appLanguage,
  selectedMonth,
}) => {
  const { monthIndex, dayNumberInWeek, timestamp } = calendarDay;
  const sendOutIdStartedInCurrentDay: string[] = [];

  const currentTime = getCurrentTimeStampAtStartOfDay(new Date());

  const weekDayText = weekDaysGetDayFormat[appLanguage][dayNumberInWeek - 1];

  const isPast = currentTime > timestamp;

  const isGrayText = monthIndex !== selectedMonth || isPast;

  const sendOutElements: TSendOutElement[] = [];

  sendOuts.forEach(item => {
    const {
      startDate,
      endDate,
      duration,
      isNew,
      sendOutId,
      isActiveSendOut,
      daySendOutLimit,
      totalCount,
      remainder,
    } = item;
    if (startDate && endDate && duration) {
      const startDateAtStartOfDay = getCurrentTimeStampAtStartOfDay(startDate);
      const endDateAtStartOfDay = getCurrentTimeStampAtStartOfDay(endDate);

      let isSendOutElementFind = false;

      let isHead = false;

      let isTail = false;

      let isNewSendOut = false;

      let isActive = false;

      let sendOutNumber = '';

      // let remainder = 0;

      let widthInPercents = 100;

      if (startDateAtStartOfDay === timestamp) {
        isSendOutElementFind = true;
        sendOutIdStartedInCurrentDay.push(sendOutId);
        isHead = true;
        isNewSendOut = isNew;
        sendOutNumber = String(sendOutId) || '';
        isActive = isActiveSendOut;
      }

      if (startDateAtStartOfDay < timestamp && endDateAtStartOfDay > timestamp) {
        isSendOutElementFind = true;
        isNewSendOut = isNew;
        sendOutNumber = String(sendOutId) || '';
        isActive = isActiveSendOut;
      }

      if (endDateAtStartOfDay === timestamp) {
        isSendOutElementFind = true;
        sendOutIdStartedInCurrentDay.push(sendOutId);
        isTail = true;
        isNewSendOut = isNew;
        sendOutNumber = String(sendOutId) || '';
        isActive = isActiveSendOut;

        // remainder = +totalCount % +daySendOutLimit;

        if (remainder && remainder !== 0) {
          // const sendOutDuration = endDate.getTime() - startDate.getTime();
          // const width = (sendOutDuration / ONE_DAY_IN_MS) * 100;

          // widthInPercents = width;
          // console.log(width);

          widthInPercents = (remainder / daySendOutLimit) * 100;
        }

        if (totalCount) {
          widthInPercents = ((+totalCount % +daySendOutLimit) / daySendOutLimit) * 100;
        }
      }

      if (isSendOutElementFind) {
        sendOutElements.push({
          isHead,
          isTail,
          endDate,
          isActive,
          isNewSendOut,
          sendOutNumber,
          widthInPercents,
          remainder: remainder || +totalCount % +daySendOutLimit,
        });
      }
    }
  });

  return {
    isPast,
    isGrayText,
    weekDayText,
    sendOutElements,
    sendOutIdStartedInCurrentDay: new Set(sendOutIdStartedInCurrentDay),
  };
};

/**
 * Проверяет помещается ли новая рассылка в выбранную дату
 * @param newSendOut Данные новой рассылки
 * @param sendOuts Данные запранированных рассылок
 */
export const checkNewSendOut = (newSendOut: TSendOutsData, sendOuts: TSendOutsData[]): boolean => {
  const { startDate, endDate } = newSendOut;

  if (startDate && endDate) {
    const newSendOutStartDateTimeStamp = startDate.getTime();
    const newSendOutEndDateTimeStamp = endDate.getTime();

    let isGood = true;

    sendOuts.forEach(sendOut => {
      if (sendOut.startDate && sendOut.endDate && !sendOut.isNew) {
        const sendOutStartDateTimeStamp = sendOut.startDate.getTime();
        const sendOutEndDateTimeStamp = sendOut.endDate.getTime();

        if (
          newSendOutStartDateTimeStamp < sendOutStartDateTimeStamp &&
          newSendOutEndDateTimeStamp > sendOutStartDateTimeStamp
        ) {
          isGood = false;
        }

        if (
          newSendOutStartDateTimeStamp > sendOutStartDateTimeStamp &&
          newSendOutEndDateTimeStamp < sendOutEndDateTimeStamp
        ) {
          isGood = false;
        }

        if (
          newSendOutStartDateTimeStamp > sendOutStartDateTimeStamp &&
          newSendOutStartDateTimeStamp < sendOutEndDateTimeStamp
        ) {
          isGood = false;
        }
      }
    });

    return isGood;
  }

  return false;
};
/**
 * Сортирует переданный массив с рассылками для корректного отображения в календаре
 * @param sedOutsData
 */
export const sortSendOutsData = (sedOutsData: TSendOutsData[]): TSendOutsData[] => {
  return sedOutsData.sort((a, b) => {
    return new Date(a?.startDate || 0).getTime() - new Date(b?.startDate || 0).getTime();
  });
};

/**
 * Функция принимает в параметрах мини/макс значения и значение по умолчанию
 * сравнивает значение переменной value c lowLimit, upLimit.
 * если значение больше/меньше upLimit/lowLimit возвращаем upLimit/lowLimit
 * если значение не определено или при преобразовании в число возвращается NaN возвращает значение по умолчанию
 * @function validateValue
 * @param lowLimit {number} - числовое значение, которое определяет нижний предел допустимого диапазона.
 * @param upLimit {number} - числовое значение, которое определяет верхний предел допустимого диапазона.
 * @param value {string} - строковое значение, которое требуется проверить.
 * @param initialValue {string} - строковое значение, которое будет возвращено в случае, если value не удовлетворяет условиям валидации.
 */
export const validateValue: TValidateValue = ({ lowLimit, upLimit, value, initialValue }) => {
  if (value === '') {
    return initialValue;
  }
  if (Number.isNaN(Number(value))) {
    return initialValue;
  }
  if (Number(value) < lowLimit) {
    return initialValue;
  }
  if (upLimit && Number(value) > upLimit) {
    return String(upLimit);
  }
  return value;
};

/**
 * Функция из переданного объекта Date выделяет год, месяц, день,
 * объединят все в строку с разделителем "-" добавляет 00:00:00
 * и возвращает ее
 * @function getDateString
 * @param date {Date} объект Date
 * @return {string} строка даны в формате YYYY-MM-DD HH:MM:SS
 */
export const getDateStringToGetData = (date: Date): string => {
  return date.toJSON().slice(0, -5);
};

/**
 * Функция из созданного объекта Date отнимает 14 дней выделяет выделяет год, месяц, день,
 * объединят все в строку с разделителем "-" добавляет 00:00:00
 * и возвращает ее
 * @function getDateString
 * @param date {Date} объект Date
 * @return {string} строка даны в формате YYYY-MM-DD HH:MM:SS
 */
export const getFourteenDaysBackData = () => {
  const fourteenDaysAgo = new Date();
  fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);

  return {
    fourteenDaysAgo: fourteenDaysAgo.toJSON().slice(0, -5),
  };
};

/**
 * Функция из созданного объекта Date отнимает 14 дней выделяет выделяет год, месяц, день,
 * объединят все в строку с разделителем "-" добавляет 00:00:00
 * и возвращает ее
 * @function getDateString
 * @param date {Date} объект Date
 * @return {string} строка даны в формате YYYY-MM-DD HH:MM:SS
 */
export const getStartOfYearData = () => {
  const startOfYearDate = new Date();
  startOfYearDate.setDate(1);
  startOfYearDate.setMonth(0);

  return {
    startOfYearDate: startOfYearDate.toJSON().slice(0, -5),
  };
};

/**
 * Функция сравнивает переданное значение конверсии с данными объекта содержащего
 * максимальное значение диапазона в качестве ключа и цвет диапазона в качестве значения ключа
 * проверяет вхождение значения конверсии в диапазон и возвращает цвет диапазона
 * @function getDateString
 * @param value {number}
 * @param conversionColors {Record<number, string>}
 * @return {string} строка цвета
 */
export const getConversionColor = (
  value: number,
  conversionColors: Record<number, string>,
): string => {
  let conversionColor = 'red';

  Object.entries(conversionColors).forEach(([range, color]) => {
    if (value >= +range) {
      conversionColor = color;
    }
  });

  return conversionColor;
};
