import { TButtonText } from '@models/index';
import { TConnectionStatusText } from '@redux/connection/models';

export enum CONNECT_API_ACTIONS {
  GET_QR_CODE = 'get QR',
  LOGOUT_BOT = 'logout',
  CLEAN_MESSAGE_QUERY = 'clear message q',
  REBOOT_BOT = 'reboot',
}

export const BUTTON_TEXT: TButtonText = {
  restart: 'Перезагрузить Бота',
  clean: 'Очистить очередь',
  stop: 'Остановить Бота',
  qr: 'Получить QR-код',
};

export const BUTTON_TEXT_ENG: TButtonText = {
  restart: 'Restart Bot',
  clean: 'Clear queue',
  stop: 'Stop Bot',
  qr: 'Get QR code',
};

export const QR_LIFE_TIME = 60000;

export enum CONNECTION_STATUS {
  OK = 'authenticated',
  NO_CONNECT = 'gotQrCode',
  ERROR = 'blocked',
  WAITING = 'loading',
}

export const FILIAL_CONNECT_STATUSES = ['authenticated', 'blocked', 'loading', 'gotQrCode'];

export const connectionStatusText: Record<string, TConnectionStatusText> = {
  eng: {
    authenticated: 'Connected',
    blocked: 'Error',
    gotQrCode: 'Not connected',
    loading: 'Waiting',
  },
  rus: {
    authenticated: 'Подключен',
    blocked: 'Ошибка',
    gotQrCode: 'Не подключен',
    loading: 'Ожидание',
  },
};

export const connectionStatusFallback: Record<string, Record<string, string>> = {
  eng: {
    fallbackStatus: 'Status unknown',
  },
  rus: {
    fallbackStatus: 'Статус неизвестен',
  },
};

export const CONNECTION_PAGE_TEXT = {
  header: 'Подключение',
  connectionStatus: 'Статус подключения:',
  filial: 'Филиал: {{filialAdders}}',
  botConnectionList: {
    header: 'Подключить бота в Whatsapp',
    ul: {
      first: 'зайдите в вотсап на телефоне',
      second: 'откройте меню (настройки) вотсапа',
      third: 'далее - Связанные (привязанные) устройства',
      fourth: 'нажмите "Привязка устройства", откроется камера для сканирования QR кода',
    },
    secondUl: {
      header: 'На этой странице',
      ul: {
        first: 'запросите QR код, нажмите кнопку "Получить QR код" под этим сообщением',
        second: 'отсканируйте полученный QR код камерой Whatsapp',
      },
    },
  },
  clearMessageQueryModal: {
    title: 'Очистить очередь сообщений?',
    description:
      'Пока бот был отключен, в очереди накопилось несколько сообщений, которые не были отправлены вовремя. Они будут отправлены автоматически после подключения бота.',
    cancelButton: 'Оставить сообщения',
    deleteButton: 'Удалить сообщения',
  },
  BUTTON_TEXT,
  table: {
    header: 'Очередь сообщений',
    number: '№',
    phone: 'Номер телефона',
    message: 'Сообщение',
    searchInput: {
      buttonText: 'Найти',
      placeholder: 'Поиск',
    },
    pagination: 'Показать строк:',
    showMore: 'Показать ещё',
    noMessages: 'Нет сообщений',
  },
};

export const CONNECTION_PAGE_TEXT_ENG = {
  header: 'Connecting',
  connectionStatus: 'Connection status:',
  filial: 'Branch: {{filialAdders}}',
  botConnectionList: {
    header: 'Connect bot in Whatsapp',
    ul: {
      first: 'go to WhatsApp on your phone',
      second: 'open the WhatsApp menu (settings)',
      third: 'further - Linked devices',
      fourth: 'click "Device Linking", the camera will open to scan the QR code',
    },
    secondUl: {
      header: 'On this page',
      ul: {
        first: 'request a QR code, click the "Get QR code" button under this message',
        second: 'scan the received QR code with the Whatsapp camera',
      },
    },
  },
  BUTTON_TEXT: BUTTON_TEXT_ENG,
  table: {
    header: 'Message queue',
    number: '№',
    phone: 'Phone number',
    message: 'Message',
    searchInput: {
      buttonText: 'Find',
      placeholder: 'Search',
    },
    pagination: 'Show lines:',
    showMore: 'Show more',
    noMessages: 'No messages',
  },
};
