import React, { memo, useEffect } from 'react';
//
import { NoAccess } from '@components/noAccess';
import { ErrorMessage } from '@blocks/errorMessage';
import { BlackListTemplate } from '@templates/blackList';
import { getAccountsData } from '@redux/accounts/selectors';
import { getBlackListData } from '@redux/blackList/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getBlackList } from '@redux/blackList/blackListThunks';

const BlackListPage = memo(() => {
  const dispatch = useAppDispatch();
  const {
    isError,
    isRefresh,
    isRetries,
    isAddSuccess,
    isAccessDenied,
    isAddingNumber,
    isDeleteSuccess,
    isAddingNumberError,
    isDeleteNumberError,
  } = useAppSelector(getBlackListData);

  // получаем объект selectedFilial
  const { selectedFilial } = useAppSelector(getAccountsData);

  // запрашивает список номеров при добавлении/удалении номера из списка
  useEffect(() => {
    if (isRefresh) {
      dispatch(getBlackList());
    }
  }, [dispatch, isRefresh]);

  // Запрашивает список номеров повторно при ошибке токена
  useEffect(() => {
    if (isRetries) {
      setTimeout(() => dispatch(getBlackList()), 100);
    }
  }, [dispatch, isRetries]);

  // Запрашивает список номеров при загрузке страницы и смене филиала
  useEffect(() => {
    if (!isRetries && selectedFilial?.accId) {
      dispatch(getBlackList());
    }
  }, [dispatch, isRetries, selectedFilial?.accId]);

  return (
    <>
      {isError && !isAccessDenied ? <ErrorMessage /> : null}
      {isAccessDenied && !isError ? <NoAccess /> : null}
      {!isError && !isAccessDenied ? (
        <BlackListTemplate
          isRefresh={isRefresh}
          isAddSuccess={isAddSuccess}
          isAddingNumber={isAddingNumber}
          isDeleteSuccess={isDeleteSuccess}
          isDeleteNumberError={isDeleteNumberError}
          isAddingNumberError={isAddingNumberError}
          className='px-4 sm:px-2 lg:px-0'
        />
      ) : null}
    </>
  );
});

BlackListPage.displayName = 'BlackListPage';

export default BlackListPage;
