import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

interface ITemplateNavItemProps {
  /**
   * Ссылка в NavLink
   * @param {string}
   */
  to: string;
  /**
   * Текст в NavLink
   * @param {string}
   */
  text: string;
  /**
   * Флаг активности ссылки, добавляет background
   * @param {boolean}
   */
  isActive: boolean;
  /**
   * Опциональный callback обрабатывает click на NavLink
   * @param {() => void}
   * @default
   */
  onClick?: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const TemplateNavItem = memo(
  ({ to, text, isActive, onClick, className = '' }: ITemplateNavItemProps) => {
    const linkClassName = `text-green1 hover:text-green1 min-h-[2.5rem] leading-[2.5rem] px-[0.75rem] hover:no-underline ${
      isActive ? 'border-b-green1 border-b-4 text-myrtle' : ''
    } tracking-[0.022em] ${className}`;

    return (
      <NavLink to={to} onClick={onClick} className={linkClassName}>
        {text}
      </NavLink>
    );
  },
);

TemplateNavItem.displayName = 'TemplateNavItem';
