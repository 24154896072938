import { templateKeysDict } from '@data/dict';
import { TKeyData } from '@redux/template/models';
import { reviewsEmptyTemplate } from '@redux/reviews/mockData';
import { TReviewData, TReviewInListMapData } from '@redux/reviews/models';
import { TAppLanguage, TSimpleStringObj, TSimpleStringObjLevel2 } from '@models/index';
import { WHEN_SEND2_SELECT_OPTION_REVIEWS, WHEN_SEND_SELECT_OPTION_REVIEWS } from '@const/reviews';

export interface IInitialState {
  [key: string]:
    | boolean
    | string
    | number
    | TReviewData
    | TReviewInListMapData[]
    | TSimpleStringObj
    | TSimpleStringObjLevel2
    | TKeyData[]
    | Record<TAppLanguage, TSimpleStringObjLevel2>;
  isLoading: boolean;
  isRefresh: boolean;
  isError: boolean;
  isAccessDenied: boolean;
  isDeleting: boolean;
  isDeletingError: boolean;
  isSwitchingError: boolean;
  isSave: boolean;
  isSaveError: boolean;
  isErrorModalOpen: boolean;
  tooManyStepsError: boolean;
  tooManyReviewsError: boolean;
  blurFromTextareaName: string;
  selectionEnd: number;
  selectionStart: number;
  stepId: number;
  errorMessage: string;
  warningMessage: string;
  recordTagNewValue: string;
  servicesNewValue: string;
  categoriesNewValue: string;
  staffNewValue: string;
  clientCategoryNewValue: string;
  channelsPhoneListNewValue: string;
  tgAlertsNewValue: string;
  blacklistNewValue: string;
  reviewsList: TReviewInListMapData[];
  oneReviewData: TReviewData;
  oldOneReviewData: TReviewData;
  deleteChainId: string;
  isSaveSuccess: boolean;
  isDeleteStepSuccess: boolean;
  isChainDeletingSuccess: boolean;
  isStepDeletingSuccess: boolean;
  isRetries: boolean;
  retries: number;
  updateNavId: string;
  whenSendSelectOption: Record<TAppLanguage, TSimpleStringObj>;
  whenSend2SelectOption: Record<TAppLanguage, TSimpleStringObjLevel2>;
  categoriesDataList: TSimpleStringObjLevel2;
  successMessage: string;
  textareaKeysData: TKeyData[];
  specialKeyDict: TSimpleStringObj;
}

export const initialState: IInitialState = {
  isLoading: false,
  isRefresh: false,
  isError: false,
  isAccessDenied: false,
  isDeleting: false,
  isDeletingError: false,
  isSave: false,
  isSaveError: false,
  isSwitchingError: false,
  isErrorModalOpen: false,
  tooManyStepsError: false,
  tooManyReviewsError: false,
  blurFromTextareaName: '',
  selectionEnd: 0,
  selectionStart: 0,
  stepId: 0,
  servicesNewValue: '',
  clientCategoryNewValue: '',
  categoriesNewValue: '',
  blacklistNewValue: '',
  recordTagNewValue: '',
  channelsPhoneListNewValue: '',
  tgAlertsNewValue: '',
  staffNewValue: '',
  errorMessage: '',
  warningMessage: '',
  reviewsList: [],
  oneReviewData: reviewsEmptyTemplate,
  oldOneReviewData: reviewsEmptyTemplate,
  deleteChainId: '',
  isChainDeletingSuccess: false,
  isStepDeletingSuccess: false,
  isSaveSuccess: false,
  isDeleteStepSuccess: false,
  isRetries: false,
  updateNavId: '',
  retries: 1,
  whenSendSelectOption: WHEN_SEND_SELECT_OPTION_REVIEWS,
  whenSend2SelectOption: WHEN_SEND2_SELECT_OPTION_REVIEWS,
  categoriesDataList: {},
  successMessage: '',
  textareaKeysData: [],
  specialKeyDict: templateKeysDict.rus.common,
};
