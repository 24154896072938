import { TSimpleStringObj } from '@models/index';

/**
 * Список HTTP запросов
 * @enum {string} HTTP_METHODS
 */
export enum HTTP_METHODS {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

/**
 * Список URL для EP
 * @enum {string} END_POINTS_URL
 */
export enum END_POINTS_URL {
  GET_TOKEN = '/get_token',
  UPDATE_TOKEN = '/upd_token',
  ACCOUNTS = '/accounts',
  CONNECT = '/connection',
  GET_QR = '/connect_action',
  LOGOUT_INSTANCE = '/connect_action',
  CLEAR_QUERY = '/connect_action',
  RELOAD_BOT = '/connect_action',
  TEMPLATE_LIST = '/template_list',
  TEMPLATE_LIST_STATISTICS = '/all_templates_stats',
  TEMPLATE_DETAIL_STATS = 'template_detail_stats',
  TEMPLATE_DETAIL_STATS_EXCEL = 'template_detail_stats_excel',
  TEMPLATE_STAT = '/one_template_stats/version',
  TEMPLATE_STAT_BY_TIME = '/one_template_stats/time',
  TEMPLATE_STAT_SEND_ONLY_LOST = '/template/stat',
  TEMPLATE = '/template',
  SWITCH_TEMPLATE = '/switch_template',
  DELETE_TEMPLATE = '/delete_template',
  DUPLICATE_TEMPLATE = '/copy_template',
  COPY_TEMPLATE_TO_BRANCHES = '/copy_template_to_branches',
  SAVE_TEMPLATE = '/template',
  REVIEWS_LIST = '/all_reviews',
  REVIEW = '/get_review',
  DELETE_REVIEW = '/delete_review',
  SAVE_REVIEW = '/save_review',
  REVIEW_SWITCH = '/review_switch',
  REVIEW_STEP_SWITCH = '/review_step_switch',
  MAILING_PAYMENT_STATUS = '/check_payed',
  MAILING = '/action_sendouts',
  STATISTICS = '/stats_sendout_detail',
  STATISTICS_DOWNLOAD = '/stats_sendout_detail_excel',
  TEST_MAILING = '/test_sendout',
  STOP_MAILING = '/stop_sendout',
  PARSE_XLSX_FILE = '/parse_xlsx',
  START_MAILING = '/start_sendout',
  STATS_SENDOUT = '/stats_sendout',
  STATS_SENDOUT_COUNT = '/stats_sendout_count',
  SENDOUT_CALENDAR = '/calendar',
  SENDOUT_DAY_DATA = '/calendar_detail',
  SENDOUT_ALLOW_TO_RUN = '/allowed_to_run',
  ACCESSES = '/accesses',
  BLACKLIST = '/blacklist',
  SETTINGS = '/settings',
  PAY = '/pay',
  AUTO_PAY_LINK = '/autopay_link',
  CHANGE_CARD = '/change_card',
  AUTO_PAY_OFF = '/autopay_off',
  CREATE_INVOICE = '/create_invoice',
  SEND_INVOICE_TO_TG = '/send_invoice_to_tg',
  UPDATE_REVIEW = '/update_review',
  DELETE_STEP_REVIEW = '/review_step_delete',
  AUTH_ID = '/tg/auth',
  CHECK_PIN = '/tg/check_pin',
  GET_INFO = '/get_info',
  PARTNERS_REFF_SALON = '/reff_salon',
  PARTNERS_REFF_SALON_PAY_HISTORY = '/reff_salon/payments_history',
  PARTNERS_REFF_CONSULTANT = '/reff_consultant',
  PARTNERS_REFF_CONSULTANT_OPERATIONS = '/reff_consultant/operations',
  PARTNERS_REFF_CONSULTANT_HISTORY = '/reff_consultant/history',
  ADD_LEAD_DATA = '/add_promo_leads',
  GET_LEAD_DATA = '/get_promo_leads',
  SEND_FEEDBACK_TO_ASANA = '/send_feedback_to_asana',
  CHAT_HISTORY = '/chat_history',
  AUTO_RESPONSE = '/get_key_phrases',
  AUTO_RESPONSE_ADD_ITEM = '/add_key_phrase',
  AUTO_RESPONSE_DELETE_ITEM = '/delete_key_phrase',
  AUTO_RESPONSE_ACTIVITY_SWITCH = '/active_key_phrase',
  AUTO_RESPONSE_UPDATE_ITEM = '/update_key_phrase',
  SEND_LINK_IN_WA = '/send_deep_link_wa',
  GET_AUTO_CONNECTION_FORM = '/get_form',
  ACCEPT_AUTO_CONNECTION_STEP = '/accept_step',
  REQUEST_CALL_BACK = '/call_back_support',
  INVITE_FRIENDS_ACTION_LIST = '/loyalty/loyalty_messages',
  INVITE_FRIENDS_SWITCH_TEMPLATE = '/loyalty/switch_template',
  INVITE_FRIENDS_ACTION = '/loyalty/loyalty_message',
  INVITE_FRIENDS_CREATE_ACTION = '/loyalty/create',
  INVITE_FRIENDS_UPDATE_ACTION = '/loyalty/loyalty_message',
  INVITE_FRIENDS_ANALYTICS = '/loyalty/loyalty_analytics',
  INVITE_FRIENDS_CARDS = '/loyalty/loyalty_cards',
  INVITE_FRIENDS_MINIMAL_PRICE = '/min_cost_service',
  INVITE_FRIENDS_COPY_TEMPLATE = '/loyalty/copy_loyalty_message_to_branches',
  YCLIENTS_START_PAGE_REQUEST = '/autoconnect_request',
}

/**
 * Список имен EP для получения списка категорий
 * @const CATEGORIES_EP_NAME
 */
export const CATEGORIES_EP_NAME: TSimpleStringObj = {
  services: '/service',
  staff: '/staff',
  staffs: '/staff',
  categories: '/category',
  clientCategory: '/client_category',
  recordTag: '/record_labels',
};

/**
 * Список action для страницы ЧС
 * @enum BLACKLIST_ACTION
 */
export enum BLACKLIST_ACTION {
  ADD_NUMBER = 'add',
  DELETE_NUMBER = 'delete',
}

/**
 * Список query параметров
 * @enum QUERY_PARAMS
 */
export enum QUERY_PARAMS {
  ID = 'id',
  USER_ID = 'user_id',
  STATE = 'state',
  ACC_ID = 'acc_id',
  EVENT_TEMPLATE = 'em',
  TIME_TEMPLATE = 'tm',
  SEND_OUT_ACCOUNT = 'sa',
  TEMPLATE_ID = 'template_id',
  NEW_TEMPLATE = 'new_template',
  STEP_NUMBER = 'step_number',
  SWITCH = 'switch',
  SENDOUT_ID = 'sendout_id',
  TEMPL_ID = 'temp_id',
  GET_EXEL_FILE = 'excel',
  METHOD = 'method',
  PROMO_CODE = 'promo_code',
  PHONE = 'phone',
  BRANCH_NAME = 'branch_name',
  BRANCH = 'branch',
  COMMENT = 'comment',
  AUTO_RESPONSE_WORD = 'word',
  AUTO_RESPONSE_RESPONSE = 'reply',
  AUTO_RESPONSE_CAPTION = 'caption',
  AUTO_RESPONSE_ACTIVE = 'active',
  TG_ID = 'tg_id',
  FORM_HASH = 'form_hash',
  TELEGRAM_ID = 'telegram_id',
  START_PAGE = 'start_page',
  LOYALTY_MESSAGE_ID = 'loyalty_message_id',
  SEND_OUT_TIME_START = 'time_start',
  SEND_OUT_QUOTE_FOR_DAY = 'quote_for_day',
  DATE = 'date',
  INSTANCE_ID = 'instance_id',
  SENDOUT_BATCH_LEN = 'sendout_batch_len',
  COUNT = 'count',
  TIME_TO_MSK = 'time_to_msk',
  SORTED_BY = 'sortedBy',
  CURRENT_PAGE = 'currentPage',
  ROWS_PER_PAGE = 'rowsPerPage',
  SEARCH_STRING = 'searchString',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  SORT_DIRECTION = 'sortDirection',
  FORM_URL = 'form_url',
  BRANCH_ID = 'branch_id',
  LEAD_ID = 'lead_id',
}
