import React, { memo, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//
import { Menu } from '@uikit/Menu';
import { Chips } from '@uikit/Chips';
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';
import { Icon } from '@blueprintjs/core';
import { TAppLanguage } from '@models/index';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { ToggleSwitch } from '@blocks/toggleSwitch';
import { INVITE_FRIENDS_DUPLICATE_DROPDOWN } from '@const/inviteFriend';
import { ReactComponent as NumberIcon } from '@img/inviteFriend/numbers.svg';
import { TActionOnTemplate, TOnChangeHandler, TOnclickHandler } from '@shared/types';
import {
  TSwitchActionData,
  TInviteFriendListData,
  INVITE_FRIENDS_LIST_DATA_KEY_NAMES,
} from '@redux/inviteFriendList/types';
import { createPortal } from 'react-dom';
import { Overlay } from '@atoms/overlay';

type TInviteFriendCardProps = {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Ссылка для перехода на шаблон
   * @param {string}
   */
  link: string;
  /**
   * Callback для удаления шаблона
   * @param {TActionOnTemplate}
   */
  deleteAction: TActionOnTemplate;
  /**
   * Callback для копирования шаблона акции
   * @param {TActionOnTemplate}
   */
  duplicateCation: TActionOnTemplate;
  /**
   * Флаг состояния запроса на удаление
   * @param {boolean}
   */
  isDeleting: boolean;
  /**
   * Флаг успешного удаления шаблона
   * @param {boolean}
   */
  isDeletingSuccess: boolean;
  /**
   * Флаг ошибки при удалении шаблона
   * @param {boolean}
   */
  isDeletingError: boolean;
  /**
   * Флаг переключения активности шаблона
   * @param {boolean}
   */
  isSwitching: boolean;
  /**
   * Callback для переключения состояния шаблона
   * @param {(actionData: TSwitchActionData) => void}
   */
  switchActionActivity: (actionData: TSwitchActionData) => void;
  /**
   * Параметр для доступа к редактированию шаблона
   * @param {boolean}
   * */
  isEditTemplatesAccess: boolean;
  /**
   * Данные шаблона для отображения в карточке
   * @param {any}
   */
  data: TInviteFriendListData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const InviteFriendCard = memo(
  ({
    data,
    link,
    isDeleting,
    appLanguage,
    isSwitching,
    deleteAction,
    duplicateCation,
    isDeletingError,
    isDeletingSuccess,
    switchActionActivity,
    isEditTemplatesAccess,
    className = '',
  }: TInviteFriendCardProps) => {
    const { t } = useTranslation();

    const { id, title, isActive, cardType, bonusType } = data;

    // состояние модалки подтверждения удаления шаблона
    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Состояние для меню действий с шаблоном
    const [isShowMenu, setIsShowMenu] = useState<boolean>(false);

    // Переключение отображения меню
    const firstSelectMenuToggle = () => {
      setIsShowMenu(prevState => !prevState);
    };

    // закрывает модалку удаления если удаление прошло успешно,
    // или возникла ошибка
    useEffect(() => {
      if (isDeletingSuccess || isDeletingError) {
        setIsOpen(false);
      }
    }, [isDeletingError, isDeletingSuccess]);

    // открывает модалку на подтверждение удаления шаблона из списка
    const modalToggle: TOnclickHandler = useCallback(event => {
      event.preventDefault();
      setIsOpen(prevState => !prevState);
    }, []);

    // Обработчик для копирования шаблона
    const duplicateActionHandler: TOnclickHandler = useCallback(
      event => {
        event.preventDefault();
        setIsShowMenu(false);
        duplicateCation(id);
      },
      [id, duplicateCation],
    );

    // вызывает коллбак удаления шаблона и передает в него его ID шаблона
    const deleteTemplate: TOnclickHandler = useCallback(
      event => {
        event.preventDefault();
        setIsShowMenu(false);
        deleteAction(id);
      },
      [id, deleteAction],
    );

    // включает или отключает шаблон по клику на переключатель
    const toggleTaskActive: TOnChangeHandler = useCallback(
      event => {
        event.preventDefault();
        switchActionActivity({ id, status: isActive });
      },
      [id, isActive, switchActionActivity],
    );

    // Пункты меню
    const menuItems = Object.entries(INVITE_FRIENDS_DUPLICATE_DROPDOWN[appLanguage]).map(
      ([key, value], index) => (
        <MenuItem
          key={key}
          onClick={!index ? duplicateActionHandler : modalToggle}
          text={
            <span
              className={`${
                index ? 'text-persianRed' : 'text-blackText'
              } leading-6 tracking-[0.0275em]`}>
              {value}
            </span>
          }
        />
      ),
    );

    // Меню для действий с шаблоном
    const menu = (
      <Menu
        view='raised'
        className={`absolute top-[3.5rem] right-4 z-[51] ${
          menuItems.length === 1 ? 'one_item_ul' : ''
        }`}>
        {menuItems}
      </Menu>
    );

    const cardTypeTitle = cardType ? cardType.title : t('INVITE_FRIEND_PAGE_TEXT.cardNotSelected');

    const bonusTypeText =
      bonusType === 'percent'
        ? t('INVITE_FRIEND_PAGE_TEXT.bonusTypePercents')
        : t('INVITE_FRIEND_PAGE_TEXT.bonusTypeFirstVisit');

    const wrapperStyle =
      'relative border border-lightGray flex flex-col grow shrink font-inter text-[1.5rem] text-blackText p-4 rounded-lg hover:shadow-btnHoverShadow transition-all';

    const iconWrapperStyle = 'flex items-center justify-center mr-2 h-[1.75rem] w-[1.75rem]';

    const textStyle = 'text-body_text text-grayText max-w-[85%]';

    return (
      <div className={`${wrapperStyle} ${className}`}>
        <div className='flex items-center mb-4'>
          {isEditTemplatesAccess && (
            <ToggleSwitch
              checked={isActive}
              disabled={!cardType}
              onChangeHandler={toggleTaskActive}
              name={INVITE_FRIENDS_LIST_DATA_KEY_NAMES.IS_ACTIVE}
              className={`${isSwitching ? 'pointer-events-none' : ''} mr-2 z-[1]`}
            />
          )}
          <span className='text-darkGray text-body_text'>#{id}</span>
          {!cardType ? (
            <Chips
              round
              removable
              type='dense'
              color='warning'
              tag={t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.draftTag')}
              className='ml-2'
            />
          ) : null}
        </div>
        <Link
          to={link}
          aria-label={t('INVITE_FRIEND_PAGE_TEXT.template', { id })}
          className='absolute top-0 right-0 bottom-0 left-0'
        />
        <p className='inline-block text-ellipsis whitespace-nowrap font-medium text-black text-h3Mobile tracking-[0.004em] leading-[1.5rem] overflow-hidden pr-[2rem] mb-2'>
          {id === title ? t('INVITE_FRIEND_PAGE_TEXT.template', { id }) : title}
        </p>
        <div className='flex flex-col justify-between items-center mt-[3rem] w-full'>
          <div className='flex items-center justify-start w-full'>
            <div className={iconWrapperStyle}>
              <Icon icon='credit-card' color='#787885' size={20} />
            </div>
            <span className={textStyle}>{cardTypeTitle}</span>
          </div>
          <div className='flex items-center justify-between w-full'>
            <div className='flex items-center'>
              <div className={iconWrapperStyle}>
                {bonusType === 'percent' ? (
                  <Icon icon='percentage' color='#787885' size={20} />
                ) : (
                  <NumberIcon />
                )}
              </div>
              <span className={`${textStyle} tracking-[0.0275em]`}>{bonusTypeText}</span>
            </div>
            {/* <div className='bg-greengage p-[0.625rem] rounded-full flex items-center justify-center h-10 w-10'>
              <Icon icon='timeline-bar-chart' size={20} color='#37833B' />
            </div> */}
          </div>
        </div>
        {isEditTemplatesAccess && (
          <button
            onClick={firstSelectMenuToggle}
            className='absolute top-[0.675rem] right-[0.675rem] rotate-90 bg-none border-none cursor-pointer p-1'>
            <Icon icon='more' size={20} color='#4A4B57' />
          </button>
        )}
        {isShowMenu ? menu : null}
        {/* {isEditTemplatesAccess && (
          <DeleteButton callback={modalToggle} className='absolute top-4 right-4' />
        )} */}
        {isOpen ? (
          <Dialog
            type='default'
            view='raised'
            color='default'
            backdropOpacity={40}
            text={<span>{title}</span>}
            title={t('INVITE_FRIEND_PAGE_TEXT.deleteModal.title', { id })}
            rightButton={
              <div className='flex flex-wrap'>
                <Button
                  dense
                  type='action'
                  view='outlined'
                  color='default'
                  onClick={modalToggle}
                  text={t('INVITE_FRIEND_PAGE_TEXT.deleteModal.cancelButton')}
                  className='mt-2 mr-6 !min-w-[6rem]'
                />
                <Button
                  dense
                  type='action'
                  view='filled'
                  color='danger'
                  loading={isDeleting}
                  disabled={isDeleting}
                  onClick={deleteTemplate}
                  text={t('INVITE_FRIEND_PAGE_TEXT.deleteModal.deleteButton')}
                  className='mt-2 !min-w-[6rem]'
                />
              </div>
            }
            isOpen={isOpen}
            onClose={modalToggle}
            className='whitespace-pre-line'
          />
        ) : null}
        {isShowMenu &&
          createPortal(<Overlay onClickCallback={firstSelectMenuToggle} />, document.body)}
      </div>
    );
  },
);

InviteFriendCard.displayName = 'InviteFriendCard';
