import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { TOnChangeHandler } from '@shared/types';
import { TextWithInfoIcon } from '@blocks/textWithInfoIcon';
import { SETTINGS_DATA_KEY_NAMES } from '@redux/settings/models';
import { SettingInputWithLabel } from '@blocks/settingInputWithLabel';
import { SETTINGS_BILLING_DATA_KEY_NAMES, TSettingsBillData } from '@const/settings';

type TSettingsBillDataProps = {
  /**
   * Callback для изменения значения в инпутах
   * @param {TOnChangeHandler}
   */
  billDataOnChangeHandler: TOnChangeHandler;
  /**
   * Данные счета
   * @param {TSettingsBillData}
   */
  billData: TSettingsBillData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const SettingsBillData = memo(
  ({ billData, billDataOnChangeHandler, className = '' }: TSettingsBillDataProps) => {
    const { t } = useTranslation();

    return (
      <div className={`flex flex-col ${className}`}>
        <TextWithInfoIcon
          marginBottom='0.5'
          fontSize='text-[1.25rem]'
          className='mb-4 max-w-[21.25rem] w-full'
          variant={SETTINGS_DATA_KEY_NAMES.BUILD_DATA}
        />
        <SettingInputWithLabel
          value={billData.name}
          onChangeHandler={billDataOnChangeHandler}
          htmlFor={SETTINGS_BILLING_DATA_KEY_NAMES.NAME}
          className='mb-8 max-w-[21.25rem] w-full'>
          {t('SETTINGS_PAGE_TEXT.nameOfLegalEntity')}
        </SettingInputWithLabel>
        <SettingInputWithLabel
          value={billData.inn}
          onChangeHandler={billDataOnChangeHandler}
          htmlFor={SETTINGS_BILLING_DATA_KEY_NAMES.INN}
          className='max-w-[21.25rem] w-full'>
          {t('SETTINGS_PAGE_TEXT.tin')}
        </SettingInputWithLabel>
      </div>
    );
  },
);

SettingsBillData.displayName = 'SettingsBillData';
