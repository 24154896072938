import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
//
import { Menu } from '@uikit/Menu';
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';
import { PATH_NAMES } from '@data/dict';
import { createPortal } from 'react-dom';
import { Icon } from '@blueprintjs/core';
import { Overlay } from '@atoms/overlay';
import { TAppLanguage } from '@models/index';
import { useAppSelector } from '@store/store';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { ToggleSwitch } from '@blocks/toggleSwitch';
import { getAccountsData } from '@redux/accounts/selectors';
import { TEMPLATE_DUPLICATE_DROPDOWN } from '@const/templtateList';
import { getTemplateListPageData } from '@redux/templateList/selectors';
import { TASK_LIST_KEY_NAMES, TTemplateInfo } from '@redux/templateList/types';
import { TActionOnTemplate, TOnChangeHandler, TOnclickHandler } from '@shared/types';

interface ICardProps {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Callback для удаления шаблона
   * @param {TActionOnTemplate}
   */
  deleteTask: TActionOnTemplate;
  /**
   * Callback для копирования шаблона
   * @param {TActionOnTemplate}
   */
  duplicateTemplate: TActionOnTemplate;
  /**
   * Callback для переключения состояния шаблона
   * @param {(id: string) => void}
   */
  switchTask: (id: string) => void;
  /**
   * Параметр для доступа к редактированию шаблона
   * @param {boolean}
   * */
  isEditTemplatesAccess: boolean;
  /**
   * Данные шаблона для отображения в карточке
   * @param {TTemplateInfo}
   */
  data: TTemplateInfo;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const Card = memo(
  ({
    data,
    deleteTask,
    switchTask,
    appLanguage,
    duplicateTemplate,
    isEditTemplatesAccess,
    className = '',
  }: ICardProps) => {
    const { t } = useTranslation();
    const { selectedFilial } = useAppSelector(getAccountsData);
    const { isDeleting, isDeletingSuccess, isDeletingError } =
      useAppSelector(getTemplateListPageData);

    const { id, name, time, active, link, title } = data;

    // Состояние для модального окна подтверждения удаления
    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Состояние для меню действий с шаблоном
    const [isShowMenu, setIsShowMenu] = useState<boolean>(false);

    // Переключение отображения меню
    const firstSelectMenuToggle = () => {
      setIsShowMenu(prevState => !prevState);
    };

    // Закрывает окно подтверждения при успешном удалении или возникновении ошибки
    useEffect(() => {
      if (isDeletingSuccess || isDeletingError) {
        setIsOpen(false);
      }
    }, [isDeletingSuccess, isDeletingError]);

    // Открывает/закрывает модальное окно подтверждения удаления шаблона
    const modalToggle: TOnclickHandler = event => {
      event.preventDefault();
      setIsShowMenu(false);
      setIsOpen(prevState => !prevState);
    };

    // Обработчик для копирования шаблона
    const duplicateTemplateHandler: TOnclickHandler = event => {
      event.preventDefault();
      setIsShowMenu(false);
      duplicateTemplate(id);
    };

    // Обработчик для удаления шаблона
    const deleteTemplate: TOnclickHandler = event => {
      event.preventDefault();
      deleteTask(id);
    };

    // Обработчик для переключения состояния активности шаблона
    const toggleTaskActive: TOnChangeHandler = event => {
      event.preventDefault();
      switchTask(id);
    };

    // Пункты меню
    const menuItems = Object.entries(TEMPLATE_DUPLICATE_DROPDOWN[appLanguage]).map(
      ([key, value], index) => (
        <MenuItem
          key={key}
          onClick={!index ? duplicateTemplateHandler : modalToggle}
          text={
            <span
              className={`${
                index ? 'text-persianRed' : 'text-blackText'
              } leading-6 tracking-[0.0275em]`}>
              {value}
            </span>
          }
        />
      ),
    );

    // Меню для действий с шаблоном
    const menu = (
      <Menu
        view='raised'
        className={`absolute top-[3.5rem] right-4 z-[51] ${
          menuItems.length === 1 ? 'one_item_ul' : ''
        }`}>
        {menuItems}
      </Menu>
    );

    return (
      <div
        className={`relative border border-lightGray flex flex-col grow shrink font-inter text-[1.5rem] text-blackText p-4 rounded-lg hover:shadow-btnHoverShadow transition-all ${className}`}>
        <div className='flex items-center mb-4'>
          {isEditTemplatesAccess && (
            <ToggleSwitch
              checked={active}
              name={TASK_LIST_KEY_NAMES.ACTIVE}
              onChangeHandler={toggleTaskActive}
              className='mr-2 z-[1]'
            />
          )}
          <span className='text-darkGray text-body_text'>{id}</span>
        </div>
        <Link
          to={link}
          aria-label={t('TEMPLATE_LIST_PAGE_TEXT.card.ariaLabel', { id })}
          className='absolute top-0 right-0 bottom-0 left-0'
        />
        <p className='break-words font-medium text-black text-h3Mobile tracking-[0.004em] leading-[1.5rem] overflow-hidden pr-[2rem] mb-2'>
          {id.slice(1) === title ? t('TEMPLATE_LIST_PAGE_TEXT.card.title', { id }) : `${title}`}
        </p>
        <p className='text-body_text h-12 overflow-hidden mb-9'>{name}</p>
        <div className='flex justify-between items-center'>
          <p className='bg-dot bg-left-0.625rem bg-no-repeat pl-[2.25rem] text-body_text text-grayText max-w-[85%]'>
            {time.slice(1)}
          </p>
          {data.sendOnlyLostClient ? (
            <div className='bg-greengage p-[0.625rem] rounded-full z-[2]'>
              <Link
                aria-label='template detail statistics'
                className='flex'
                to={`${PATH_NAMES.TEMPLATE_STAT}?acc_id=${selectedFilial?.accId}&temp_id=${id.slice(
                  1,
                )}`}>
                <Icon icon='timeline-bar-chart' size={20} color='#37833B' />
              </Link>
            </div>
          ) : null}
        </div>
        {isEditTemplatesAccess && (
          <button
            onClick={firstSelectMenuToggle}
            className='absolute top-[0.675rem] right-[0.675rem] rotate-90 bg-none border-none cursor-pointer p-1'>
            <Icon icon='more' size={20} color='#4A4B57' />
          </button>
        )}
        {isShowMenu ? menu : null}
        <Dialog
          type='default'
          view='raised'
          color='default'
          isOpen={isOpen}
          backdropOpacity={40}
          onClose={modalToggle}
          text={<span>{`${name} \n\n ${time}`}</span>}
          title={t('TEMPLATE_LIST_PAGE_TEXT.card.deleteDialogTitle', { id })}
          rightButton={
            <div className='flex flex-wrap'>
              <Button
                dense
                type='action'
                view='outlined'
                color='default'
                onClick={modalToggle}
                text={t('TEMPLATE_LIST_PAGE_TEXT.card.cancelButton')}
                className='mt-2 mr-6 !min-w-[6rem]'
              />
              <Button
                dense
                type='action'
                view='filled'
                color='danger'
                loading={isDeleting}
                disabled={isDeleting}
                onClick={deleteTemplate}
                text={t('TEMPLATE_LIST_PAGE_TEXT.card.confirmButton')}
                className='mt-2 !min-w-[6rem]'
              />
            </div>
          }
          className='whitespace-pre-line'
        />
        {isShowMenu &&
          createPortal(<Overlay onClickCallback={firstSelectMenuToggle} />, document.body)}
      </div>
    );
  },
);

Card.displayName = 'Card';
