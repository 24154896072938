import React, { memo, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
//
import { TWithChildren } from '@models/index';

interface INavLiProps extends TWithChildren {
  /**
   * Ссылка в Link
   * @param {string}
   * @default
   */
  href: string;
  /**
   * Опциональный параметр флаг активности ссылки, добавляет background
   * @param {boolean}
   * @default
   */
  active?: boolean;
  /**
   * Опциональный параметр открывает/закрывает выпадающий список
   * @param {boolean}
   * @default
   */
  isOpen?: boolean;
  /**
   * Опциональный параметр меняет стиль шрифта
   * @param {boolean}
   * @default
   */
  isSecondary?: boolean;
  /**
   * Опциональный параметр добавляет иконку выпадающего списка
   * @param {boolean}
   * @default
   */
  isDropDown?: boolean;
  /**
   * Опциональный callback обрабатывает click на элементе списка
   * @param {() => void}
   * @default
   */
  onClick?: () => void;
  /**
   * Опциональный параметр callback для открытия/закрытия выпадающего списка
   * @param {() => void}
   * @default
   */
  toggleDropdown?: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const NavLi = memo(
  ({
    href,
    active = false,
    isOpen = false,
    onClick = () => '',
    isDropDown = false,
    isSecondary = false,
    toggleDropdown = () => '',
    className = '',
    children,
  }: INavLiProps) => {
    // изменяются стили отображения пункта меню в зависимости от опционального параметра isSecond
    const style = useMemo(
      () => ({
        default:
          'flex flex-nowrap items-center m-0 px-0 text-blackText font-inter hover:no-underline hover:text-blackText text-body_text min-h-[1.75rem] w-full',
      }),
      [],
    );

    const memoizedChildren = useMemo(() => [children], [children]);

    return (
      <li
        onClick={onClick}
        className={`flex items-center ${isDropDown ? '' : 'pl-[2.75rem]'} ${
          active ? 'bg-ivory' : ''
        } py-[0.375rem]`}>
        {isDropDown ? (
          <div
            onClick={toggleDropdown}
            className={`pl-2.5 pr-[0.875rem] mb-1
                ${isOpen ? '' : 'opacity-50 -rotate-90'} cursor-pointer`}>
            <svg
              width='20'
              height='20'
              fill='none'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                fill='#4A4B57'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M16 7C16 6.45 15.55 6 15 6H5C4.45 6 4 6.45 4 7C4 7.24 4.1 7.46 4.24 7.63L4.23 7.64L9.23 13.64L9.24 13.63C9.43 13.85 9.69 14 10 14C10.31 14 10.57 13.85 10.76 13.63L10.77 13.64L15.77 7.64L15.76 7.63C15.9 7.46 16 7.24 16 7Z'
              />
            </svg>
          </div>
        ) : null}
        <NavLink
          to={href}
          className={`${style.default} ${className} ${
            isSecondary ? 'font-normal' : 'font-medium'
          }`}>
          {memoizedChildren}
        </NavLink>
      </li>
    );
  },
);

NavLi.displayName = 'NavLi';
