import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Table } from '@uikit/Table/Table';
import { useAppDispatch } from '@store/store';
import { TMappedTableData } from '@models/index';
import { TEMPLATE_DETAIL_TABS } from '@const/templates';
import { TableTextBlock } from '@components/tableTextBlock';
import { sortTemplateTableData } from '@redux/templateStats/templateStatsSlice';
import {
  TTemplateStatisticTableData,
  TTemplateStatisticDataByTime,
} from '@redux/templateStats/models';

type TTemplateStatTableProps = {
  /**
   * Варионт отображения данных таблицы в зависимости от выбранного вида отображения версия\период
   */
  version: TEMPLATE_DETAIL_TABS;
  /**
   * Даные шаблона
   * @param {TTemplateStatisticTableData[]}
   */
  template: TTemplateStatisticTableData[];
  /**
   * Данные шаблона сгрупированные по времени
   * @type {TTemplateStatisticDataByTime}
   */
  templateByTime: TTemplateStatisticDataByTime;
  /**
   * Опциональный параметр строки классов
   * @param {string}
   */
  className?: string;
};

export const TemplateStatTable = memo(
  ({ template, className = '', version, templateByTime }: TTemplateStatTableProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const renderText = ({ text, rate }: { text: number; rate: number }) => {
      return (
        <div className='flex flex-col'>
          <p className='text-blackText'>{text}</p>
          <p className='text-textareaBlack text-inner_text'>{`${rate}%`}</p>
        </div>
      );
    };

    /**
     * Генерирует данные таблицы, отображая массив элементов статистики шаблона.
     *
     * @param {TTemplateStatisticTableData[]} data - Массив элементов статистики шаблона, который требуется отобразить.
     * @return {TMappedTableData[]} Отображенные данные таблицы.
     */
    const mapToTableData = useCallback(
      (data: TTemplateStatisticTableData[]): TMappedTableData[] => {
        const defaultTableData: [] = [];

        if (!data.length) {
          return defaultTableData;
        }

        return data.map(item => ({
          version: item.version,
          date: <p>{`${item.date}, ${item.time}`}</p>,
          text: <TableTextBlock text={item.text} className='min-w-[18rem]' />,
          sendTime: item.sendTime,
          sended: renderText({ text: item.sended, rate: item.sendRate }),
          answered: renderText({ text: item.answered, rate: item.answeredRate }),
          recorded: renderText({ text: item.recorded, rate: item.recordedRate }),
          earned: <p>{item.earned}</p>,
          earnedOneMessage: <p>{item.earnOneMsg}</p>,
        }));
      },
      [],
    );

    const mapToTableDataByTime = useCallback(({ data }: TTemplateStatisticDataByTime) => {
      const defaultTableData: [] = [];

      if (!data.length) {
        return defaultTableData;
      }

      return data.map(item => ({
        versions: item.versions.join(', '),
        period: item.period,
        sended: renderText({ text: item.sendCount, rate: item.sendRate }),
        answered: renderText({ text: item.answeredCount, rate: item.answeredRate }),
        recorded: renderText({ text: item.recordedCount, rate: item.recordedRate }),
        earned: item.earnedCount,
        earnedOneMessage: item.earnOneMsg,
      }));
    }, []);

    // Хэндлер для сортировки таблицы
    const sortHandler = (columnName: keyof TMappedTableData, order: string) => {
      dispatch(sortTemplateTableData({ columnName, order }));
    };

    // Массив заголовков таблицы
    const tableHeaderVersions = useMemo(
      () => [
        { id: 'version', label: t('TEMPLATE_PAGE_TEXT.statistics.table.version.version') },
        { id: 'date', label: t('TEMPLATE_PAGE_TEXT.statistics.table.version.date') },
        { id: 'text', label: t('TEMPLATE_PAGE_TEXT.statistics.table.version.text'), noSort: true },
        {
          id: 'sendTime',
          label: t('TEMPLATE_PAGE_TEXT.statistics.table.version.sendTime'),
          noSort: true,
        },
        { id: 'sended', label: t('TEMPLATE_PAGE_TEXT.statistics.table.version.sended') },
        { id: 'answered', label: t('TEMPLATE_PAGE_TEXT.statistics.table.version.answered') },
        { id: 'recorded', label: t('TEMPLATE_PAGE_TEXT.statistics.table.version.recorded') },
        { id: 'earned', label: t('TEMPLATE_PAGE_TEXT.statistics.table.version.earned') },
        {
          id: 'earnedOneMessage',
          label: t('TEMPLATE_PAGE_TEXT.statistics.table.version.earnedOneMessage'),
        },
      ],
      [t],
    );

    const tableHeaderTime = useMemo(
      () => [
        {
          id: 'versions',
          label: t('TEMPLATE_PAGE_TEXT.statistics.table.time.version'),
          noSort: true,
        },
        { id: 'period', label: t('TEMPLATE_PAGE_TEXT.statistics.table.time.period'), noSort: true },
        { id: 'sended', label: t('TEMPLATE_PAGE_TEXT.statistics.table.time.sended') },
        { id: 'answered', label: t('TEMPLATE_PAGE_TEXT.statistics.table.time.answered') },
        { id: 'recorded', label: t('TEMPLATE_PAGE_TEXT.statistics.table.time.recorded') },
        { id: 'earned', label: t('TEMPLATE_PAGE_TEXT.statistics.table.time.earned') },
        {
          id: 'earnedOneMessage',
          label: t('TEMPLATE_PAGE_TEXT.statistics.table.time.earnedOneMessage'),
        },
      ],
      [t],
    );

    const getTableHeader = useMemo(() => {
      if (version === TEMPLATE_DETAIL_TABS.VERSION) {
        return tableHeaderVersions;
      }

      if (version === TEMPLATE_DETAIL_TABS.TIME) {
        return tableHeaderTime;
      }
    }, [version, tableHeaderVersions, tableHeaderTime]);

    const getPosition = useMemo(() => {
      if (version === TEMPLATE_DETAIL_TABS.VERSION) {
        return ['left', 'left', 'left', 'right', 'right', 'right', 'right', 'right', 'right'];
      }

      if (version === TEMPLATE_DETAIL_TABS.TIME) {
        return ['left', 'left', 'right', 'right', 'right', 'right', 'right'];
      }
    }, [version]);

    const getTableData = useMemo(() => {
      if (version === TEMPLATE_DETAIL_TABS.VERSION) {
        return mapToTableData(template);
      }

      if (version === TEMPLATE_DETAIL_TABS.TIME) {
        return mapToTableDataByTime(templateByTime);
      }
    }, [version, template, templateByTime, mapToTableData, mapToTableDataByTime]);

    return (
      <div className={`w-screen xl:max-w-7xl overflow-x-auto ${className}`}>
        <Table
          name={getTableHeader}
          data={getTableData}
          position={getPosition}
          type='def'
          view='flat'
          color='default'
          pages
          rowsPerPageOptions={[5, 10, 20]}
          defRows={5}
          checkboxes={false}
          paperClassName='no_shadow'
          onSortCallback={sortHandler}
          style={{ boxShadow: 'none', marginLeft: '6px' }}
          labelRowsPerPage={t('TEMPLATE_PAGE_TEXT.statistics.table.pagination')}
        />
      </div>
    );
  },
);

TemplateStatTable.displayName = 'TemplateStatTable';
