import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { BANNER_LINK } from '@const/pay';
import giftIcon from '@img/pay/giftIcon.png';
import { changePageOnClick } from '@helpers/index';
import { REACH_GOAL_EVENT_NAME, YANDEX_METRIKA_ID } from '@const/yandexMetrika';

type INewBannerProps = {
  /**
   * Опциональный параметр - строка классов
   * @param {string}
   */
  className?: string;
};

export const NewBanner = memo(({ className = '' }: INewBannerProps) => {
  const { t } = useTranslation();

  const changePageOnClickHandler = useCallback(() => {
    try {
      // eslint-disable-next-line
      // @ts-ignore
      ym(YANDEX_METRIKA_ID, 'reachGoal', REACH_GOAL_EVENT_NAME.YCLIENTS_MARKETPLACE_FEEDBACK);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
    changePageOnClick({
      isTargetBlank: true,
      href: BANNER_LINK,
    });
  }, []);

  return (
    <div
      className={`border border-lightGreen bg-aquaSpring rounded-lg flex flex-col pt-3 pb-6 px-6 ${className}`}>
      <img src={giftIcon} alt='gift' height={84} width={48} />
      <span className='font-bold text-blackText leading-8 tracking-[0.0075em] mb-1'>
        {t('PAY_PAGE_TEXT.commercialHeader')}
      </span>
      <span className='text-balticSea leading-6 tracking-[0.0075em] mb-3'>
        {t('PAY_PAGE_TEXT.commercialDescription')}
        <span className='font-bold text-balticSea leading-6 tracking-[0.0075em] ml-1'>
          {t('PAY_PAGE_TEXT.commercialGift')}
        </span>
      </span>
      <div className='bg-white w-[9rem]'>
        <Button
          fill
          dense
          type='default'
          view='outlined'
          color='success'
          onClick={changePageOnClickHandler}
          text={t('PAY_PAGE_TEXT.commercialButton')}
        />
      </div>
    </div>
  );
});

NewBanner.displayName = 'NewBanner';
