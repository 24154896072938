import { PATH_NAMES } from '@data/dict';
import { TAppLanguage, TSimpleStringObj } from '@models/index';
import { TInviteFriendBonusType } from '@redux/inviteFriendList/types';
import { TStatisticsSortFields } from '@redux/inviteFrendsStatistics/types';
import { TCardType, TInviteFriendsKeys } from '@redux/inviteFriendsAction/type';

export const YCLIENTS_CARD_CREATION_INSTRUCTION = 'https://support.yclients.com/58-60-123';

export const INVITE_FRIENDS_PAGES_NAME_DICT: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [PATH_NAMES.INVITE_FRIEND_LIST]: 'Promotions for friends',
    [PATH_NAMES.INVITE_FRIEND_STATISTICS]: 'Statistics',
  },
  rus: {
    [PATH_NAMES.INVITE_FRIEND_LIST]: 'Акции для друзей',
    [PATH_NAMES.INVITE_FRIEND_STATISTICS]: 'Статистика',
  },
};

export const FAST_FEEDBACK_BUTTON_TEXT_KEYS = {
  NOT_CLEAR: 'NOT_CLEAR',
  NO_RESULT: 'NO_RESULT',
  DIFFICULT_TO_SET_UP: 'DIFFICULT_TO_SET_UP',
  SOMETHING_IS_NOT_WORK: 'SOMETHING_IS_NOT_WORK',
};

export const FAST_FEEDBACK_BUTTON_TEXT: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [FAST_FEEDBACK_BUTTON_TEXT_KEYS.NOT_CLEAR]: 'Not clear',
    [FAST_FEEDBACK_BUTTON_TEXT_KEYS.NO_RESULT]: 'No result',
    [FAST_FEEDBACK_BUTTON_TEXT_KEYS.DIFFICULT_TO_SET_UP]: 'Difficult to set up',
    [FAST_FEEDBACK_BUTTON_TEXT_KEYS.SOMETHING_IS_NOT_WORK]: 'Something is not working',
  },
  rus: {
    [FAST_FEEDBACK_BUTTON_TEXT_KEYS.NOT_CLEAR]: 'Непонятно',
    [FAST_FEEDBACK_BUTTON_TEXT_KEYS.NO_RESULT]: 'Нет результата',
    [FAST_FEEDBACK_BUTTON_TEXT_KEYS.DIFFICULT_TO_SET_UP]: 'Сложно настроить',
    [FAST_FEEDBACK_BUTTON_TEXT_KEYS.SOMETHING_IS_NOT_WORK]: 'Что-то не работает',
  },
};

export const INVITE_FRIENDS_SORT_DROPDOWN_VALUES: Record<string, TStatisticsSortFields> = {
  SUM: 'amountPaymentsChild',
  CONVERSION: 'countChildPaid',
};

export const INVITE_FRIENDS_SORT_DROPDOWN: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [INVITE_FRIENDS_SORT_DROPDOWN_VALUES.SUM]: 'Amount of earnings',
    [INVITE_FRIENDS_SORT_DROPDOWN_VALUES.CONVERSION]: 'Number of clients who visited the salon',
  },
  rus: {
    [INVITE_FRIENDS_SORT_DROPDOWN_VALUES.SUM]: 'Сумме заработка',
    [INVITE_FRIENDS_SORT_DROPDOWN_VALUES.CONVERSION]: 'Количеству клиентов посетивших салон',
  },
};

export enum WHEN_SEND_VALUE_INVITE_FRIENDS {
  BEFORE_START = 'before_start',
  AFTER_END = 'after_end',
  ALL_DAY_RECS_NOTIFICATION = 'all_day_recs_notifications',
}

export const WHEN_SEND_SELECT_OPTION_INVITE_FRIENDS: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [WHEN_SEND_VALUE_INVITE_FRIENDS.BEFORE_START]: 'Before your visit',
    [WHEN_SEND_VALUE_INVITE_FRIENDS.AFTER_END]: 'After the visit',
    [WHEN_SEND_VALUE_INVITE_FRIENDS.ALL_DAY_RECS_NOTIFICATION]: 'For those who have a record',
  },
  rus: {
    [WHEN_SEND_VALUE_INVITE_FRIENDS.BEFORE_START]: 'До визита',
    [WHEN_SEND_VALUE_INVITE_FRIENDS.AFTER_END]: 'После визита',
    [WHEN_SEND_VALUE_INVITE_FRIENDS.ALL_DAY_RECS_NOTIFICATION]: 'Тем у кого запись',
  },
};

export const WHEN_SEND2_SELECT_OPTION_VALUE_INVITE_FRIENDS: Record<
  TAppLanguage,
  { [key: string]: TSimpleStringObj }
> = {
  eng: {
    before_start: {
      '24.0': 'In 24 hours',
      '12.0': 'Before 12 hours',
      '6.0': 'In 6 hours',
      '3.0': 'In 3 hours',
      '2.0': 'In 2 hours',
      '1.5': 'In 1.5 hours',
      '1.0': 'In 1 hour',
      '0.5': 'In 30 minutes',
      '0.25': 'In 15 minutes',
    },
    after_end: {
      '24.0': 'In 24 hours',
      '12.0': 'In 12 hours',
      '6.0': 'In 6 hours',
      '3.0': 'In 3 hours',
      '2.0': 'In 2 hours',
      '1.5': 'In 1.5 hours',
      '1.0': 'In 1 hour',
      '0.5': 'In 30 minutes',
      '0.25': 'In 15 minutes',
    },
    all_day_recs_notifications: {
      '-1': 'Has been done',
      '0': 'Today',
      '1': 'Will be in',
    },
  },
  rus: {
    before_start: {
      '24.0': 'За 24 часа',
      '12.0': 'За 12 часов',
      '6.0': 'За 6 часов',
      '3.0': 'За 3 часа',
      '2.0': 'За 2 часа',
      '1.5': 'За 1.5 часа',
      '1.0': 'За 1 час',
      '0.5': 'За 30 минут',
      '0.25': 'За 15 минут',
    },
    after_end: {
      '24.0': 'Через 24 часа',
      '12.0': 'Через 12 часов',
      '6.0': 'Через 6 часов',
      '3.0': 'Через 3 часа',
      '2.0': 'Через 2 часа',
      '1.5': 'Через 1.5 часа',
      '1.0': 'Через 1 час',
      '0.5': 'Через 30 минут',
      '0.25': 'Через 15 минут',
    },
    all_day_recs_notifications: {
      '-1': 'Была сделана',
      '0': 'Сегодня',
      '1': 'Будет через',
    },
  },
};

export const WHEN_SEND_INITIAL = WHEN_SEND_VALUE_INVITE_FRIENDS.BEFORE_START;

export const WHEN_SEND_2_INITIAL = '0.25';

export const NOT_SELECTED_CARD_ID = 0;

export const NOT_SELECTED_CARD: Record<TAppLanguage, TCardType> = {
  eng: {
    id: NOT_SELECTED_CARD_ID,
    title: 'Not selected',
    salonGroupId: 0,
  },
  rus: {
    id: NOT_SELECTED_CARD_ID,
    title: 'Не выбрано',
    salonGroupId: 0,
  },
};

export const CARD_NOT_FIND: Record<TAppLanguage, TCardType> = {
  eng: {
    id: NOT_SELECTED_CARD_ID,
    title: 'No card types',
    salonGroupId: 0,
  },
  rus: {
    id: NOT_SELECTED_CARD_ID,
    title: 'Нет типов карт',
    salonGroupId: 0,
  },
};

export const INVITE_FRIENDS_DUPLICATE_DROPDOWN: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    duplicate: 'Create a copy of the stock',
    delete: 'Delete the stock',
  },
  rus: {
    duplicate: 'Создать копию акции',
    delete: 'Удалить акцию',
  },
};

export enum BONUSES {
  NOT_SELECTED = 'notSelected',
  FIRST_VISITS = 'first_visit',
  PERCENTS = 'percent',
}

export const BONUS_TYPE_RESPONSE: Record<string, TInviteFriendBonusType> = {
  first_visit: 'first_visit',
  percent: 'percent',
};

export const BONUS_TYPE: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [BONUSES.NOT_SELECTED]: 'Not selected',
    [BONUSES.FIRST_VISITS]: 'Points',
    [BONUSES.PERCENTS]: 'Percent',
  },
  rus: {
    [BONUSES.NOT_SELECTED]: 'Не выбрано',
    [BONUSES.FIRST_VISITS]: 'Баллы',
    [BONUSES.PERCENTS]: 'Проценты',
  },
};

export const INVITE_FRIEND_PAGE_TEXT = {
  header: 'Приведи друга',
  descriptionPath1: 'Хотите чтобы ваши клиенты сами приглашали новых за бонусы? Для этого:',
  descriptionPath2: {
    numericList: {
      first: 'Создайте новый тип карты лояльности в Yclients.',
      second:
        'Определите как вы хотите начислять бонус за приведенного друга когда она(он) придет на услугу:',
      unorderedList: {
        first: 'один раз но побольше',
        second: 'каждый раз, но поменьше',
      },
      third: 'Настройте акцию для друзей, отредактировав текущую или создав новую',
      forth: 'Следите за тем сколько новых клиентов приходит по рекомендации',
      linkToInstruction: 'Инструкция',
    },
  },
  ability: 'Посмотрите какие возможности вы получаете.',
  videoInstruction: 'Видеоинструкция',
  readMore: 'Читать дальше',
  hide: 'Скрыть',
  faq: 'Частые вопросы',
  createAction: 'Создать акцию для друзей',
  template: 'Акция {{id}}',
  cardNotSelected: 'Карта не выбрана',
  bonusTypeFirstVisit: 'Баллы',
  bonusTypePercents: 'Проценты',
  copyButton: 'Copy to another branch',
  preview: {
    title: 'Превью сообщений',
    showPreviewButton: 'Показать превью',
    firstMessageHeader: 'Шаблон, который предлагает старым клиентам приглашать новых',
    secondMessageHeader:
      'Шаблон, который получит старый клиент и сможет его переслать новому клиенту',
  },
  feedback: {
    header: 'Как вам новый функционал?',
    placeholder: 'Напишите ваше мнение',
    buttonText: 'Отправить',
    rating: '{{rating}}/5 звезд',
    leaveAComment: 'Оставьте комментарий*',
    feedbackSend: 'Отзыв отправлен',
    successModalTitle: 'Спасибо, ваш отзыв успешно отправлен!',
    successModalButton: 'Хорошо',
  },
  deleteModal: {
    title: 'Вы хотите удалить\n акцию {{id}}?',
    cardType: '',
    cancelButton: 'Отмена',
    deleteButton: 'Удалить',
    deletingSuccessMessage: 'Акция №{{id}}\n успешно удалена',
  },
  warningModal: {
    title: 'Вы хотите включить акцию?',
    cancelButton: 'Закрыть',
    activateWarningMessage: `Переподключите приложение для использования функционала "Приведи друга"!\n\n Для корректной работы функционала "Приведи друга" необходимо переподключить приложение в маркетплейсе. Это обеспечит получение необходимых прав для работы с программой лояльности.`,
  },
  actionDuplicate: 'Акция скопирована',
  statistics: {
    sortBy: 'Сортировать по',
    startOfAction: 'Начало акции {{date}}',
    efficiencyOfAction: 'Эффективность акции',
    visitsOfAction: 'Визиты',
    invitationsSent: 'Принято приглашений по акции',
    acceptedInvitation: 'Клиентов записались на услуги',
    visitedSalon: 'Клиентов посетило салон',
    numberOfCustomer: 'Кол-во визитов  клиентов по акции',
    averageVisits: 'Среднее кол-во визитов на одного клиента',
    averageVisitBill: 'Средний чек визита',
    earnedFromNewClients: 'Заработано по акции',
    bonusesPaidToOldClients: 'Начислено партнеру по акции',
    bonusesPaidToNewClients: 'Начислено новым клиентам по акции',
    numberOfProposalsSent: 'Кол-во отправленных предложений',
    conversionToIncomingRequests: 'Конверсия во входящие обращения',
  },
  actionTemplate: {
    draftTag: 'Черновик',
    templateId: '#{{id}}',
    templateHeader: 'Акция {{id}}',
    newTemplateHeader: 'Название акции',
    templateSwitchTitle: 'Действующая акция',
    templateSwitch: 'Включите, чтобы акция начала действовать',
    templateName: 'Название акции',
    templateNamePlaceholder: 'Придумайте название',
    bonusSettings: 'Настройки акции',
    cardType: 'Выберите карту лояльности из Yclients',
    bonusType: 'Тип бонусов',
    inviteFriends: 'Количество баллов, которые получит клиент за приглашенного друга',
    inviteFriendsDescription: '',
    newCustomerPoints: 'Количество баллов новому клиенту при создании первой записи',
    pointsDescription: 'Баллы начисляются единоразово',
    interestRegularCustomer:
      'Процент от чека, который будет получать старый клиент приведший друга',
    percentDescription: 'Проценты начисляются от каждой оплаты услуг новым клиентом',
    minimalPriceWarning:
      'Обратите внимание, что минимальная стоимость услуги в вашем салоне — {{minimalPrice}}₽',
    attention: 'Внимание',
    messages: 'Тексты сообщений',
    textBeforeCounter: 'Начислять баллы старому клиенту до',
    textAfterCounter: 'визитов нового',
    sendingConditionals: 'Условия отправки',
    blacklistPlaceholder: 'Введите номер телефона',
    onlineRecordHeader: 'Отправлять только при онлайн записи',
    onlineRecordDescription: 'Отправлять сообщения клиентам, которые записались онлайн',
    createTemplate: 'Создать шаблон',
    cardTypeNotSelected: 'Вы не можете включить шаблон, так как не указан тип карты.',
    noFirstMessage:
      'Вы не можете включить шаблон, так как не заполнен шаблон который предлагает старым клиентам приглашать новых.',
    noSecondMessage:
      'Вы не можете включить шаблон, так как не заполнен шаблон который получит старый клиент и сможет его переслать новому клиенту.',
    noRequiredKeyError:
      'В шаблоне, который получит старый клиент\n отсутствует обязательный ключ {{key}}.\n Пожалуйста добавьте его.',
    saveTemplate: 'Сохранить изменения',
  },
};

export const INVITE_FRIEND_PAGE_TEXT_ENG = {
  header: 'Refer a friend',
  descriptionPath1: 'Do you want your clients to invite new ones for bonuses? To do this:',
  descriptionPath2: {
    numericList: {
      first: 'Create a new type of loyalty card in Yclients.',
      second:
        'Define how you want to accrue a bonus for a referred friend when he/she comes to the service:',
      unorderedList: {
        first: 'once but more',
        second: 'every time, but less',
      },
      third: 'Set up a promotion for friends by editing the current one or creating a new one',
      forth: 'Keep track of how many new clients come from recommendations',
      linkToInstruction: 'Instruction',
    },
  },
  ability: 'See what opportunities you get.',
  videoInstruction: 'Video instruction',
  readMore: 'Read more',
  hide: 'Hide',
  faq: 'Frequently asked questions',
  createAction: 'Create a promotion for friends',
  template: 'Promotion {{id}}',
  cardNotSelected: 'No card selected',
  bonusTypeFirstVisit: 'Points',
  bonusTypePercents: 'Percentages',
  copyButton: 'Copy to another branch',
  preview: {
    title: 'Message preview',
    showPreviewButton: 'Show preview',
    firstMessageHeader: 'A template that invites old clients to invite new ones',
    secondMessageHeader:
      'A template that an old client will receive and can forward to a new client',
  },
  feedback: {
    header: 'How do you like the new functionality?',
    placeholder: 'Write your opinion',
    buttonText: 'Send',
    rating: '{{rating}}/5 stars',
    leaveAComment: 'Leave comment*',
    feedbackSend: 'Feedback sent',
    successModalTitle: 'Thank you, your feedback has been sent successfully!',
    successModalButton: 'Okay',
  },
  deleteModal: {
    title: 'Do you want to delete\n the {{id}} promotion?',
    cardType: '',
    cancelButton: 'Cancel',
    deleteButton: 'Delete',
    deletingSuccessMessage: 'Promotion №{{id}}\n successfully deleted',
  },
  warningModal: {
    title: 'Do you want to enable the promotion?',
    cancelButton: 'Close',
    activateWarningMessage: `Reconnect the app to use the "Refer a friend" functionality!\n\n For the "Refer a friend" functionality to work correctly, you need to reconnect the app in the marketplace. This will ensure that you have the necessary rights to work with the loyalty program.`,
  },
  actionDuplicate: 'Promotion copied',
  statistics: {
    sortBy: 'Sort by',
    startOfAction: 'Promotion start {{date}}',
    efficiencyOfAction: 'Promotion efficiency',
    visitsOfAction: 'Visits',
    invitationsSent: 'Accepted invitations for the promotion',
    acceptedInvitation: 'Clients signed up for services',
    visitedSalon: 'Clients visited the salon',
    numberOfCustomer: 'Number of customer visits for the promotion',
    averageVisits: 'Average number of visits per customer',
    averageVisitBill: 'Average bill per visit',
    earnedFromNewClients: 'Earned for the promotion',
    bonusesPaidToOldClients: 'Awarded to partner under the promotion',
    bonusesPaidToNewClients: 'Awarded to new clients under the promotion',
    numberOfProposalsSent: 'Number of sent offers',
    conversionToIncomingRequests: 'Conversion to incoming requests',
  },
  actionTemplate: {
    draftTag: 'Draft',
    templateId: '#{{id}}',
    templateHeader: 'Promotion {{id}}',
    newTemplateHeader: 'Promotion name',
    templateSwitchTitle: 'Current promotion',
    templateSwitch: 'Turn on to activate the promotion',
    templateName: 'Promotion name',
    templateNamePlaceholder: 'Come up with a name',
    bonusSettings: 'Promotion settings',
    cardType: 'Select a loyalty card from Yclients',
    bonusType: 'Bonus type',
    inviteFriends: 'The number of points the customer will receive for inviting a friend',
    inviteFriendsDescription: '',
    newCustomerPoints:
      'The number of points a new customer will receive when creating their first record',
    pointsDescription: 'Points accrued once',
    interestRegularCustomer:
      'The percentage of the check that an old client who has referred a friend will receive',
    percentDescription: 'Percentages are accrued from each payment for services by a new client',
    minimalPriceWarning:
      'Please note that the minimum cost of a service in your salon is {{minimalPrice}}₽',
    attention: 'Attention',
    messages: 'Message texts',
    textBeforeCounter: 'Award points to an old client until',
    textAfterCounter: 'visits of a new one',
    sendingConditionals: 'Sending conditions',
    blacklistPlaceholder: 'Enter a phone number',
    onlineRecordHeader: 'Send only for online booking',
    onlineRecordDescription: 'Send messages to clients who have booked online',
    createTemplate: 'Create template',
    cardTypeNotSelected: 'You cannot enable the template because the card type is not specified.',
    noFirstMessage:
      'You cannot enable the template because the template that asks old customers to invite new ones is not filled in.',
    noSecondMessage:
      'You cannot enable the template because the template that the old customer will receive and can forward to the new customer is not filled in.',
    noRequiredKeyError:
      'The template that the old customer will receive\n is missing the required key {{key}}.\n Please add it.',
    saveTemplate: 'Save changes',
  },
};

export const CREATE_CARD_LINK =
  'https://botsarmy.teamly.ru/space/78d25157-1d03-4884-bc5b-5c7b812b4bc5/article/10f67e56-0a0d-4fd2-bf92-9aae58a398dd';

export const NO_CARD_CALLOUT_TEXT: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    description:
      'To create a "Refer a Friend" promotion, you must first create at least one type of card in YCLIENTS',
    instructionTitle: 'Instructions for creating a map type:',
    1: 'Go to Loyalty > Card Types in the YCLIENTS network interface.',
    2: 'Click on the "Add card type" button.',
    3: 'Specify the name of the card type, for example, "Refer a friend Admin Bot."',
    4: 'Select the branch where the card will be valid.',
    5: 'Set up automatic card issuance and points redemption conditions.',
    6: 'Click on the "Save" button.',
    link: 'Detailed instructions',
  },
  rus: {
    description:
      'Для создания акции «Приведи друга» необходимо предварительно создать хотя бы один тип карты в YCLIENTS',
    instructionTitle: 'Инструкция по созданию типа карты:',
    1: 'Перейдите в раздел «Лояльность» > «Типы карт» в сетевом интерфейсе YCLIENTS.',
    2: 'Нажмите на кнопку «Добавить тип карты».',
    3: 'Укажите название типа карты, например, «Приведи друга Админ Бот».',
    4: 'Выберите филиал, в котором будет действовать карта.',
    5: 'Настройте автоматический выпуск карт и условия сгорания баллов.',
    6: 'Нажмите на кнопку «Сохранить».',
    link: 'Подробная инструкция',
  },
};

export const PLACEHOLDER_VARIANTS_IF: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    enterText: 'Enter text',
  },
  rus: {
    enterText: 'Введите текст',
  },
};

export const POINTS_TEXT: Record<TAppLanguage, string[]> = {
  eng: ['point', 'points', 'points'],
  rus: ['балл', 'балла', 'баллов'],
};

export const REQUIRED_KEY = 'partner_link';

export const inviteFriendKeysDict: Record<TAppLanguage, TInviteFriendsKeys> = {
  eng: {
    common: {
      name: `To substitute the client name`,
      full_name: 'Full client name, as in YCL',
      [REQUIRED_KEY]: 'A unique link for a new client to go to the salon chat',
    },
  },
  rus: {
    common: {
      name: `Для подстановки имени клиента`,
      full_name: 'Полное имя клиента, как в YCL',
      [REQUIRED_KEY]: 'Уникальная ссылка для перехода нового клиента в чат салона',
    },
  },
};

export const INVITE_FRIENDS_NEW_BALLOON_VALUE_POSTFIX = 'NewValue';

export const InviteFriendStaticsColors = {
  0: '#43a047',
  2.1: '#43a047',
  4.6: '#43a047',
  7.6: '#43a047',
};
