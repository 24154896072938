import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { BotButton } from '@blocks/botButton';
import { QR_LIFE_TIME } from '@const/connect';
import { CONNECTION_STATUSES, TQRCodeData } from '@models/index';
import { TConnectionStatusData } from '@redux/connection/models';
import { ClearMessageQueryModal } from '@blocks/clearMessageQueryModal';
import { ReactComponent as QrIcon } from '@img/connect/qt-icon-black.svg';

type TConnectionGetQrProps = {
  /**
   * Callback для получения QR кода
   * @param {() => void}
   */
  getQr: () => void;
  /**
   * Флаг процесса получения QR кода
   * @param {boolean}
   */
  isFetchingQR: boolean;
  /**
   * Флаг ошибки получения QR кода
   * @param {boolean}
   */
  isGetQRError: boolean;
  /**
   * Callback для очистки очереди сообщений
   * @param {() => void}
   */
  clearQuery: () => void;
  /**
   * Данные QR кода
   * @param {TQRCodeData | undefined}
   */
  qrData: TQRCodeData | undefined;
  /**
   * Данные страницы подключения
   * @param {TConnectionStatusData}
   */
  data: TConnectionStatusData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const ConnectionGetQr = memo(
  ({
    getQr,
    qrData,
    clearQuery,
    isFetchingQR,
    isGetQRError,
    data,
    className = '',
  }: TConnectionGetQrProps) => {
    const { t } = useTranslation();

    // деструктурезируем state
    const { status, messages } = data;

    // храним строку qr кода
    const [qr, setQr] = useState(qrData?.qr);

    const [isOpen, setIsOpen] = useState(false);

    // Состояние отражает запущен ли таймер или нет
    const timerSetStatusRef = useRef<boolean>(false);

    // Эффект запускается при первом получении QR кода и запрашивает новый через 61 секунду. срок жизни QR кода 1 мин.
    useEffect(() => {
      if (!isFetchingQR && !isGetQRError && qrData) {
        setQr(qrData.qr);
        if (!timerSetStatusRef.current) {
          timerSetStatusRef.current = true;
          setTimeout(() => {
            timerSetStatusRef.current = false;
            getQr();
          }, QR_LIFE_TIME + 1000);
        }
      }
    }, [qr, qrData, getQr, setQr, isFetchingQR, isGetQRError]);

    // Закрывает открытое модальное окно с вопросом о очистке очереди и запрашивает QR код
    const closeModalHandler = useCallback(() => {
      setIsOpen(false);
    }, []);

    // Закрывает окно и запрашивает QR оставляет сообщения в очереди
    const getQwWithoutClearQuery = useCallback(() => {
      setIsOpen(false);
      getQr();
    }, [getQr]);

    // Функция открывает окно с предложением очистить очередь сообщений если есть сообщения в очереди
    const getQrHandler = useCallback(() => {
      if (messages.length) {
        setIsOpen(true);
      } else {
        getQr();
      }
    }, [getQr, messages.length]);

    // Закрывает модалку, очищает очередь сообщений и запрашивает QR
    const clearMessageQuery = useCallback(() => {
      setIsOpen(false);
      clearQuery();
      getQr();
    }, [clearQuery, getQr]);

    return (
      <div className={className}>
        <div className='border border-quartz bg-white rounded-lg pt-[1.375rem] pb-[0.7rem] px-4'>
          <QrIcon fill='#4A4B57' className='h-10 w-10' />
          <div className='mt-5'>
            <h3 className='font-bold text-blackText text-h6Mobile'>
              {t('CONNECTION_PAGE_TEXT.botConnectionList.header')}
            </h3>
            <ul className='pl-[1.5rem] leading-6 list-disc mt-[0.3rem] tracking-wide'>
              <li>{t('CONNECTION_PAGE_TEXT.botConnectionList.ul.first')}</li>
              <li>{t('CONNECTION_PAGE_TEXT.botConnectionList.ul.second')}</li>
              <li>{t('CONNECTION_PAGE_TEXT.botConnectionList.ul.third')}</li>
              <li>{t('CONNECTION_PAGE_TEXT.botConnectionList.ul.fourth')}</li>
            </ul>
            <div className='ml-4'>
              <h4 className='font-medium mt-1'>
                {t('CONNECTION_PAGE_TEXT.botConnectionList.secondUl.header')}
              </h4>
              <ul className='pl-[1.5rem] leading-6 list-disc mt-[0.3rem] tracking-wide'>
                <li>{t('CONNECTION_PAGE_TEXT.botConnectionList.secondUl.ul.first')}</li>
                <li>{t('CONNECTION_PAGE_TEXT.botConnectionList.secondUl.ul.second')}</li>
              </ul>
            </div>
          </div>
        </div>
        {qr ? (
          <div className='flex justify-center sm:justify-start mt-6'>
            <img alt='qr' src={qr} className='' />
          </div>
        ) : null}
        <BotButton
          variant='qr'
          color='success'
          callBack={getQrHandler}
          isLoading={isFetchingQR}
          disabled={status === CONNECTION_STATUSES.LOADING}
          className='mt-6 !min-w-[13rem] w-full sm:w-fit'
        />
        <ClearMessageQueryModal
          isOpen={isOpen}
          closeModalHandler={closeModalHandler}
          clearQueryHandler={clearMessageQuery}
          getQRWithoutClearQueryHandler={getQwWithoutClearQuery}
        />
      </div>
    );
  },
);

ConnectionGetQr.displayName = 'ConnectionGetQr';
