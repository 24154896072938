import { apiService } from '@api/ApiService';
import { TReviewInListData } from '@redux/reviews/models';
import { CATEGORIES_EP_NAME, END_POINTS_URL, QUERY_PARAMS } from '@const/apiConstants';
import {
  TSaveReview,
  TUpdateReview,
  TRequestStatus,
  TStaffListItem,
  TGetReviewData,
  TGetOneReviewData,
  ISwitchReviewChain,
  TGetResponseWithData,
  TDeleteReviewByIdData,
  TDeleteReviewStepData,
  TSwitchReviewChainStepData,
} from '@api/types';

/**
 * API цепочки отзывов
 * @module reviewsApi
 */

export const reviewsApi = {
  /**
   * Метод запрашивает список цепочек отзывов
   * @method getReviewsListData
   * @return {Promise<AxiosResponse<TGetResponseWithData<TReviewInListData[]> | TRequestStatus, any>>}
   */
  getReviewsListData() {
    return apiService.get<TGetResponseWithData<TReviewInListData[]> | TRequestStatus>(
      END_POINTS_URL.REVIEWS_LIST,
    );
  },
  /**
   * Метод запрашивает данные одной цепочки отзывов по id
   * @param data {TGetReviewData}
   * @return {Promise<AxiosResponse<TRequestStatus | TGetResponseWithData<TGetOneReviewData>, any>>}
   */
  getReviewData(data: TGetReviewData) {
    return apiService.get<TGetResponseWithData<TGetOneReviewData> | TRequestStatus>(
      END_POINTS_URL.REVIEW,
      {
        [QUERY_PARAMS.TEMPLATE_ID]: data.templateId,
      },
    );
  },
  /**
   * Метод удаляет цепочку по id
   * @method deleteReviewById
   * @param data {TDeleteReviewByIdData}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  deleteReviewById(data: TDeleteReviewByIdData) {
    return apiService.delete<TRequestStatus>(
      END_POINTS_URL.DELETE_REVIEW,
      {
        user_id: data.data.userId,
        acc_id: data.data.accId,
      },
      {
        params: { [QUERY_PARAMS.TEMPLATE_ID]: data.templateId },
      },
    );
  },
  /**
   * Метод удаляет шаг в цепочке
   * @method deleteStepReview
   * @param data {TDeleteReviewStepData}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  deleteStepReview(data: TDeleteReviewStepData) {
    return apiService.delete<TRequestStatus>(
      END_POINTS_URL.DELETE_STEP_REVIEW,
      {
        user_id: data.data.userId,
        acc_id: data.data.accId,
      },
      {
        params: {
          [QUERY_PARAMS.TEMPLATE_ID]: data.templateId,
          [QUERY_PARAMS.STEP_NUMBER]: data.stepNumber,
        },
      },
    );
  },
  /**
   * Метод сохраняет новую цепочку отзывов
   * @method saveReview
   * @param data {TSaveReview}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  saveReview(data: TSaveReview) {
    return apiService.post<TRequestStatus>(END_POINTS_URL.SAVE_REVIEW, data.data);
  },
  /**
   * Метод сохраняет изменения в существующую цепочку отзывов
   * @method updateReview
   * @param data {TUpdateReview}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  updateReview(data: TUpdateReview) {
    return apiService.put<TRequestStatus>(END_POINTS_URL.UPDATE_REVIEW, data.data, {
      params: { [QUERY_PARAMS.TEMPLATE_ID]: data.templateId },
    });
  },
  /**
   * Метод отключает/включает всю цепочку отзывов по id первого шага
   * @method switchReviewChain
   * @param data {ISwitchReviewChain}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  switchReviewChain(data: ISwitchReviewChain) {
    return apiService.post<TRequestStatus>(
      END_POINTS_URL.REVIEW_SWITCH,
      {
        user_id: data.data.userId,
        acc_id: data.data.accId,
      },
      {
        params: {
          [QUERY_PARAMS.TEMPLATE_ID]: data.templateId,
          [QUERY_PARAMS.SWITCH]: data.stepSwitchStatus ? 'on' : 'off',
        },
      },
    );
  },
  /**
   * Метод переключает активность шагов в цепочке
   * @method switchReviewChainStep
   * @param data {TSwitchReviewChainStepData}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  switchReviewChainStep(data: TSwitchReviewChainStepData) {
    return apiService.post<TRequestStatus>(
      END_POINTS_URL.REVIEW_STEP_SWITCH,
      {
        user_id: data.data.userId,
        acc_id: data.data.accId,
      },
      {
        params: {
          [QUERY_PARAMS.TEMPLATE_ID]: data.templateId,
          [QUERY_PARAMS.STEP_NUMBER]: data.stepNumber,
          [QUERY_PARAMS.SWITCH]: data.switch ? 'on' : 'off',
        },
      },
    );
  },
  /**
   * Метод запрашивает список категорий для отображения выпадающего списка
   * @method getDropdownList
   * @param data {string} название категории
   * @return {Promise<AxiosResponse<TRequestStatus | TGetResponseWithData<TStaffListItem[]>, any>>}
   */
  getDropdownList(data: string) {
    return apiService.get<TGetResponseWithData<TStaffListItem[]> | TRequestStatus>(
      CATEGORIES_EP_NAME[data],
    );
  },
};
