import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Callout } from '@uikit/Callout';
import { Icon } from '@blueprintjs/core';
import { TAppLanguage } from '@models/index';
import { CREATE_CARD_LINK, NO_CARD_CALLOUT_TEXT } from '@const/inviteFriend';

type TInviteFriendsNoCardCalloutProps = {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguages: TAppLanguage;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const InviteFriendsNoCardCallout = memo(
  ({ appLanguages, className = '' }: TInviteFriendsNoCardCalloutProps) => {
    const { t } = useTranslation();

    const textStyle = 'text-black leading-6 tracking-[0.0275em]';

    const listItem = useMemo(
      () =>
        [1, 2, 3, 4, 5, 6].map(item => (
          <li key={item} className={textStyle}>
            {NO_CARD_CALLOUT_TEXT[appLanguages][item]}
          </li>
        )),
      [appLanguages],
    );

    const link = useMemo(
      () => (
        <a
          target='_blank'
          href={CREATE_CARD_LINK}
          rel='nofollow noreferrer'
          className='underline text-green1'>
          {NO_CARD_CALLOUT_TEXT[appLanguages].link}
        </a>
      ),
      [appLanguages],
    );

    return (
      <div className={className}>
        <Callout
          type='def'
          view='smooth'
          color='warning'
          title={t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.attention')}
          icon={<Icon icon='info-sign' iconSize={20} />}>
          <div>
            <p className={textStyle}>{NO_CARD_CALLOUT_TEXT[appLanguages].description}</p>
            <p className={textStyle}>{NO_CARD_CALLOUT_TEXT[appLanguages].instructionTitle}</p>
            <ol className='list-decimal pl-5'>{listItem}</ol>
            {link}
          </div>
        </Callout>
      </div>
    );
  },
);

InviteFriendsNoCardCallout.displayName = 'InviteFriendsNoCardCallout';
