import { TAppLanguage, TSimpleStringObj } from '@models/index';

export const TEMPLATE_LIST_PAGE_TEXT = {
  header: 'Шаблоны',
  headerButton: 'Новый шаблон',
  beforeVisit: 'До визита',
  afterVisit: 'После визита',
  duplicateTemplateSuccess: 'Шаблон скопирован',
  deleteTemplateSuccess: 'Шаблон {{id}} \n успешно удален',
  card: {
    ariaLabel: 'шаблон {{id}}',
    title: 'Шаблон {{id}}',
    deleteDialogTitle: 'Вы хотите удалить\n шаблон {{id}}?',
    cancelButton: 'Отмена',
    confirmButton: 'Удалить',
  },
};

export const TEMPLATE_LIST_PAGE_TEXT_ENG = {
  header: 'Templates',
  headerButton: 'New template',
  beforeVisit: 'Before the visit',
  afterVisit: 'After the visit',
  duplicateTemplateSuccess: 'Template copied',
  deleteTemplateSuccess: 'Template {{id}}\n successfully deleted',
  card: {
    ariaLabel: 'template {{id}}',
    title: 'Template {{id}}',
    deleteDialogTitle: 'Do you want to delete\ntemplate {{id}}?',
    cancelButton: 'Cancel',
    confirmButton: 'Delete',
  },
};

export const TEMPLATE_DUPLICATE_DROPDOWN: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    duplicate: 'Create a copy of the template',
    delete: 'Delete template',
  },
  rus: {
    duplicate: 'Создать копию шаблона',
    delete: 'Удалить шаблон',
  },
};
