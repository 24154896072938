import { apiService } from '@api/ApiService';
import { END_POINTS_URL } from '@const/apiConstants';
import { TCreateInvoiceResponse, TCrateInvoice } from '@redux/pay/models';

import { TRequestStatus, TPayResponseData, TGetPayLinkResponse } from './types';

/**
 * API страницы оплаты
 * @module payApi
 */

export const payApi = {
  /**
   * Метод запрашивает данные для страницы оплаты
   * @method getPayData
   * @return {Promise<AxiosResponse<TPayResponseData | TRequestStatus, any>>}
   */
  getPayData() {
    return apiService.get<TPayResponseData | TRequestStatus>(END_POINTS_URL.PAY);
  },
  /**
   * Метод запрашивает ссылку для включения автоплатежа
   * @method getAutoPayLink
   * @return {Promise<AxiosResponse<TPayResponseData | TRequestStatus, any>>}
   */
  getAutoPayLink() {
    return apiService.get<TGetPayLinkResponse | TRequestStatus>(END_POINTS_URL.AUTO_PAY_LINK);
  },
  /**
   * Метод запрашивает ссылку для изменения сохраненной карточки используемой для автоплатежа
   * @method changeCard
   * @return {Promise<AxiosResponse<TPayResponseData | TRequestStatus, any>>}
   */
  changeCard() {
    return apiService.get<TGetPayLinkResponse | TRequestStatus>(END_POINTS_URL.CHANGE_CARD);
  },
  /**
   * Метод отключает автоплатёж
   * @method turnOffAutoPayment
   * @return {Promise<AxiosResponse<TPayResponseData | TRequestStatus, any>>}
   */
  turnOffAutoPayment() {
    return apiService.post<TGetPayLinkResponse | TRequestStatus>(END_POINTS_URL.AUTO_PAY_OFF);
  },
  /**
   * @method createInvoice
   * @param data {TCrateInvoice} данные для формирования запроса
   * @return Promise<AxiosResponse<TRequestStatus | TCreateInvoiceResponse, any>>
   */
  createInvoice(data: TCrateInvoice) {
    return apiService.post<TCreateInvoiceResponse | TRequestStatus>(
      END_POINTS_URL.CREATE_INVOICE,
      {},
      {
        params: {
          amount: data.amount,
          months: data.months,
        },
      },
    );
  },
  sendInvoiceToTg(data: TCreateInvoiceResponse) {
    return apiService.post<TCreateInvoiceResponse | TRequestStatus>(
      END_POINTS_URL.SEND_INVOICE_TO_TG,
      data,
    );
  },
};
