import { TConsultantFullTableData } from '@redux/consultantFullTable/models';

export interface IInitialState {
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  sortCollumName: string;
  isAccessDenied: boolean;
  qrCode: string;
  promoUrl: string;
  promoCode: string;
  data: TConsultantFullTableData[];
}

export const initialState: IInitialState = {
  isError: false,
  isLoading: false,
  isFetching: false,
  isSuccess: false,
  isAccessDenied: false,
  sortCollumName: '',
  qrCode: '',
  promoUrl: '',
  promoCode: '',
  data: [],
};
