import React, { memo } from 'react';
//
import { Icon } from '@atoms/icon';

interface IConnectionStatusWithTextProps {
  /**
   * Статус подключения
   * @param {string}
   */
  status: string;
  /**
   * Текстовое описание статуса
   * @param {string}
   */
  statusText: string;
}

export const ConnectionStatusWithText = memo(
  ({ status, statusText }: IConnectionStatusWithTextProps) => (
    <div className='flex items-center'>
      <Icon variant={status} className='mr-2.5 h-5 w-5' />
      <span className='font-inter font-medium text-body_text text-blackText'>{statusText}</span>
    </div>
  ),
);
