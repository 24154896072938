import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@atoms/icon';
import { TAppLanguage } from '@models/index';
import {
  INSTRUCTION_LINKS_DATA,
  MAILING_IMPORTANT_INFORMATION_LINK2,
  MAILING_IMPORTANT_INFORMATION_LINK3,
} from '@const/mailing';

type MailingInstructionProps = {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const MailingInstruction = memo(
  ({ appLanguage, className = '' }: MailingInstructionProps) => {
    const { t } = useTranslation();

    const instructionToRender = Object.entries(INSTRUCTION_LINKS_DATA[appLanguage]).map(
      ([key, linkData]) => {
        const { name, iconName, href } = linkData;

        const linkStyle =
          'flex m:grow m:basis-[8rem] items-center justify-center border border-green1 rounded-lg py-[0.375rem] font-bold text-tooltips no-underline text-green1 hover:text-green1 hover:no-underline mr-2 mb-2';

        return (
          <a key={key} href={href} target='_blank' rel='noreferrer noopener' className={linkStyle}>
            <Icon variant={iconName} className='mr-2' />
            {name}
          </a>
        );
      },
    );

    const linkStyle = 'underline text-green1 hover:text-green1 visited:text-green1';

    return (
      <div
        className={`border border-quartz rounded-lg text-gray2 flex flex-col pt-4 pb-1 pl-4 pr-2 h-fit ${className}`}>
        <p className='text-black leading-6 tracking-[0.0275em] mb-[1.3rem]'>
          {t('MAILING_PAGE_TEXT.mailingInstruction.title')}
          <span className='font-medium text-persianRed'>
            {' '}
            {t('MAILING_PAGE_TEXT.mailingInstruction.warning')},{' '}
          </span>
          <a
            target='_blank'
            rel='noreferrer noopener'
            href={MAILING_IMPORTANT_INFORMATION_LINK2.href}
            className={linkStyle}>
            {t('MAILING_PAGE_TEXT.mailingInstruction.instructions')}
          </a>
          <span> {t('MAILING_PAGE_TEXT.mailingInstruction.and')} </span>
          <a
            target='_blank'
            rel='noreferrer noopener'
            href={MAILING_IMPORTANT_INFORMATION_LINK3.href}
            className={linkStyle}>
            {t('MAILING_PAGE_TEXT.mailingInstruction.examples')}
          </a>
        </p>
        <p className='font-bold text-blackText text-h6Mobile leading-8 tracking-[0.0275em] mb-2'>
          {t('MAILING_PAGE_TEXT.mailingInstruction.instructionHeader')}
        </p>
        <div className='flex flex-col m:flex-row flex-wrap justify-between w-full'>
          {instructionToRender}
        </div>
      </div>
    );
  },
);
