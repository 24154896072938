import { TMediaFileType } from '@models/index';
import { TIME_PERIODS } from '@const/templates';
import { TKeyData } from '@redux/template/models';
import { ACTIVE_STEP_NUMBER } from '@const/mailing';
import { getIncrementedCurrentTime } from '@helpers/index';
import { emptyParsedXSLXFileData, emptySendOut } from '@redux/mailing/mockData';
import {
  TCustomerKeys,
  TSendOutsData,
  TMailingTableData,
  TParsedXlsxFileData,
  TSendOutsDetailData,
} from '@redux/mailing/models';

export interface IInitialState {
  isRefresh: boolean;
  isRetries: boolean;
  retries: number;
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
  showDrawer: boolean;
  isTestMessageSending: boolean;
  isTestMessageSendSuccess: boolean;
  isTestMessageSendError: boolean;
  stoppedSendOutId: number;
  isStoppingSendOut: boolean;
  isStoppingSendOutSuccess: boolean;
  isStoppingSendOutError: boolean;
  isParsingXLSXFile: boolean;
  isParsingXLSXFileSuccess: boolean;
  isParsingXLSXFileError: boolean;
  parsedXSLXFileData: TParsedXlsxFileData;
  isStartingSendOut: boolean;
  isStartingSendOutSuccess: boolean;
  isStartingSendOutError: boolean;
  sendOutErrorMessage: string;
  isAccessDenied: boolean;
  text: string;
  error: boolean;
  mediaFileType: TMediaFileType;
  mediaFileName: string;
  mediaFileTooBigError: boolean;
  unsupportedFileError: boolean;
  mediaFile: File | null;
  clientFile: File | null;
  clientFileTooBigError: boolean;
  clientFileName: string;
  mediaFileURL: string | null;
  showParsedInfo: boolean;
  xlsxFileInfoMessages: string[];
  activeStep: number;
  blurFromTextareaName: string;
  selectionEnd: number;
  selectionStart: number;
  data: TMailingTableData[];
  errorMessage: string;
  successMessage: string;
  textareaKeysData: TKeyData[];
  isShowCalendar: boolean;
  isImmediateLaunch: boolean;
  isScheduledLaunch: boolean;
  oldMailingStartDate: Date;
  mailingStartDate: Date;
  oldMailingStartTime: string;
  mailingStartTime: string;
  selectedMonth: number;
  selectedDay: number;
  selectedYear: number;
  newSendOutData: TSendOutsData;
  sendOuts: TSendOutsData[];
  oldSendOuts: TSendOutsData[];
  minimalRecommendedStartTime: number;
  daySendOutLimit: number;
  isGetsCalendarData: boolean;
  isShowCalendarError: boolean;
  isImmediateStartAllowed: boolean;
  monthNameList: string[];
  isGettingSendOutDetailData: boolean;
  isShowSendOutDetailDataModal: boolean;
  isCheckingNewSendOutFitToDate: boolean;
  sendOutDetailData: TSendOutsDetailData[];
  dateForGetDetailData: Date;
  isShowWarning: boolean;
  isPayedAccount: boolean;
  customerKeys: TCustomerKeys;
}

const newDate = new Date();

export const initialState: IInitialState = {
  isRefresh: false,
  isRetries: false,
  retries: 1,
  isLoading: false,
  isError: false,
  isFetching: false,
  showDrawer: false,
  isTestMessageSending: false,
  isTestMessageSendSuccess: false,
  isTestMessageSendError: false,
  stoppedSendOutId: 0,
  isStoppingSendOut: false,
  isStoppingSendOutError: false,
  isStoppingSendOutSuccess: false,
  isParsingXLSXFile: false,
  isParsingXLSXFileError: false,
  isParsingXLSXFileSuccess: false,
  parsedXSLXFileData: emptyParsedXSLXFileData,
  xlsxFileInfoMessages: [],
  showParsedInfo: false,
  isStartingSendOut: false,
  isStartingSendOutError: false,
  sendOutErrorMessage: '',
  isStartingSendOutSuccess: false,
  isAccessDenied: false,
  text: '',
  error: false,
  mediaFileType: null,
  mediaFileName: '',
  mediaFile: null,
  mediaFileURL: null,
  mediaFileTooBigError: false,
  unsupportedFileError: false,
  clientFile: null,
  clientFileName: '',
  clientFileTooBigError: false,
  activeStep: ACTIVE_STEP_NUMBER.FIRST_STEP,
  blurFromTextareaName: '',
  selectionEnd: 0,
  selectionStart: 0,
  errorMessage: '',
  successMessage: '',
  data: [],
  textareaKeysData: [],
  isShowCalendar: false,
  isImmediateLaunch: true,
  isScheduledLaunch: false,
  mailingStartDate: new Date(),
  oldMailingStartDate: new Date(),
  mailingStartTime: getIncrementedCurrentTime(1),
  oldMailingStartTime: getIncrementedCurrentTime(1),
  selectedDay: newDate.getDate(),
  selectedMonth: newDate.getMonth(),
  selectedYear: newDate.getFullYear(),
  minimalRecommendedStartTime: newDate.getTime() + TIME_PERIODS.HOUR_IN_MS,
  newSendOutData: emptySendOut,
  daySendOutLimit: 0,
  sendOuts: [],
  oldSendOuts: [],
  isGetsCalendarData: false,
  isShowCalendarError: false,
  isImmediateStartAllowed: false,
  monthNameList: [],
  isGettingSendOutDetailData: false,
  isShowSendOutDetailDataModal: false,
  sendOutDetailData: [],
  dateForGetDetailData: newDate,
  isCheckingNewSendOutFitToDate: false,
  isShowWarning: false,
  isPayedAccount: false,
  customerKeys: {},
};

export enum MAILING_DATA_KEY_NAMES {
  IS_IMMEDIATE_LAUNCH = 'isImmediateLaunch',
  IS_SCHEDULE_LAUNCH = 'isScheduledLaunch',
}
