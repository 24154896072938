import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@atoms/icon';
import { Support } from '@blocks/support';
import { VideoInstructionModal } from '@blocks/videoInstructionModal';
import { VideoInstructionButton } from '@blocks/videoInstructionButton';

interface IFooterProps {
  /**
   * Ссылка на странице Бьюти бота
   * @param {string}
   */
  bbLandingPageLink: string;
  /**
   * Ссылка на канал поддержки в telegram
   * @param {string}
   */
  supportTelegramLink: string;
  /**
   * Ссылка на базу знаний
   * @param {string}
   */
  linkToKnowledgeBase: string;
  /**
   * Номер телефона в href, передается без форматирования (+77777777777)
   * @param {string}
   */
  supportPhoneToHref: string;
  /**
   * Телефонный номер на страницу, передается с форматированием для лучшей читаемости (+7 (777) 777 7777)
   * @param {string}
   */
  supportPhoneToShow: string;
  /**
   * Линк на видео размещенное на YouTube
   * @param {string}
   */
  videoLink: string;
  /**
   * Текст в заголовок
   * @param{string}
   */
  headerText: string;
  /**
   * Время начала проигрывания
   * @param {string}
   */
  startTime?: string;
  /**
   * Флаг открытия страницы авторизации
   * @param {boolean}
   */
  isLoginPage?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const Footer = memo(
  ({
    startTime,
    videoLink,
    headerText,
    isLoginPage,
    bbLandingPageLink,
    supportPhoneToHref,
    supportPhoneToShow,
    supportTelegramLink,
    linkToKnowledgeBase,
    className = '',
  }: IFooterProps) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const showModal = useCallback(() => {
      setIsOpen(prevState => !prevState);
    }, []);

    return (
      <footer className='absolute left-0 right-0 bottom-0 bg-footerGray w-full'>
        <div
          className={`bg-footerGray font-inter flex flex-wrap justify-between items-center m-auto min-h-[4rem] max-w-[101.5rem] w-full px-4 ${className}`}>
          <div
            className={`flex flex-wrap justify-between items-center ${
              !isLoginPage ? 'w-full' : ''
            }`}>
            {!isLoginPage ? (
              <a
                href={bbLandingPageLink}
                className='no-underline font-inter text-small_text text-darkGray hidden sm:inline hover:no-underline hover:text-darkGray'
                target='_blank'
                rel='noopener noreferrer'>
                {t('BASE_TEMPLATE_PAGE_TEXT.footer.link')}
              </a>
            ) : null}
            <Support
              isFooter
              isShowPhone
              supportPhoneToHref={supportPhoneToHref}
              supportPhoneToShow={supportPhoneToShow}
              supportTelegramLink={supportTelegramLink}
            />
            {!isLoginPage ? (
              <div className='flex flex-wrap items-center justify-between'>
                <a
                  href={linkToKnowledgeBase}
                  className='flex flex-col justify-center items-center no-underline font-inter text-small_text text-darkGray hover:no-underline hover:text-darkGray'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <Icon variant='learning' className='h-5 w-[1.31255rem]' />
                  {t('BASE_TEMPLATE_PAGE_TEXT.footer.knowledgeBase')}
                </a>
              </div>
            ) : null}
          </div>
          {isLoginPage ? (
            <>
              <VideoInstructionButton
                isShowRightIcon={false}
                onClickCallback={showModal}
                buttonText={t('BASE_TEMPLATE_PAGE_TEXT.lkLoginInstruction')}
              />
              {isOpen ? (
                <VideoInstructionModal
                  isOpen={isOpen}
                  startTime={startTime}
                  videoLink={videoLink}
                  headerText={headerText}
                  closeModalHandler={showModal}
                />
              ) : null}
            </>
          ) : null}
        </div>
      </footer>
    );
  },
);

Footer.displayName = 'Footer';
