import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
//
import { Button } from '@uikit/Button';
import { Table } from '@uikit/Table/Table';
import { BotButton } from '@blocks/botButton';
import { TMessages } from '@redux/connection/models';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { TableTextBlock } from '@components/tableTextBlock';
import { useAppDispatch, useAppSelector } from '@store/store';
import { TOnChangeHandler, TOnclickHandler } from '@shared/types';
import { getConnectionTableData } from '@redux/connection/selectors';
import { SearchInputWithButton } from '@components/searchInputWithButton';
import { MobileConnectionTableCard } from '@blocks/mobileConnectionTableCard';
import {
  showMoreCP,
  setTableDataCP,
  setSortTypeDataCP,
  setTableSearchStringCP,
} from '@redux/connection/connetionTableSlice';

interface IConnectionTableProps {
  /**
   * Данные для таблицы
   * @param {TMessages[]}
   */
  messages: TMessages[];
  /**
   * Callback для очистки очереди сообщений
   * @param {TOnclickHandler}
   */
  cleanMessagesCallBack: TOnclickHandler;
  /**
   * Параметр указывает на статус запроса очистки очереди,
   * используется для отображения лоадера и блокировки кнопки очистки очереди
   * @param {boolean}
   */
  isFetchingCleanQuery: boolean;
}

export const ConnectionTable = memo(
  ({ cleanMessagesCallBack, messages, isFetchingCleanQuery }: IConnectionTableProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { filteredData, searchString, totalMobilePages, currentMobilePage, mobileData } =
      useAppSelector(getConnectionTableData);

    const width = useWindowWidth();

    useEffect(() => {
      dispatch(setTableDataCP(messages));
    }, [dispatch, messages]);

    // записывает введенные данные в состояние, для последующей обработки
    const searchHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setTableSearchStringCP({ value }));
      },
      [dispatch],
    );

    const clearSearchString = useCallback(() => {
      dispatch(setTableSearchStringCP({ value: '' }));
    }, [dispatch]);

    const startSearchHandler = useCallback(() => {
      dispatch(setTableSearchStringCP({ value: searchString }));
    }, [searchString, dispatch]);

    // функция сортировки, получает название столбца отправляет в редакс
    const setSortTypeHandler = useCallback(
      (name: string, order: string) => {
        dispatch(setSortTypeDataCP({ collumName: name, order }));
      },
      [dispatch],
    );

    // Функция добавляет к показанному списке еще 4 сообщения,
    // используется в мобильном отображении
    const showMoreHandler = useCallback(() => {
      dispatch(showMoreCP());
    }, [dispatch]);

    // Данные для формирования шапки таблицы
    const Head = useMemo(
      () => [
        { id: 'id', label: t('CONNECTION_PAGE_TEXT.table.number') },
        { id: 'phone', label: t('CONNECTION_PAGE_TEXT.table.phone') },
        { id: 'body', label: t('CONNECTION_PAGE_TEXT.table.message') },
      ],
      [t],
    );

    // Данные для формирования тела таблицы
    const getTableData = useMemo(
      () => (data: TMessages[]) => {
        const emptyTableData = [{ id: ' ', body: ' ' }];
        if (!data.length) {
          return emptyTableData;
        }
        return data.map(item => {
          return {
            id: +item.id,
            phone: item.phone,
            body: <TableTextBlock text={item.body} />,
          };
        });
      },
      [],
    );

    return (
      <div className='flex flex-col max-w-[82.5rem]'>
        <div className='flex flex-wrap flex-col sm:flex-row items-center justify-between mb-4'>
          <SearchInputWithButton
            hideSearchButton
            searchString={searchString}
            disableSearchButton={false}
            setSearchStringHandler={searchHandler}
            startSearchHandler={startSearchHandler}
            clearSearchStringHandler={clearSearchString}
            buttonText={t('CONNECTION_PAGE_TEXT.table.searchInput.buttonText')}
            placeholder={t('CONNECTION_PAGE_TEXT.table.searchInput.placeholder')}
            className='pl-px mb-4 sm:mb-0 md:max-w-[25.3rem]'
          />
          {messages.length ? (
            <BotButton
              color='danger'
              variant='clean'
              callBack={cleanMessagesCallBack}
              isLoading={isFetchingCleanQuery}
              className='w-full sm:w-fit'
            />
          ) : null}
        </div>
        <div className='overflow-x-auto'>
          {width > 769 ? (
            <Table
              pages
              name={Head}
              defRows={8}
              view='flat'
              type='def'
              position={['left', 'left', 'left', 'left']}
              color='default'
              checkboxes={false}
              paperClassName='no_shadow'
              style={{ marginLeft: '6px' }}
              rowsPerPageOptions={[5, 8, 20]}
              data={getTableData(filteredData)}
              onSortCallback={setSortTypeHandler}
              labelRowsPerPage={t('CONNECTION_PAGE_TEXT.table.pagination')}
            />
          ) : null}
          {width < 770 ? (
            <>
              <MobileConnectionTableCard head={Head} data={mobileData} />
              {mobileData.length ? (
                <div className='p-px'>
                  <Button
                    dense
                    type='action'
                    icon='repeat'
                    view='outlined'
                    color='default'
                    onClick={showMoreHandler}
                    disabled={totalMobilePages === currentMobilePage}
                    text={t('CONNECTION_PAGE_TEXT.table.showMore')}
                    className='md:hidden mt-8 w-full'
                  />
                </div>
              ) : (
                <p className='text-h3_body text-grayText'>
                  {t('CONNECTION_PAGE_TEXT.table.noMessages')}
                </p>
              )}
            </>
          ) : null}
        </div>
      </div>
    );
  },
);

ConnectionTable.displayName = 'ConnectionTable';
