import { TAppLanguage, TSimpleStringObj, TSimpleStringObjLevel2 } from '@models/index';

export const REVIEW_BUTTON_TEXT: TSimpleStringObj = {
  edit: 'Редактировать',
  deleteReview: 'Удалить цепочку',
  addStep: 'Добавить шаг',
  saveChain: 'Сохранить цепочку отзывов',
  preview: 'Показать превью',
};

export const REVIEW_BUTTON_TEXT_ENG: TSimpleStringObj = {
  edit: 'Edit',
  deleteReview: 'Delete chain',
  addStep: 'Add step',
  saveChain: 'Save the review chain',
  preview: 'Show preview',
};

export enum REVIEW_RECORD_STATUS {
  CLIENT_EXPECTATION = '0',
  CLIENT_CAME = '1',
  CLIENT_CONFIRMED = '2',
  CLIENT_DID_NOT_COME = '-1',
}

export enum WHEN_SEND_VALUE_REVIEWS {
  ALL_DAY_RECS_NOTIFICATION = 'all_day_recs_notifications',
  AFTER_END = 'after_end',
}

export const WHEN_SEND_SELECT_OPTION_REVIEWS: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [WHEN_SEND_VALUE_REVIEWS.ALL_DAY_RECS_NOTIFICATION]: 'To all clients per day',
    [WHEN_SEND_VALUE_REVIEWS.AFTER_END]: 'After recording ends',
  },
  rus: {
    [WHEN_SEND_VALUE_REVIEWS.ALL_DAY_RECS_NOTIFICATION]: 'Всем клиентам за день',
    [WHEN_SEND_VALUE_REVIEWS.AFTER_END]: 'По окончании записи',
  },
};

export const WHEN_SEND2_SELECT_OPTION_REVIEWS: Record<TAppLanguage, TSimpleStringObjLevel2> = {
  eng: {
    [WHEN_SEND_VALUE_REVIEWS.AFTER_END]: {
      '24.0': 'In 24 hours',
      '12.0': 'In 12 hours',
      '6.0': 'In 6 hours',
      '3.0': 'In 3 hours',
      '2.0': 'In 2 hours',
      '1.5': 'In 1.5 hours',
      '1.0': 'In 1 hour',
      '0.5': 'In 30 minutes',
      '0.25': 'In 15 minutes',
    },
    [WHEN_SEND_VALUE_REVIEWS.ALL_DAY_RECS_NOTIFICATION]: {
      '0': 'Today',
      '-1': 'Yesterday',
      '-2': 'The day before yesterday',
      '-3': '3 days ago',
      '-5': '5 days ago',
      '-7': 'A week ago',
    },
  },
  rus: {
    [WHEN_SEND_VALUE_REVIEWS.AFTER_END]: {
      '24.0': 'Через 24 часа',
      '12.0': 'Через 12 часов',
      '6.0': 'Через 6 часов',
      '3.0': 'Через 3 часа',
      '2.0': 'Через 2 часа',
      '1.5': 'Через 1,5 часа',
      '1.0': 'Через 1 час',
      '0.5': 'Через 30 минут',
      '0.25': 'Через 15 минут',
    },
    [WHEN_SEND_VALUE_REVIEWS.ALL_DAY_RECS_NOTIFICATION]: {
      '0': 'Сегодня',
      '-1': 'Вчера',
      '-2': 'Позавчера',
      '-3': '3 дня назад',
      '-5': '5 дней назад',
      '-7': 'Неделю назад',
    },
  },
};

export enum ALL_DAY_RECS_NOTIFICATION_VALUE {
  TODAY = '0',
}

export enum AFTER_END {
  IN_FIFTEEN_MINUTES = '0.25',
}

export const REVIEW_TOAST_MESSAGE = {
  SAVE_SUCCESS: 'Цепочка успешно сохранена',
};

export const REVIEW_TOAST_MESSAGE_ENG = {
  SAVE_SUCCESS: 'Chain saved successfully',
};

export const REVIEW_MESSAGE = {
  SAVE_SUCCESS: 'REVIEW_TOAST_MESSAGE.SAVE_SUCCESS',
};

export const REVIEW_PAGE_TEXT = {
  chain: 'Цепочка {{id}}',
  REVIEW_BUTTON_TEXT,
  header: 'Цепочка отзывов #{{id}}',
  headerNewChain: 'Новая цепочка отзывов',
  toggleDescription: 'Действующая цепочка',
  chainName: 'Название цепочки',
  chainNamePlaceholder: 'Введите название',
  preview: 'Превью шага',
  step: 'Шаг {{stepId}}',
  stepPostfix: '(последний)',
  requiredFields: 'Обязательные поля отмечены звёздочкой',
  content: 'Контент для отправки',
  sendCondition: 'Условия отправки',
  select: 'Выберите',
  clientPhoneList: 'Укажите номер телефона для отправки сообщений в WhatsApp',
  tgAlerts: 'Укажите telegram ID для отправки сообщений в Telegram',
  blackList: 'Введите номер телефона',
};

export const REVIEW_PAGE_TEXT_ENG = {
  chain: 'Chain {{id}}',
  REVIEW_BUTTON_TEXT: REVIEW_BUTTON_TEXT_ENG,
  header: 'Feedback thread #{{id}}',
  headerNewChain: 'New review chain',
  toggleDescription: 'Live chain',
  chainName: 'Chain name',
  chainNamePlaceholder: 'Enter a name',
  preview: 'Step preview',
  step: 'Step {{stepId}}',
  stepPostfix: '(last)',
  requiredFields: 'Required fields are marked with an asterisk',
  content: 'Content to send',
  sendCondition: 'Sending conditions',
  select: 'Select',
  clientPhoneList: 'Specify the phone number to send messages on WhatsApp',
  tgAlerts: 'Specify telegram ID to send messages to Telegram',
  blackList: 'Enter phone number',
};
