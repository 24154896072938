import { TPayData } from '@redux/pay/models';
import { numberOfGiftWeek, numberOfMonth } from '@const/pay';

export const emptyMonthData = {
  '1': 0,
  '3': 0,
  '6': 0,
  '12': 0,
};

export const payPageEmptyData: TPayData = {
  branchEmployees: '',
  branch: '',
  amount: '',
  isAutoPayEnable: false,
  cardLastFourDigits: '',
  payStatus: false,
  payTillDate: '',
  paymentData: [],
  payLinks: ['', '', '', ''],
  numberOfMonth,
  numberOfGiftWeek,
  createBill: false,
  reviewBanner: false,
  bonusWeek: emptyMonthData,
  monthCost: emptyMonthData,
  discount: 0,
};

export const payMockData: TPayData = {
  branchEmployees: '10',
  branch: '86774',
  amount: '2390',
  isAutoPayEnable: false,
  cardLastFourDigits: '4444',
  payStatus: true,
  payTillDate: '02.12.2025',
  reviewBanner: true,
  payLinks: [
    'https://yoomoney.ru/checkout/payments/v2/contract/bankcard?orderId=2aabe1a3-000f-5000-9000-1b77b576be1a',
    'https://yoomoney.ru/checkout/payments/v2/contract/bankcard?orderId=2aabe1a3-000f-5000-9000-1b77b576be1a',
    'https://yoomoney.ru/checkout/payments/v2/contract/bankcard?orderId=2aabe1a3-000f-5000-9000-1b77b576be1a',
    'https://yoomoney.ru/checkout/payments/v2/contract/bankcard?orderId=2aabe1a3-000f-5000-9000-1b77b576be1a',
  ],
  numberOfMonth,
  numberOfGiftWeek,
  bonusWeek: {
    '1': 0,
    '3': 1,
    '6': 3,
    '12': 8,
  },
  monthCost: {
    '1': 940.0,
    '3': 929.8378378378378,
    '6': 924.8387096774194,
    '12': 919.8930481283422,
  },
  discount: 10,
  paymentData: [
    {
      date: '24.12.2020',
      period: '26.01.2021',
      sum: 1890,
    },
    {
      date: '25.01.2021',
      period: '26.02.2021',
      sum: 1890,
    },
    {
      date: '25.03.2021',
      period: '26.04.2021',
      sum: 1890,
    },
    {
      date: '26.04.2021',
      period: '26.05.2021',
      sum: 1890,
    },
    {
      date: '26.05.2021',
      period: '26.06.2021',
      sum: 1890,
    },
    {
      date: '26.06.2021',
      period: '26.07.2021',
      sum: 1890,
    },
    {
      date: '25.07.2021',
      period: '26.08.2021',
      sum: 1890,
    },
    {
      date: '29.08.2021',
      period: '29.09.2021',
      sum: 1890,
    },
    {
      date: '30.09.2021',
      period: '30.10.2021',
      sum: 1890,
    },
    {
      date: '02.11.2021',
      period: '02.12.2021',
      sum: 1890,
    },
  ],
  createBill: true,
};

export const getPayPageResponseMockData = {
  pay_status: true,
  pay_date: '24.08.2023',
  branch_employees: 2,
  amount: 1796,
  payments: [
    {
      date: '24.12.2020',
      period: '26.01.2021',
      summ: 1890,
    },
    {
      date: '25.01.2021',
      period: '26.02.2021',
      summ: 1890,
    },
    {
      date: '25.03.2021',
      period: '26.04.2021',
      summ: 1890,
    },
    {
      date: '26.04.2021',
      period: '26.05.2021',
      summ: 1890,
    },
    {
      date: '26.05.2021',
      period: '26.06.2021',
      summ: 1890,
    },
    {
      date: '26.06.2021',
      period: '26.07.2021',
      summ: 1890,
    },
    {
      date: '25.07.2021',
      period: '26.08.2021',
      summ: 1890,
    },
    {
      date: '29.08.2021',
      period: '29.09.2021',
      summ: 1890,
    },
    {
      date: '30.09.2021',
      period: '30.10.2021',
      summ: 1890,
    },
    {
      date: '02.11.2021',
      period: '02.12.2021',
      summ: 1890,
    },
  ],
  branch: '367678',
  autopay: false,
  last_4: '0000',
  links: {
    month_1:
      'https://yoomoney.ru/checkout/payments/v2/contract?orderId=2c5f59bf-000f-5000-a000-14569ba9db99',
    month_3:
      'https://yoomoney.ru/checkout/payments/v2/contract?orderId=2c5f59c0-000f-5000-8000-1e873e9856a6',
    month_6:
      'https://yoomoney.ru/checkout/payments/v2/contract?orderId=2c5f59c0-000f-5000-a000-18e863279875',
    month_12:
      'https://yoomoney.ru/checkout/payments/v2/contract?orderId=2c5f59c0-000f-5000-8000-151fee2b8bdc',
  },
};
