import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Menu } from '@uikit/Menu';
import { Chips } from '@uikit/Chips';
import { createPortal } from 'react-dom';
import { Overlay } from '@atoms/overlay';
import { Dropdown } from '@uikit/Dropdown';
import { FormLabel } from '@blocks/formLabel';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { BALLOONS_ACTIONS } from '@const/common';
import { getAppData } from '@redux/app/selectors';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { THelpKeys, TSimpleStringObj } from '@models/index';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getListOfCategoriesTS } from '@redux/template/templateThunks';
import { getDropdownList, getSecondDropdownListTitle2, sortObj } from '@helpers/index';
import { TAddBalloonOnBlurHandler, TDeleteBalloonSet, TOnChangeHandler } from '@shared/types';

interface IInputWithDropdownProps {
  /**
   * Массив данных для отображения Chips components
   * @param {string[]}
   */
  addedValues: string[];
  /**
   * Id на input для ввода нового значения
   * @param {string}
   */
  inputId: string;
  /**
   * Значение input
   * @param {boolean}
   */
  newValue: string;
  /**
   * Параметр передает данные по успешном завершении получения данных страницы,
   * используется для отправки запроса к ЕР для получения списка для dropdown.
   * Запрос за списком уходит только если этот параметр true.
   * @param {boolean}
   */
  isFetchingSuccess: boolean;
  /**
   * Вариант текста в label, из словаря берется текст и подставляется в label
   * @param {THelpKeys}
   */
  labelTextVariant: THelpKeys;
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Объект с данными для отображения выпадающего списка
   * @param {{ [key: string]: TSimpleStringObj }}
   */
  categoriesDataList: { [key: string]: TSimpleStringObj };
  /**
   * Callback для удаления Chips component
   * @param {TDeleteBalloonSet}
   */
  deleteBalloon: TDeleteBalloonSet;
  /**
   * Callback для добавления Chips component при событии blur
   * @param {TAddBalloonOnBlurHandler}
   */
  addBalloonOnBlur: TAddBalloonOnBlurHandler;
  /**
   * Опциональный параметр, отключает возможность ввода новых данных
   * @param {boolean}
   */
  disabled?: boolean;
  /**
   * Опциональный параметр tailwind класс для изменения цвета текста label
   * @param {string}
   * @default
   */
  textColor?: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const InputWithDropdownFromEP = memo(
  ({
    inputId,
    newValue,
    textColor,
    addedValues,
    deleteBalloon,
    addBalloonOnBlur,
    labelTextVariant,
    isFetchingSuccess,
    categoriesDataList,
    selectedFilialAccId,
    className = '',
    disabled = false,
  }: IInputWithDropdownProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { appLanguage } = useAppSelector(getAppData);
    const [action, setAction] = useState('add');
    const [filterValue, seFilterValue] = useState('');
    const [isShowDropdown, setIsShowDropdown] = useState(false);
    const isClientCategory = useMemo(() => inputId === 'clientCategory', [inputId]);
    const dropdownList = useMemo(() => categoriesDataList[inputId], [inputId, categoriesDataList]);
    const [filteredList, setFilteredList] = useState<TSimpleStringObj>({});
    const [filteredBySearchStringList, setFilteredBySearchStringList] = useState<TSimpleStringObj>(
      {},
    );

    useEffect(() => {
      if (inputId && isFetchingSuccess && selectedFilialAccId) {
        dispatch(getListOfCategoriesTS({ categoryName: inputId }));
      }
    }, [dispatch, inputId, isFetchingSuccess, selectedFilialAccId]);

    useEffect(() => {
      const moduloValues = addedValues.map(item => item.replace(/^-*/gm, ''));
      const newList = Object.fromEntries(
        Object.entries(dropdownList || []).filter(
          item => !moduloValues.includes(isClientCategory ? item[1] : item[0]),
        ),
      );
      setFilteredList(newList || []);
    }, [dropdownList, inputId, addedValues, isClientCategory]);

    // Добавляет новый "шарик"
    const addBalloons = (balloonValue: string) => () => {
      const newList = Object.fromEntries(
        Object.entries(filteredList).filter(
          item => !balloonValue.includes(isClientCategory ? item[1] : item[0]),
        ),
      );
      setFilteredList(newList);

      if (action === BALLOONS_ACTIONS.ADD && !newValue?.startsWith('-')) {
        addBalloonOnBlur(inputId, balloonValue);
      } else {
        addBalloonOnBlur(inputId, `-${balloonValue}`);
      }
      if (isShowDropdown) {
        setIsShowDropdown(false);
        seFilterValue('');
      }
    };

    // Удаляет "шарик" из списка
    const deleteBalloonHandler: TDeleteBalloonSet = (item, inputName) => () => {
      const deletedItem = Object.fromEntries(
        Object.entries(dropdownList).filter(listItem => listItem[0] === item.replace(/-/gm, '')),
      );
      const newSortedList = sortObj(Object.assign(filteredList, deletedItem));

      setFilteredList(newSortedList);

      deleteBalloon(item, inputName)();
    };

    // Подготовка "шариков" для рендеринга
    const balloons = (addedValues || []).map((item, index) => {
      const key = `${index}${item}`;
      const itemText = item?.startsWith('-') ? item.slice(1) : item;
      const chipsText = isClientCategory
        ? `${itemText}`
        : `${categoriesDataList?.[inputId]?.[itemText] || ''} (${itemText})`;
      return (
        <Chips
          key={key}
          removable
          type='def'
          tag={chipsText}
          icon={item?.startsWith('-') ? 'disable' : null}
          onRemove={deleteBalloonHandler(item, inputId)}
          color={item?.startsWith('-') ? 'danger' : 'default'}
          className='tracking-wide mr-2.5 mb-2.5 !w-screen m:!w-fit'
        />
      );
    });

    // Список действий с шариками, к стандартным добавить/удалить добавляется название категории
    const actionsList = getDropdownList(labelTextVariant, appLanguage);

    // Значение плейсхолдера в инпут
    const secondDropdownPlaceholder = getSecondDropdownListTitle2(labelTextVariant, appLanguage);

    // MenuItem для дропдауна с действиями
    const itemsToRenderInFirstDropdown = Object.entries(actionsList).map(item => (
      <MenuItem key={item[0]} onClick={() => setAction(item[0])} text={<span>{item[1]}</span>} />
    ));

    // MenuItem для дропдауна со значениями в категории
    const itemsToRenderInSecondDropdown = Object.entries(
      filterValue ? filteredBySearchStringList : filteredList,
    ).map(item => (
      <MenuItem
        key={item[0]}
        onClick={
          item[0] !== t('ERROR_MESSAGES_TEXT.nothingFound')
            ? addBalloons(isClientCategory ? item[1] : item[0])
            : undefined
        }
        text={<span>{`${item[1]}(${item[0]})`}</span>}
      />
    ));

    // Меню для второго дропдауна
    const menuToRender = (
      <Menu
        view='raised'
        className={`dropdown-list-scrollbar ${
          itemsToRenderInSecondDropdown.length === 1 ? `one_item_ul` : ''
        } max-h-[50vh] overflow-y-auto`}>
        {itemsToRenderInSecondDropdown}
      </Menu>
    );

    // Запрашивает список при нажатии на инпут
    const fetchList = () => {
      if (!Object.entries(dropdownList || {}).length) {
        dispatch(getListOfCategoriesTS({ categoryName: inputId }));
      }
    };

    // Показывает выпадающий список
    const dropdownVisibleHandler = () => {
      setIsShowDropdown(true);
    };

    // Обработчик фильтрует данные во во втором дропдауне в зависимости от введенного значения в инпут
    const filterInputHandler: TOnChangeHandler = event => {
      const { value } = event.currentTarget;

      if (value) {
        const newArray = Object.entries(filteredList).filter(
          item =>
            item[0]?.toLowerCase().includes(value.toLowerCase()) ||
            item[1]?.toLowerCase().includes(value.toLowerCase()),
        );
        const newList = Object.fromEntries(
          newArray.length ? newArray : [[t('ERROR_MESSAGES_TEXT.nothingFound'), '']],
        );
        seFilterValue(value);
        setFilteredBySearchStringList(newList);
      } else {
        seFilterValue(value);
        setIsShowDropdown(true);
        setFilteredList(filteredList);
      }
    };

    // Скрывает выпадающий список и очищает значение в инпуте
    const dropdownCloseHandler = () => {
      seFilterValue('');
      setIsShowDropdown(false);
      setFilteredList(filteredList);
    };

    return (
      <div className={`flex flex-col ${className}`}>
        <FormLabel
          htmlFor={inputId}
          textColor={textColor}
          labelTextVariant={labelTextVariant}
          className='text-body_text mb-2.5'
        />
        <div className='flex flex-wrap sm:flex-nowrap items-center'>
          <div className='sm:mr-2 mb-2 w-full sm:w-fit'>
            <Dropdown
              color='default'
              view='outlined'
              position='bottom-left'
              text={
                <span className={disabled ? 'cursor-not-allowed text-start w-full' : ''}>
                  {actionsList[action]}
                </span>
              }
              className={`when_send_dropdown !m-0 sm:!min-w-[17.5rem] w-full ${className}`}
              content={
                <Menu
                  view='raised'
                  className={itemsToRenderInFirstDropdown.length === 1 ? `one_item_ul` : ''}>
                  {itemsToRenderInFirstDropdown}
                </Menu>
              }
              disabled={disabled}
            />
          </div>
          <div className='relative mb-2 w-full sm:!max-w-[29rem]'>
            <TextInput
              fill
              type='text'
              clearButton
              error={false}
              view='outlined'
              color='default'
              success={false}
              value={filterValue}
              onClick={fetchList}
              disabled={disabled}
              onChange={filterInputHandler}
              onFocus={dropdownVisibleHandler}
              placeholder={secondDropdownPlaceholder}
              clearSearchString={() => seFilterValue('')}
              className={`${isShowDropdown ? 'z-[60]' : ''} ${
                filterValue ? '' : 'chevron_in_after'
              }`}
            />
            <div className={`${isShowDropdown ? 'block' : 'hidden'} absolute top-[3rem] z-[70]`}>
              {menuToRender}
            </div>
          </div>
        </div>
        <div className='flex items-center flex-wrap overflow-hidden w-full'>{balloons}</div>
        {isShowDropdown
          ? createPortal(<Overlay onClickCallback={dropdownCloseHandler} />, document.body)
          : null}
      </div>
    );
  },
);

InputWithDropdownFromEP.displayName = 'InputWithDropdownFromEP';
