import { TAppLanguage } from '@models/index';

export const smsOperatorIndexName: Record<TAppLanguage, TSMSOperatorIndexName> = {
  eng: {
    1: 'disabled',
    2: 'smsgorod',
    3: 'alfasms',
    4: 'sigmasms',
    5: 'prontosms',
    6: 'messagio',
    7: 'notisend',
    8: 'iqsms',
    9: 'sms-prosto',
    10: 'rumessage',
    11: 'stream-telecom',
    12: 'p1sms',
    13: 'prostor-sms',
    14: 'sms.md',
    15: 'messedo',
    16: 'pushsms',
    17: 'intistelecom',
  },
  rus: {
    1: 'отключено',
    2: 'smsgorod',
    3: 'alfasms',
    4: 'sigmasms',
    5: 'prontosms',
    6: 'messagio',
    7: 'notisend',
    8: 'iqsms',
    9: 'sms-prosto',
    10: 'rumessage',
    11: 'stream-telecom',
    12: 'p1sms',
    13: 'prostor-sms',
    14: 'sms.md',
    15: 'messedo',
    16: 'pushsms',
    17: 'intistelecom',
  },
};

export const SMS_OPERATOR_NAMES = {
  TURN_OFF: 'отключено',
};

export const SMS_OPERATOR_NAMES_ENG = {
  TURN_OFF: 'disabled',
};

export const TOKEN_REQUIRED = [
  'pushsms',
  'smsgorod',
  'alfasms',
  'sms-prosto',
  'p1sms',
  'sms.md',
  'messedo',
  'notisend',
  'intistelecom',
];
export const LOGIN_REQUIRED = [
  'sigmasms',
  'prontosms',
  'messagio',
  'notisend',
  'iqsms',
  'rumessage',
  'stream-telecom ',
  'stream-telecom',
  'prostor-sms',
  'sms-prosto',
  'intistelecom',
];
export const PASSWORD_REQUIRED = [
  'sigmasms',
  'prontosms',
  'messagio',
  'iqsms',
  'rumessage',
  'stream-telecom ',
  'stream-telecom',
  'prostor-sms',
  'sms-prosto',
];
export const SENDER_NAME_REQUIRED = [
  'alfasms',
  'imobis',
  'iqsms',
  'sms-prosto',
  'rumessage',
  'stream-telecom ',
  'stream-telecom',
  'prostor-sms',
  'sms.md',
  'messedo',
  'messagio',
  'pushsms',
  'notisend',
  'intistelecom',
];

export enum PROVIDER_NAMES {
  PUSH_SMS = 'pushsms',
  SMS_GOROD = 'smsgorod',
  ALFA_SMS = 'alfasms',
  SMS_PROSTO = 'sms-prosto',
  P_1_SMS = 'p1sms',
  SMS_MD = 'sms.md',
  MESSEDO = 'messedo',
  NOTISEND = 'notisend',
  SIGMA_SMS = 'sigmasms',
  PROSTO_SMS = 'prontosms',
  MESSAGIO = 'messagio',
  IQ_SMS = 'iqsms',
  RU_MESSAGE = 'rumessage',
  STREAM_TELECOM = 'stream-telecom',
  STREAM_TELECOM_WITH_SPACE_ON_END = 'stream-telecom ',
  PROSTOR_SMS = 'prostor-sms',
  IMOBIS = 'imobis',
  INTISTELECOM = 'intistelecom',
}

export const PROVIDER_NAME_FOR_SHOW_ADDITIONAL_INFO: Record<string, string> = {
  rumessage: 'Данный провайдер принимает пароль только в формате MD5',
  'sms-prosto':
    'Можно указать или ключ API или емайл и пароль.\n Имя отправителя является обязательным',
};

export const PROVIDER_NAME_FOR_SHOW_ADDITIONAL_INFO_ENG: Record<string, string> = {
  rumessage: 'This provider only accepts passwords in MD5 format',
  'sms-prosto':
    "You can specify either an API key or an email and password.\n The sender's name is required",
};

export const Midnight = '00:00' as const;
export const OneHourBeforeMidnight = '23:00' as const;

export const SETTINGS_TOAST_MESSAGES = {
  SMS_CONFIRM_TURN_ON_ERROR: 'Нельзя выбрать этот пункт \n без настроенного СМС провайдера',
  SMS_SENDER_FIELD_EMPTY_ERROR:
    'Заполните все поля настроек провайдера, \n эти поля не могут быть пустыми',
  SMS_CONFIRM_TURN_OFF_WARNING:
    'Отключен пункт\n «Отправлять коды подтверждения в СМС».\n Чтобы включить его выберите СМС провайдера.',
};

export const SETTINGS_TOAST_MESSAGES_ENG = {
  SMS_CONFIRM_TURN_ON_ERROR: 'This item cannot be selected \n without a configured SMS provider',
  SMS_SENDER_FIELD_EMPTY_ERROR:
    'Fill in all fields of the provider settings,\nthese fields cannot be empty',
  SMS_CONFIRM_TURN_OFF_WARNING:
    'The item\n “Send verification codes via SMS” is disabled.\n To enable it, select the SMS provider.',
};

export const SETTINGS_MESSAGES = {
  SMS_CONFIRM_TURN_ON_ERROR: 'SETTINGS_PAGE_TEXT.SETTINGS_TOAST_MESSAGES.SMS_CONFIRM_TURN_ON_ERROR',
  SMS_SENDER_FIELD_EMPTY_ERROR:
    'SETTINGS_PAGE_TEXT.SETTINGS_TOAST_MESSAGES.SMS_SENDER_FIELD_EMPTY_ERROR',
  SMS_CONFIRM_TURN_OFF_WARNING:
    'SETTINGS_PAGE_TEXT.SETTINGS_TOAST_MESSAGES.SMS_CONFIRM_TURN_OFF_WARNING',
};

export type TSMSOperatorIndexName = {
  1: 'отключено' | 'disabled';
  2: 'smsgorod';
  3: 'alfasms';
  4: 'sigmasms';
  5: 'prontosms';
  6: 'messagio';
  7: 'notisend';
  8: 'iqsms';
  9: 'sms-prosto';
  10: 'rumessage';
  11: 'stream-telecom';
  12: 'p1sms';
  13: 'prostor-sms';
  14: 'sms.md';
  15: 'messedo';
  16: 'pushsms';
  17: 'intistelecom';
};

export type TSettingsBillData = {
  [key: string]: string;
  inn: string;
  name: string;
  kpp: string;
};

export enum SETTINGS_BILLING_DATA_KEY_NAMES {
  INN = 'inn',
  NAME = 'name',
  KPP = 'kpp',
}

export enum SETTINGS_BILL_DATA_KEY_NAMES {
  INN = '',
  NAME = '',
  KPP = '',
}

export const SETTINGS_PAGE_TEXT = {
  header: 'Настройки',
  chatEnableModal: {
    title: 'Подтвердите включение чата',
    description:
      'Вы даете согласие на подключение чата в Yclients, переписка рабочего вотсапа будет доступна всем сотрудникам, у кого есть доступ в журнал записи',
    confirmButton: 'Включить',
    cancelButton: 'Отмена',
  },
  phoneNumberLabel: 'Номер телефона:',
  sendTimeLimit: {
    clearButton: 'Очистить',
    from: 'c',
    to: 'до',
  },
  nameOfLegalEntity: 'Наименование юр.лица',
  tin: 'ИНН',
  saveButton: 'Сохранить настройки',
  saveButtonTitle: 'Недостаточно прав',
  smsOperatorSelect: {
    additionalInfo: 'Доп. информация по настройке СМС провайдера',
    apiKey: 'Ключ API',
    login: 'Логин',
    passWord: 'Пароль',
    senderName: 'Имя отправителя',
    disabled: 'отключено',
  },
  categoriesDataListNoEntries: 'Нет записей',
  SMS_OPERATOR_NAMES,
  SETTINGS_TOAST_MESSAGES,
  PROVIDER_NAME_FOR_SHOW_ADDITIONAL_INFO,
};

export const SETTINGS_PAGE_TEXT_ENG = {
  header: 'Settings',
  phoneNumberLabel: 'Phone number:',
  sendTimeLimit: {
    clearButton: 'Clear',
    from: 'from',
    to: 'to',
  },
  nameOfLegalEntity: 'Name of legal entity',
  tin: 'TIN',
  saveButton: 'Save settings',
  saveButtonTitle: 'Insufficient rights',
  smsOperatorSelect: {
    additionalInfo: 'Additional information on setting up SMS provider',
    apiKey: 'API Key',
    login: 'Login',
    passWord: 'Password',
    senderName: "Sender's name",
    disabled: 'disabled',
  },
  categoriesDataListNoEntries: 'No entries',
  SMS_OPERATOR_NAMES: SMS_OPERATOR_NAMES_ENG,
  SETTINGS_TOAST_MESSAGES: SETTINGS_TOAST_MESSAGES_ENG,
  PROVIDER_NAME_FOR_SHOW_ADDITIONAL_INFO: PROVIDER_NAME_FOR_SHOW_ADDITIONAL_INFO_ENG,
};
