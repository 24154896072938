import React, { memo } from 'react';

type TTemplateCardWrapper = {
  /**
   * Массив с карточками шаблонов
   * @param {React.ReactNode}
   */
  templateCard: React.ReactNode;
};

export const TemplateCardWrapper = memo(({ templateCard }: TTemplateCardWrapper) => {
  return (
    <div className='grid grid-cols-3temp xxl:grid-cols-3tempXXL gap-[1.5rem]'>{templateCard}</div>
  );
});

TemplateCardWrapper.displayName = 'TemplateCardWrapper';
