import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FAST_FEEDBACK_BUTTON_TEXT } from '@const/inviteFriend';
import { sendFeedbackData } from '@redux/feedback/feedbackThunk';
import { TAppLanguage, TRejectResponseData } from '@models/index';
import { REJECT_RESPONSE_2_KEY, REJECT_RESPONSE_KEY } from '@api/types';
import { TInviteFriendListData, TSwitchActionData } from '@redux/inviteFriendList/types';
import { IInitialState, initialState } from '@redux/inviteFriendList/inviteFriendInitialState';
import {
  deleteActionThunk,
  switchActionThunk,
  getActionListThunk,
  copyActionToFilialThunk,
} from '@redux/inviteFriendList/inviteFriendsThunk';

import i18n from 'i18n';

const inviteFriendListSlice = createSlice({
  name: 'inviteFriendSlice',
  initialState,
  reducers: {
    setPageData(state: IInitialState, action: PayloadAction<TInviteFriendListData[]>) {
      state.data = action.payload;
    },
    setFeedbackTextIF(state: IInitialState, action: PayloadAction<string>) {
      state.feedbackText = action.payload;
    },
    addFastReview(state: IInitialState, action: PayloadAction<string>) {
      const lang = i18n.language as TAppLanguage;
      const newFastReview = action.payload;

      const oldFastReview = state.fastReview;
      const oldFastReviewText = FAST_FEEDBACK_BUTTON_TEXT[lang][oldFastReview];
      const newFastReviewText = FAST_FEEDBACK_BUTTON_TEXT[lang][newFastReview];

      const text = state.feedbackText.replace(oldFastReviewText, '');

      state.fastReview = newFastReview;

      state.feedbackText = `${newFastReviewText || ''}${text}`;
    },
    hideWarningModalIFL(state: IInitialState) {
      state.isShowWarningModal = false;
    },
    clearAllStatusMessages(state: IInitialState) {
      state.errorMessage = '';
      state.successMessage = '';
      state.isDeletingError = false;
      state.isDeletingSuccess = false;
    },
    clearAllErrorIFL(state: IInitialState) {
      state.isError = false;
      state.isAccessDenied = false;
      state.isDeletingError = false;
      state.isSwitchingError = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getActionListThunk.pending, (state: IInitialState) => {
      state.isLoading = true;
    });
    builder.addCase(
      getActionListThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<TInviteFriendListData[] | void>) => {
        state.isLoading = false;
        if (action.payload) {
          state.data = action.payload;
        }
      },
    );
    builder.addCase(
      getActionListThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isLoading = false;
            } else {
              return;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
          }
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(deleteActionThunk.pending, (state: IInitialState) => {
      state.isDeleting = true;
    });
    builder.addCase(
      deleteActionThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<string | void>) => {
        if (action.payload) {
          const id = action.payload;

          state.isDeleting = false;
          state.isDeletingSuccess = true;
          state.successMessage = i18n.t(
            'INVITE_FRIEND_PAGE_TEXT.deleteModal.deletingSuccessMessage',
            { id },
          );

          state.data = state.data.filter(item => item.id !== id);
        }
      },
    );
    builder.addCase(
      deleteActionThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isDeleting = false;
            } else {
              return;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
          }
        } else {
          state.isDeletingError = true;
          state.errorMessage = i18n.t('COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE');
        }
        state.isDeleting = false;
      },
    );
    builder.addCase(switchActionThunk.pending, (state: IInitialState) => {
      state.isSwitching = true;
    });
    builder.addCase(
      switchActionThunk.fulfilled,
      (
        state: IInitialState,
        action: PayloadAction<{ data: TSwitchActionData; loyaltyPermissionStatus: boolean } | void>,
      ) => {
        if (action.payload) {
          const {
            data: { id, status },
            loyaltyPermissionStatus,
          } = action.payload;

          state.isSwitching = false;

          if (status || (!status && loyaltyPermissionStatus)) {
            state.data = state.data.map(item => {
              if (item.id === id) {
                return { ...item, isActive: !status };
              }
              return item;
            });
          }

          if (!loyaltyPermissionStatus && !status) {
            state.isShowWarningModal = true;
          }
        }
      },
    );
    builder.addCase(
      switchActionThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isSwitching = false;
            } else {
              return;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
          }
        } else {
          state.errorMessage = i18n.t('COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE');
        }
        state.isSwitching = false;
      },
    );
    builder.addCase(copyActionToFilialThunk.pending, (state: IInitialState) => {
      state.isDeleting = true;
    });
    builder.addCase(
      copyActionToFilialThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<TInviteFriendListData | void>) => {
        if (action.payload) {
          state.isDeleting = false;
          state.data.push(action.payload);
          state.successMessage = i18n.t('INVITE_FRIEND_PAGE_TEXT.actionDuplicate');
        }
      },
    );
    builder.addCase(copyActionToFilialThunk.rejected, (state: IInitialState) => {
      state.isDeleting = false;
      state.errorMessage = i18n.t('COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE');
    });
    builder.addCase(sendFeedbackData.pending, (state: IInitialState) => {
      state.isFeedbackSending = true;
    });
    builder.addCase(
      sendFeedbackData.fulfilled,
      (state: IInitialState, action: PayloadAction<boolean>) => {
        state.fastReview = '';
        state.feedbackText = '';
        state.isFeedbackSending = false;

        if (action.payload) {
          state.successMessage = i18n.t('INVITE_FRIEND_PAGE_TEXT.feedback.feedbackSend');
        }
      },
    );
    builder.addCase(
      sendFeedbackData.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;

            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              return;
            }
          }
        }
        state.isFeedbackSending = false;
        state.errorMessage = i18n.t('COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE');
      },
    );
  },
});

export const inviteFriendListSliceReducer = inviteFriendListSlice.reducer;

export const {
  setPageData,
  addFastReview,
  clearAllErrorIFL,
  setFeedbackTextIF,
  hideWarningModalIFL,
  clearAllStatusMessages,
} = inviteFriendListSlice.actions;
