import React, { memo } from 'react';
//
import { TMonthData } from '@api/types';
import { PayCard } from '@components/payCard';
import { TCardSelectHandler } from '@shared/types';
import { TAppLanguage } from '@models/index';

type TPayCards = {
  /**
   * Цена 1 месяца
   * @param {string}
   */
  amount: string;
  /**
   * Стоимость месяца с учетом бонусных недель
   * @param {TMonthData}
   */
  monthCost: TMonthData;
  /**
   * Флаг состояния автоплатежа
   * @param {boolean}
   */
  isAutoPayEnable: boolean;
  /**
   * Массив с количеством месяцев для оплаты
   * @param {string}
   */
  numberOfMonth: string[];
  /**
   * Массив с количеством недель при оплате
   * @param {string[]}
   */
  numberOfGiftWeek: string[];
  /**
   * Номер выбранной карточки
   * @param {number}
   */
  selectedCardNumber: number;
  /**
   * Callback для выбора карточки для оплаты
   * @param {TCardSelectHandler}
   */
  cardSelectCallback: TCardSelectHandler;
  /**
   * Массив ссылок в карточки для оплаты
   * @param {string[]}
   */
  payLinks: string[];
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const PayCards = memo(
  ({
    amount,
    payLinks,
    monthCost,
    appLanguage,
    numberOfMonth,
    isAutoPayEnable,
    numberOfGiftWeek,
    selectedCardNumber,
    cardSelectCallback,
    className = '',
  }: TPayCards) => {
    const monthArrayToCreatePayCards =
      payLinks.length === 3 ? numberOfMonth.slice(1) : numberOfMonth;

    const numberOfGiftWeekToCards =
      payLinks.length === 3 ? numberOfGiftWeek.slice(1) : numberOfGiftWeek;

    const cardsToRender = monthArrayToCreatePayCards.map((item, index) => {
      const plusOneIndex = index + 1;
      const cardTotalMonth = +numberOfMonth[index];

      return (
        <PayCard
          key={cardTotalMonth}
          months={item}
          appLanguage={appLanguage}
          oneMonthCost={Number(amount)}
          isAutoPayEnable={isAutoPayEnable}
          giftWeeks={numberOfGiftWeekToCards[index]}
          isSelected={selectedCardNumber === plusOneIndex}
          onClickCallback={cardSelectCallback(plusOneIndex)}
          oneMonthCostWithDiscount={monthCost[numberOfMonth[index]]}
          className={plusOneIndex !== payLinks.length ? 'mr-4 mb-4' : ''}
        />
      );
    });

    return <div className={`flex lg:flex-wrap ${className}`}>{cardsToRender}</div>;
  },
);

PayCards.displayName = 'PayCards';
