import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@atoms/icon';
import { Link } from 'react-router-dom';

type TMailingSecondStepInfoProps = {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const MailingFirstStepInfo = memo(({ className = '' }: TMailingSecondStepInfoProps) => {
  const { t } = useTranslation();

  const textClass = 'text-gray2';
  const listClass = `${textClass} list-disc pl-6`;
  const titleClass = 'font-bold text-black text-h3Mobile mb-1 tracking-[0.0075em]';
  const containerClass = 'border border-quartz rounded-lg py-3 px-4 mb-4 w-full sm:max-w-[48%]';

  return (
    <div className={`flex flex-col sm:flex-row w-full ${className}`}>
      <div className={`${containerClass} mr-5`}>
        <Icon variant='yclients' className='mt-3 mb-4' />
        <p className={titleClass}>
          {t('MAILING_PAGE_TEXT.mailingSecondStepInfo.firstMethod.title')}
        </p>
        <ul className={listClass}>
          <li>{t('MAILING_PAGE_TEXT.mailingSecondStepInfo.firstMethod.ul.first')}</li>
          <li>{t('MAILING_PAGE_TEXT.mailingSecondStepInfo.firstMethod.ul.second')}</li>
          <li>{t('MAILING_PAGE_TEXT.mailingSecondStepInfo.firstMethod.ul.third')}</li>
        </ul>
      </div>
      <div className={`${containerClass} flex flex-col items-start justify-between`}>
        <Icon variant='excel' className='mt-3 mb-4' />
        <div>
          <p className={titleClass}>
            {t('MAILING_PAGE_TEXT.mailingSecondStepInfo.secondMethod.title')}
          </p>
          <p className={textClass}>
            {t('MAILING_PAGE_TEXT.mailingSecondStepInfo.secondMethod.warningMessage')}
          </p>
          <ul className={listClass}>
            <li>{t('MAILING_PAGE_TEXT.mailingSecondStepInfo.secondMethod.ul.first')}</li>
            <li>{t('MAILING_PAGE_TEXT.mailingSecondStepInfo.secondMethod.ul.second')}</li>
          </ul>
        </div>
        <p className={`${textClass} leading-6 tracking-[0.0275em] mt-6`}>
          {t('MAILING_PAGE_TEXT.mailingSecondStepInfo.fileExample')}
          <Link to='/files/template.xlsx' className='text-link ml-1' target='_blank' download>
            {t('MAILING_PAGE_TEXT.mailingSecondStepInfo.download')}
          </Link>
        </p>
      </div>
    </div>
  );
});

MailingFirstStepInfo.displayName = 'MailingSecondStepInfo';
