import { TGetRequestData } from '@api/types';
import { TSimpleStringObj } from '@models/index';
import { TTelegramAuthData } from '@redux/auth/models';

/**
 * IStorageApi interface defines the contract for the StorageApi class.
 * It lists all the methods that need to be implemented in the StorageApi class.
 */
export interface IStorageApi {
  setUserData: (userData: TTelegramAuthData) => void;
  setUserId: (userId: number) => void;
  getUserId: () => { userId: string | null };
  getUserData: () => { userData: TTelegramAuthData | null };
  setAuth: (status: boolean) => void;
  getAuth: () => { isAuth: boolean };
  setSelectedFilialData: ({
    accId,
    filialName,
    isSendOutAccount,
  }: {
    accId: string;
    filialName: string;
    isSendOutAccount: boolean;
  }) => void;
  getSelectedFilialData: () => {
    accId: string | null;
    filialName: string | null;
    sendOutAccount: boolean;
  };
  getEmoji: () => { emoji: TSimpleStringObj };
  setEmoji: (emoji: TSimpleStringObj) => void;
  getTooltipStatus: () => { tooltip: string };
  setTooltipStatus: (tooltip: string) => void;
  getHeaderBannerStatus: () => { isHeaderBannerStatus: string };
  setHeaderBannerStatus: (isHeaderBannerStatus: string) => void;
  getHeaderBannerText: () => { text: string };
  setHeaderBannerText: (text: string) => void;
  setToken: (token: string) => void;
  getToken: () => { token: string | null };
  getRequestData: () => TGetRequestData;
  removeKeyFromDB: (keyName: string) => void;
  clearDB: () => void;
}

/**
 * StorageApi class is responsible for managing browser storage.
 * It can save data to localStorage and sessionStorage.
 * The storage object is passed when creating an instance of the class.
 * @class StorageApi
 */
class StorageApi implements IStorageApi {
  private static _instance: StorageApi;

  private DB: Storage;

  private readonly Salt: string;

  private readonly Rights: string;

  private readonly AccId: string;

  private readonly UserId: string;

  private readonly FilialName: string;

  private readonly Auth: string;

  private readonly Token: string;

  private readonly UserData: string;

  private readonly IsSendOutAccount: string;

  private readonly Emoji: string;

  private readonly ShowTooltip: string;

  private readonly ShowHeaderBanner: string;

  private readonly HeaderBannerText: string;

  /**
   * Private constructor for the StorageApi singleton class.
   * @constructor
   * @param {Storage} DB - The browser storage object (localStorage or sessionStorage).
   */
  private constructor(DB: Storage) {
    this.DB = DB;
    this.Auth = 'auth';
    this.UserId = 'id';
    this.AccId = 'accId';
    this.Emoji = 'emoji';
    this.Token = 'token';
    this.UserData = 'userData';
    this.FilialName = 'filialName';
    this.IsSendOutAccount = 'isSendOutAccount';
    this.ShowTooltip = 'tooltip';
    this.ShowHeaderBanner = 'headerBanner';
    this.HeaderBannerText = 'headerBannerText';
    this.Rights = 'rights';
    this.Salt = 'salt';
  }

  /**
   * Static method to get the singleton instance of the StorageApi class.
   * @static
   * @param {Storage} DB - The browser storage object (localStorage or sessionStorage).
   * @returns {StorageApi} The singleton instance of the StorageApi class.
   */
  public static getInstance(DB: Storage): StorageApi {
    if (!StorageApi._instance) {
      StorageApi._instance = new StorageApi(DB);
      Object.freeze(StorageApi._instance);
    }
    return StorageApi._instance;
  }

  private getItemFromDB(itemName: string): string {
    return this.DB.getItem(itemName) || '';
  }

  private setItemToDB({ itemName, value }: { itemName: string; value: string }): void {
    this.DB.setItem(itemName, value);
  }

  private crypt(textToCrypt: string) {
    const textToChars = (text: string) => text.split('').map(c => c.charCodeAt(0));
    const byteHex = (n: number) => `0${Number(n).toString(16)}`.substr(-2);
    const applySaltToChar = ([code]: number[]) =>
      textToChars(this.Salt).reduce((a, b) => a ^ b, code);

    return textToCrypt.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
  }

  private decrypt(encoded: string): string {
    const textToChars = (text: string) => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = (code: number) => textToChars(this.Salt).reduce((a, b) => a ^ b, code);
    const matchArray = encoded.match(/.{1,2}/g) || [];
    return matchArray
      .map(hex => parseInt(hex, 16))
      .map(applySaltToChar)
      .map(charCode => String.fromCharCode(charCode))
      .join('');
  }

  setRight(rights: string[]) {
    const cryptRights = this.crypt(JSON.stringify(rights));
    this.setItemToDB({ itemName: this.Rights, value: cryptRights });
  }

  getRights(): string[] {
    const cryptRights = this.getItemFromDB(this.Rights);
    if (cryptRights) {
      let rights: string[] = [];
      try {
        rights = JSON.parse(this.decrypt(cryptRights));
        return rights;
      } catch (e) {
        // console.error(e);
      }
    }
    return [];
  }

  /**
   * Возвращает из хранилища список последних эмодзи
   * @method getEmoji
   * @return {emoji: TSimpleStringObj}
   */
  getEmoji() {
    const emoji = this.getItemFromDB(this.Emoji);
    try {
      return { emoji: JSON.parse(emoji) };
    } catch (error) {
      return { emoji: {} };
    }
  }

  /**
   * Сохраняет в хранилище  список последних эмодзи
   * @method setEmoji
   * @param emoji {TSimpleStringObj}
   */
  setEmoji(emoji: TSimpleStringObj) {
    this.setItemToDB({ itemName: this.Emoji, value: JSON.stringify(emoji) });
  }

  /**
   * Возвращает из хранилища статус ознакомительной подсказки
   * @method getTooltipStatus
   * @return {tooltip: string}
   */
  getTooltipStatus() {
    const tooltip = this.getItemFromDB(this.ShowTooltip);
    return { tooltip };
  }

  /**
   * Сохраняет в хранилище статус ознакомительной подсказки
   * @method setTooltipStatus
   * @param tooltip {string}
   */
  setTooltipStatus(tooltip: string) {
    this.setItemToDB({ itemName: this.ShowTooltip, value: tooltip });
  }

  /**
   * Возвращает из хранилища статус баннера состояния
   * @method getHeaderBannerStatus
   * @return {isHeaderBannerStatus: string}
   */
  getHeaderBannerStatus() {
    const isHeaderBannerStatus = this.getItemFromDB(this.ShowHeaderBanner);
    return { isHeaderBannerStatus };
  }

  /**
   * Сохраняет в хранилище статус баннера состояния
   * @method setHeaderBannerStatus
   * @param isHeaderBannerStatus {string}
   */
  setHeaderBannerStatus(isHeaderBannerStatus: string) {
    this.setItemToDB({ itemName: this.ShowHeaderBanner, value: isHeaderBannerStatus });
  }

  /**
   * Возвращает из хранилища текст банера состояния
   * @method getHeaderBannerText
   * @return {text: string}
   */
  getHeaderBannerText() {
    const text = this.getItemFromDB(this.HeaderBannerText);
    return { text };
  }

  /**
   * Сохраняет в хранилище текст банера состояния
   * @method setHeaderBannerText
   * @param text {string}
   */
  setHeaderBannerText(text: string) {
    this.setItemToDB({ itemName: this.HeaderBannerText, value: text });
  }

  /**
   * Возвращает из хранилища данные о авторизации
   * @method getAuth
   * @return {string} {auth: string}
   */
  getAuth() {
    const isAuth = this.DB.getItem(this.Auth) === 'true';
    return { isAuth };
  }

  /**
   * Возвращает из хранилища данные выбранном филиале
   * @method getSelectedFilialData
   * @return {string | null | boolean} {accId: string | null, filialName: string | null, }
   */
  getSelectedFilialData() {
    const accId = this.getItemFromDB(this.AccId);
    const filialName = this.getItemFromDB(this.FilialName);
    const sendOutAccount = this.getItemFromDB(this.IsSendOutAccount) === 'true';
    return { accId, filialName, sendOutAccount };
  }

  /**
   * Возвращает из хранилища токен
   * @method getToken
   * @return {string} {token: string}
   */
  getToken() {
    const token = this.getItemFromDB(this.Token);
    return { token };
  }

  /**
   * Возвращает из хранилища данные авторизованного пользователя
   * @method getUserData
   * @return {TTelegramAuthData} {userData: TTelegramAuthData}
   */
  getUserData() {
    const userData = this.getItemFromDB(this.UserData);
    if (userData) {
      try {
        return { userData: JSON.parse(userData) as TTelegramAuthData };
      } catch (error) {
        return { userData: null };
      }
    }
    return { userData: null };
  }

  /**
   * Сохраняет в хранилище данные авторизации
   * @method setAuth
   * @param status {boolean}
   */
  setAuth(status: boolean) {
    this.setItemToDB({ itemName: this.Auth, value: JSON.stringify(status) });
  }

  /**
   * Сохраняет в хранилище данные выбранного филиала
   * @method setSelectedFilialData
   * @param accId {string} id филиала
   * @param filialName {string} название филиала
   * @param isSendOutAccount Является ли аккаунт аккаунтом рассылкой
   */
  setSelectedFilialData({
    accId,
    filialName,
    isSendOutAccount,
  }: {
    accId: string;
    filialName: string;
    isSendOutAccount: boolean;
  }): void {
    this.setItemToDB({ itemName: this.AccId, value: accId });
    this.setItemToDB({ itemName: this.FilialName, value: filialName });
    this.setItemToDB({ itemName: this.IsSendOutAccount, value: String(isSendOutAccount) });
  }

  /**
   * Сохраняет в хранилище токен
   * @method setToken
   * @param token {string} токен авторизации запросов
   */
  setToken(token: string): void {
    this.setItemToDB({ itemName: this.Token, value: token });
  }

  /**
   * Сохраняет в хранилище telegram ID авторизованного пользователя
   * @method setUserId
   * @param userId {number}
   */
  setUserId(userId: number) {
    this.setItemToDB({ itemName: this.UserId, value: JSON.stringify(userId) });
  }

  /**
   * Возвращает из хранилища telegram ID авторизованного пользователя
   * @method getUserId
   * @return {string} {userId: string}
   */
  getUserId() {
    const userId = this.getItemFromDB(this.UserId);
    return { userId };
  }

  /**
   * Сохраняет в хранилище данные авторизованного пользователя
   * @method setUserData
   * @param userData {TTelegramAuthData}
   * @return {void}
   */
  setUserData(userData: TTelegramAuthData): void {
    const data = JSON.stringify(userData);
    this.setItemToDB({ itemName: this.UserData, value: data });
    this.setItemToDB({ itemName: this.UserId, value: String(userData.id) });
  }

  /**
   * Возвращает из хранилища данные для осуществления запросов к API
   * @method getRequestData
   * @return {number | string} {userId: number, accId: string, accessToken: string}
   */
  getRequestData() {
    const { token } = this.getToken();
    const userId = Number(this.getUserId().userId);
    const { accId, sendOutAccount } = this.getSelectedFilialData();

    return { userId: userId || 0, accId: accId || '', accessToken: token || '', sendOutAccount };
  }

  /**
   * Удаляет из хранилища ключ по переданному названию
   * @method removeKeyFromDB
   * @param keyName
   * @return {void}
   */
  removeKeyFromDB(keyName: string) {
    this.DB.removeItem(keyName);
  }

  /**
   * Очищает данные в базе
   * @method clearDB
   */
  clearDB() {
    this.DB.clear();
  }
}

export const storageDb = StorageApi.getInstance(localStorage);
