import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { COMMON_TOAST } from '@const/common';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { dateSort, simpleSort, utcDateSort } from '@helpers/index';
import { IInitialState, initialState } from '@redux/templateStats/initialState';
import { mapTemplateToTableData } from '@redux/templateStats/templateStatsMappers';
import { TEMPLATE_DETAIL_TABS, TEMPLATE_DETAIL_TIME_TABS } from '@const/templates';
import { SEND_OUT_DETAIL_STATISTICS_KEY_NAMES, TRejectResponseData } from '@models/index';
import {
  REJECT_RESPONSE_KEY,
  REJECT_RESPONSE_2_KEY,
  TTemplateDetailStatsData,
  TTemplateDetailStatsItemData,
} from '@api/types';
import {
  getTemplateStatistic,
  getTemplatesStatisticsData,
  getTemplateDetailStatsThunk,
  getTemplateStatisticByTimeThunk,
  sendTemplateDetailStatsToTGlThunk,
} from '@redux/templateStats/templateStatsThunks';

import {
  TTemplateStatisticData,
  TTemplatesStatisticsData,
  TTemplateStatisticTableData,
  TTemplateDetailFilterGroupBy,
  TTemplateStatisticDataByTime,
} from './models';

export const templatesStatisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    clearNumberOfRequestSP(state: IInitialState) {
      state.retries = 0;
    },
    clearTemplateStatisticsState(state: IInitialState) {
      state.template = initialState.template;
      state.isTemplateSuccess = initialState.isTemplateSuccess;
      state.isTemplateError = initialState.isTemplateError;
    },
    sortTemplateTableData(
      state: IInitialState,
      action: PayloadAction<{ columnName: string; order: string }>,
    ) {
      const { columnName, order } = action.payload;

      const key = columnName as unknown as keyof TTemplateStatisticTableData;

      if (columnName === 'date') {
        state.templateStatTableData = dateSort<TTemplateStatisticTableData>(
          state.templateStatTableData,
          key,
          order,
        );
      } else {
        state.templateStatTableData = simpleSort<TTemplateStatisticTableData>(
          state.templateStatTableData,
          key,
          order,
        );
      }
    },
    setTemplateDetailActiveTab(state: IInitialState, action: PayloadAction<TEMPLATE_DETAIL_TABS>) {
      state.templateDetailActiveTab = action.payload;
    },
    setTemplateDetailActiveTimeTab(
      state: IInitialState,
      action: PayloadAction<TEMPLATE_DETAIL_TIME_TABS>,
    ) {
      state.templateDetailActiveTimeTab = action.payload;
    },
    setTemplateDetailFilter(
      state: IInitialState,
      action: PayloadAction<
        Partial<{
          value: string;
          dateFrom: string;
          dateTo: string;
          groupBy: TTemplateDetailFilterGroupBy;
          filteredDetailTableDropdown: string[];
        }>
      >,
    ) {
      state.templateDetailFilter = {
        ...state.templateDetailFilter,
        ...action.payload,
      };
    },
    setTemplateDetailSort(
      state: IInitialState,
      action: PayloadAction<{ columnName: string; order: string }>,
    ) {
      const { columnName, order } = action.payload;

      const key = columnName as unknown as keyof TTemplateDetailStatsItemData;

      if (
        columnName === SEND_OUT_DETAIL_STATISTICS_KEY_NAMES.REPLY_TIME ||
        columnName === SEND_OUT_DETAIL_STATISTICS_KEY_NAMES.RECORD_TIME ||
        columnName === SEND_OUT_DETAIL_STATISTICS_KEY_NAMES.SEND_TIME
      ) {
        state.templateDetailStatsData = utcDateSort<TTemplateDetailStatsItemData>(
          state.templateDetailStatsData,
          key,
          order,
        );
      } else {
        state.templateDetailStatsData = simpleSort<TTemplateDetailStatsItemData>(
          state.templateDetailStatsData,
          key,
          order,
        );
      }
    },
    clearErrorMessage(state: IInitialState) {
      state.errorMessage = '';
    },
    clearSuccessMessage(state: IInitialState) {
      state.successMessage = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTemplatesStatisticsData.pending, (state: IInitialState) => {
        state.isFetching = true;
      })
      .addCase(
        getTemplatesStatisticsData.fulfilled,
        (state: IInitialState, action: PayloadAction<TTemplatesStatisticsData[] | void>) => {
          state.isFetching = false;
          state.isSuccess = true;
          if (action.payload) {
            state.templates = action.payload;
          }
        },
      )
      .addCase(getTemplatesStatisticsData.rejected, state => {
        state.isFetching = false;
        state.isError = true;
      });
    builder.addCase(getTemplateStatistic.pending, (state: IInitialState) => {
      state.isTemplateFetching = true;
      state.template = initialState.template;
    });
    builder.addCase(
      getTemplateStatistic.fulfilled,
      (state: IInitialState, action: PayloadAction<TTemplateStatisticData | void>) => {
        state.isTemplateFetching = false;
        state.isTemplateSuccess = true;
        if (action.payload) {
          const data = action.payload.data.sort((a, b) => a.version - b.version);
          state.template = {
            ...action.payload,
            data,
          };

          state.templateStatTableData = mapTemplateToTableData({
            ...action.payload,
            data,
          });
        }
      },
    );
    builder.addCase(getTemplateStatistic.rejected, state => {
      state.isTemplateFetching = false;
      state.isTemplateError = true;
    });
    builder.addCase(getTemplateStatisticByTimeThunk.pending, (state: IInitialState) => {
      state.isTemplateFetching = true;
      state.templateByTime = initialState.templateByTime;
    });
    builder.addCase(
      getTemplateStatisticByTimeThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<TTemplateStatisticDataByTime | void>) => {
        state.isTemplateFetching = false;
        state.isTemplateSuccess = true;
        if (action.payload) {
          state.templateByTime = action.payload;
        }
      },
    );
    builder.addCase(getTemplateStatisticByTimeThunk.rejected, state => {
      state.isTemplateFetching = false;
      state.isTemplateError = true;
    });
    builder.addCase(getTemplateDetailStatsThunk.pending, (state: IInitialState) => {
      state.isTemplateFetching = true;
    });
    builder.addCase(
      getTemplateDetailStatsThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<TTemplateDetailStatsData | void>) => {
        state.isTemplateFetching = false;
        state.isTemplateSuccess = true;
        if (action.payload) {
          state.templateDetailStatsData = action.payload;
        }
      },
    );
    builder.addCase(getTemplateDetailStatsThunk.rejected, (state: IInitialState) => {
      state.isTemplateFetching = false;
      state.isTemplateError = true;
    });
    builder.addCase(sendTemplateDetailStatsToTGlThunk.fulfilled, (state: IInitialState) => {
      state.successMessage = i18n.t(
        'TEMPLATE_PAGE_TEXT.statistics.table.templatesDetailStatsTable.sendSuccessMessage',
      );
    });
    builder.addCase(
      sendTemplateDetailStatsToTGlThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
          }
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
            }
          }
          if (REJECT_RESPONSE_KEY.MESSAGE in action.payload) {
            state.isError = true;
          }
        } else {
          state.isError = true;
        }
        state.errorMessage = i18n.t(COMMON_TOAST.COMMON_ERROR_MESSAGE);
      },
    );
  },
});

export const templatesStatisticsReducer = templatesStatisticsSlice.reducer;
export const {
  clearErrorMessage,
  clearSuccessMessage,
  setTemplateDetailSort,
  sortTemplateTableData,
  clearNumberOfRequestSP,
  setTemplateDetailFilter,
  setTemplateDetailActiveTab,
  clearTemplateStatisticsState,
  setTemplateDetailActiveTimeTab,
} = templatesStatisticsSlice.actions;
