import { TAutoConnectionData } from '@redux/autoConnect/models';
import { AGE_OF_WHATSAPP_ACCOUNT_KEYS } from '@const/autoConnect';

export const autoConnectionEmptyData: TAutoConnectionData = {
  stepId: 1,
  promoCode: '',
  promoCodeData: {
    bonusDays: 0,
    discount: 0,
  },
  phoneNumberForSend: '',
  salonName: '',
  salonType: '',
  salonEmoji: '',
  emojiList: [],
  linksOnSalon: [],
  salonAddress: '',
  imageSrc: '',
  botLink: '',
  ageOfWhatsappAccount: AGE_OF_WHATSAPP_ACCOUNT_KEYS.NOT_SELECTED,
  ownerPhoneNumber: '',
  salonPhoneNumber: '',
};

export const autoConnectionMockData: TAutoConnectionData = {
  stepId: 9,
  promoCode: '',
  promoCodeData: {
    bonusDays: 0,
    discount: 0,
  },
  phoneNumberForSend: '79630840601',
  salonName: 'test',
  salonType: '',
  salonEmoji: '❤',
  emojiList: ['❤', '💘'],
  linksOnSalon: ['https://www.ya.ru'],
  salonAddress: 'test address',
  imageSrc: '',
  botLink: 'https://www.ya.ru',
  ageOfWhatsappAccount: AGE_OF_WHATSAPP_ACCOUNT_KEYS.NOT_SELECTED,
  ownerPhoneNumber: '79630840600',
  salonPhoneNumber: '79630840600',
};
