import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { getAppData } from '@redux/app/selectors';
import { TKeyData } from '@redux/template/models';
import { EmojiClickData } from 'emoji-picker-react';
import { DivWithLabel } from '@components/divWithLabel';
import { getSelectionOffset } from '@helpers/selection';
import { PLACEHOLDER_VARIANTS } from '@const/templates';
import { WhenSendReviews } from '@blocks/whenSendReviews';
import { emojiPickerToggle } from '@redux/emoji/emojiSlice';
import { useAppDispatch, useAppSelector } from '@store/store';
import { ReviewsStepsWrapper } from '@blocks/reviewStepsWrapper';
import { ReviewSendCondition } from '@components/reviewSendCondition';
import { TemplateAdditionalInfo } from '@blocks/templateAdditionalInfo';
import { parseDivValue, addStringEndInPreviewReview } from '@helpers/index';
import { REVIEW_STEP_ONE_KEY_NAMES, TReviewsStep1Data } from '@redux/reviews/models';
import { TAppLanguage, TSimpleStringObj, TSimpleStringObjLevel2 } from '@models/index';
import {
  AFTER_END,
  WHEN_SEND_VALUE_REVIEWS,
  ALL_DAY_RECS_NOTIFICATION_VALUE,
} from '@const/reviews';
import {
  onChange,
  wrapTextRV,
  setTextareaValueRV,
  addKeyInTextareaRV,
  setAbandonedTextareaDataRV,
} from '@redux/reviews/reviewsSlice';
import {
  TClickHandler,
  TOnChangeHandler,
  TDivOnClickHandler,
  TDivOnKeyUpHandler,
  TDivOnInputHandler,
  TSetEmojiInTextarea,
  TShowPreviewHandler,
  TSetViewEmojiPicker,
  TOnEmojiViewChangeReview,
} from '@shared/types';

interface IReviewsStep1Props {
  /**
   * ID шага
   * @param {number}
   */
  stepId: number;
  /**
   * ID цепочки
   * @param {number}
   */
  chainId: number;
  /**
   * Параметр используется для добавления нового шарика в блок staff
   * @param {string}
   */
  staffNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок recordTag
   * @param {string}
   */
  recordTagNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок services
   * @param {string}
   */
  servicesNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок blacklist
   * @param {string}
   */
  blacklistNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок categories
   * @param {string}
   */
  categoriesNewValue: string;
  /**
   * Флаг состояния запроса
   * @param {boolean}
   */
  isFetchingSuccess: boolean;
  /**
   * ID выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Имя textarea для добавления ключей или эмодзи
   * @param {string}
   */
  blurFromTextareaName: string;
  /**
   * Параметр используется для добавления нового шарика в блок clientCategory
   * @param {string}
   */
  clientCategoryNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок channelsPhoneList
   * @param {string}
   */
  channelsPhoneListNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок tgAlerts
   * @param {string}
   */
  tgAlertsNewValue: string;
  /**
   * Данные для отображения выпадающих списков в dropdown
   * @param {{ [key: string]: TSimpleStringObj }}
   */
  categoriesDataList: { [key: string]: TSimpleStringObj };
  /**
   * Данные первого шага цепочки
   * @param {TReviewsStep1Data}
   */
  data: TReviewsStep1Data;
  /**
   * Callback для предпросмотра текста сообщения
   * @param {string}
   */
  showPreviewHandler: TShowPreviewHandler;
  /**
   * Словарь ключей для рендеренга HTML
   * @param {TSimpleStringObj}
   */
  specialKeyDict: TSimpleStringObj;
  /**
   * ref на contenteditable div для передачи в него новых данных
   * @param {React.MutableRefObject<(EventTarget & HTMLDivElement) | null>}
   */
  div: React.MutableRefObject<(EventTarget & HTMLDivElement) | null>;
  /**
   * Данные о расположении спец ключей
   * @param {TKeyData[]}
   */
  textareaKeysData: TKeyData[];
  /**
   * Объект с данными для формирования опций в выпадающем списке
   * @param {Record<TAppLanguage, TSimpleStringObj>}
   */
  whenSendSelectOption: Record<TAppLanguage, TSimpleStringObj>;
  /**
   * Объект с данными для формирования опций в выпадающем списке
   * @param {Record<TAppLanguage, TSimpleStringObjLevel2>}
   */
  whenSend2SelectOption: Record<TAppLanguage, TSimpleStringObjLevel2>;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const ReviewsStep1 = memo(
  ({
    div,
    data,
    stepId,
    chainId,
    staffNewValue,
    specialKeyDict,
    servicesNewValue,
    tgAlertsNewValue,
    textareaKeysData,
    recordTagNewValue,
    blacklistNewValue,
    isFetchingSuccess,
    categoriesDataList,
    categoriesNewValue,
    showPreviewHandler,
    selectedFilialAccId,
    blurFromTextareaName,
    whenSendSelectOption,
    whenSend2SelectOption,
    clientCategoryNewValue,
    channelsPhoneListNewValue,
    className = '',
  }: IReviewsStep1Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { appLanguage } = useAppSelector(getAppData);

    const onEmojiViewChange: TOnEmojiViewChangeReview = useCallback(
      ({ textAreaName, textAreaValue, stepIdEmoji }) =>
        () => {
          dispatch(
            setAbandonedTextareaDataRV({
              name: textAreaName,
              stepId: stepIdEmoji,
              selectionStart: textAreaValue.length,
              selectionEnd: textAreaValue.length,
            }),
          );
        },
      [dispatch],
    );

    // Обрабатывает событие change на чекбоксе отключения/включения шага, собирает данные из события
    // создает объект с данными
    const selectOnChangeHandler: TOnChangeHandler = event => {
      const { name, checked } = event.currentTarget;
      dispatch(onChange({ stepId, stepData: { ...data, [name]: checked } }));
    };

    const divOnInputHandler: TDivOnInputHandler = useCallback(
      ({ name, value, selectionEnd, selectionStart, newTextareaKeysData }) => {
        dispatch(
          setTextareaValueRV({
            stepId,
            name: name || '',
            value,
            selectionEnd,
            selectionStart,
            newTextareaKeysData,
          }),
        );
      },
      [stepId, dispatch],
    );

    const divOnClickHandler: TDivOnClickHandler = useCallback(
      event => {
        const {
          dataset: { name },
        } = event.currentTarget;

        const [start, end] = getSelectionOffset(event.currentTarget);

        div.current = event.currentTarget;

        dispatch(
          setAbandonedTextareaDataRV({
            stepId,
            name: name || '',
            selectionStart: start,
            selectionEnd: end,
          }),
        );
      },
      [div, dispatch, stepId],
    );

    const setCursorPositionHandler: TDivOnKeyUpHandler = useCallback(
      event => {
        const {
          dataset: { name },
        } = event.currentTarget;

        const [start, end] = getSelectionOffset(event.currentTarget);

        div.current = event.currentTarget;

        dispatch(
          setAbandonedTextareaDataRV({
            name: name || '',
            stepId,
            selectionStart: start,
            selectionEnd: end,
          }),
        );
      },
      [div, stepId, dispatch],
    );

    // Callback передается в компонент <TemplateAdditionalInfo />
    // добавляет спец ключ в текс 'выбранной' textarea
    const setKeyInTextarea = useCallback(
      (key: string) => () => {
        if (blurFromTextareaName && div.current) {
          dispatch(addKeyInTextareaRV({ innerText: key, callback: parseDivValue(div.current) }));
          div.current.focus();
        }
      },
      [blurFromTextareaName, div, dispatch],
    );

    const setEmojiInTextarea: TSetEmojiInTextarea = useCallback(
      (emoji: EmojiClickData) => {
        if (blurFromTextareaName && div.current) {
          dispatch(
            addKeyInTextareaRV({ innerText: emoji.emoji, callback: parseDivValue(div.current) }),
          );
          div.current.focus();
        }
      },
      [blurFromTextareaName, div, dispatch],
    );

    // Оборачивает выделенный текст в выбранные спецсимволы
    const wrapSelectedTextInTextarea: TClickHandler = useCallback(
      event => {
        if (blurFromTextareaName && div.current) {
          // получаем выбранный спецсимвол вместе с примером текстом в функцию для формирования
          // новой строки передается первый символ
          const { innerText } = event.currentTarget;
          dispatch(wrapTextRV({ innerText, callback: parseDivValue(div.current) }));
          div.current.focus();
        }
      },
      [blurFromTextareaName, div, dispatch],
    );

    const setWhenSendHandler = useCallback(
      (value: string) => () => {
        if (value === WHEN_SEND_VALUE_REVIEWS.ALL_DAY_RECS_NOTIFICATION) {
          dispatch(
            onChange({
              stepId,
              stepData: {
                ...data,
                whenSend: ALL_DAY_RECS_NOTIFICATION_VALUE.TODAY,
                whenTime: '10:00',
                timeType: value,
              },
            }),
          );
        } else {
          dispatch(
            onChange({
              stepId,
              stepData: {
                ...data,
                whenSend: AFTER_END.IN_FIFTEEN_MINUTES,
                timeType: value,
                whenTime: '',
              },
            }),
          );
        }
      },
      [stepId, data, dispatch],
    );

    const setWhenSend2Handler = useCallback(
      (value: string) => () => {
        dispatch(onChange({ stepId, stepData: { ...data, whenSend: value } }));
      },
      [stepId, data, dispatch],
    );

    const setWhenTimeHandler = useCallback(
      (value: string) => {
        dispatch(onChange({ stepId, stepData: { ...data, whenTime: value } }));
      },
      [stepId, data, dispatch],
    );

    const setViewEmojiPicker: TSetViewEmojiPicker = ({ name, divRef, isOpenEmoji }) => {
      if (divRef) {
        div.current = divRef;
      }

      dispatch(emojiPickerToggle({ isOpen: isOpenEmoji, name }));
    };

    const memoizedShowFirstStepPreviewButton = useMemo(
      () => (
        <Button
          dense
          type='default'
          view='outlined'
          color='default'
          rightIcon='application'
          text={t('REVIEW_PAGE_TEXT.REVIEW_BUTTON_TEXT.preview')}
          onClick={showPreviewHandler(addStringEndInPreviewReview(data.whatsapp))}
          className='mb-8 lg:mb-10 w-[11rem]'
        />
      ),
      [t, showPreviewHandler, data],
    );

    return (
      <ReviewsStepsWrapper
        stepId={stepId}
        chainId={chainId}
        status={data.status}
        className={className}
        isDeleteButton={false}
        onChangeHandler={selectOnChangeHandler}
        toggleSwitchName={REVIEW_STEP_ONE_KEY_NAMES.STATUS}>
        <WhenSendReviews
          isFirstTooltip
          time={data.whenTime}
          whenSend={data.timeType}
          whenSend2={data.whenSend}
          setWhenSend={setWhenSendHandler}
          setWhenTime={setWhenTimeHandler}
          setWhenSend2={setWhenSend2Handler}
          whenSendSelectOption={whenSendSelectOption[appLanguage]}
          whenSend2SelectOption={whenSend2SelectOption[appLanguage]}
          className='mb-8'
        />
        <p className='font-bold text-black text-h3Mobile leading-8 mb-4'>
          {t('REVIEW_PAGE_TEXT.content')}
        </p>
        <DivWithLabel
          isRequired
          isEmoji
          setEmojiInTextarea={setEmojiInTextarea}
          showEmojiPicker={onEmojiViewChange({
            textAreaName: REVIEW_STEP_ONE_KEY_NAMES.WHATSAPP,
            stepIdEmoji: stepId,
            textAreaValue: data.whatsapp,
          })}
          setViewEmojiPicker={setViewEmojiPicker}
          htmlFor={REVIEW_STEP_ONE_KEY_NAMES.WHATSAPP}
          value={data.whatsapp}
          isError={!data.whatsapp}
          specialKeyDict={specialKeyDict}
          textareaKeysData={textareaKeysData}
          divOnInputHandler={divOnInputHandler}
          divOnClickHandler={divOnClickHandler}
          setCursorPosition={setCursorPositionHandler}
          placeholder={PLACEHOLDER_VARIANTS[appLanguage].whatsapp}
          className='mb-2'
        />
        {memoizedShowFirstStepPreviewButton}
        <TemplateAdditionalInfo
          forMobile
          specialKeyDict={specialKeyDict}
          setKeyInTextarea={setKeyInTextarea}
          wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
          className='flex xl:hidden flex-col mb-10'
        />
        <ReviewSendCondition
          stepId={stepId}
          staffNewValue={staffNewValue}
          tgAlertsNewValue={tgAlertsNewValue}
          servicesNewValue={servicesNewValue}
          blacklistNewValue={blacklistNewValue}
          isFetchingSuccess={isFetchingSuccess}
          recordTagNewValue={recordTagNewValue}
          categoriesDataList={categoriesDataList}
          categoriesNewValue={categoriesNewValue}
          selectedFilialAccId={selectedFilialAccId}
          clientCategoryNewValue={clientCategoryNewValue}
          channelsPhoneListNewValue={channelsPhoneListNewValue}
          data={data}
        />
      </ReviewsStepsWrapper>
    );
  },
);

ReviewsStep1.displayName = 'ReviewsStep1';
