import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Menu } from '@uikit/Menu';
import { Dropdown } from '@uikit/Dropdown';
import { FormLabel } from '@blocks/formLabel';
import { SendTime } from '@components/sendTime';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { WHEN_SEND_VALUE_REVIEWS } from '@const/reviews';
import { REVIEW_STEP_ONE_KEY_NAMES } from '@redux/reviews/models';
import { getSortedWhenSend2ReviewsOptionArray } from '@helpers/index';
import { TSimpleStringObj, TSimpleStringObjLevel2 } from '@models/index';

interface IWhenSendReviewsProps {
  /**
   * Значение timePicker whenTime в формате HH:MM
   * @param {string}
   */
  time: string;
  /**
   * Callback для изменения параметра whenSend
   * @param {(name: string) => void;}
   */
  whenSend: string;
  /**
   * Callback для изменения параметра whenSend2
   * @param {(name: string) => void;}
   */
  whenSend2: string;
  /**
   * Callback для изменения параметра time
   * @param {(name: string) => void;}
   */
  setWhenTime: (name: string) => void;
  /**
   * Callback для изменения параметра whenSend
   * @param {(name: string) => void;}
   */
  setWhenSend: (name: string) => () => void;
  /**
   * Callback для изменения параметра whenSend2
   * @param {(name: string) => void;}
   */
  setWhenSend2: (name: string) => () => void;
  /**
   * Объект с данными для формирования опций в выпадающем списке
   * @param {TSimpleStringObjLevel2}
   */
  whenSendSelectOption: TSimpleStringObj;
  /**
   * Объект с данными для формирования опций в выпадающем списке
   * @param {TSimpleStringObjLevel2}
   */
  whenSend2SelectOption: TSimpleStringObjLevel2;
  /**
   * Опциональный параметр, если true при первом визите на страницу будет показан обучающий попап
   * @param {boolean}
   */
  isFirstTooltip?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const WhenSendReviews = memo(
  ({
    time,
    whenSend,
    whenSend2,
    setWhenTime,
    setWhenSend,
    setWhenSend2,
    isFirstTooltip,
    whenSendSelectOption,
    whenSend2SelectOption,
    className = '',
  }: IWhenSendReviewsProps) => {
    const { t } = useTranslation();

    // получаем массив для опций в селекте whenSend
    const whenSendOptionList = useMemo(
      () =>
        Object.entries(whenSendSelectOption).map(item => (
          <MenuItem
            key={item[0]}
            text={<span>{item[1]}</span>}
            onClick={setWhenSend(item[0])}
            className={whenSend === item[0] ? '!bg-ivory' : ''}
          />
        )),
      [whenSend, setWhenSend, whenSendSelectOption],
    );

    // получаем массив для опций в селекте whenSend2
    const whenSend2OptionArray = getSortedWhenSend2ReviewsOptionArray(
      whenSend,
      whenSend2SelectOption[whenSend],
    );

    // формируем список опций для селекта whenSend2
    const whenSend2OptionList = useMemo(
      () =>
        whenSend2OptionArray.map(item => (
          <MenuItem
            key={item[0]}
            text={<span>{item[1]}</span>}
            onClick={setWhenSend2(item[0])}
            className={whenSend2 === item[0] ? '!bg-ivory' : ''}
          />
        )),
      [whenSend2, setWhenSend2, whenSend2OptionArray],
    );

    // Получаем текст для dropdown
    const selectedWhenSendOptionText = useMemo(
      () => whenSendSelectOption[whenSend] || t('REVIEW_PAGE_TEXT.select'),
      [t, whenSend, whenSendSelectOption],
    );

    // Получаем текст для dropdown
    const selectedWhenSend2OptionText = useMemo(
      () => whenSend2SelectOption[whenSend][whenSend2] || t('REVIEW_PAGE_TEXT.select'),
      [t, whenSend, whenSend2, whenSend2SelectOption],
    );

    return (
      <div className={`flex flex-col ${className}`}>
        <FormLabel
          showInfo
          isRequired
          labelTextVariant='whenSend'
          isFirstTooltip={isFirstTooltip}
          fontSize='text-h3Mobile leading-8'
          htmlFor={REVIEW_STEP_ONE_KEY_NAMES.WHEN_SEND}
          className='mb-0.5'
        />
        <div className='flex flex-wrap flex-col sm:flex-row mt-3'>
          <div className='mr-2 mb-2'>
            <Dropdown
              color='default'
              view='outlined'
              position='bottom-left'
              text={<span>{selectedWhenSendOptionText}</span>}
              content={
                <Menu
                  view='raised'
                  className={whenSend2OptionList.length === 1 ? `one_item_ul` : ''}>
                  {whenSendOptionList}
                </Menu>
              }
              className='when_send_dropdown min-w-[15rem] w-full'
            />
          </div>
          <div className='pr-[5px] mb-2'>
            <Dropdown
              color='default'
              view='outlined'
              position='bottom-left'
              text={<span>{selectedWhenSend2OptionText}</span>}
              content={
                <Menu
                  view='raised'
                  className={
                    Object.entries(whenSend2OptionArray).length === 1 ? `one_item_ul` : ''
                  }>
                  {whenSend2OptionList}
                </Menu>
              }
              className='when_send_dropdown min-w-[15rem] w-full'
            />
          </div>
          {whenSend === WHEN_SEND_VALUE_REVIEWS.ALL_DAY_RECS_NOTIFICATION ? (
            <div className='ml-2 mb-2'>
              <SendTime
                endMinutes={59}
                startMinutes={0}
                sendingTime={time}
                minIncreaseStep={5}
                setSendingTime={setWhenTime}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);

WhenSendReviews.displayName = 'WhenSendReviews';
