import React, { memo } from 'react';
//
import { Loader } from '@blocks/loader';
import { useAppSelector } from '@store/store';
import { getPayHistorySalonTablePageData } from '@redux/payHistorySalonTable/selectors';
import { PartnersPayHistorySalonTable } from '@components/partnersPayHistorySalonTable';
import { getPayHistoryConsultantTablePageData } from '@redux/payHistoryConsultantTable/selectors';
import { PartnersPayHistoryConsultantTable } from '@components/partnersPayHistoryConsultantTable';

interface IPayHistoryTemplateProps {
  /**
   * Флаг показывает что аккаунт является салоном
   * @param {boolean}
   */
  isSalon: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const PayHistoryTemplate = memo(({ isSalon, className = '' }: IPayHistoryTemplateProps) => {
  const { data: partnersPayHistory, isLoading: partnersPayHistoryIsLoading } = useAppSelector(
    getPayHistorySalonTablePageData,
  );

  const { data: consultantPayHistory, isLoading: consultantPayHistoryIsLoading } = useAppSelector(
    getPayHistoryConsultantTablePageData,
  );

  return (
    <div className={className}>
      {isSalon ? (
        <div>
          {partnersPayHistoryIsLoading ? (
            <Loader className='h-[50vh]' />
          ) : (
            <PartnersPayHistorySalonTable
              data={partnersPayHistory}
              className='overflow-x-auto mt-10 max-w-[47rem] w-full'
            />
          )}
        </div>
      ) : (
        <div>
          {consultantPayHistoryIsLoading ? (
            <Loader className='h-[50vh]' />
          ) : (
            <PartnersPayHistoryConsultantTable
              data={consultantPayHistory}
              className='overflow-x-auto mt-10 max-w-[47rem] w-full'
            />
          )}
        </div>
      )}
    </div>
  );
});

PayHistoryTemplate.displayName = 'PayHistoryTemplate';
