import { storageDb } from '@api/storageApi';
import { templateApi } from '@api/templateApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRejectResponseData } from '@models/index';
import { TTemplateInfo } from '@redux/templateList/types';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { getMappedTemplateListPageResponse } from '@redux/templateList/templateListMappers';
import { REJECT_RESPONSE_KEY, ITemplateOperationData, TEMPLATE_RESPONSE_KEYS } from '@api/types';

/**
 * Thunk-экшен для получения списка шаблонов.
 *
 * @param {Object} data - Объект с параметрами запроса.
 * @param {boolean} data.em - Параметр "em".
 * @param {boolean} data.tm - Параметр "tm".
 * @returns {TTemplateInfo[]|void} - Список шаблонов или void в случае ошибки.
 * @throws {TRejectResponse|TRejectResponse2} - Объект с данными об ошибке.
 */
const getTemplateList = createAsyncThunk<
  TTemplateInfo[] | void,
  { em: boolean; tm: boolean },
  { rejectValue: TRejectResponseData }
>('templateList/getTemplateList', (data, { dispatch, rejectWithValue }) => {
  const requestData = storageDb.getRequestData();
  return templateApi
    .getTemplateListData(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (TEMPLATE_RESPONSE_KEYS.DATA in response.data) {
        return getMappedTemplateListPageResponse(response.data, requestData.accId);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getTemplateList(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для переключения шаблона.
 *
 * @param {ITemplateOperationData} data - Данные операции с шаблоном.
 * @param {string} data.id - Идентификатор шаблона.
 * @param {boolean} data.em - Параметр "em".
 * @param {boolean} data.tm - Параметр "tm".
 * @param {boolean} data.state - Состояние шаблона.
 * @returns {string} - Идентификатор переключенного шаблона.
 * @throws {TRejectResponse|TRejectResponse2} - Объект с данными об ошибке.
 */
const switchTemplate = createAsyncThunk<
  string,
  ITemplateOperationData,
  { rejectValue: TRejectResponseData }
>('templateList/switchTemplate', (data, { dispatch, rejectWithValue }) => {
  return templateApi
    .switchTemplateById(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      return data.id;
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(switchTemplate(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для удаления шаблона.
 *
 * @param {ITemplateOperationData} data - Данные операции с шаблоном.
 * @param {string} data.id - Идентификатор шаблона.
 * @param {boolean} data.em - Параметр "em".
 * @param {boolean} data.tm - Параметр "tm".
 * @param {boolean} data.state - Состояние шаблона.
 * @returns {string} - Идентификатор удаленного шаблона.
 * @throws {TRejectResponse|TRejectResponse2} - Объект с данными об ошибке.
 */
const deleteTemplate = createAsyncThunk<
  string,
  ITemplateOperationData,
  { rejectValue: TRejectResponseData }
>('templateList/deleteTemplate', (data, { dispatch, rejectWithValue }) => {
  return templateApi
    .deleteTemplateById(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      return data.id;
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(deleteTemplate(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для создания копии шаблона.
 *
 * @param {string} id - Id шаблона.
 * @returns {string} - Id скопированного шаблона.
 * @throws {TRejectResponse|TRejectResponse2} - Объект с данными об ошибке.
 */
const duplicateTemplate = createAsyncThunk<string, string, { rejectValue: TRejectResponseData }>(
  'templateList/duplicateTemplate',
  (id, { dispatch, rejectWithValue }) => {
    return templateApi
      .duplicateTemplateById(id)
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data));
        }
        return id;
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(duplicateTemplate(id)), 200);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

export { getTemplateList, switchTemplate, deleteTemplate, duplicateTemplate };
