import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Visits } from '@blocks/visits';
import { FormInput } from '@blocks/formInput';
import { useAppDispatch } from '@store/store';
import { REVIEW_RECORD_STATUS } from '@const/reviews';
import { VisitQuantity } from '@blocks/visitQuantity';
import { getNewRate, validatePhoneNumber } from '@helpers/index';
import { ReviewsRecordStatus } from '@blocks/reviewsRecordStatus';
import { EVENT_KEY_NAMES, THelpKeys, TSimpleStringObj } from '@models/index';
import { REVIEW_STEP_ONE_KEY_NAMES, TReviewsStep1Data } from '@redux/reviews/models';
import { ReviewInputWithDropdownList } from '@components/reviewInputWithDropdownList';
import {
  onChange,
  addBalloonRV,
  deleteBalloonRV,
  setNewBalloonValueRV,
  clearNewBalloonValueRV,
  showVisitsWarningMessage,
} from '@redux/reviews/reviewsSlice';
import {
  TOnChangeHandler,
  TAddBalloonOnBlur,
  TBalloonsKeyNames,
  TDeleteBalloonSetTP,
  TAddBalloonOnBlurHandler,
  TVisitQuantityOnChangeHandler,
} from '@shared/types';

type TReviewSendConditionProps = {
  /**
   * Номер шага цепочки
   * @param {number}
   */
  stepId: number;
  /**
   * Флаг состояния запроса
   * @param {boolean}
   */
  isFetchingSuccess: boolean;
  /**
   * ID выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Параметр используется для добавления нового шарика в блок staff
   * @param {string}
   */
  staffNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок recordTag
   * @param {string}
   */
  recordTagNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок services
   * @param {string}
   */
  servicesNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок categories
   * @param {string}
   */
  categoriesNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок clientCategory
   * @param {string}
   */
  clientCategoryNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок channelsPhoneList
   * @param {string}
   */
  channelsPhoneListNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок tgAlerts
   * @param {string}
   */
  tgAlertsNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок blacklist
   * @param {string}
   */
  blacklistNewValue: string;
  /**
   * Данные для отображения выпадающих списков в dropdown
   * @param {{ [key: string]: TSimpleStringObj }}
   */
  categoriesDataList: { [key: string]: TSimpleStringObj };
  /**
   * Данные первого шага цепочки
   * @param {TReviewsStep1Data}
   */
  data: TReviewsStep1Data;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const ReviewSendCondition = memo(
  ({
    stepId,
    staffNewValue,
    tgAlertsNewValue,
    servicesNewValue,
    blacklistNewValue,
    recordTagNewValue,
    isFetchingSuccess,
    categoriesDataList,
    categoriesNewValue,
    selectedFilialAccId,
    clientCategoryNewValue,
    channelsPhoneListNewValue,
    data,
    className = '',
  }: TReviewSendConditionProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    // Обрабатывает событие change в компоненте <ReviewsRecordStatus />, получает массив статусов вызовом getNewRate()
    const onChangeSelectHandler: TOnChangeHandler = event => {
      const newStatuses = getNewRate(event, data.statuses);
      dispatch(onChange({ stepId, stepData: { ...data, statuses: newStatuses } }));
    };

    // Обрабатывает изменение newBalloonValue
    const newBalloonValueHandler: TOnChangeHandler = useCallback(
      event => {
        const { name, value } = event.currentTarget;
        dispatch(setNewBalloonValueRV({ value, name }));
      },
      [dispatch],
    );

    // Очищает поле ввода newBalloonValue
    const clearNewBalloonValueHandler = useCallback(
      (name: string) => {
        dispatch(clearNewBalloonValueRV({ name }));
      },
      [dispatch],
    );

    // Удаляет "шарик" по его значению
    const deleteBalloon: TDeleteBalloonSetTP = useCallback(
      (value, keyName) => () => {
        const tKeyName = keyName as TBalloonsKeyNames;
        dispatch(deleteBalloonRV({ keyName: tKeyName, value }));
      },
      [dispatch],
    );

    const addBalloonHandler = useCallback(
      ({ name, value }: { name: string; value: string }) => {
        if (value) {
          const keyName = name as TBalloonsKeyNames;

          if (
            name === REVIEW_STEP_ONE_KEY_NAMES.BLACKLIST ||
            (name === REVIEW_STEP_ONE_KEY_NAMES.CHANNEL_PHONE_LIST && value.length < 13)
          ) {
            dispatch(addBalloonRV({ keyName, value: validatePhoneNumber(value) }));
          } else {
            dispatch(addBalloonRV({ keyName, value }));
          }
        }
      },
      [dispatch],
    );

    // Добавляет новый по событию Blur шарик и очищает инпут
    const addBalloonOnBlurHandlerEvent: TAddBalloonOnBlur = useCallback(
      event => {
        const { name, value } = event.currentTarget;
        addBalloonHandler({ name, value });
      },
      [addBalloonHandler],
    );

    // Добавляет новый по событию Blur шарик и очищает инпут
    const addBalloonOnBlurHandler: TAddBalloonOnBlurHandler = useCallback(
      (name, value) => {
        addBalloonHandler({ name, value });
      },
      [addBalloonHandler],
    );

    // Добавляет новый по событию KeyUp шарик и очищает инпут
    const addBalloonByEnterKeyEvent = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === EVENT_KEY_NAMES.ENTER) {
          addBalloonOnBlurHandlerEvent(event);
        }
      },
      [addBalloonOnBlurHandlerEvent],
    );

    // Обрабатывает событие change в компоненте <ReviewsRecordStatus />, собирает данные
    const visitQuantityOnChangeHandler: TVisitQuantityOnChangeHandler = useCallback(
      ({ newQuantityFromValue, newQuantityToValue }) => {
        if (newQuantityToValue && +newQuantityToValue < +data.visits) {
          dispatch(
            onChange({
              stepId,
              stepData: {
                ...data,
                quantityFrom: newQuantityFromValue,
                quantityTo: newQuantityToValue,
                visits: newQuantityToValue,
              },
            }),
          );
        } else {
          dispatch(
            onChange({
              stepId,
              stepData: {
                ...data,
                quantityFrom: newQuantityFromValue,
                quantityTo: newQuantityToValue,
              },
            }),
          );
        }
      },
      [stepId, data, dispatch],
    );

    // Обрабатывает изменения visits
    const visitsOnChangeHandler = useCallback(
      (visits: string) => {
        const newVisitsValue = +visits > 0 ? visits : '';

        if (data.quantityTo && newVisitsValue > data.quantityTo) {
          dispatch(showVisitsWarningMessage());
        } else {
          dispatch(
            onChange({
              stepId,
              stepData: {
                ...data,
                visits: newVisitsValue,
              },
            }),
          );
        }
      },
      [stepId, data, dispatch],
    );

    const formAlertData = [
      {
        typeOfValueIsNumber: false,
        variant: 'channels',
        value: data.channelsPhoneList,
        newValue: channelsPhoneListNewValue,
        placeholder: t('REVIEW_PAGE_TEXT.clientPhoneList'),
        inputId: REVIEW_STEP_ONE_KEY_NAMES.CHANNEL_PHONE_LIST,
        classNameText: 'mb-8',
      },
      {
        typeOfValueIsNumber: false,
        variant: 'tgAlerts',
        value: data.tgAlerts,
        newValue: tgAlertsNewValue,
        placeholder: t('REVIEW_PAGE_TEXT.tgAlerts'),
        inputId: REVIEW_STEP_ONE_KEY_NAMES.TG_ALERTS,
        classNameText: 'mb-8',
      },
      {
        typeOfValueIsNumber: true,
        value: data.blacklist,
        textSize: 'text-h3Mobile',
        newValue: blacklistNewValue,
        inputId: REVIEW_STEP_ONE_KEY_NAMES.BLACKLIST,
        placeholder: t('REVIEW_PAGE_TEXT.blackList'),
        variant: REVIEW_STEP_ONE_KEY_NAMES.BLACKLIST,
        classNameText: '',
      },
    ];

    const formAlertToRender = formAlertData.map(
      ({
        variant,
        value,
        newValue,
        inputId,
        typeOfValueIsNumber,
        placeholder,
        classNameText,
        textSize,
      }) => (
        <FormInput
          key={inputId}
          value={value}
          isShowInfoIcon
          textColor='black'
          inputId={inputId}
          newValue={newValue}
          textSize={textSize}
          placeholder={placeholder}
          deleteBalloon={deleteBalloon}
          variant={variant as THelpKeys}
          setNewValue={newBalloonValueHandler}
          typeOfValueIsNumber={typeOfValueIsNumber}
          clearNewValue={clearNewBalloonValueHandler}
          addBalloonOnBlur={addBalloonOnBlurHandlerEvent}
          addBalloonByEnterKey={addBalloonByEnterKeyEvent}
          className={classNameText}
        />
      ),
    );

    return (
      <div className={className}>
        <p className='font-medium text-black text-h3Mobile leading-8 mb-4 tracking-[0.004em]'>
          {t('REVIEW_PAGE_TEXT.sendCondition')}
        </p>
        <ReviewsRecordStatus
          clientCame={data.statuses.includes(REVIEW_RECORD_STATUS.CLIENT_CAME)}
          clientConfirmed={data.statuses.includes(REVIEW_RECORD_STATUS.CLIENT_CONFIRMED)}
          clientExpectation={data.statuses.includes(REVIEW_RECORD_STATUS.CLIENT_EXPECTATION)}
          clientDidNotCome={data.statuses.includes(REVIEW_RECORD_STATUS.CLIENT_DID_NOT_COME)}
          onChangeHandler={onChangeSelectHandler}
          className='mb-[1.375rem]'
        />
        <ReviewInputWithDropdownList
          staffNewValue={staffNewValue}
          deleteBalloon={deleteBalloon}
          servicesNewValue={servicesNewValue}
          recordTagNewValue={recordTagNewValue}
          isFetchingSuccess={isFetchingSuccess}
          categoriesNewValue={categoriesNewValue}
          categoriesDataList={categoriesDataList}
          selectedFilialAccId={selectedFilialAccId}
          addBalloonOnBlur={addBalloonOnBlurHandler}
          clientCategoryNewValue={clientCategoryNewValue}
          data={data}
          className='mb-8'
        />
        {formAlertToRender.slice(0, 2)}
        <VisitQuantity
          quantityFrom={data.quantityFrom}
          quantityTo={data.quantityTo}
          onChangeHandler={visitQuantityOnChangeHandler}
          className='mb-10'
        />
        <Visits visits={data.visits} onChangeHandler={visitsOnChangeHandler} className='mb-10' />
        {formAlertToRender.slice(2)}
      </div>
    );
  },
);

ReviewSendCondition.displayName = 'ReviewSendCondition';
