import React, { memo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
//
import { PATH_NAMES } from '@data/dict';
import { getLinkHref } from '@helpers/index';
import { TAppLanguage } from '@models/index';
import { NavComponent } from '@components/navComponent';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { InviteFriendsStatisticsCard } from '@components/inviteFriendsStatisticsCard';
import { getInviteFriendsStatisticsData } from '@redux/inviteFrendsStatistics/selectors';
import { InviteFriendsSortTypeDropdown } from '@components/inviteFriendsSortTypeDropdown';
import { INVITE_FRIENDS_PAGES_NAME_DICT, INVITE_FRIENDS_SORT_DROPDOWN } from '@const/inviteFriend';
import {
  setSortField,
  setSortDirection,
} from '@redux/inviteFrendsStatistics/inviteFriendsStatisticsSlice';
import {
  TStatisticsSortFields,
  TInviteFriendsStatisticsItemData,
} from '@redux/inviteFrendsStatistics/types';

type TInviteFriendsStatisticsProps = {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Данные в карточку
   * @param {TInviteFriendsStatisticsItemData[]}
   */
  data: TInviteFriendsStatisticsItemData[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const InviteFriendsStatistics = memo(
  ({ appLanguage, data, className = '' }: TInviteFriendsStatisticsProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();

    const { selectedFilial } = useAppSelector(getAccountsData);

    const { sortBy, sortDirection } = useAppSelector(getInviteFriendsStatisticsData);

    // Устанавливает название поля по которому осуществляется сортировка
    const setSortByHandler = (fieldName: string) => () => {
      dispatch(setSortField(fieldName as TStatisticsSortFields));
    };

    // Тогл для направления сортировки
    const sorDirectionToggle = () => {
      dispatch(setSortDirection());
    };

    // Обработчик нажатия на кнопку создать акцию
    const addAction = useCallback(() => {
      navigate(
        getLinkHref({
          accId: selectedFilial?.accId || '',
          route: PATH_NAMES.INVITE_FRIEND_NEW_TEMPLATE,
        }),
      );
    }, [selectedFilial?.accId, navigate]);

    const statisticsCards = data.map(cardData => (
      <InviteFriendsStatisticsCard
        key={cardData.templateId}
        data={cardData}
        appLanguage={appLanguage}
        className='mb-6'
      />
    ));

    return (
      <div className={className}>
        <div className='flex items-center mb-6 sm:mb-0 w-full'>
          <NavComponent
            path={pathname}
            isDisable={false}
            isEditTemplatesAccess
            addNewActionTemplate={addAction}
            selectedFilialAccId={selectedFilial?.accId || ''}
            pagesNames={INVITE_FRIENDS_PAGES_NAME_DICT[appLanguage]}
            className='w-full'
          />
        </div>
        <InviteFriendsSortTypeDropdown
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSortByHandler={setSortByHandler}
          setSortDirectionHandler={sorDirectionToggle}
          dropdownOptionDict={INVITE_FRIENDS_SORT_DROPDOWN[appLanguage]}
        />
        <div className='mt-6'>{statisticsCards}</div>
      </div>
    );
  },
);

InviteFriendsStatistics.displayName = 'InviteFriendsStatistics';
