import React, { memo, useCallback, useEffect } from 'react';
//
import isEqual from '@helpers/isEqual';
import { NoAccess } from '@components/noAccess';
import { ErrorMessage } from '@blocks/errorMessage';
import { TSettingsPostHandler } from '@shared/types';
import { AccessesTemplate } from '@templates/accesses';
import { SettingsTemplate } from '@templates/settings';
import { getSettingsData } from '@redux/settings/selectors';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { clearAccessDeniedErrorAP } from '@redux/access/accessesSlice';
import { clearAccessDeniedErrorSP } from '@redux/settings/settingsSlice';
import { getAccessData, postAccesses } from '@redux/access/accessThunks';
import { getAccessDataFetchProcessStatus } from '@redux/access/selectors';
import { getSettings, postSettings } from '@redux/settings/settingsThunks';

type TSettingsPageProps = {
  /**
   * Флаг открытия страницы настроек
   * @param {boolean}
   */
  isSettingsPage: boolean;
};

const SettingsPage = memo(({ isSettingsPage }: TSettingsPageProps) => {
  const dispatch = useAppDispatch();
  const { selectedFilial } = useAppSelector(getAccountsData);
  const {
    isError,
    isRetries,
    isPosting,
    isPostError,
    isAccessDenied,
    isSaveSettingSuccess,
    data,
    oldData,
  } = useAppSelector(getSettingsData);

  const {
    isLoading,
    isPostSuccess,
    data: accessData,
    isUniqueTgIdError,
    isEmptyInputError,
    isError: accessIsError,
    oldData: oldAccessData,
    isPosting: accessIsPosting,
    isRetries: accessIsRetries,
    isPostError: accessIsPostError,
    errorMessage: accessErrorMessage,
    successMessage: accessSuccessMessage,
    isAccessDenied: accessIsAccessDenied,
  } = useAppSelector(getAccessDataFetchProcessStatus);

  // Эффект делает запрос за данными страницы при загрузке страницы и при смене филиала
  useEffect(() => {
    if (isSettingsPage && !isRetries && selectedFilial?.accId) {
      dispatch(clearAccessDeniedErrorAP());
      dispatch(getSettings());
    }
  }, [dispatch, isRetries, isSettingsPage, selectedFilial?.accId]);

  // Эффект делает запрос за данными страницы если isRetries равен true
  useEffect(() => {
    if (isSettingsPage && isRetries) {
      setTimeout(() => dispatch(getSettings()), 200);
    }
  }, [dispatch, isSettingsPage, isRetries]);

  // При первой загрузке страницы запрашиваем данные
  useEffect(() => {
    if (!isSettingsPage && selectedFilial?.accId) {
      dispatch(clearAccessDeniedErrorSP());
      dispatch(getAccessData());
    }
  }, [dispatch, isSettingsPage, selectedFilial?.accId]);

  // Запрашиваем данные при ошибке токена
  useEffect(() => {
    if (!isSettingsPage && accessIsRetries) {
      setTimeout(() => dispatch(getAccessData()), 200);
    }
  }, [dispatch, isSettingsPage, accessIsRetries]);

  // функция для сохранения настроек
  const postSettingHandler: TSettingsPostHandler = useCallback(
    (newSettings, phonesNewValue) => {
      dispatch(postSettings({ data: newSettings, phonesDontShowInBotNewValue: phonesNewValue }));
    },
    [dispatch],
  );

  // обработчик для сохранения измененных, передаем параметры для запроса
  const postAccessesHandler = () => {
    dispatch(postAccesses(accessData));
  };

  const showErrorPage = isError || accessIsError;

  const showAccessDeniedPage = isAccessDenied || accessIsAccessDenied;

  const isSettingDataNotChanged = isEqual(data, oldData);

  const isSaveSettingsButtonDisabled = isSettingDataNotChanged || isPosting;

  const isAccessDataNotChanged = isEqual(accessData, oldAccessData);

  const isAccessSaveButtonDisable = isAccessDataNotChanged || accessIsPosting;

  return (
    <>
      {showErrorPage && !showAccessDeniedPage ? <ErrorMessage /> : null}
      {showAccessDeniedPage && !showErrorPage ? <NoAccess /> : null}
      {!showErrorPage && !showAccessDeniedPage ? (
        <>
          {isSettingsPage ? (
            <SettingsTemplate
              data={data}
              isPosting={isPosting}
              isPostError={isPostError}
              postCallback={postSettingHandler}
              isSaveSettingSuccess={isSaveSettingSuccess}
              selectedFilialAccId={selectedFilial?.accId || ''}
              isSaveButtonDisable={isSaveSettingsButtonDisabled}
              className='px-4 md:px-2 lg:px-0'
            />
          ) : null}
          {!isSettingsPage ? (
            <AccessesTemplate
              data={accessData}
              isLoading={isLoading}
              isPosting={accessIsPosting}
              isPostError={accessIsPostError}
              isPostingSuccess={isPostSuccess}
              errorMessage={accessErrorMessage}
              successMessage={accessSuccessMessage}
              isUniqueTgIdError={isUniqueTgIdError}
              isEmptyInputError={isEmptyInputError}
              postAccessesHandler={postAccessesHandler}
              isSaveButtonDisable={isAccessSaveButtonDisable}
              className='px-4 md:px-0'
            />
          ) : null}
        </>
      ) : null}
    </>
  );
});

SettingsPage.displayName = 'SettingsPage';

export default SettingsPage;
