import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { ICON_VARIANT } from '@data/dict';
import { BUTTON_TEXT } from '@const/connect';
import { TOnclickHandler } from '@shared/types';

interface IBotButtonProps {
  /**
   * Опциональный параметр настраивает цвет кнопки
   * @param {string}
   */
  color?: string;
  /**
   * Опциональный параметр позволяет отключить кнопку
   * @param {boolean}
   */
  disabled?: boolean;
  /**
   * Параметр позволяет показать загрузчик в кнопке
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Callback для обработки нажатия на кнопку
   * @param {TOnclickHandler}
   */
  callBack: TOnclickHandler;
  /**
   * Вариант текста для метки, берется по ключу из словаря
   * @param {'restart' | 'clean' | 'stop' | 'qr'}
   */
  variant: 'restart' | 'clean' | 'stop' | 'qr';
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const BotButton = memo(
  ({ color, variant, disabled, callBack, isLoading, className = '' }: IBotButtonProps) => {
    const { t } = useTranslation();
    return (
      <Button
        dense
        type='action'
        onClick={callBack}
        loading={isLoading}
        disabled={disabled}
        className={className}
        color={color || `default`}
        view={variant === 'qr' ? 'filled' : 'outlined'}
        text={t(`CONNECTION_PAGE_TEXT.BUTTON_TEXT.${variant}`)}
        icon={<img src={ICON_VARIANT[variant]} alt={BUTTON_TEXT[variant]} className='h-4 w-4' />}
      />
    );
  },
);

BotButton.displayName = 'BotButton';
