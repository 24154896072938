import { TAppLanguage } from '@models/index';

export const numberOfMonth = ['1', '3', '6', '12'];
export const numberOfGiftWeek = ['', '1', '3', '8'];

export const PAY_PAGE_SUCCESS_MESSAGES = {
  SEND_INVOICE_IN_TG: 'Счет отправлен в telegram',
};

export const PAY_PAGE_SUCCESS_MESSAGES_ENG = {
  SEND_INVOICE_IN_TG: 'Invoice sent to telegram',
};

export const PAY_PAGE_SUCCESS_TOAST_MESSAGES = {
  SEND_INVOICE_IN_TG: 'PAY_PAGE_SUCCESS_MESSAGES.SEND_INVOICE_IN_TG',
};

export const BANNER_LINK = 'https://yclients.com/m/mp_3_beautybot_review/';

export const MONTH_WORDS = {
  rus: ['месяц', 'месяца', 'месяцев'],
  eng: ['month', 'months', 'month'],
};

export const WEEK_WORDS = {
  rus: ['неделя', 'недели', 'недель'],
  eng: ['week', 'weeks', 'weeks'],
};

export const EMPLOYEES: Record<TAppLanguage, string[]> = {
  eng: ['employee', 'employees', 'employees'],
  rus: ['сотрудник', 'сотрудника', 'сотрудников'],
};

export const PAY_PAGE_TEXT = {
  header: 'Оплата',
  payStatusTitle: 'статус оплаты',
  payTill: 'Оплачен до {{payTillDate}}',
  notPaid: 'Не оплачен',
  payEvery: 'Оплата каждое {{payTillDate}} число месяца',
  filial: 'Филиал: {{filialName}} №{{branch}}',
  tariffData: '👩 {{branchEmployees}} {{employersText}} \n💳 {{amount}} руб. в месяц',
  rate: 'Филиал: {{filialName}} №{{branch}}\n Ваш тариф:\n 👩 {{branchEmployees}} {{employersText}} \n💳 {{amount}} руб. в месяц',
  discount: '🛒 Скидка: {{discount}}%',
  autoPaymentStatus: 'Автоплатеж {{status}}',
  totalAmount: '{{oneMonthCostWithDiscount}} ₽ / мес.',
  totalForAllMonth: 'итого {{totalAmount}} ₽',
  giftText: '🎁 +{{giftWeeks}} {{weekText}}',
  payButtonText: 'Оплатить {{selectedCardTotalAmountText}}',
  billDownloadModalRate:
    'Ваш тариф: 👩 {{branchEmployees}} {{employersText}}, 💳 {{monthAmount}} руб. в месяц',
  selectedCardTotalAmountText: '{{selectedCardTotalAmount}} ₽ {{paymentMethodText}}',
  yourTariff: 'Ваш тариф:',
  employers: 'сотрудников',
  rubl: 'руб.',
  month: 'в месяц',
  autoPayment: 'Автоплатеж',
  autoPaymentEnabled: 'подключен',
  autoPaymentDisabled: 'не подключен',
  card: 'Карта **** **** **** {{cardLastFourDigits}}',
  enableAutoPayment: 'Подключите автоплатёж',
  changeCard: 'Изменить карту',
  rublSign: '₽',
  monthShort: 'мес.',
  amount: 'итого',
  withAutoPayments: 'с автоплатежом',
  promoCodeLabel: 'Введите промокод',
  promoCodePlaceholder: 'Промокод',
  setPromCode: 'Применить',
  enableAutomaticPayment: 'Вы хотите подключить автоплатеж',
  paymentDescription: 'Вы будете перенаправлены на сайт платёжной системы, слейдуйте инструкциям.',
  paymentDescription2:
    'В окне кассы укажите карту и выполните платёж. После завершения оплаты, пожалуйста, обновите страницу.',
  refresh: 'Обновить',
  cancel: 'Отмена',
  connect: 'Подключить',
  changeAutomaticPaymentCardTitle: 'Вы желаете изменить карту для автоплатежа',
  changeAutomaticPaymentCard: 'Вы желаете изменить карту для филиала:',
  changeAutomaticPaymentCardDescription:
    'В окне кассы укажите карту и выполните платёж. После завершения оплаты, пожалуйста, обновите страницу.',
  change: 'Изменить',
  disableAutomaticPaymentTitle: 'Вы хотите отключить автоплатеж',
  disable: 'Отключить',
  automaticPaymentDisabledTitle: 'Автоплатёж успешно отключен.',
  pleaseRefreshPage: 'Пожалуйста обновите страницу.',
  billCreated: 'Счет сформирован ({{totalAmount}} ₽)',
  billCreated2:
    'Счет сформирован за {{paymentPeriod}} {{paymentPeriodText}} на сумму {{totalAmount}} руб.',
  sendInTg: 'Отправить в TG',
  downloadPdf: 'Скачать .PDF',
  billSum: 'на сумму',
  downloadBill: 'Скачать счет',
  pay: 'Оплатить',
  cardPay: 'по карте',
  linkPay: 'по ссылке',
  commercialHeader: '2 недели в подарок',
  commercialDescription: 'Оставьте отзыв в маркетплейсе YCLIENTS и получите',
  commercialGift: '2 недели в подарок!',
  commercialButton: 'Написать отзыв',
  tableHeader: 'Предыдущие платежи',
  tableDate: 'Дата',
  tablePeriod: 'Период',
  tableSum: 'Сумма',
  tableBeforePeriod: 'до',
  paginationText: 'Показать строк:',
};

export const PAY_PAGE_TEXT_ENG = {
  header: 'Payment',
  payStatusTitle: 'payment status',
  payTill: 'Paid by {{payTillDate}}',
  notPaid: 'Not paid',
  payEvery: 'Payment every {{payTillDate}} day of the month',
  filial: 'Branch: {{filialName}} №{{branch}}',
  tariffData: '👩 {{branchEmployees}} {{employersText}} \n💳 {{amount}} rub. per month',
  rate: 'Branch: {{filialName}} №{{branch}}\n Your tariff:\n 👩 {{branchEmployees}} {{employersText}} \n💳 {{amount}} rub. per month',
  discount: '🛒 Discount: {{discount}}%',
  autoPaymentStatus: 'Autopayment {{status}}',
  totalAmount: '{{oneMonthCostWithDiscount}} ₽ / month',
  totalForAllMonth: 'total {{totalAmount}} ₽',
  giftText: '🎁 +{{giftWeeks}} {{weekText}}',
  payButtonText: 'Pay {{selectedCardTotalAmountText}}',
  billDownloadModalRate:
    'Your tariff: 👩 {{branchEmployees}} {{employersText}}, 💳 {{monthAmount}} rub. per month',
  selectedCardTotalAmountText: '{{selectedCardTotalAmount}} ₽ {{paymentMethodText}}',
  yourTariff: 'Your tariff:',
  employers: 'employees',
  rubl: 'rub.',
  month: 'per month',
  autoPayment: 'Autopayment',
  autoPaymentEnabled: 'connected',
  autoPaymentDisabled: 'not connected',
  card: 'Card **** **** **** {{cardLastFourDigits}}',
  enableAutoPayment: 'Enable auto payment',
  changeCard: 'Change card',
  rublSign: '₽',
  monthShort: 'month',
  amount: 'total',
  withAutoPayments: 'with auto payment',
  promoCodeLabel: 'Enter promo code',
  promoCodePlaceholder: 'Promo Code',
  setPromCode: 'Apply',
  enableAutomaticPayment: 'You want to enable automatic payment',
  paymentDescription:
    'You will be redirected to the payment system website, follow the instructions.',
  paymentDescription2:
    'Indicate your card in the cashier window and make the payment. After completing your payment, please refresh the page.',
  refresh: 'Refresh',
  cancel: 'Cancel',
  connect: 'Connect',
  changeAutomaticPaymentCardTitle: 'You would like to change your automatic payment card',
  changeAutomaticPaymentCard: 'You wish to change the card for the branch:',
  changeAutomaticPaymentCardDescription:
    'Indicate your card in the cashier window and make the payment. After completing your payment, please refresh the page.',
  change: 'Change',
  disableAutomaticPaymentTitle: 'Do you want to disable automatic payment',
  disable: 'Disable',
  automaticPaymentDisabledTitle: 'Automatic payment has been successfully disabled.',
  pleaseRefreshPage: 'Please refresh the page.',
  billCreated: 'The account has been created ({{totalAmount}} ₽)',
  billCreated2:
    'The account was generated in {{paymentPeriod}} {{paymentPeriodText}} in the amount of RUB {{totalAmount}}.',
  sendInTg: 'Send to TG',
  downloadPdf: 'Download .PDF',
  billSum: 'total amount',
  downloadBill: 'Download bill',
  pay: 'Pay',
  cardPay: 'by card',
  linkPay: 'by link',
  commercialHeader: '2 weeks free',
  commercialDescription: 'Leave a review in the YCLIENTS marketplace and get',
  commercialGift: '2 weeks free!',
  commercialButton: 'Write a review',
  tableHeader: 'Previous payments',
  tableDate: 'Date',
  tablePeriod: 'Period',
  tableSum: 'Sum',
  tableBeforePeriod: 'before',
  paginationText: 'Show lines:',
};
