import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Card } from '@components/card';
import { TAppLanguage } from '@models/index';
import { CustomSpinner } from '@blocks/customSpinner';
import { TTemplateInfo } from '@redux/templateList/types';
import { TActionOnTemplate, TTemplateAction } from '@shared/types';
import { TemplateCardWrapper } from '@blocks/templateCardWrapper';
import { TEMPLATE_SEND_TIME, TIME_EVENT_NAME } from '@const/templates';
import { EventTemplateListWrapper } from '@blocks/eventTemplateListWrapper';

interface ICardsListProps {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Флаг шаблонов по времени
   * @param {boolean}
   */
  isEm: boolean;
  /**
   * Флаг шаблонов по событию
   * @param {boolean}
   */
  isTm: boolean;
  /**
   * Флаг состояния загрузки
   * @param {boolean}
   */
  isFetching: boolean;
  /**
   * Массив данных для отображения карточек шаблонов
   * @param {TTemplateInfo[]}
   */
  data: TTemplateInfo[];
  /**
   * Callback для копирования шаблона
   * @param {TActionOnTemplate}
   */
  duplicateTemplate: TActionOnTemplate;
  /**
   * Callback для удаления шаблона
   * @param {TTemplateAction}
   */
  deleteTemplate: TTemplateAction;
  /**
   * Callback для переключения активности шаблона шаблона
   * @param {TTemplateAction}
   */
  switchTask: TTemplateAction;
  /**
   * Флаг доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess: boolean;
}

export const CardList = memo(
  ({
    isEm,
    isTm,
    isFetching,
    switchTask,
    appLanguage,
    deleteTemplate,
    duplicateTemplate,
    isEditTemplatesAccess,
    data,
  }: ICardsListProps) => {
    const { t } = useTranslation();
    const [isBeforeDropdownOpen, setIsBeforeDropdownOpen] = useState<boolean>(true);
    const [isAfterDropdownOpen, setIsAfterDropdownOpen] = useState<boolean>(true);

    const beforeDropdownToggle = useCallback(() => {
      setIsBeforeDropdownOpen(prevState => !prevState);
    }, []);

    const afterDropdownToggle = useCallback(() => {
      setIsAfterDropdownOpen(prevState => !prevState);
    }, []);

    const generateCard = useCallback(
      (card: TTemplateInfo) => (
        <Card
          appLanguage={appLanguage}
          duplicateTemplate={duplicateTemplate}
          isEditTemplatesAccess={isEditTemplatesAccess}
          key={card.id}
          data={card}
          deleteTask={deleteTemplate({
            id: card.id,
            state: card.active,
            tm: card.event === TIME_EVENT_NAME.TIMING_MESSAGE,
            em: card.event === TIME_EVENT_NAME.EVENT_MESSAGE,
          })}
          switchTask={switchTask({
            id: card.id,
            state: card.active,
            tm: card.event === TIME_EVENT_NAME.TIMING_MESSAGE,
            em: card.event === TIME_EVENT_NAME.EVENT_MESSAGE,
          })}
        />
      ),
      [appLanguage, duplicateTemplate, isEditTemplatesAccess, deleteTemplate, switchTask],
    );

    // Список всех шаблонов
    const cardsAll = useMemo(
      () => (isEm && isTm ? data.map(generateCard) : null),
      [isEm, isTm, generateCard, data],
    );

    // Список шаблонов по времени
    const cardsBefore = useMemo(
      () =>
        !isEm && isTm
          ? data.map(
              card =>
                card.templateSendTime?.includes(TEMPLATE_SEND_TIME.SEND_BEFORE_EVENT) &&
                generateCard(card),
            )
          : null,
      [isEm, isTm, generateCard, data],
    );

    // Список шаблонов по времени
    const cardsAfter = useMemo(
      () =>
        !isEm && isTm
          ? data.map(
              card =>
                card.templateSendTime?.includes(TEMPLATE_SEND_TIME.SEND_AFTER_EVENT) &&
                generateCard(card),
            )
          : null,
      [isEm, isTm, generateCard, data],
    );

    // Список шаблонов по событиям
    const cardsEvent = isEm && !isTm ? data.map(generateCard) : null;

    return (
      <div className='px-4 sm:px-0'>
        {isFetching ? <CustomSpinner /> : null}
        {isEm && isTm ? <TemplateCardWrapper templateCard={cardsAll} /> : null}
        {isEm && !isTm ? <TemplateCardWrapper templateCard={cardsEvent} /> : null}
        {!isEm && isTm ? (
          <>
            <EventTemplateListWrapper
              cartList={cardsBefore}
              isOpen={isBeforeDropdownOpen}
              onClickCallback={beforeDropdownToggle}
              text={t('TEMPLATE_LIST_PAGE_TEXT.beforeVisit')}
              className='mb-10'
            />
            <EventTemplateListWrapper
              cartList={cardsAfter}
              isOpen={isAfterDropdownOpen}
              onClickCallback={afterDropdownToggle}
              text={t('TEMPLATE_LIST_PAGE_TEXT.afterVisit')}
            />
          </>
        ) : null}
      </div>
    );
  },
);

CardList.displayName = 'CardList';
