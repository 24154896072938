import { apiService } from '@api/ApiService';
import { TBlackListData } from '@redux/blackList/models';
import { BLACKLIST_ACTION, END_POINTS_URL } from '@const/apiConstants';
import {
  TRequestStatus,
  TGetResponseWithData,
  TAddDeleteNumberInBlackListQueryData,
} from '@api/types';

/**
 * API страницы черный список
 * @module blackListApi
 */

export const blackListApi = {
  /**
   * Метод получает массив номеров в ЧС
   * @method getBlackList
   * @return {Promise<AxiosResponse<TRequestStatus | TGetResponseWithData<TBlackListData[]>, any>>}
   */
  getBlackList() {
    return apiService.get<TGetResponseWithData<TBlackListData[]> | TRequestStatus>(
      END_POINTS_URL.BLACKLIST,
    );
  },
  /**
   * Метод добавляет новый номер в ЧС
   * @method addNumberToBlacklist
   * @param data {TAddDeleteNumberInBlackListQueryData}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  addNumberToBlacklist(data: TAddDeleteNumberInBlackListQueryData) {
    return apiService.post<TRequestStatus>(END_POINTS_URL.BLACKLIST, {
      action: BLACKLIST_ACTION.ADD_NUMBER,
      phone: data.phone,
    });
  },
  /**
   * Метод удаляет номер из ЧС
   * @method deleteNumberToBlacklist
   * @param data {TAddDeleteNumberInBlackListQueryData}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  deleteNumberToBlacklist(data: TAddDeleteNumberInBlackListQueryData) {
    return apiService.post<TRequestStatus>(END_POINTS_URL.BLACKLIST, {
      action: BLACKLIST_ACTION.DELETE_NUMBER,
      phone: data.phone,
    });
  },
};
