import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { getStatusText } from '@helpers/index';
import { TConnectionStatusData } from '@redux/connection/models';
import { CONNECTION_STATUSES, TAppLanguage } from '@models/index';
import { ConnectionStatusWithText } from '@blocks/connectionStatusWithText';
import { connectionStatusFallback, connectionStatusText } from '@const/connect';

type TConnectionInfoProps = {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Адрес выбранного филиала
   * @param {string}
   */
  selectedFilialAddress: string;
  /**
   * Данные страницы подключения
   * @param {TConnectionStatusData}
   */
  data: TConnectionStatusData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const ConnectionInfo = memo(
  ({ appLanguage, selectedFilialAddress, data, className = '' }: TConnectionInfoProps) => {
    const { t } = useTranslation();

    // деструктурезируем state
    const { status, reference } = data;

    return (
      <div className={className}>
        <h2 className='font-inter font-bold text-h3Mobile text-black mb-2'>
          {t('CONNECTION_PAGE_TEXT.connectionStatus')}
        </h2>
        <p className='font-inter text-body_text text-grayText'>
          {t('CONNECTION_PAGE_TEXT.filial', { filialAdders: selectedFilialAddress })}
        </p>
        <div className='mt-2.5 mb-[1.8rem]'>
          <ConnectionStatusWithText
            status={status}
            statusText={getStatusText({
              status,
              appLanguage,
              connectionStatusText,
              connectionStatusFallback,
            })}
          />
          {status === CONNECTION_STATUSES.BLOCKED ? (
            <span className='block font-medium mt-2.5 whitespace-pre-line'>{reference}</span>
          ) : null}
        </div>
      </div>
    );
  },
);

ConnectionInfo.displayName = 'renderConnectionInfo';
