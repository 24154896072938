import React, { memo, useEffect } from 'react';
import { useNavigate, useLocation, Routes } from 'react-router-dom';
//
import { PATH_NAMES } from '@data/dict';
import { storageDb } from '@api/storageApi';
import { logout } from '@redux/auth/authSlice';
import { getAuthData } from '@redux/auth/selectors';
import { updateToken } from '@redux/auth/authThunks';
import { ROUTES_ACCESS_RIGHTS } from '@const/access';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { setSelectedFilial } from '@redux/accounts/accountsSlice';

export interface IPrivateRouteProps {
  children: React.ReactNode;
}

export const PrivateRoute = memo(({ children }: IPrivateRouteProps) => {
  const dispatch = useAppDispatch();

  // получаем объект navigate для организации редиректов
  const navigate = useNavigate();

  const { selectedFilial, isSalon, isSalonConsultant, data } = useAppSelector(getAccountsData);

  // получаем состояние запроса токена авторизации
  const { isUpdTokenSuccess, isUpdTokenError, isUpdTokenPending, isRedirectToConnectionPage } =
    useAppSelector(getAuthData);

  // Получаем массив прав если он есть и проверяем наличие необходимых прав
  const rights = storageDb.getRights();

  // получаем текущий путь
  const path = useLocation().pathname;

  // получаем из LS статус авторизации auth
  const { isAuth } = storageDb.getAuth();

  // получаем из LS id выбранного ранее филиала
  const { accId } = storageDb.getSelectedFilialData();

  // получаем из LS флаг является ли выбранный аккаунт аккаунтом рассылкой
  const { sendOutAccount: isSendOutAccount } = storageDb.getSelectedFilialData();

  // получаем из LS данные авторизованного пользователя
  const { userData } = storageDb.getUserData();

  // Получаем из LS авторизационный токен пользователя для последующего обновления
  const { token } = storageDb.getToken();

  // Поучаем лаг показа страниц лояльности
  const isShowLoyaltyPage = selectedFilial?.testLoyalty || false;

  // Эффект осуществляет переход на страницу всех шаблонов если у пользователя 1 филиал
  useEffect(() => {
    if (
      isAuth &&
      data.length === 1 &&
      !isRedirectToConnectionPage &&
      (path === PATH_NAMES.ACCOUNTS || path === PATH_NAMES.START_PAGE)
    ) {
      const { accId: filialId, sendOutAccount, address, rights: filialRight } = data[0];
      dispatch(
        setSelectedFilial({
          accId: filialId,
          filialName: address,
          isSendOutAccount: sendOutAccount,
          rights: filialRight,
        }),
      );
      // amplitudeSetUserId(branchId);

      navigate(PATH_NAMES.TEMPLATE_LIST_ALL);
    }
  }, [isRedirectToConnectionPage, isAuth, accId, data, navigate, path, dispatch]);

  // Эффект осуществляет переход на страницу подключения если пользователь заполнил форму авто подключения,
  // в ссылке при переходе на страницу авторизации передан параметр start_page и у пользователя 1 филиал
  useEffect(() => {
    if (
      isAuth &&
      isRedirectToConnectionPage &&
      data.length === 1 &&
      (path === PATH_NAMES.ACCOUNTS || path === PATH_NAMES.START_PAGE)
    ) {
      const { accId: filialId, sendOutAccount, address, rights: filialRight } = data[0];
      dispatch(
        setSelectedFilial({
          accId: filialId,
          filialName: address,
          isSendOutAccount: sendOutAccount,
          rights: filialRight,
        }),
      );
      // amplitudeSetUserId(branchId);

      navigate(PATH_NAMES.CONNECTION);
    }
  }, [isRedirectToConnectionPage, isAuth, accId, data, navigate, path, dispatch]);

  // Эффект переписывает данне о выбранном филиале в LS и обновляет токен при возвращении фокуса на вкладку
  useEffect(() => {
    const setSelectFilialData = () => {
      if (document.visibilityState === 'visible' && token && path !== PATH_NAMES.START_PAGE) {
        dispatch(updateToken(token));
        storageDb.setSelectedFilialData({
          accId: selectedFilial?.accId || '',
          filialName: selectedFilial?.address || '',
          isSendOutAccount: selectedFilial?.sendOutAccount || false,
        });
      }
    };
    document.addEventListener('visibilitychange', setSelectFilialData);

    return () => {
      document.removeEventListener('visibilitychange', setSelectFilialData);
    };
  });

  // Эффект проверяет наличие флага авторизации, в случае его отсутствия происходит редирект на страницу авторизации
  useEffect(() => {
    if (
      (!userData || !isAuth) &&
      !(
        path === PATH_NAMES.START_PAGE ||
        path === PATH_NAMES.AUTO_CONNECT ||
        path === PATH_NAMES.AUTO_CONNECT_FORM ||
        path === PATH_NAMES.YCLIENTS_START_PAGE
      )
    ) {
      dispatch(logout());
      navigate(PATH_NAMES.START_PAGE);
    }
  });

  // При переходе на страницу авторизации эффект проверяет ести ли у нас авторизационные данные пользователя (авторизовывался ранее)
  // Если есть обновляем токен и переходим на страницу со списком филиалов
  useEffect(() => {
    if (isAuth && token && path === PATH_NAMES.START_PAGE) {
      if (!isUpdTokenPending && !isUpdTokenError && !isUpdTokenSuccess) {
        dispatch(updateToken(token));
      }

      if (isUpdTokenSuccess) navigate(PATH_NAMES.ACCOUNTS);
    }
  }, [isUpdTokenPending, isUpdTokenError, isUpdTokenSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  // Эффект сделан для обработки прямых ссылок сохраненных или полученных от саппорта,
  // проверяется наличие авторизации и id выбранного ранее филиала.
  // Если филиал не выбран, редирект на страницу со списком филиалов.
  useEffect(() => {
    if (
      isSalon &&
      !accId &&
      isAuth &&
      path !== PATH_NAMES.ACCOUNTS &&
      path !== PATH_NAMES.START_PAGE &&
      path !== PATH_NAMES.AUTO_CONNECT &&
      path !== PATH_NAMES.AUTO_CONNECT_FORM &&
      path !== PATH_NAMES.YCLIENTS_START_PAGE
    ) {
      navigate(PATH_NAMES.ACCOUNTS);
    }
  });

  // Эффект запрещает переходить по сохраненным и прямым ссылкам на страницы шаблонов для аккаунтов-рассылок
  // если в LS записано, что выбранный ранее аккаунт является аккаунтом рассылкой,
  // при попытке перейти в шаблоны происходит редирект на страницу рассылок
  useEffect(() => {
    if (
      isAuth &&
      isSendOutAccount &&
      path !== PATH_NAMES.MAILING &&
      path !== PATH_NAMES.START_PAGE &&
      path.includes('template')
    ) {
      navigate(PATH_NAMES.MAILING);
    }
  }, [isAuth, navigate, path, isSendOutAccount, selectedFilial?.accId]);

  // Эффект запрещает переходить по сохраненным и прямым ссылкам на страницу цепочки отзывов для аккаунтов-рассылок
  // если в LS записано, что выбранный ранее аккаунт является аккаунтом рассылкой,
  // при попытке перейти на страницу цепочки отзывов происходит редирект на страницу рассылок
  useEffect(() => {
    if (
      isAuth &&
      isSendOutAccount &&
      path !== PATH_NAMES.MAILING &&
      path !== PATH_NAMES.START_PAGE &&
      path.includes('reviews')
    ) {
      navigate(PATH_NAMES.MAILING);
    }
  }, [isAuth, navigate, path, isSendOutAccount, selectedFilial?.accId]);

  // Эффект запрещает переходить по сохраненным и прямым ссылкам на страницы запрещенные для консультантов
  // при попытке перейти на запрещенные страницы происходит редирект на страницу партнерской программы
  useEffect(() => {
    if (
      !isSalon &&
      !isSalonConsultant &&
      isAuth &&
      path !== PATH_NAMES.PARTNERS_PROGRAM &&
      path !== PATH_NAMES.PAYMENT_HISTORY &&
      path !== PATH_NAMES.LIST_OF_OPERATION &&
      path !== PATH_NAMES.ADD_LEADS
    ) {
      navigate(PATH_NAMES.PARTNERS_PROGRAM);
    }
  }, [isAuth, navigate, path, selectedFilial?.accId, isSalon, isSalonConsultant]);

  // Эффект запрещает переходить по сохраненным и прямым ссылкам на страницы программы лояльности/приведи друга
  // при попытке перейти на запрещенные страницы происходит редирект на страницу партнерской программы
  useEffect(() => {
    if (!selectedFilial?.accId) return;
    if (
      isAuth &&
      !isShowLoyaltyPage &&
      (path === PATH_NAMES.INVITE_FRIEND_LIST ||
        path === PATH_NAMES.INVITE_FRIEND_TEMPLATE ||
        path === PATH_NAMES.INVITE_FRIENDS ||
        path === PATH_NAMES.INVITE_FRIEND_STATISTICS ||
        path === PATH_NAMES.INVITE_FRIEND_NEW_TEMPLATE)
    ) {
      navigate(PATH_NAMES.ACCOUNTS);
    }
  }, [isAuth, navigate, path, selectedFilial?.accId, isShowLoyaltyPage]);

  useEffect(() => {
    const isOwner = rights?.includes(ROUTES_ACCESS_RIGHTS.OWNER);
    const isConnection = rights?.includes(ROUTES_ACCESS_RIGHTS.MANAGE_WAPP);
    const isMailing = rights?.includes(ROUTES_ACCESS_RIGHTS.SENDOUTS);
    const isSettings = rights?.includes(ROUTES_ACCESS_RIGHTS.TRANSLATION_SWITCH);
    const isTemplateEditor = rights?.includes(ROUTES_ACCESS_RIGHTS.EDIT_TEMPLATES);

    // Проверяем доступ пользователя к разделу "Connection"
    // Если пользователь не является владельцем и путь равен "CONNECTION",
    // то происходит редирект на страницу "ACCOUNTS"
    const isAccessToConnect = isOwner || isConnection;

    // Проверяем доступ пользователя к разделу "Mailing" и "Started Mailing"
    // Если пользователь не является владельцем и путь равен "MAILING" или "STARTED_MAILING",
    // то происходит редирект на страницу "ACCOUNTS"
    const isAccessToMailing = isOwner || isMailing;

    // Проверяем доступ пользователя к разделу "Settings" и "Accesses"
    // Если пользователь не является владельцем и путь равен "SETTINGS" или "ACCESSES",
    // то происходит редирект на страницу "ACCOUNTS"
    const isAccessToSettings = isOwner || isSettings;

    // Проверяем доступ пользователя к разделу "Accesses"
    // Если пользователь не является владельцем и путь равен "ACCESSES",
    // то происходит редирект на страницу "ACCOUNTS"
    const isAccessToAccess = isOwner;

    const isAccessToEditTemplates = isOwner || isTemplateEditor;

    if (
      ((isSalon || isSalonConsultant) && !isAccessToConnect && path === PATH_NAMES.CONNECTION) ||
      ((isSalon || isSalonConsultant) &&
        !isAccessToMailing &&
        (path === PATH_NAMES.MAILING || path === PATH_NAMES.STARTED_MAILING)) ||
      ((isSalon || isSalonConsultant) &&
        !isAccessToSettings &&
        (path === PATH_NAMES.SETTINGS || path === PATH_NAMES.ACCESSES)) ||
      ((isSalon || isSalonConsultant) && !isAccessToAccess && path === PATH_NAMES.ACCESSES) ||
      ((isSalon || isSalonConsultant) &&
        !isAccessToEditTemplates &&
        (path === PATH_NAMES.NEW_TEMPLATE || path === PATH_NAMES.NEW_WR_TEMPLATE))
    ) {
      navigate(PATH_NAMES.ACCOUNTS);
    }
  }, [path, isSalon, rights, isSalonConsultant, navigate]);

  return <Routes>{children}</Routes>;
});
