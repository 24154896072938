import { apiService } from '@api/ApiService';
import { CONNECT_API_ACTIONS } from '@const/connect';
import { END_POINTS_URL } from '@const/apiConstants';
import { TRequestStatus, TGetQRCodeResponse, TGetConnectionStatusResponse } from '@api/types';

/**
 * API страницы подключения
 * @module connectionApi
 */

export const connectionApi = {
  /**
   * Метод делает запрос за данными для страницы подключения
   * @method getConnectionStatus
   * @return {Promise<AxiosResponse<TRequestStatus | TGetConnectionStatusResponse, any>>}
   */
  getConnectionStatus() {
    return apiService.get<TGetConnectionStatusResponse | TRequestStatus>(END_POINTS_URL.CONNECT);
  },
  /**
   * Метод получает данные для отображения QR кода
   * @method getQRCodeString
   * @return {Promise<AxiosResponse<TRequestStatus | TGetQRCodeResponse, any>>}
   */
  getQRCodeString() {
    return apiService.get<TGetQRCodeResponse | TRequestStatus>(END_POINTS_URL.GET_QR, {
      action: CONNECT_API_ACTIONS.GET_QR_CODE,
    });
  },
  /**
   * Метод останавливает телеграм бота
   * @method logOutInstance
   * @return {Promise<AxiosResponse<TRequestStatus | TGetQRCodeResponse, any>>}
   */
  logOutInstance() {
    return apiService.get<TGetQRCodeResponse | TRequestStatus>(END_POINTS_URL.LOGOUT_INSTANCE, {
      action: CONNECT_API_ACTIONS.LOGOUT_BOT,
    });
  },
  /**
   * Метод очищает очередь не отправленных сообщений
   * @method clearQuery
   * @return {Promise<AxiosResponse<TRequestStatus | TGetQRCodeResponse, any>>}
   */
  clearQuery() {
    return apiService.get<TGetQRCodeResponse | TRequestStatus>(END_POINTS_URL.CLEAR_QUERY, {
      action: CONNECT_API_ACTIONS.CLEAN_MESSAGE_QUERY,
    });
  },
  /**
   * Метод перезагружает телеграм бота
   * @method reloadBot
   * @return {Promise<AxiosResponse<TRequestStatus | TGetQRCodeResponse, any>>}
   */
  reloadBot() {
    return apiService.get<TGetQRCodeResponse | TRequestStatus>(END_POINTS_URL.RELOAD_BOT, {
      action: CONNECT_API_ACTIONS.REBOOT_BOT,
    });
  },
};
