import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { Loader } from '@blocks/loader';
import { Spinner } from '@uikit/Spinner';
import { NUMBERS } from '@const/blacklist';
import { EVENT_KEY_NAMES } from '@models/index';
import { getAppData } from '@redux/app/selectors';
import { VIDEO_INSTRUCTIONS } from '@const/common';
import { BlackListInput } from '@atoms/blackListInput';
import { BlackListItem } from '@components/blackListItem';
import { ToastsMessages } from '@components/toastsMessages';
import { getBlackListData } from '@redux/blackList/selectors';
import { numWord, validatePhoneNumber } from '@helpers/index';
import { useAppDispatch, useAppSelector } from '@store/store';
import { HeaderWithButton } from '@components/headerWithButton';
import { TKeyboardClickEvent, TOnChangeHandler } from '@shared/types';
import { BlackListSearchInput } from '@components/blackListSearchInput';
import { addNumberToBlacklist, deleteNumberFromBlacklist } from '@redux/blackList/blackListThunks';
import {
  setNewNumber,
  setSearchString,
  hideAllErrorToast,
  setNumberToDelete,
  hideAllSuccessToast,
} from '@redux/blackList/blackListSlice';

interface IBlackListProps {
  /**
   * Флаг обновления данных на странице после удаления или добавления номера
   * @param {boolean}
   */
  isRefresh: boolean;
  /**
   * Флаг успешного добавления номера в ЧС
   * @param {boolean}
   */
  isAddSuccess: boolean;
  /**
   * Флаг состояния запроса на добавление номера
   * @param {boolean}
   */
  isAddingNumber: boolean;
  /**
   * Флаг успешного удаления номера в ЧС
   * @param {boolean}
   */
  isDeleteSuccess: boolean;
  /**
   * Флаг ошибки при удалении номера в ЧС
   * @param {boolean}
   */
  isDeleteNumberError: boolean;
  /**
   * Флаг ошибки при добавлении номера в ЧС
   * @param {boolean}
   */
  isAddingNumberError: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const BlackListTemplate = memo(
  ({
    isRefresh,
    isAddSuccess,
    isAddingNumber,
    isDeleteSuccess,
    isDeleteNumberError,
    isAddingNumberError,
    className = '',
  }: IBlackListProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { appLanguage } = useAppSelector(getAppData);

    // получаем данные из store/blacklist
    const { newNumber, searchString, filteredState, successMessage, errorMessage, isLoading } =
      useAppSelector(getBlackListData);

    const hideAllSuccessToastHandler = useCallback(() => {
      dispatch(hideAllSuccessToast());
    }, [dispatch]);

    const hideAllErrorToastHandler = useCallback(() => {
      dispatch(hideAllErrorToast());
    }, [dispatch]);

    // Проверяет введен ли номер и вызывает callBack для добавления номера в базу.
    const addNumber = useCallback(() => {
      dispatch(addNumberToBlacklist({ number: newNumber }));
    }, [dispatch, newNumber]);

    // Вызывает callBack для удаления номера из базы.
    const deleteNumber = useCallback(
      (number: string) => () => {
        dispatch(setNumberToDelete({ number }));
        dispatch(deleteNumberFromBlacklist(number));
      },
      [dispatch],
    );

    // Обработчик события Change, добавляет введенные данные в state.
    const inputHandler: TOnChangeHandler = useCallback(
      event => {
        if (!Number.isNaN(+event.currentTarget.value)) {
          dispatch(setNewNumber({ value: event.target.value }));
        } else {
          dispatch(setNewNumber({ value: '' }));
        }
      },
      [dispatch],
    );

    // Обработчик события Change, добавляет данные в state для выполнения фильтрации по номеру.
    const setSearch: TOnChangeHandler = useCallback(
      event => {
        if (!Number.isNaN(+event.currentTarget.value)) {
          dispatch(setSearchString({ value: event.target.value }));
        } else {
          dispatch(setSearchString({ value: '' }));
        }
      },
      [dispatch],
    );

    // Очищает введенное значение
    const clearSearchString = useCallback(() => {
      dispatch(setSearchString({ value: '' }));
    }, [dispatch]);

    // Обработчик события keyDown, добавляет номер при нажатии на enter
    const onKeyDownAddNumberHandler: TKeyboardClickEvent = useCallback(
      event => {
        const {
          key,
          currentTarget: { value },
        } = event;
        if (key === EVENT_KEY_NAMES.ENTER && value) {
          dispatch(addNumberToBlacklist({ number: newNumber }));
        }
      },
      [dispatch, newNumber],
    );

    // Подготавливает список чипсов с телефонами для рендеринга
    const blacklist = useMemo(
      () =>
        filteredState.map(({ number }) => {
          return (
            <div key={number} className='mr-2 mb-2'>
              <BlackListItem
                number={number}
                deleteNumber={deleteNumber(number)}
                isDeleteError={isDeleteNumberError}
              />
            </div>
          );
        }),
      [isDeleteNumberError, deleteNumber, filteredState],
    );

    // Проверяет введенный номер на валидность
    const isNumberValid = validatePhoneNumber(newNumber).length > 9;

    return (
      <div className={`flex flex-col relative ${className}`}>
        <HeaderWithButton
          headerText={t('BLACKLIST_PAGE_TEXT.header')}
          instructionData={VIDEO_INSTRUCTIONS.blacklist}
          className='mb-10'
        />
        <div className='mb-8'>
          <BlackListSearchInput
            value={searchString}
            onChangeHandler={setSearch}
            clearSearchString={clearSearchString}
            placeholder={t('BLACKLIST_PAGE_TEXT.searchInputPlaceholder')}
          />
        </div>
        <div>
          <h2 className='font-inter text-[1.25rem] font-bold text-blackHeader mb-1 tracking-[0.0075em]'>
            {t('BLACKLIST_PAGE_TEXT.listHeader')}
          </h2>
          <span className='text-textareaBlack tracking-[0.018em]'>
            {filteredState.length
              ? t('BLACKLIST_PAGE_TEXT.listItemsQuality', {
                  quality: filteredState.length,
                  text: numWord(filteredState.length, NUMBERS[appLanguage]),
                })
              : t('BLACKLIST_PAGE_TEXT.noItemsInList')}
          </span>
        </div>
        <div className='flex flex-wrap flex-col sm:flex-row mt-2 mb-4'>
          <div className='flex flex-col mr-4 w-full max-w-[21.25rem]'>
            <BlackListInput
              value={newNumber}
              onChangeHandler={inputHandler}
              onKeyDownHandler={onKeyDownAddNumberHandler}
              placeholder={t('BLACKLIST_PAGE_TEXT.numberInputPlaceholder')}
            />
            <span className='font-inter text-[0.75rem] text-stormGray tracking-[0.033em] w-[24em]'>
              {t('BLACKLIST_PAGE_TEXT.numberInputDescription')}
            </span>
          </div>
          <div className='pt-[0.145rem] mt-4 sm:mt-0'>
            <Button
              dense
              icon='plus'
              fill={false}
              type='action'
              view='outlined'
              color='default'
              onClick={addNumber}
              loading={isAddingNumber}
              text={t('BLACKLIST_PAGE_TEXT.addButton')}
              disabled={isAddingNumber || !isNumberValid}
            />
          </div>
        </div>
        <div>
          {isLoading ? (
            <Loader className='h-[50vh]' />
          ) : (
            <>
              <ul className='flex flex-wrap items-center relative'>
                {isRefresh ? (
                  <Spinner
                    size={40}
                    color='default'
                    className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50'
                  />
                ) : null}
                {!filteredState.length ? (
                  <p className='m-0'>{t('BLACKLIST_PAGE_TEXT.noNumbers')}</p>
                ) : (
                  blacklist
                )}
              </ul>
              <ToastsMessages
                errorMessage={errorMessage}
                successMessage={successMessage}
                clearErrorCallback={hideAllErrorToastHandler}
                isShowSuccessToast={isDeleteSuccess || isAddSuccess}
                clearSuccessStatusCallback={hideAllSuccessToastHandler}
                isShowErrorToast={isAddingNumberError || isDeleteNumberError}
              />
            </>
          )}
        </div>
      </div>
    );
  },
);

BlackListTemplate.displayName = 'BlackListTemplate';
