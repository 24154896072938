import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { INPUT_NAMES } from '@models/index';
import { FormLabel } from '@blocks/formLabel';
import { WhenTime } from '@components/whenTime';
import { TIME_PERIODS } from '@const/templates';
import { getTimeString, parseTime } from '@helpers/index';
import { Midnight, OneHourBeforeMidnight } from '@const/settings';

interface ISendTimeLimitProps {
  /**
   * Значение поля "отправлять с"
   * @param {string}
   */
  sendFromTime: string;
  /**
   * Значение поля "отправлять до"
   * @param {string}
   */
  sendToTime: string;
  /**
   * Callback для сброса введенного времени на время по умолчанию
   * @param {() => void}
   */
  clearTime: () => void;
  /**
   * Callback для обработки изменения значение поля "отправлять с"
   * @param {(value: string) => void}
   */
  setFromTime: (value: string) => void;
  /**
   * Callback для обработки изменения значение поля "отправлять до"
   * @param {(value: string) => void}
   */
  setToTime: (value: string) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const SendTimeLimit = memo(
  ({
    setToTime,
    clearTime,
    sendToTime,
    setFromTime,
    sendFromTime,
    className = '',
  }: ISendTimeLimitProps) => {
    const { t } = useTranslation();

    // Обработчик обрабатывает изменения поля отправлять с,
    // если from > to, новое значение не запишется
    const setFromTimeHandler = useCallback(
      (value: string) => {
        const from = parseTime(value);
        const to = parseTime(sendToTime);

        const adjustedToValue =
          to <= from
            ? value !== OneHourBeforeMidnight && sendToTime === Midnight
              ? TIME_PERIODS.TWENTY_THREE_HOURS
              : from + TIME_PERIODS.HOUR_IN_MS
            : to;

        setFromTime(value);
        setToTime(getTimeString(adjustedToValue));
      },
      [setFromTime, setToTime, sendToTime],
    );

    // Обработчик обрабатывает изменения поля отправлять до,
    // если from > to, новое значение не запишется
    const setToTimeHandler = useCallback(
      (value: string) => {
        const from = parseTime(sendFromTime);
        const to = parseTime(value);

        const adjustedFromValue = from >= to ? to - TIME_PERIODS.HOUR_IN_MS : from;

        setFromTime(getTimeString(adjustedFromValue));
        setToTime(value);
      },
      [setFromTime, setToTime, sendFromTime],
    );

    const memoizedClearButton = useMemo(
      () => (
        <Button
          icon='cross'
          view='outlined'
          color='default'
          onClick={clearTime}
          disabled={!sendToTime || !sendFromTime}
          text={t('SETTINGS_PAGE_TEXT.sendTimeLimit.clearButton')}
          className='sm:ml-4 mt-4 sm:mt-0'
        />
      ),
      [t, clearTime, sendToTime, sendFromTime],
    );

    return (
      <div className={className}>
        <FormLabel
          showInfo
          labelTextVariant='sendTime'
          htmlFor={INPUT_NAMES.QUANTITY_FROM}
          className='mb-2'
        />
        <div className='flex flex-wrap flex-col sm:flex-row sm:items-center w-fit'>
          <span className='mr-2.5'>{t('SETTINGS_PAGE_TEXT.sendTimeLimit.from')}</span>
          <WhenTime whenTime={sendFromTime} isSetOnlyHour onChangeHandler={setFromTimeHandler} />
          <span className='sm:mx-2.5 my-2.5 sm:my-0'>
            {t('SETTINGS_PAGE_TEXT.sendTimeLimit.to')}
          </span>
          <WhenTime whenTime={sendToTime} isSetOnlyHour onChangeHandler={setToTimeHandler} />
          {memoizedClearButton}
        </div>
      </div>
    );
  },
);

SendTimeLimit.displayName = 'SendTimeLimit';
