import { TAppLanguage, THelp, TIcon } from '@models/index';
import payIcon from '@img/pay/money.svg';
import infoIcon from '@img/common/info.svg';
import plusIcon from '@img/trash/plusB.svg';
import paidIcon from '@img/connect/paid.svg';
import editIcon from '@img/common/edit.svg';
import playIcon from '@img/mailing/play.svg';
import stopIcon from '@img/connect/stop.svg';
import qrIcon from '@img/connect/qr-icon.svg';
import infoRed from '@img/common/infoRed.svg';
import excelIcon from '@img/mailing/excel.svg';
import deleteIcon from '@img/common/close.svg';
import textInfoIcon from '@img/trash/text.svg';
import downloadIcon from '@img/pay/download.svg';
import menuIcon from '@img/baseTemplate/menu.svg';
import addFileIcon from '@img/common/addFile.svg';
import chevronIcon from '@img/common/chevron.svg';
import spinnerIcon from '@img/common/spinner.gif';
import errorToastIcon from '@img/toast/error.svg';
import on from '@img/trash/template_statusOn.svg';
import restartIcon from '@img/connect/restart.svg';
import videoInfoIcon from '@img/mailing/video.svg';
import smallTGIcon from '@img/pay/smallTGIcon.svg';
import phoneIcon from '@img/baseTemplate/phone.svg';
import trashIcon from '@img/templateList/trash.svg';
import notPaidIcon from '@img/accounts/notPaid.svg';
import infoGrayIcon from '@img/common/infoGray.svg';
import settingsIcon from '@img/common/settings.svg';
import off from '@img/trash/template_statusOff.svg';
import yclientsIcon from '@img/mailing/yclients.svg';
import errorRedIcon from '@img/mailing/errorRed.svg';
import infoToastIcon from '@img/toast/infoToast.svg';
import iconTooltip from '@img/toast/iconTooltip.svg';
import calendarIcon from '@img/template/calendar.svg';
import logoutIcon from '@img/baseTemplate/logout.svg';
import statusError from '@img/trash/status_error.svg';
import telegramIconW from '@img/trash/telegram-1.svg';
import { TTemplateKeys } from '@redux/template/models';
import caretDownIcon from '@img/baseTemplate/caret.svg';
import deleteFileIcon from '@img/common/deletefile.svg';
import tickCircleIcon from '@img/common/tickCircle.svg';
import mailingIcon from '@img/baseTemplate/whatsapp.svg';
import cleanIcon from '@img/templateList/trash-icon.svg';
import learningIcon from '@img/baseTemplate/learning.svg';
import formattingIcon from '@img/template/formatting.svg';
import statusWaiting from '@img/trash/status_waiting.svg';
import telegramIcon from '@img/baseTemplate/telegram.svg';
import downloadWhiteIcon from '@img/pay/downloadWhite.svg';
import playIvoryIcon from '@img/mailing/playIvoryIcon.svg';
import mailingTextExampleIcon from '@img/mailing/text.svg';
import stepSuccessIcon from '@img/mailing/stepSuccess.svg';
import successToastIcon from '@img/toast/successToast.svg';
import greenChevronLeftIcon from '@img/login/chevronLeft.svg';
import whatsAppGreenIcon from '@img/mailing/whatsAppGreen.svg';
import errorInInputIcon from '@img/common/errorInInputIcon.svg';
import mailingExampleIcon from '@img/mailing/mailingExample.svg';
import adminBotIcon from '@img/baseTemplate/smalAdminBotLogo.png';
import telegramCyanIcon from '@img/baseTemplate/telegramCyan.svg';
import verticalMoreIcon from '@img/baseTemplate/moreVertical.svg';
import statusWaitingYellow from '@img/trash/status_waiting_yellow.svg';
import qrCodeTelegramLinkIcon from '@img/login/qrCodeTelegramLink.svg';
import videoInstructionIcon from '@img/baseTemplate/video_instruction.svg';
import IconCloseHeaderBanner from '@img/baseTemplate/IconCloseHeaderBanner.svg';
import videoInstructionSmallIcon from '@img/baseTemplate/video_instruction_small.svg';
import messageTailIcon from '@img/autoConnect/message_tail.svg';

export const TEMPLATE_FILE_ACCEPT_IMAGE = [
  '.jpeg',
  '.jpg',
  '.png',
  '.gif',
  '.apng',
  '.svg',
  '.bmp',
  '.ico',
];

export const TEMPLATE_FILE_ACCEPT_VIDEO = [
  '.mp4',
  '.mov',
  '.wmv',
  '.avi',
  '.avchd',
  '.flv',
  '.f4v',
  '.swf',
  '.mkv',
];

export const TEMPLATE_FILE_ACCEPT_DOC = ['.pdf', '.doc', '.docx', '.txt'];

export const ICON_VARIANT: TIcon = {
  authenticated: paidIcon,
  error: statusError,
  loading: statusWaiting,
  gotQrCode: notPaidIcon,
  waiting_yellow: statusWaitingYellow,
  delete: deleteIcon,
  phone: phoneIcon,
  logout: logoutIcon,
  telegram: telegramIcon,
  statusOn: on,
  statusOff: off,
  info: infoIcon,
  infoRed,
  pay: payIcon,
  mailing: mailingIcon,
  settings: settingsIcon,
  restart: restartIcon,
  cleanRed: cleanIcon,
  clean: cleanIcon,
  stop: stopIcon,
  qr: qrIcon,
  plus: plusIcon,
  telegramIconWhite: telegramIconW,
  addFile: addFileIcon,
  play: playIcon,
  deleteFile: deleteFileIcon,
  menu: menuIcon,
  spinner: spinnerIcon,
  chevron: chevronIcon,
  errorToast: errorToastIcon,
  successToast: successToastIcon,
  infoToast: infoToastIcon,
  telegramCyan: telegramCyanIcon,
  caretDown: caretDownIcon,
  learning: learningIcon,
  notPaid: notPaidIcon,
  paid: paidIcon,
  trash: trashIcon,
  formatting: formattingIcon,
  adminBot: adminBotIcon,
  errorInInput: errorInInputIcon,
  infoGray: infoGrayIcon,
  deleteReview: cleanIcon,
  edit: editIcon,
  errorRed: errorRedIcon,
  videoInfo: videoInfoIcon,
  textInfo: textInfoIcon,
  mailingExample: mailingExampleIcon,
  tickCircle: tickCircleIcon,
  yclients: yclientsIcon,
  excel: excelIcon,
  whatsAppGreen: whatsAppGreenIcon,
  playIvory: playIvoryIcon,
  stepSuccess: stepSuccessIcon,
  greenChevronLeft: greenChevronLeftIcon,
  qrCodeTelegramLink: qrCodeTelegramLinkIcon,
  iconTooltip,
  iconCloseBanner: IconCloseHeaderBanner,
  download: downloadIcon,
  downloadWhite: downloadWhiteIcon,
  smallTG: smallTGIcon,
  text: mailingTextExampleIcon,
  calendar: calendarIcon,
  messageTail: messageTailIcon,
  videoInstruction: videoInstructionIcon,
  videoInstructionSmall: videoInstructionSmallIcon,
  verticalMore: verticalMoreIcon,
};

export enum NO_FILIAL_ERROR {
  TEXT = 'noFilial',
}

export enum PATH_NAMES {
  YCLIENTS_START_PAGE = '/yclients_start_page',
  AUTO_CONNECT = '/auto_connect',
  AUTO_CONNECT_FORM = '/auto_connect_form',
  START_PAGE = '/',
  ACCOUNTS = '/accounts',
  CONNECTION = '/connection',
  TEMPLATE_LIST = '/template_list',
  TEMPLATE_LIST_ALL = '/template_list/all',
  TEMPLATE_LIST_EVENT = '/template_list/event',
  TEMPLATE_LIST_TIME = '/template_list/time',
  TEMPLATE_LIST_STATISTICS = '/template_list/statistic',
  TEMPLATE_STAT = '/template/stat',
  TEMPLATE = '/template',
  NEW_TEMPLATE = '/new_template',
  NEW_WR_TEMPLATE = '/new_wr_template',
  REVIEWS = '/reviews',
  NEW_REVIEW = '/new_review',
  REVIEW = '/review',
  MAILING = '/mailing',
  STARTED_MAILING = '/started_mailing',
  MAILING_DETAIL_STATS = '/stats',
  STATISTICS = '/statistics',
  ADD_LEADS = '/add_leads',
  ACCESSES = '/accesses',
  BLACKLIST = '/blacklist',
  SETTINGS = '/settings',
  PAY = '/pay',
  PARTNERS_PROGRAM = '/partners_program',
  LIST_OF_OPERATION = '/list_of_operation',
  PAYMENT_HISTORY = '/history_of_payment',
  CHAT_HISTORY = '/chat_history',
  AUTO_RESPONSE = '/auto_response',
  INVITE_FRIENDS = '/invite_fiend',
  INVITE_FRIEND_LIST = '/invite_fiend/list',
  INVITE_FRIEND_TEMPLATE = '/invite_fiend/action',
  INVITE_FRIEND_NEW_TEMPLATE = '/invite_fiend/new_action',
  INVITE_FRIEND_STATISTICS = '/invite_fiend/statistic',
  PAGE_404 = '/*',
}

export const LABEL_TEXT: THelp = {
  // firstTooltip
  firstTooltipText: '',
  firstTooltipButtonText: '',
  tooltipTitle: '',
  // autoConnection
  emojiList: 'Выберите подходящий эмоджи',
  ageOfWhatsappAccount: 'Укажите срок использования WhatsApp',
  // template
  testHelp: 'test help string',
  textWhatsapp: 'Текст для Whatsapp',
  textExamples: 'Примеры текстов рассылок:',
  textSms: 'Текст для СМС',
  selectFileText: 'Добавить медиафайл',
  whenSend: 'Отправить',
  services: 'Услуги',
  categories: 'Категории услуг',
  products: 'Товары',
  staff: 'Сотрудники',
  clientCategory: 'Категории клиентов',
  visits: 'Отправлять через каждые',
  visitQuantity: 'Клиентам с количеством записей',
  blackList: 'Черный список',
  blacklist: 'Черный список',
  sendOnlyLostClient: 'Отправлять только потерявшимся клиентам',
  onlineRecord: 'Отправлять только при онлайн записи?',
  confirmRecord: 'Подтверждать запись?',
  textConfirmation: 'Текст при подтверждении записи',
  textCancel: 'Текст при отмене записи',
  recordStatus: 'Статус записи',
  clientDidNotCome: 'Клиент не пришел',
  clientExpectation: 'Ожидание клиента',
  clientCame: 'Клиент пришёл',
  clientConfirmed: 'Клиент подтвердил',
  recordTag: 'Категории записей',
  statisticSegment: 'Выберите сегмент',
  statisticEarnOneMessage: 'Заработок с 1 отпр. сообщения',
  // _______________________
  // reviews
  whatsapp: 'Текст для Whatsapp',
  badReviews: 'Трансляция негативных отзывов',
  channels: 'Трансляция негативных отзывов в WhatsApp',
  tgAlerts: 'Трансляция негативных отзывов в Telegram',
  messageStep2_4: 'Текст сообщения',
  staffs: 'Сотрудники',
  positive: 'Текст для положительного отзыва',
  negative: 'Текст для отрицательного отзыва',
  detailFilter: 'Выберите сегмент',
  //-------------------------
  // autoResponse
  responseToClient: 'Ответ клиенту',
  // settings
  exceptionMastersNames: 'Исключить мастеров:',
  phonesDontShowInBot: 'Не показывать номера в трансляции:',
  smsProvider: 'Выберите СМС провайдера',
  replyMsgCheck: 'Проверка на ответ',
  sendContact: 'Отправка контакта',
  savePhonesYcl: 'Сохранять входящие номера в YCLIENTS',
  enableReplyInBot: 'Отвечать на сообщения в телеграм',
  smsConfirm: 'Отправлять коды подтверждения в СМС',
  billData: 'Данные счета',
  sendTime: 'Отправлять сообщения',
  disableChat: 'Включить WhatsApp-чат в журнале записи Yсlients',
  // _______________________
  // pay
  tariff: 'Ваш тариф',
  yourTariff: 'Ваш тариф',
  promoCode: 'Введите промокод',
  // ______________________
  // connection
  messageQuery: 'Очередь сообщений',
  // ______________________
  // mailing
  text: 'Введите текст',
  mailingText: '1. Введите текст рассылки',
  dataFile: '2. Загрузите файл с данными для рассылки',
  templateHelp: '👉 Шаблон файла для рассылок:',
  ownerMailingText: '3. Тест рассылки на номере владельца',
  startMailing: '4. Начать рассылку',
  // ----------------------
  // feedback
  feedback: 'Введите текст',
  feedbackAddMediaFile: 'Добавить медиафайл (необязательно)',
  // templateStat
  templateStatsChart: 'Процент ответивших и записавшихся',
  // inviteFriends
  faq: 'Частые вопросы',
  earnedFromNewClients: 'Заработано с новых клиентов',
  // inviteFriendsTemplate
  templateTitle: 'Название шаблона',
  firstMessage: 'Шаблон, который предлагает старым клиентам приглашать новых',
  secondMessage: 'Шаблон, который получит старый клиент и сможет его переслать новому клиенту',
  cardType: 'Тип карты',
  bonusType: 'Тип бонусов',
  inviteFriends: 'Количество баллов, которые получит клиент за приглашенного друга',
  newCustomerPoints: 'Количество баллов, которые получит новый клиент после оплаты',
  interestRegularCustomer:
    'Процент от стоимости услуг, которые получит клиент за приглашенного друга',
  inviteFriendsWhenSend: 'Отправить',
  serviceIF: 'Услуги',
  categoriesIF: 'Категории услуг',
  staffIF: 'Сотрудники',
  clientCategoryIF: 'Категории клиентов',
  visitsIF: 'Клиентам с количеством визитов',
  blacklistIF: 'Чёрный список',
  // inviteFriendsStats
  childSignupRatio: 'Клиентов записались на услуги',
  childPaymentRatio: 'Клиентов посетило салон',
};

export const LABEL_TEXT_ENG: THelp = {
  // firstTooltip
  firstTooltipText: '',
  firstTooltipButtonText: '',
  tooltipTitle: '',
  // autoConnection
  emojiList: 'Select the appropriate emoji',
  ageOfWhatsappAccount: 'Specify the expiration date for WhatsApp',
  // template
  testHelp: 'test help string',
  textWhatsapp: 'Text for Whatsapp',
  textExamples: 'Examples of mailing texts:',
  textSms: 'Text for SMS',
  selectFileText: 'Add media file',
  whenSend: 'Send',
  services: 'Services',
  categories: 'Service Categories',
  products: 'Products',
  staff: 'Staff',
  clientCategory: 'Client categories',
  visits: 'Send every',
  visitQuantity: 'Clients with number of records',
  blackList: 'Black list',
  blacklist: 'Blacklist',
  sendOnlyLostClient: 'Send only to lost clients',
  onlineRecord: 'Send only when recording online?',
  confirmRecord: 'Do you want to confirm the record?',
  textConfirmation: 'Text when confirming the entry',
  textCancel: 'Text when canceling entry',
  recordStatus: 'Record status',
  clientDidNotCome: 'Client did not come',
  clientExpectation: 'Waiting for client',
  clientCame: 'The client has arrived',
  clientConfirmed: 'Client confirmed',
  recordTag: 'Record Categories',
  statisticSegment: 'Select a segment',
  statisticEarnOneMessage: 'Earnings from 1 send. messages',
  // _______________________
  // reviews
  whatsapp: 'Text for Whatsapp',
  badReviews: 'Broadcast negative reviews',
  channels: 'Broadcast negative reviews on WhatsApp',
  tgAlerts: 'Broadcast negative reviews on Telegram',
  messageStep2_4: 'Message text',
  staffs: 'Staff',
  positive: 'Text for positive feedback',
  negative: 'Text for negative review',
  detailFilter: 'Select a segment',
  //-------------------------
  // autoResponse
  responseToClient: 'Response to client',
  // settings
  exceptionMastersNames: 'Exclude masters:',
  phonesDontShowInBot: 'Do not show numbers in broadcast:',
  smsProvider: 'Select SMS provider',
  replyMsgCheck: 'Checking for reply',
  sendContact: 'Sending a contact',
  savePhonesYcl: 'Save incoming numbers to YCLIENTS',
  enableReplyInBot: 'Reply to telegram messages',
  smsConfirm: 'Send confirmation codes via SMS',
  billData: 'Account data',
  sendTime: 'Send messages',
  // _______________________
  // pay
  tariff: 'Your tariff',
  yourTariff: 'Your tariff',
  promoCode: 'Enter promo code',
  // ______________________
  // connection
  messageQuery: 'Message Queue',
  // ______________________
  // mailing
  text: 'Enter text',
  mailingText: '1. Enter the text of the newsletter',
  dataFile: '2. Upload a file with data for mailing',
  templateHelp: '👉 File template for mailings:',
  ownerMailingText: '3. Mailing test on owner`s number',
  startMailing: '4. Start mailing',
  //---------------------
  // feedback
  feedback: 'Enter text',
  feedbackAddMediaFile: 'Add media file (optional)',
  // templateStat
  templateStatsChart: 'Percentage of respondents and signups',
  // inviteFriends
  faq: 'Frequently Asked Questions',
  earnedFromNewClients: 'Earned from new clients',
  // inviteFriendsTemplate
  templateTitle: 'Template title',
  firstMessage: 'Template that invites old clients to invite new ones',
  secondMessage: 'Template that the old client will receive and can forward to the new client',
  cardType: 'Card type',
  bonusType: 'Bonus type',
  inviteFriends: 'The number of points the client will receive for inviting a friend',
  newCustomerPoints: 'The number of points that a new customer will receive after payment',
  interestRegularCustomer:
    'Percentage of the cost of services that the client will receive for an invited friend',
  inviteFriendsWhenSend: 'Send',
  serviceIF: 'Services',
  categoriesIF: 'Service Categories',
  staffIF: 'Staff',
  clientCategoryIF: 'Client categories',
  visitsIF: 'Clients with number of visits',
  blacklistIF: 'Blacklist',
  disableChat: 'Enable WhatsApp chat in Yclients recording log',
  // inviteFriendsStats
  childSignupRatio: 'Clients booked for services',
  childPaymentRatio: 'Clients visited the salon',
};

export const TOOLTIPS_TEXT: THelp = {
  // first tooltip
  firstTooltipText: 'Наведите на значок «i», чтобы прочитать подсказку',
  firstTooltipButtonText: 'Понятно',
  tooltipTitle: 'Как рассчитывается?',
  // autoConnection
  emojiList: 'Выберите подходящий эмоджи',
  ageOfWhatsappAccount:
    'Важно указать информацию корректно, так как это будет влиять на количество доступных к отправке сообщений в день при запуске рассылки',
  // template
  testHelp: 'test help string',
  textWhatsapp:
    'Введите текст, который будет отправляться клиентам в WhatsApp.\n Вы можете подставить в сообщение любую информацию по записи, для этого вставьте в текст любой из ключей',
  textExamples: 'Перейдите по ссылке для просмотра примеров оформления сообщений',
  textSms:
    'Введите текст, который будет отправляться клиентам в СМС, когда не найден WhatsApp на номере',
  selectFileText: `Выберите файл для отправки в сообщении\n\n Доступные форматы:\n ${'\u2022'} изображения: ${TEMPLATE_FILE_ACCEPT_IMAGE.join(
    ', ',
  )}\n ${'\u2022'} видео: ${TEMPLATE_FILE_ACCEPT_VIDEO.join(
    ', ',
  )}\n ${'\u2022'} файлы: ${TEMPLATE_FILE_ACCEPT_DOC.join(
    ', ',
  )} \n\n Максимальный размер файла: 20 мегабайт`,
  whenSend: 'Выберите когда отправлять сообщение',
  services: 'Выберите услуги при которых отправлять или не отправлять шаблон',
  categories: 'Выберите категории услуг при которых отправлять или не отправлять шаблон',
  products:
    "Укажите код товара из CRM системы, для которого необходимо отправлять шаблон. Оставьте пустым для отправки всем клиентам. Через '-' вы можете указать товары которые необходимо игнорировать",
  staff: 'Выберите сотрудников на которых отправлять или не отправлять шаблон',
  clientCategory: 'Выберите категории клиентов которым отправлять или не отправлять шаблон',
  visits:
    'Выберите значение частоты записей при котором будет уходить шаблон.\n Пример: Если выбрать 2, то каждую 2ю запись будет отправляться сообщение, т.е. 2, 4, 6 и т.д.',
  visitQuantity:
    'Укажите количество записей, при котором необходимо отправлять сообщение. Границы указываются включительно.\n Пример: 0 и 1 для отправки клиентам которые пришли в первый раз, 2 и 6 для отправки клиентам которые были от 2х до 6и раз',
  blackList: 'Добавьте номер в черный список. По указанным номерам шаблон отправляться не будет',
  blacklist: 'Добавьте номер в черный список. По указанным номерам шаблон отправляться не будет',
  sendOnlyLostClient: 'Отправляет сообщение клиентам которые давно не были',
  onlineRecord: 'Отправляет сообщение клиентам которые записались онлайн',
  confirmRecord: 'Данное будет ожидать подтверждения от клиента',
  textConfirmation: 'Данное сообщение отправится клиентам в случае успешного подтверждения записи',
  textCancel: 'Данное сообщение отправится клиентам в случае отмены записи',
  recordStatus: 'В каком статусе записи отправлять шаблон',
  clientDidNotCome: 'нет описания',
  clientExpectation: 'нет описания',
  clientCame: 'нет описания',
  clientConfirmed: 'нет описания',
  recordTag: 'Категории записей в YCLIENTS',
  statisticSegment:
    'Мы отслеживаем насколько хорошо работает каждая версия шаблона.\n\nВы можете посмотреть сколько потеряшек вернула каждая версия этого шаблона',
  statisticEarnOneMessage:
    'Заработок с 1 сообщения - это обобщающая метрика, по которой можно оценить успешность шаблонов в целом.\n\nСчитается как:\n\n(весь заработок с шаблона) / (кол-во отправленных сообщений) = заработок с 1 отправленного сообщения.',
  // _______________________
  // reviews
  whatsapp:
    'Введите текст для оценивания необходимого вам показателя качества. Внимание! Шкалу оценивания в сообщение вставлять не нужно, она автоматически подставится при отправке',
  badReviews:
    'Введите номер телефона или id группы в WhatsApp, куда будут приходить низкие оценки и негативные отзывы',
  channels:
    'Введите номер телефона или id группы в WhatsApp, куда будут приходить низкие оценки и негативные отзывы',
  tgAlerts:
    'Для того чтобы получить telegram id отправьте "я" в бота. Для отправки в группу добавьте бота в нужную группу и введите id который он пришлет после добавления',
  messageStep2_4:
    'Введите текст для оценивания необходимого вам показателя качества. Внимание! Шкалу оценивания в сообщение вставлять не нужно, она автоматически подставится при отправке',
  staffs:
    "Укажите код сотрудника из CRM системы, для которого необходимо отправлять шаблон. Оставьте пустым для отправки всем клиентам. Через '-' вы можете указать сотрудников которых необходимо игнорировать. Вы можете отключить отправку всех шаблонов сотруднику в настройках",
  positive:
    'Введите текст который будет отправляться в случае позитивного отзыва. В данное поле вы можете вставить текст с ссылками на карты, чтобы ваши клиенты могли оставить отзыв',
  negative:
    'Введите текст который будет отправляться в случае негативного отзыва. Ответ клиента на данное сообщение вы получите на номер или в группу указанную в первом шаге',
  // autoResponse
  responseToClient: 'нет описания',
  // settings
  exceptionMastersNames: 'Для данных сотрудников не будет осуществляться отправка сообщений',
  phonesDontShowInBot: 'Сообщения с этих номеров не будут отображаться в трансляции телеграм',
  smsProvider:
    'Выберите СМС провайдера для использования дополнительного канала отправки в случае отсутствия WhatsApp у клиента',
  replyMsgCheck:
    'Проверяет был ли записан клиент после обращения в WhatsApp и уведомляет владельца если не записан',
  savePhonesYcl: 'Сохраняет номер телефона клиента когда он пишет в WhatsApp в базу CRM системы',
  enableReplyInBot: 'Позволяет отвечать клиента в WhatsApp из бота в телеграмме через "ответить"',
  sendContact: 'Отправка контакта с номером на который подключен WA для новых клиентов',
  smsConfirm: 'Отправлять коды подтверждения только в СМС',
  billData: 'Информация по расчетному счету на который будут генерироваться платежка',
  sendTime:
    'При ограничении времени отправки сообщения будут уходить только в ЗАДАННОЕ время. Если клиент запишется ночью через онлайн запись сообщение он получит только в момент открытия. RED',
  disableChat: 'Включить WhatsApp-чат в журнале записи Yсlients',
  // _______________________
  // pay
  yourTariff: 'Ваш тариф',
  tariff: `Тариф рассчитывается исходя из количества сотрудников в СRM системе.\n  - 1 сотрудник - 990 ₽ в месяц,\n - от 2 до 5 сотрудников - 1990 ₽ в месяц,\n - от 6 до 10 сотрудников - 2990 ₽ в месяц,\n - от 11 до 15 сотрудников - 3490 ₽ в месяц \n - от 16 до 20 сотрудник - 3890 ₽ в месяц,\n - безлимит - 4290 ₽ в месяц.\n\n Рассылки (без интеграции с CRM):\n - 990 ₽ в месяц `,
  promoCode: '',
  // ______________________
  // connection
  messageQuery:
    'Здесь указаны сообщения которые находятся в очереди на отправку и будут отправлены после подключения бота к WhatsApp',
  // ______________________
  // mailing
  text: '',
  mailingText:
    "🤔 Любая рассылка несёт в себе риск того, что ваш вотсап заблокируют. Не надо бояться делать рассылки, однако, лучше придерживаться следующих правил:❗️ Не отправляйте сообщения тем, кто вас не знает и не ждёт вашего сообщения. Такие 'холодные' клиенты, скорее всего, будут жаловаться на спам и ваш номер может быть заблокирован.❗️ Используйте {name} в тексте сообщения, так вы делаете каждое сообщение уникальным.❗️ Не делайте рассылки с новых номеров вотсапа. Минимальный возраст вотсапа для рассылок - 2 месяца. Соблюдая эти правила, ваши рассылки всегда будут эффективными.Что повышает риск?👉 возраст вотсапа: если вотсапу меньше 1.5 месяцев, не рекомендуется делать рассылки вообще👉 наличие внешней ссылки в текстеМы не несём ответственности в случае блокировки вашего вотсапа",
  dataFile: 'Поддерживаются файлы xls и xlsx формата. Мы рекомендуем загружать файл в xlsx формате',
  templateHelp: 'Вы можете вставить свои данные в этот шаблон, а затем загрузить файл шаблона',
  ownerMailingText:
    'Вы можете проверить внешний вид рассылки в WA отправив тестовое сообщение на номер владельца аккаунта. В тестовом сообщении ключ {name} не подставляется. Не переживайте, в вашей рассылке все имена подставятся согласно файлу',
  startMailing: 'Запустить рассылку с указанным текстом на номера из файла excel',
  detailFilter:
    'Вы можете выбрать любой сегмент клиентов из прошедшей рассылки. Например, вы можете посмотреть данные по тем кто не ответил',
  // feedback
  feedback: '',
  feedbackAddMediaFile: '',
  // templateStat
  templateStatsChart: 'Процент ответивших и записавшихся',
  // inviteFriends
  faq:
    '###### Как можно использовать новый функционал?\n' +
    'Для расчёта берётся предыдущий период, который можно настроить в зависимости от специфики вашего салона. Указанные в настройках периоды для расчета возвращаемости\n' +
    '\n' +
    '###### Как создать новую акцию?\n' +
    'Показывает количество вернувшихся клиентов, которые ранее были у данного сотрудника.\n' +
    '\n' +
    '###### Что, если в моём салоне нет карт лояльности?\n ' +
    'Вы можете создать карты в YCLIENTS. Для этого воспользуйтесь [инструкцией](https://botsarmy.teamly.ru/space/78d25157-1d03-4884-bc5b-5c7b812b4bc5/article/10f67e56-0a0d-4fd2-bf92-9aae58a398dd).',
  earnedFromNewClients: 'Заработано с новых клиентов',
  // inviteFriendsTemplate
  templateTitle:
    'Введите название акции. Это название будет отображаться в списке акций и поможет вам идентифицировать условия работы акции.',
  firstMessage:
    'Введите текст сообщения, которое старый клиент получит с предложением пригласить новых клиентов',
  secondMessage:
    'Введите текст сообщения, которое старый клиент сможет переслать новому клиенту. Это сообщение должно содержать приглашение и ссылку для нового клиента. Ссылка добавляется автоматически.',
  cardType: 'Выберите тип карты лояльности, который будет использоваться для начисления бонусов.',
  bonusType:
    'Укажите, будут ли бонусы начисляться в виде баллов или в виде процентов от стоимости услуги.\n' +
    '- **Фикс** - Количество рублей, которые будут начислены один раз. Их можно будет использовать как скидку на услуги.\n' +
    '- **Процент** - Процент от стоимости услуги, которые клиент будет получать за каждый визит друга. Более выгодный вариант для бизнеса\n',
  inviteFriends:
    'Укажите количество баллов, которые будут начислены старому клиенту за каждого приглашенного нового клиента, после оплаты оказанной услуги. Баллы могут быть использованы для получения скидок при оплате услуг или товаров.',
  newCustomerPoints:
    'Укажите количество баллов, которые будут начислены новому клиенту сразу после создания первой записи в системе. Это стимулирует новых клиентов делать первую запись и становиться постоянными клиентами.',
  interestRegularCustomer:
    'Укажите процент от суммы чека, который будет начисляться старому клиенту за каждую оплату услуг новым клиентом. Это процентное вознаграждение будет зачисляться на карту лояльности старого клиента.',
  inviteFriendsWhenSend:
    'Укажите условия или события, при наступлении которых сообщения будут отправляться клиентам',
  serviceIF:
    ' Выберите услуги, при оказании которых сообщения будут отправляться или не отправляться клиентам.',
  categoriesIF:
    'Выберите категории услуг, которые посещают или не посещают клиенты, которые будут получать сообщения на приглашение друзей/подруг',
  staffIF:
    'Выберите сотрудников, к которым приходят клиенты, которые будут получать сообщения на приглашение друзей/подруг',
  clientCategoryIF:
    'Выберите категории клиентов которые будут получать сообщения на приглашение друзей/подруг',
  visitsIF:
    'Укажите количество визитов старых клиентов, которые будут получать ссылки на приглашение друзей/подруг. \n Границы указываются включительно. \n Пример: 0 и 1 для отправки клиентам, которые пришли в первый раз, 2 и 6 для отправки клиентам, которые были от 2 до 6 раз.',
  blacklistIF:
    'Добавьте номер в черный список. Сообщения на приглашение друзей/подруг не будут отправляться по указанным номерам.',
  // inviteFriendsStats
  childSignupRatio: 'Отношение между записавшимися и принявшими приглашение клиентами',
  childPaymentRatio: 'Отношение между записавшимися и посетившими салон клиентами',
};

export const TOOLTIPS_TEXT_ENG: THelp = {
  // firstTooltip
  firstTooltipText: "Click on the 'i' icon to read the tooltip",
  firstTooltipButtonText: 'Got it',
  tooltipTitle: 'How is it calculated?',
  // autoConnection
  emojiList: 'Select the appropriate emoji',
  ageOfWhatsappAccount:
    'It is important to enter the information correctly, as this will affect the number of messages available for sending per day when the newsletter is launched',
  // template
  testHelp: 'test help string',
  textWhatsapp:
    'Enter the text that will be sent to clients on WhatsApp.\n You can insert any information about the entry into the message, to do this, insert any of the keys into the text',
  textExamples: 'Follow the link to view examples of message formatting',
  textSms:
    'Enter the text that will be sent to customers via SMS when WhatsApp is not found on the number',
  selectFileText: 'Select a file to send as a message',
  whenSend: 'Choose when to send the message',
  services: 'Select services for which to send or not send a template',
  categories: 'Select categories of services for which to send or not send a template',
  products:
    "Indicate the product code from the CRM system for which you want to send a template. Leave blank to send to all customers. Through '-' you can specify products that should be ignored",
  staff: 'Select employees to whom to send or not send the template',
  clientCategory: 'Select client categories to whom to send or not send the template',
  visits:
    'Select the frequency of records at which the template will be sent.\n Example: If you select 2, then every 2nd visit a message will be sent, i.e. 2, 4, 6, etc.',
  visitQuantity:
    'Specify the number of records at which the message must be sent. The boundaries are indicated inclusive.\n Example: 0 and 1 for sending to clients who came for the first time, 2 and 6 for sending to clients who came from 2 to 6 times',
  blackList:
    'Add the number to the blacklist. The template will not be sent to the specified numbers',
  blacklist:
    'Add the number to the blacklist. The template will not be sent to the specified numbers',
  sendOnlyLostClient: 'Sends a message to clients who have not visited for a long time',
  onlineRecord: 'Sends a message to clients who have registered online',
  confirmRecord: 'This will await confirmation from the client',
  textConfirmation: 'This message will be sent to clients if the record is successfully confirmed',
  textCancel: 'This message will be sent to clients if the appointment is cancelled',
  recordStatus: 'In what record status should the template be sent?',
  clientDidNotCome: 'no description',
  clientExpectation: 'no description',
  clientCame: 'no description',
  clientConfirmed: 'no description',
  recordTag: 'Categories of records in YCLIENTS',
  statisticSegment:
    'We track how well each version of the template performs.\n\nYou can see how many losses each version of this template returned',
  statisticEarnOneMessage:
    'Earnings from 1 message is a general metric by which you can evaluate the success of templates as a whole.\n\nIt is calculated as:\n\n(all earnings from a template) / (number of messages sent) = earnings from 1 message sent. ',
  // _______________________
  // reviews
  whatsapp:
    'Enter the text to evaluate the quality indicator you need. Attention! There is no need to insert a rating scale into the message, it will be automatically inserted when sending',
  badReviews:
    'Enter the phone number or ID of the WhatsApp group where low ratings and negative reviews will be sent',
  channels:
    'Enter the phone number or ID of the WhatsApp group where low ratings and negative reviews will be sent',
  tgAlerts:
    'In order to get a telegram id, send "I" to the bot. To send to a group, add the bot to the desired group and enter the id that it will send after adding',
  messageStep2_4:
    'Enter the text to evaluate the quality indicator you need. Attention! There is no need to insert a rating scale into the message, it will be automatically inserted when sending',
  staffs:
    "Indicate the code of the employee from the CRM system for whom you want to send a template. Leave empty to send to all clients. Using '-' you can specify the employees you want to ignore. You can disable sending all templates to an employee in the settings",
  positive:
    'Enter the text that will be sent in case of positive feedback. In this field you can insert text with links to maps so that your customers can leave feedback',
  negative:
    'Enter the text that will be sent in case of negative feedback. You will receive the client`s response to this message to the number or group specified in the first step',
  // autoResponse
  responseToClient: 'no description',
  // settings
  exceptionMastersNames: 'No messages will be sent to these employees',
  phonesDontShowInBot:
    'Messages from these numbers will not be displayed in the telegram broadcast',
  smsProvider:
    'Select SMS provider to use an additional sending channel if the client does not have WhatsApp',
  replyMsgCheck:
    'Checks whether the client has been recorded after contacting WhatsApp and notifies the owner if not registered',
  savePhonesYcl:
    'Saves the client`s phone number when he writes on WhatsApp to the CRM system database',
  enableReplyInBot: 'Allows the client to reply to WhatsApp from a bot in a telegram via "reply"',
  sendContact: 'Sending a contact with the number to which WA is connected for new clients',
  smsConfirm: 'Send confirmation codes only via SMS',
  billData: 'Information on the current account to which the payment will be generated',
  sendTime:
    'If you limit the sending time, messages will only go out at the SET time. If a client makes an appointment at night via online registration, he will receive a message only at the moment of opening. RED',
  disableChat: 'Enable WhatsApp chat in Yclients recording log',
  // _______________________
  // pay
  yourTariff: 'Your tariff',
  tariff: `The tariff is calculated based on the number of employees in the CRM system.\n - 1 employee - 990 ₽ per month, \n - from 2 to 5 employees - 1990 ₽ per month, \n - from 6 to 10 employees - 2990 ₽ per month month,\n - from 11 to 15 employees - 3490 ₽ per month \n - from 16 to 20 employees - 3890 ₽ per month,\n - unlimited - 4290 ₽ per month.\n\n Mailings (without integration with CRM) :\n - 990 ₽ per month`,
  promoCode: '',
  // ______________________
  // connection
  messageQuery:
    'Here are messages that are in the queue for sending and will be sent after connecting the bot to WhatsApp',
  // ______________________
  // mailing
  text: '',
  mailingText:
    "🤔 Any mailing carries the risk that your WhatsApp will be blocked. Do not be afraid to send mailings, however, it is better to adhere to the following rules:❗️ Do not send messages to those who do not know you and are not waiting for your message. These are 'cold' clients, Most likely, they will complain about spam and your number may be blocked.❗️ Use {name} in the body of the message, this way you make each message unique.❗️ Do not send mailings from new WhatsApp numbers. The minimum age of WhatsApp for mailings is 2 months. Observing these rules, your mailings will always be effective. What increases the risk?👉 age of WhatsApp: if WhatsApp is less than 1.5 months old, it is not recommended to send mailings at all👉 presence of an external link in the text We are not responsible if your WhatsApp is blocked",
  dataFile: 'Xls and xlsx files are supported. We recommend uploading the file in xlsx format',
  templateHelp: 'You can paste your data into this template and then load the template file',
  ownerMailingText:
    'You can check the appearance of the newsletter in WA by sending a test message to the account owner`s number. The {name} key is not substituted in the test message. Don`t worry, in your mailing list all names will be entered according to the file',
  startMailing: 'Start mailing with the specified text to numbers from the excel file',
  detailFilter:
    'You can select any customer segment from the past mailing. For example, you can look at data on those who did not respond',
  // feedback
  feedback: '',
  feedbackAddMediaFile: '',
  // templateStat
  templateStatsChart: 'Percentage of respondents and signups',
  // inviteFriends
  faq: 'How can I use the new functionality?\nThe calculation is based on the previous period, which can be customized depending on the specifics of your salon.\nThe periods specified in the settings for calculating return\n\nHow do I create a new promotion?\nShows the number of returning customers who were previously with this employee.\n\nWhat if my salon does not have loyalty cards?\nYou can create cards in YCLIENTS. To do this, use the [instructions](ya.ru).',
  earnedFromNewClients: 'Earned from new clients',
  // inviteFriendsTemplate
  templateTitle:
    'Enter the name of the promotion. This name will be displayed in the list of promotions and will help you identify the terms of the promotion.',
  firstMessage:
    'Enter the text of the message that the old client will receive with an offer to invite new clients',
  secondMessage:
    'Enter the text of the message that the old client can forward to the new client. This message should include an invitation and link for the new client. The link is added automatically.',
  cardType: 'Select the type of loyalty card that will be used to accrue bonuses.',
  bonusType:
    'Specify whether bonuses will be awarded as points or as a percentage of the service cost.\n' +
    '- **Fix** - The number of rubles that will be awarded once. They can be used as a discount on services.\n' +
    '- **Percentage** - The percentage of the service cost that the client will receive for each visit by a friend. A more profitable option for business\n',
  inviteFriends:
    'Indicate the number of points that will be awarded to the old client for each new client invited after payment for the service provided. Points can be used to receive discounts when paying for services or goods.',
  newCustomerPoints:
    'Indicate the number of points that will be awarded to a new client immediately after creating the first record in the system. This encourages new customers to make their first entry and become repeat customers.',
  interestRegularCustomer:
    "Indicate the percentage of the check amount that will be charged to the old client for each payment for services by a new client. This percentage reward will be credited to the old customer's loyalty card.",
  inviteFriendsWhenSend:
    'Specify conditions or events upon the occurrence of which messages will be sent to clients',
  serviceIF: 'Select services for which messages will be sent or not sent to clients.',
  categoriesIF:
    'Select categories of services that are visited or not visited by clients who will receive messages to invite friends/girlfriends',
  staffIF:
    'Select the employees to whom clients come and who will receive messages to invite friends',
  clientCategoryIF: 'Select categories of clients who will receive messages for inviting friends',
  visitsIF:
    'Indicate the number of visits from old clients who will receive links to invite friends. \nThe boundaries are inclusive. \nExample: 0 and 1 to send to clients who came for the first time, 2 and 6 to send to clients who came from 2 to 6 times.',
  blacklistIF:
    'Add the number to the blacklist. Messages to invite friends/girlfriends will not be sent to the specified numbers.',
  // inviteFriendsStats
  childSignupRatio: 'The relationship between clients who signed up and accepted the invitation',
  childPaymentRatio: 'The relationship between clients who signed up and visited the salon',
};

export const templateKeysDict: Record<TAppLanguage, TTemplateKeys> = {
  eng: {
    common: {
      name: `To substitute the client name`,
      full_name: 'Full client name, as in YCL',
      day_of_week: 'To substitute the day of the week',
      day_month: 'For substituting days and months',
      digital_date: 'Date in numerical form',
      start_time: 'Service start time',
      end_time: 'Service end time',
      start_end_time: 'Start and end time of recording',
      master: 'Master name',
      price: 'Price of services',
      filial_name: 'Branch name',
      client_phone: 'Client number',
      services: 'Service',
      time_service: 'Recording time with service name',
      time_master: 'Record time with master name',
      review_link: 'Link for review',
      record_link: 'Record Management',
      tips_link: 'Link to tips',
      bonus_add: 'Bonuses added',
      bonus_withdraw: 'Bonuses written off',
      bonus_balance: 'Total bonus balance',
    },
    birthday: {
      name: 'To substitute the client name',
    },
    mailing: {
      name: 'To substitute the client name',
      phone: "To substitute the client's phone number",
    },
    integration: {},
  },
  rus: {
    common: {
      name: `Для подстановки имени клиента`,
      full_name: 'Полное имя клиента, как в YCL',
      day_of_week: 'Для подстановки дня недели',
      day_month: 'Для подстановки числа и месяцев',
      digital_date: 'Дата в численной форме',
      start_time: 'Время начала услуги',
      end_time: 'Время окончания услуги',
      start_end_time: 'Время начала и окончания записи',
      master: 'Имя мастера',
      price: 'Цена услуг',
      filial_name: 'Имя филиала',
      client_phone: 'Номер клиента',
      services: 'Услуга',
      time_service: 'Время записи с названием услуг',
      time_master: 'Время записи с именем мастера',
      review_link: 'Ссылка для отзыва',
      record_link: 'Управление записью',
      tips_link: 'Ссылка на чаевые',
      bonus_add: 'Добавлено бонусов',
      bonus_withdraw: 'Списано бонусов',
      bonus_balance: 'Общий баланс бонусов',
    },
    birthday: {
      name: 'Для подстановки имени клиента',
    },
    mailing: {
      name: 'Для подстановки имени клиента',
      phone: 'Для подстановки телефона клиента',
    },
    integration: {},
  },
};

export const statisticReportMockDescriptionData =
  '🏬 *{filial_name}*\n' +
  '=========================\n' +
  '\n' +
  '💳 Выручка: *{all_amount}₽*\n' +
  'Создано новых записей: {all_records}\n' +
  'Записей реализовано: {all_paid}\n' +
  '\n' +
  '📞 *Данные о звонках*\n' +
  'Исходящих звонков: {all_out_calls}\n' +
  'Успешных исходящих: {success_out_calls}\n' +
  '\n' +
  '🤳 *Звонки входящие*\n' +
  'Входящих звонков: {all_in_calls}\n' +
  'Из них новых клиентов: {new_clients}\n' +
  'Не дозвонились: {missed_calls}\n' +
  'Не перезвонили: {dont_recall}\n' +
  '\n' +
  '🟢 *Вотсап*\n' +
  'Скорость ответов: {wa_average_time}\n' +
  '\n' +
  '*Данные о задачах*\n' +
  'Задач поставлено: *{tasks_created}*\n' +
  'Из них выполнено: *{tasks_completed}*\n' +
  'Просрочено с предыдущего дня: *{tasks_missed}*\n' +
  'Среднее время выполнения: *{completion_time}*';
