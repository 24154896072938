import React, { memo, useCallback, useEffect, useState } from 'react';
//
import { Menu } from '@uikit/Menu';
import { Chips } from '@uikit/Chips';
import { Dropdown } from '@uikit/Dropdown';
import { FormLabel } from '@blocks/formLabel';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { getAppData } from '@redux/app/selectors';
import { THelpKeys, TSimpleStringObj } from '@models/index';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getSecondDropdownListTitle, sortObj } from '@helpers/index';
import { getListOfCategoriesSet } from '@redux/settings/settingsThunks';
import { TAddBalloonOnBlurHandler, TDeleteBalloonSet } from '@shared/types';

interface IInputWithDropdownFromEPSPProps {
  /**
   * Массив данных для отображения Chips components
   * @param {string[]}
   */
  value: string[];
  /**
   * Id на input для ввода нового значения
   * @param {string}
   */
  inputId: string;
  /**
   * Параметр передает данные по успешном завершении получения данных страницы,
   * используется для отправки запроса к ЕР для получения списка для dropdown.
   * Запрос за списком уходит только если этот параметр true.
   * @param {boolean}
   */
  isFetchingSuccess: boolean;
  /**
   * Вариант текста в label, из словаря берется текст и подставляется в label
   * @param {THelpKeys}
   */
  labelTextVariant: THelpKeys;
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Объект с данными для отображения выпадающего списка
   * @param {{ [key: string]: TSimpleStringObj }}
   */
  categoriesDataList: { [key: string]: TSimpleStringObj };
  /**
   * Имя ключа в categoriesDataList
   * @param {string}
   */
  categoryName: string;
  /**
   * Флаг отображения иконки в Chips
   * @param {boolean}
   */
  isShowIcon?: boolean;
  /**
   * Имя иконки из библиотеки
   * @param {string}
   */
  iconName?: string;
  /**
   * Callback для удаления Chips component
   * @param {TDeleteBalloonSet}
   */
  deleteBalloon: TDeleteBalloonSet;
  /**
   * Callback для добавления Chips component при событии blur
   * @param {TAddBalloonOnBlurHandler}
   */
  addBalloonOnBlur: TAddBalloonOnBlurHandler;
  /**
   * Цвет элемента Chips
   * @param {string}
   */
  colorOfChips?: string;
  /**
   * Опциональный параметр включает или отключает валидацию нового значения, если параметр true
   * в input нельзя ввести текст, только число.
   * @param {boolean}
   * @default
   */
  typeOfValueIsNumber?: boolean;
  /**
   * Индекс элемента который берется из массива образованного преобразованием объекта через
   * Object.Entries(obj).map(item => item[indexOfValueToSet]);
   */
  indexOfValueToSet?: 0 | 1;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const InputWithDropdownFromEPSP = memo(
  ({
    value,
    inputId,
    isShowIcon,
    categoryName,
    deleteBalloon,
    addBalloonOnBlur,
    labelTextVariant,
    isFetchingSuccess,
    categoriesDataList,
    typeOfValueIsNumber,
    selectedFilialAccId,
    iconName = 'disable',
    indexOfValueToSet = 0,
    colorOfChips = 'danger',
    className = '',
  }: IInputWithDropdownFromEPSPProps) => {
    const dispatch = useAppDispatch();
    const { appLanguage } = useAppSelector(getAppData);

    // Получаем данные для формирования выпадающего списка
    const dropdownList = categoriesDataList[inputId];

    // Отфильтрованный список для выпадающего списка, из списка удалены уже выбранные поля
    const [filteredStaffList, setFilteredStaffList] = useState<TSimpleStringObj>({});

    // Запрашивает данные для выпадающего списка
    useEffect(() => {
      if (inputId && isFetchingSuccess && selectedFilialAccId) {
        dispatch(getListOfCategoriesSet({ categoryName }));
      }
    }, [dispatch, inputId, isFetchingSuccess, selectedFilialAccId, categoryName]);

    // Эффект фильтрует dropdownList, удаляет выбранные поля и записывает новые данные в filteredStaffList
    useEffect(() => {
      const newList = Object.fromEntries(
        Object.entries(dropdownList || []).filter(item => !value.includes(item[indexOfValueToSet])),
      );
      setFilteredStaffList(newList || []);
    }, [dropdownList, inputId, value, indexOfValueToSet]);

    // Валидирует введенное значение, валидация происходит если передан опциональный проп "typeOfValueIsNumber"
    const isBalloonValueValid = useCallback(
      (valueToValidate: string) => {
        const valueWithOutMinus = valueToValidate.replace(/^-*/gm, '');

        if (!valueWithOutMinus) return false;

        if (typeOfValueIsNumber) {
          return !Number.isNaN(Number(valueToValidate));
        }

        return true;
      },
      [typeOfValueIsNumber],
    );

    // Добавляет новый "шарик", если введенные данные валидны, в противном случае очищает поле ввода
    const addBalloons = useCallback(
      (balloonValue: string) => () => {
        if (isBalloonValueValid(balloonValue)) {
          const newList = Object.fromEntries(
            Object.entries(filteredStaffList).filter(
              item => !balloonValue.includes(item[indexOfValueToSet]),
            ),
          );

          setFilteredStaffList(newList);

          addBalloonOnBlur(labelTextVariant, balloonValue);
        }
      },
      [
        addBalloonOnBlur,
        labelTextVariant,
        filteredStaffList,
        indexOfValueToSet,
        isBalloonValueValid,
        setFilteredStaffList,
      ],
    );

    // Обработчик удаления "шарика"
    const deleteBalloonHandler: TDeleteBalloonSet = useCallback(
      (item, inputName) => () => {
        const deletedItem = Object.fromEntries(
          Object.entries(dropdownList).filter(listItem => listItem[indexOfValueToSet] === item),
        );

        const newSortedList = sortObj(Object.assign(filteredStaffList, deletedItem));

        setFilteredStaffList(newSortedList);

        deleteBalloon(item, inputName)();
      },
      [deleteBalloon, dropdownList, filteredStaffList, indexOfValueToSet],
    );

    // Подготовка "шариков" для рендеринга
    const balloons = value.map((item, index) => {
      const key = `${index}${item}`;
      return isShowIcon ? (
        <Chips
          key={key}
          removable
          type='def'
          tag={item}
          icon={iconName}
          color={colorOfChips}
          onRemove={deleteBalloonHandler(item, inputId)}
          className='tracking-wide mr-2.5 mb-2.5'
        />
      ) : (
        <Chips
          key={key}
          removable
          type='def'
          tag={item}
          color={colorOfChips}
          onRemove={deleteBalloonHandler(item, inputId)}
          className='tracking-wide mr-2.5 mb-2.5'
        />
      );
    });

    // Формируем тайтл для выпадающего списка
    const secondDropdownTitle = getSecondDropdownListTitle(labelTextVariant, appLanguage);

    // Подготавливаем массив компонентов для отображения выпадающего списка
    const itemsToRenderInDropdown = Object.entries(filteredStaffList).map(item => (
      <MenuItem
        key={item[0]}
        onClick={addBalloons(item[indexOfValueToSet])}
        text={<span>{`${item[1]}(${item[0]})`}</span>}
      />
    ));

    // Функция для запроса списка, срабатывает при клике на Dropdown если нет загруженного списка
    const fetchStaffList = () => {
      if (!Object.entries(dropdownList || {}).length) {
        dispatch(getListOfCategoriesSet({ categoryName }));
      }
    };

    return (
      <div className={`flex flex-col ${className}`}>
        <FormLabel
          htmlFor={inputId}
          labelTextVariant={labelTextVariant}
          className='text-body_text mb-2.5'
        />
        <div className='flex flex-wrap sm:flex-nowrap items-center'>
          <div className='mb-2 w-full'>
            <Dropdown
              color='default'
              view='outlined'
              position='bottom-left'
              onClick={fetchStaffList}
              text={<span>{secondDropdownTitle}</span>}
              className={`when_send_dropdown !m-0 !w-full ${className}`}
              content={
                <Menu
                  view='raised'
                  className={itemsToRenderInDropdown.length === 1 ? `one_item_ul` : ''}>
                  {itemsToRenderInDropdown}
                </Menu>
              }
            />
          </div>
        </div>
        <div className='flex items-center flex-wrap w-full'>{balloons}</div>
      </div>
    );
  },
);

InputWithDropdownFromEPSP.displayName = 'InputWithDropdownFromEPSP';
