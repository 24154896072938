import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { COMMON_TOAST } from '@const/common';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import {
  REJECT_RESPONSE_KEY,
  REJECT_RESPONSE_2_KEY,
  TGetConnectionStatusResponse,
} from '@api/types';
import {
  TQRCodeData,
  TConnectStatuses,
  CONNECTION_STATUSES,
  TRejectResponseData,
} from '@models/index';
import {
  stopBot,
  getQrData,
  restartBot,
  clearMessages,
  getConnectionPageStatus,
} from '@redux/connection/connectionThunks';

import { IInitialState, initialState } from './initialState';

export const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getConnectionPageStatus.pending, (state: IInitialState) => {
      state.isError = false;
      state.isLoading = true;
      state.isLoading = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isFetchingQR = false;
      state.isGetQRError = false;
      state.isRestartingBot = false;
      state.isBotRestartError = false;
      state.isCleanQueryError = false;
      state.isBotStoppingQuery = false;
      state.isBotStoppingError = false;
      state.isFetchingCleanQuery = false;
      state.isAccessToConnectionDenied = false;
    });
    builder.addCase(
      getConnectionPageStatus.fulfilled,
      (state: IInitialState, action: PayloadAction<TGetConnectionStatusResponse | void>) => {
        state.isSuccess = true;
        state.isLoading = false;

        if (action.payload) {
          const { status } = action.payload;

          let newStatus = '';
          if (status === CONNECTION_STATUSES.GOT_QR_CODE_BACK_STATUS) {
            newStatus = CONNECTION_STATUSES.GOT_QR_CODE;
          }

          if (state.isRetries) {
            state.retries = 1;
            state.isRetries = false;
          }

          state.data = {
            ...action.payload,
            status: (newStatus || action.payload.status) as TConnectStatuses,
            messages: action.payload.messages.map((message, index) => ({
              id: index + 1,
              phone: message.phone,
              body: message.body,
            })),
          };
        }
      },
    );
    builder.addCase(
      getConnectionPageStatus.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
            state.isRetries = true;
            state.retries = state.retries--;
          } else {
            state.isError = true;
          }
        } else if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessToConnectionDenied = true;
          } else {
            state.isError = true;
          }
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(getQrData.pending, (state: IInitialState) => {
      state.isFetchingQR = true;
      state.isGetQRError = false;
    });
    builder.addCase(
      getQrData.fulfilled,
      (state: IInitialState, action: PayloadAction<TQRCodeData | void>) => {
        state.isFetchingQR = false;
        if (action.payload) {
          const { qr } = action.payload;

          if (qr.includes('data:image')) {
            state.qrData = action.payload;
          } else {
            const newQr = `data:image/jpeg;base64,${qr}`;
            state.qrData = { ...action.payload, qr: newQr };
          }
        }
      },
    );
    builder.addCase(
      getQrData.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
            state.isRetries = true;
            state.retries = state.retries--;
          } else {
            state.isGetQRError = true;
            state.isFetchingQR = false;
            state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
          }
        } else if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessToConnectionDenied = true;
          } else {
            state.isGetQRError = true;
            state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
          }
          state.isFetchingQR = false;
        } else {
          state.isGetQRError = true;
          state.isFetchingQR = false;
          state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
        }
      },
    );
    builder.addCase(clearMessages.pending, (state: IInitialState) => {
      const { isFetchingCleanQuery } = state;

      state.isSuccess = false;
      state.isCleanQueryError = false;
      state.isLoading = isFetchingCleanQuery;
    });
    builder.addCase(clearMessages.fulfilled, (state: IInitialState) => {
      const { isFetchingCleanQuery } = state;
      if (isFetchingCleanQuery) {
        state.isLoading = false;
        state.isFetchingCleanQuery = false;
      } else {
        state.isLoading = false;
      }
    });
    builder.addCase(
      clearMessages.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
            state.isRetries = true;
            state.retries = state.retries--;
          } else {
            state.isCleanQueryError = true;
            state.isFetchingCleanQuery = false;
            state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
          }
        } else if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessToConnectionDenied = true;
          } else {
            state.isCleanQueryError = true;
            state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
          }
          state.isFetchingCleanQuery = false;
        } else {
          state.isCleanQueryError = true;
          state.isFetchingCleanQuery = false;
          state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
        }
      },
    );
    builder.addCase(stopBot.pending, (state: IInitialState) => {
      state.isBotStoppingQuery = true;
      state.isBotStoppingError = false;
    });
    builder.addCase(stopBot.fulfilled, (state: IInitialState) => {
      state.isBotStoppingQuery = false;
    });
    builder.addCase(
      stopBot.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
            state.isRetries = true;
            state.retries = state.retries--;
          } else {
            state.isBotStoppingError = true;
            state.isBotStoppingQuery = false;
            state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
          }
        } else if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessToConnectionDenied = true;
          } else {
            state.isBotStoppingError = true;
            state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
          }
          state.isBotStoppingQuery = false;
        } else {
          state.isBotStoppingError = true;
          state.isBotStoppingQuery = false;
          state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
        }
      },
    );
    builder.addCase(restartBot.pending, (state: IInitialState) => {
      state.isRestartingBot = true;
      state.isBotRestartError = false;
    });
    builder.addCase(restartBot.fulfilled, (state: IInitialState) => {
      state.isRestartingBot = false;
    });
    builder.addCase(
      restartBot.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
            state.isRetries = true;
            state.retries = state.retries--;
          } else {
            state.isRestartingBot = false;
            state.isBotRestartError = true;
            state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
          }
        } else if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessToConnectionDenied = true;
          } else {
            state.isBotRestartError = true;
            state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
          }
          state.isRestartingBot = false;
        } else {
          state.isRestartingBot = false;
          state.isBotRestartError = true;
          state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
        }
      },
    );
  },
});

export const connectionReducer = connectionSlice.reducer;
