import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Dialog } from '@uikit/Dialog';
import { Button } from '@uikit/Button';
import { useAppDispatch } from '@store/store';
import { AddFile } from '@components/addFile';
import { parseDivValue } from '@helpers/index';
import { TKeyData } from '@redux/template/models';
import { MediaPreview } from '@blocks/mediaPreview';
import { MAILING_FILE_ACCEPT } from '@const/mailing';
import { getSelectionOffset } from '@helpers/selection';
import { DivWithLabel } from '@components/divWithLabel';
import { TEXTAREA_TEXT_MAX_LENGTH } from '@const/common';
import { emojiPickerToggle } from '@redux/emoji/emojiSlice';
import { TAppLanguage, TMediaFileType } from '@models/index';
import { PreviewComponent } from '@components/previewComponent';
import { ImportantMailingInfo } from '@blocks/importantMailingInfo';
import { MailingBlockingWarning } from '@blocks/mailingBlockingWarning';
import { MailingSecondStepWarning } from '@blocks/mailingSecondStepWarning';
import { MAILING_PAGE_DATA_KEY_NAME, TCustomerKeys } from '@redux/mailing/models';
import {
  addKeyInTextareaMP,
  setTextareaValueMP,
  setAbandonedTextareaDataMP,
} from '@redux/mailing/mailingSlice';
import {
  TAddFile,
  TClickHandler,
  TOnChangeHandler,
  TDivOnClickHandler,
  TDivOnKeyUpHandler,
  TOnEmojiViewChange,
  TDivOnInputHandler,
  TSetEmojiInTextarea,
  TSetViewEmojiPicker,
  TShowPreviewHandler,
  TClearMediaFileHandler,
} from '@shared/types';

type TMailingStep2Props = {
  /**
   * Текст сообщения в рассылке
   * @param {string}
   */
  text: string;
  /**
   * Имя загруженного файла, отображается только для не медиафайлов
   * @param {string}
   */
  mediaFileName: string;
  /**
   * URL загруженного медиафайла
   * @param {string | null}
   */
  mediaFileURL: string | null;
  /**
   * Имя textarea используется для добавления ключей и эмодзи в textarea
   * @param {string}
   */
  blurFromTextareaName: string;
  /**
   * Ошибка, файл слишком большой
   * @param {boolean}
   */
  mediaFileTooBigError: boolean;
  /**
   * Ошибка, файл не поддерживается
   * @param {boolean}
   */
  unsupportedFileError: boolean;
  /**
   * Callback для перехода на следующий шаг рассылки
   * @param {() => void}
   */
  setNextStepHandler: () => void;
  /**
   * Callback для загрузки файла
   * @param {TOnChangeHandler}
   */
  setMediaFileHandler: TOnChangeHandler;
  /**
   * Тип файла
   * @param {TMediaFileType}
   */
  mediaFileType: TMediaFileType;
  /**
   * Callback для открытия окна загрузки файла
   * @param {TAddFile}
   */
  addFile: TAddFile;
  /**
   * Callback для очистки инпута
   * @param {TClearMediaFileHandler}
   */
  clearMediaFileHandler: TClearMediaFileHandler;
  /**
   * Callback для добавления ключа в текст
   * @param {(key: string) => () => void}
   */
  setKeyInTextarea: (key: string) => () => void;
  /**
   * Callback для оборачивания выделенного текста в символы форматирования
   * @param {TClickHandler}
   */
  wrapSelectedTextInTextarea: TClickHandler;
  /**
   * Callback для открытия drawer с примерами шаблонов
   * @param {() => void}
   */
  openDrawer: () => void;
  /**
   * ref на contenteditable div для передачи в него новых данных
   * @param {React.MutableRefObject<(EventTarget & HTMLDivElement) | null>}
   */
  div: React.MutableRefObject<(EventTarget & HTMLDivElement) | null>;
  /**
   * Данные о расположении спец ключей
   * @param {TKeyData[]}
   */
  textareaKeysData: TKeyData[];
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Пользовательские ключи полученные после парсинга файла с данными клиентов
   * @param {TCustomerKeys}
   */
  customerKeys: TCustomerKeys;
  /**
   * Опциональный параметр отключает возможность ввода текста сообщения и переход на след шаг
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const MailingStep2 = memo(
  ({
    div,
    addFile,
    disabled,
    openDrawer,
    appLanguage,
    customerKeys,
    mediaFileURL,
    mediaFileType,
    mediaFileName,
    setKeyInTextarea,
    textareaKeysData,
    text: textMessage,
    setNextStepHandler,
    setMediaFileHandler,
    blurFromTextareaName,
    mediaFileTooBigError,
    unsupportedFileError,
    clearMediaFileHandler,
    wrapSelectedTextInTextarea,
    className = '',
  }: TMailingStep2Props) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    useEffect(() => {
      window.scroll({ top: 0, behavior: 'smooth' });
    }, []);

    // реф на инпут с медиафайлом используется для удаления загруженного файла
    const mediaFileInputRef = useRef<HTMLInputElement>(null);

    const [previewText, setPreviewText] = useState<string>('');

    const [isShowPreview, setIsShowPreview] = useState<boolean>(false);

    // Toggle для блока с превью
    const previewDialogToggle = useCallback(() => setIsShowPreview(prevState => !prevState), []);

    // При возникновении ошибок по загруженному файлу, эффект очищает инпут
    useEffect(() => {
      if (mediaFileTooBigError || unsupportedFileError) {
        clearMediaFileHandler(mediaFileInputRef)();
      }
    }, [mediaFileTooBigError, unsupportedFileError, clearMediaFileHandler]);

    // Записывает эмодзи в textarea
    const setEmojiInTextarea: TSetEmojiInTextarea = useCallback(
      emoji => {
        if (blurFromTextareaName && div.current) {
          dispatch(
            addKeyInTextareaMP({ innerText: emoji.emoji, callback: parseDivValue(div.current) }),
          );
          div.current.focus();
        }
      },
      [blurFromTextareaName, div, dispatch],
    );

    // Callback срабатывает при открытии блока с эмодзи,
    // записывает в стор положение курсора и текстовый блок в котором открыт блок с эмодзи
    const onEmojiViewChange: TOnEmojiViewChange =
      ({ textAreaName, textAreaValue }) =>
      () => {
        if (div.current) {
          const [start, end] = getSelectionOffset(div.current);

          dispatch(
            setAbandonedTextareaDataMP({
              name: textAreaName,
              selectionStart: start || textAreaValue.length,
              selectionEnd: end || textAreaValue.length,
            }),
          );
        }
      };

    // Показывает/скрывает блок с эмодзи
    const setViewEmojiPicker: TSetViewEmojiPicker = ({ name, isOpenEmoji }) => {
      dispatch(emojiPickerToggle({ isOpen: isOpenEmoji, name }));
    };

    // Обработчик изменений в contentEditable div
    const divOnInputHandler: TDivOnInputHandler = useCallback(
      ({ value, selectionEnd, selectionStart, newTextareaKeysData }) => {
        if (div) {
          dispatch(
            setTextareaValueMP({
              value,
              selectionEnd,
              selectionStart,
              newTextareaKeysData,
            }),
          );
        }
      },
      [div, dispatch],
    );

    // Обрабатывает клики по contentEditable div, сохраняет положение курсора
    // и имя contentEditable div
    const divOnClickHandler: TDivOnClickHandler = useCallback(
      event => {
        const {
          dataset: { name },
        } = event.currentTarget;

        const [start, end] = getSelectionOffset(event.currentTarget);

        div.current = event.currentTarget;

        dispatch(
          setAbandonedTextareaDataMP({
            name: name || '',
            selectionStart: start,
            selectionEnd: end,
          }),
        );
      },
      [div, dispatch],
    );

    // сохраняет положение курсора и имя contentEditable div
    const setCursorPositionHandler: TDivOnKeyUpHandler = useCallback(
      event => {
        const {
          dataset: { name },
        } = event.currentTarget;

        const [start, end] = getSelectionOffset(event.currentTarget);

        div.current = event.currentTarget;

        dispatch(
          setAbandonedTextareaDataMP({
            name: name || '',
            selectionStart: start,
            selectionEnd: end,
          }),
        );
      },
      [div, dispatch],
    );

    // Показывает модалку с превью текста шаблона
    const showPreviewHandler: TShowPreviewHandler = useCallback(
      text => () => {
        if (text) {
          setPreviewText(text);
          previewDialogToggle();
        }
      },
      [previewDialogToggle],
    );

    const memoizedPreviewDialog = useMemo(
      () => (
        <Dialog
          type='default'
          view='raised'
          color='default'
          backdropOpacity={40}
          isOpen={isShowPreview}
          onClose={previewDialogToggle}
          title={t('MAILING_PAGE_TEXT.sendOutPreview')}
          text={
            isShowPreview ? (
              <PreviewComponent
                description={previewText}
                customerKeys={customerKeys}
                isFileAdded={!!mediaFileName}
                mediaPreviewComponent={
                  <MediaPreview
                    isMessageStyle
                    isFileToBig={false}
                    fileURL={mediaFileURL}
                    fileName={mediaFileName}
                    fileType={mediaFileType}
                    clearInputCallback={clearMediaFileHandler(mediaFileInputRef)}
                    className='mt-4 mb-4'
                  />
                }
              />
            ) : (
              <span />
            )
          }
          className='whitespace-pre-line break-words report-preview'
        />
      ),
      [
        t,
        previewText,
        customerKeys,
        mediaFileURL,
        mediaFileName,
        isShowPreview,
        mediaFileType,
        previewDialogToggle,
        clearMediaFileHandler,
      ],
    );

    // Условия отключения кнопки перехода на следующий шаг
    const isNextButtonDisable =
      !disabled &&
      ((!!mediaFileName && !mediaFileTooBigError) ||
        (!!textMessage && !(textMessage.length > 2000)));

    // тайтл для кнопки перехода на следующий шаг
    const nextButtonTitle = !isNextButtonDisable ? t('MAILING_PAGE_TEXT.nextButtonTitle') : '';

    return (
      <div className={`relative ${className}`}>
        <MailingSecondStepWarning className='mb-4' />
        <DivWithLabel
          isEmoji
          showInfo={false}
          disabled={disabled}
          value={textMessage}
          specialKeyDict={customerKeys}
          textareaKeysData={textareaKeysData}
          maxLength={TEXTAREA_TEXT_MAX_LENGTH}
          divOnClickHandler={divOnClickHandler}
          divOnInputHandler={divOnInputHandler}
          setEmojiInTextarea={setEmojiInTextarea}
          setViewEmojiPicker={setViewEmojiPicker}
          htmlFor={MAILING_PAGE_DATA_KEY_NAME.TEXT}
          setCursorPosition={setCursorPositionHandler}
          showEmojiPicker={onEmojiViewChange({
            textAreaValue: textMessage,
            textAreaName: MAILING_PAGE_DATA_KEY_NAME.TEXT,
          })}
          placeholder={t('MAILING_PAGE_TEXT.textareaPlaceholder')}
          className='mb-2'
        />
        <div className='mb-8'>
          <Button
            dense
            type='default'
            view='outlined'
            color='default'
            disabled={disabled}
            rightIcon='application'
            text={t('MAILING_PAGE_TEXT.showPreview')}
            onClick={showPreviewHandler(textMessage)}
            className='w-[11rem]'
          />
        </div>
        {memoizedPreviewDialog}
        <div className={`flex flex-wrap items-center ${mediaFileName ? 'hidden' : ''}`}>
          <p className='text-grayText mb-0.5 leading-6 tracking-[0.0275em]'>
            {t('MAILING_PAGE_TEXT.addMediaFileHeader')}
          </p>
          <AddFile
            disabled={disabled}
            iconVariant='addFile'
            ref={mediaFileInputRef}
            isFileToBig={mediaFileTooBigError}
            onChangeHandler={setMediaFileHandler}
            accept={MAILING_FILE_ACCEPT.join(', ')}
            addFileCallback={addFile(mediaFileInputRef)}
            name={MAILING_PAGE_DATA_KEY_NAME.MEDIA_FILE}>
            {t('MAILING_PAGE_TEXT.addMediaFile')}
          </AddFile>
        </div>
        <MediaPreview
          fileURL={mediaFileURL}
          fileName={mediaFileName}
          fileType={mediaFileType}
          isFileToBig={mediaFileTooBigError}
          clearInputCallback={clearMediaFileHandler(mediaFileInputRef)}
          className={mediaFileURL ? 'mt-4' : ''}
        />
        <div className='flex flex-col sm:flex-row mt-6 sm:mt-8'>
          <Button
            fill
            dense
            type='action'
            view='filled'
            color='success'
            icon='chevron-right'
            title={nextButtonTitle}
            onClick={setNextStepHandler}
            disabled={!isNextButtonDisable}
            text={t('MAILING_PAGE_TEXT.continueButton')}
            className='mb-2 sm:mb-0 sm:mr-6 max-w-[10.5625rem]'
          />
          <MailingBlockingWarning />
        </div>
        <ImportantMailingInfo
          isMobileView
          isShowAdditionalInfo
          openDrawer={openDrawer}
          appLanguage={appLanguage}
          isShowExampleBlock={false}
          customerKeys={customerKeys}
          disableOpenDrawerButton={false}
          setKeyInTextarea={setKeyInTextarea}
          wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
          className='mt-8 xl:hidden'
        />
      </div>
    );
  },
);
