import React, { memo, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
//
import { PATH_NAMES } from '@data/dict';
import { NoAccess } from '@components/noAccess';
import { QUERY_PARAMS } from '@const/apiConstants';
import { ErrorMessage } from '@blocks/errorMessage';
import { ReviewTemplate } from '@templates/reviews';
import { getAccountsData } from '@redux/accounts/selectors';
import { getReviewById } from '@redux/reviews/reviewsThunks';
import { getReviewsPageData } from '@redux/reviews/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { reviewsEmptyTemplate } from '@redux/reviews/mockData';
import { clearUpdateNavId, setOneReviewData } from '@redux/reviews/reviewsSlice';

interface IReviewsProps {
  /**
   * Флаг новой цепочки
   * @param {boolean}
   */
  isNewReviews: boolean;
}

const ReviewsPage = memo(({ isNewReviews }: IReviewsProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get(QUERY_PARAMS.ID);
  const { selectedFilial } = useAppSelector(getAccountsData);
  const { oneReviewData, blurFromTextareaName, isAccessDenied, isError, isLoading, updateNavId } =
    useAppSelector(getReviewsPageData);

  useEffect(() => {
    if (updateNavId) {
      dispatch(clearUpdateNavId());
      navigate(
        `${PATH_NAMES.REVIEW}?${QUERY_PARAMS.ACC_ID}=${selectedFilial?.accId || ''}&${
          QUERY_PARAMS.ID
        }=${updateNavId}`,
      );
    }
  }, [navigate, updateNavId, selectedFilial?.accId, dispatch]);

  useEffect(() => {
    if (!isNewReviews && id) {
      dispatch(getReviewById({ templateId: String(id) }));
    }
  }, [isNewReviews, id, dispatch]);

  useEffect(() => {
    if (isNewReviews) {
      dispatch(setOneReviewData(reviewsEmptyTemplate));
    }

    return () => {
      dispatch(setOneReviewData(reviewsEmptyTemplate));
    };
  }, [isNewReviews, dispatch]);

  return (
    <>
      {isError && !isLoading && !isAccessDenied ? <ErrorMessage /> : null}
      {isAccessDenied && !isLoading && !isError ? <NoAccess /> : null}
      {!isError && !isLoading && !isAccessDenied ? (
        <ReviewTemplate
          data={oneReviewData}
          isNewReviews={isNewReviews}
          blurFromTextareaName={blurFromTextareaName}
          selectedFilialAccId={selectedFilial?.accId || ''}
          className='px-4'
        />
      ) : null}
    </>
  );
});

ReviewsPage.displayName = 'ReviewsPage';

export default ReviewsPage;
