import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
//
import { appReducer } from '@redux/app/slice';
import { payReducer } from '@redux/pay/paySlice';
import { authReducer } from '@redux/auth/authSlice';
import { emojiReducer } from '@redux/emoji/emojiSlice';
import { reviewsReducer } from '@redux/reviews/reviewsSlice';
import { mailingReducer } from '@redux/mailing/mailingSlice';
import { accessesReducer } from '@redux/access/accessesSlice';
import { settingsReducer } from '@redux/settings/settingsSlice';
import { accountsReducer } from '@redux/accounts/accountsSlice';
import { addLeadsReducer } from '@redux/addLeads/addLeadsSlice';
import { feedbackReducer } from '@redux/feedback/feedbackSlice';
import { templateReducer } from '@redux/template/templateSlice';
import { blackListReducer } from '@redux/blackList/blackListSlice';
import { yclientsSliceReducer } from '@redux/yclients/yclientsSlice';
import { statisticsReducer } from '@redux/statistics/statisticsSlice';
import { connectionReducer } from '@redux/connection/connectionSlice';
import { mailingTableReducer } from '@redux/mailing/mailingTableSlice';
import { chatHistoryReducer } from '@redux/chatHistory/chatHistorySlice';
import { autoResponseReducer } from '@redux/autoResponse/autoResponseSlice';
import { autoConnectionReducer } from '@redux/autoConnect/autoConnectSlice';
import { templateListReducer } from '@redux/templateList/templateListSlice';
import { connectionTableReducer } from '@redux/connection/connetionTableSlice';
import { templatesStatisticsReducer } from '@redux/templateStats/templateStatsSlice';
import { inviteFriendActionReducer } from '@redux/inviteFriendsAction/inviteFriendSlice';
import { partnerFullTableReducer } from '@redux/partnersFullTable/partnerFullTableSlice';
import { inviteFriendListSliceReducer } from '@redux/inviteFriendList/inviteFriendListSlice';
import { operationListTableReducer } from '@redux/operationListTable/operationListTableSlice';
import { consultantFullTableReducer } from '@redux/consultantFullTable/consultantFullTableSlice';
import { payHistorySalonTableReducer } from '@redux/payHistorySalonTable/payHistorySalonTableSlice';
import { sendOutStatisticsTableReducer } from '@redux/sendOutStatisticsTable/sendOutStatisticsTableSlice';
import { initialFriendsStatisticsReducer } from '@redux/inviteFrendsStatistics/inviteFriendsStatisticsSlice';
import { payHistoryConsultantTableReducer } from '@redux/payHistoryConsultantTable/payHistoryConsultantTableSlice';

const RootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  accounts: accountsReducer,
  templateList: templateListReducer,
  template: templateReducer,
  templateStatistics: templatesStatisticsReducer,
  connection: connectionReducer,
  reviews: reviewsReducer,
  mailing: mailingReducer,
  blackList: blackListReducer,
  accesses: accessesReducer,
  settings: settingsReducer,
  pay: payReducer,
  connectionTable: connectionTableReducer,
  statistics: statisticsReducer,
  mailingTable: mailingTableReducer,
  emoji: emojiReducer,
  partnerFullTable: partnerFullTableReducer,
  consultantFullTable: consultantFullTableReducer,
  operationListTable: operationListTableReducer,
  payHistorySalonTable: payHistorySalonTableReducer,
  payHistoryConsultantTable: payHistoryConsultantTableReducer,
  feedback: feedbackReducer,
  chatHistory: chatHistoryReducer,
  sendOutStatisticsTable: sendOutStatisticsTableReducer,
  addLeadSlice: addLeadsReducer,
  autoResponse: autoResponseReducer,
  autoConnection: autoConnectionReducer,
  inviteFriendsList: inviteFriendListSliceReducer,
  inviteFriendsStatistics: initialFriendsStatisticsReducer,
  inviteFriendsAction: inviteFriendActionReducer,
  yclientsStartPage: yclientsSliceReducer,
});

export const store = configureStore({
  reducer: RootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: true,
        ignoredPaths: [
          'mailing.sendOuts',
          'mailing.mediaFile',
          'mailing.clientFile',
          'template.data.files',
          'mailing.oldSendOuts',
          'wrTemplate.data.files',
          'template.oldData.files',
          'template.data.mediaFile',
          'mailing.mailingStartDate',
          'mailing.sendOutDetailData',
          'template.oldData.mediaFile',
          'mailing.oldMailingStartDate',
          'mailing.dateForGetDetailData',
          'mailing.newSendOutData.endDate',
          'mailing.newSendOutData.startDate',
        ],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
