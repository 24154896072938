import React from 'react';
import { TRejectResponse, TRejectResponse2 } from '@api/types';

export type TIcon = {
  [key: string]: string;
  authenticated: string;
  infoRed: string;
  error: string;
  loading: string;
  gotQrCode: string;
  waiting_yellow: string;
  delete: string;
  phone: string;
  logout: string;
  telegram: string;
  telegramCyan: string;
  statusOn: string;
  statusOff: string;
  info: string;
  pay: string;
  mailing: string;
  settings: string;
  restart: string;
  cleanRed: string;
  stop: string;
  qr: string;
  plus: string;
  telegramIconWhite: string;
  addFile: string;
  play: string;
  deleteFile: string;
  menu: string;
  spinner: string;
  chevron: string;
  errorToast: string;
  successToast: string;
  infoToast: string;
  caretDown: string;
  learning: string;
  notPaid: string;
  paid: string;
  trash: string;
  formatting: string;
  adminBot: string;
  errorInInput: string;
  infoGray: string;
  deleteReview: string;
  edit: string;
  errorRed: string;
  videoInfo: string;
  textInfo: string;
  mailingExample: string;
  tickCircle: string;
  yclients: string;
  excel: string;
  whatsAppGreen: string;
  playIvory: string;
  stepSuccess: string;
  greenChevronLeft: string;
  qrCodeTelegramLink: string;
  iconTooltip: string;
  iconCloseBanner: string;
  download: string;
  downloadWhite: string;
  smallTG: string;
  text: string;
  calendar: string;
  messageTail: string;
};

export type TIconKey = keyof TIcon;

export type TSimpleStringObj = {
  [key: string]: string;
};

export type TSimpleStringObjLevel2 = {
  [key: string]: TSimpleStringObj;
};

export type TRejectResponseData = TRejectResponse | TRejectResponse2;

export type TQRCodeData = {
  ok: boolean;
  qr: string;
};

export type TWithChildren = {
  children: React.ReactNode;
};

export type TWithOptionalChildren = Partial<TWithChildren>;

export type TButtonText = {
  restart: string;
  clean: string;
  stop: string;
  qr: string;
};

export type THelp = {
  // first tooltip
  firstTooltipText: string;
  firstTooltipButtonText: string;
  tooltipTitle: string;
  // autoConnection
  emojiList: string;
  ageOfWhatsappAccount: string;
  // template
  testHelp: string;
  textWhatsapp: string;
  textExamples: string;
  textSms: string;
  selectFileText: string;
  whenSend: string;
  services: string;
  categories: string;
  products: string;
  staff: string;
  clientCategory: string;
  recordStatus: string;
  visits: string;
  visitQuantity: string;
  blackList: string;
  blacklist: string;
  sendOnlyLostClient: string;
  onlineRecord: string;
  confirmRecord: string;
  textConfirmation: string;
  textCancel: string;
  clientDidNotCome: string;
  clientExpectation: string;
  clientCame: string;
  clientConfirmed: string;
  recordTag: string;
  statisticSegment: string;
  statisticEarnOneMessage: string;
  // _______________________
  // reviews
  whatsapp: string;
  badReviews: string;
  messageStep2_4: string;
  staffs: string;
  channels: string;
  tgAlerts: string;
  positive: string;
  negative: string;
  detailFilter: string;
  // autoResponse
  responseToClient: string;
  // settings
  exceptionMastersNames: string;
  phonesDontShowInBot: string;
  smsProvider: string;
  disableChat: string;
  replyMsgCheck: string;
  savePhonesYcl: string;
  enableReplyInBot: string;
  sendContact: string;
  smsConfirm: string;
  billData: string;
  sendTime: string;
  // _______________________
  // pay
  tariff: string;
  promoCode: string;
  yourTariff: string;
  // ______________________
  // connection
  messageQuery: string;
  // ______________________
  // mailing
  text: string;
  mailingText: string;
  dataFile: string;
  templateHelp: string;
  ownerMailingText: string;
  startMailing: string;
  // feedback
  feedback: string;
  feedbackAddMediaFile: string;
  // templateStat
  templateStatsChart: string;
  // inviteFriends
  earnedFromNewClients: string;
  // inviteFriendsTemplate
  faq: string;
  templateTitle: string;
  firstMessage: string;
  secondMessage: string;
  cardType: string;
  bonusType: string;
  inviteFriendsWhenSend: string;
  inviteFriends: string;
  newCustomerPoints: string;
  interestRegularCustomer: string;
  serviceIF: string;
  categoriesIF: string;
  staffIF: string;
  clientCategoryIF: string;
  visitsIF: string;
  blacklistIF: string;
  // inviteFriendsStats
  childSignupRatio: string;
  childPaymentRatio: string;
};

export type THelpKeys = keyof THelp;

export enum CONNECTION_STATUSES {
  AUTHENTICATED = 'authenticated',
  BLOCKED = 'blocked',
  GOT_QR_CODE = 'gotQrCode',
  LOADING = 'loading',
  GOT_QR_CODE_BACK_STATUS = 'got qr code',
}

export type TConnectStatuses =
  | CONNECTION_STATUSES.AUTHENTICATED
  | CONNECTION_STATUSES.BLOCKED
  | CONNECTION_STATUSES.GOT_QR_CODE
  | CONNECTION_STATUSES.LOADING
  | CONNECTION_STATUSES.GOT_QR_CODE_BACK_STATUS;

export const CONNECTION_STATUS = {
  OK: CONNECTION_STATUSES.AUTHENTICATED,
  NO_CONNECT: CONNECTION_STATUSES.GOT_QR_CODE,
  ERROR: CONNECTION_STATUSES.BLOCKED,
  WAITING: CONNECTION_STATUSES.LOADING,
};

export type TGetNewRate = (event: React.ChangeEvent<HTMLInputElement>, rate: string[]) => string[];

export type TUploadedFileType = 'image' | 'video' | 'doc' | 'unknown';

export type TGetMediaFileType = (file: File) => TUploadedFileType;

export type TGetLeftOffset = (clientX: number) => number;

export type TGetNewObj = <T>(obj: T) => T;

export type TNumWord = (value: number, words: string[]) => string;

export type TGetDateUSFormat = (date: string) => number;

export type TCompareDate = (v1: string, v2: string) => number;

export type TSort = <T>(
  unsortedData: Array<T>,
  fieldName: keyof T,
  sortIndex: string,
  sliceStartIndex?: number,
  isTextPeriod?: boolean,
) => T[];

export type TMediaFileType = TUploadedFileType | null;

export type TMappedTableData = {
  version: number;
  date: React.ReactNode;
  text: React.ReactNode;
  sendTime: string;
  sended: React.ReactNode;
  answered: React.ReactNode;
  recorded: React.ReactNode;
  earned: React.ReactNode;
};

export type TFeedbackData = {
  text: string;
  topic: string;
  filialName: string;
};

export type TCheckInstanceStatus = { checkStatus: boolean };

export type TFileData = {
  fileName: string;
  fileType: string;
  fileSize: number;
  downloadLink: string;
};

export type TSendOutDetailsStatisticsData = {
  phone: string;
  name: string;
  recordTime: string;
  replyTime: string;
  service: string;
  summaryCost: number | null;
};

export enum SEND_OUT_DETAIL_STATISTICS_KEY_NAMES {
  PHONE = 'phone',
  NAME = 'name',
  RECORD_TIME = 'recordTime',
  REPLY_TIME = 'replyTime',
  SEND_TIME = 'sendTime',
  SERVICE = 'service',
  SUMMARY_COST = 'summaryCost',
}

export enum FILE_TYPES {
  IMAGE = 'image',
  VIDEO = 'video',
  UNKNOWN = 'unknown',
  DOC = 'doc',
}

export enum EVENT_TYPE_NAMES {
  BLUR = 'blur',
  KEYUP = 'keyup',
}

export enum EVENT_KEY_NAMES {
  ENTER = 'Enter',
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  ARROW_UP = 'ArrowUp',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_DOWN = 'ArrowDown',
  ARROW_LEFT = 'ArrowLeft',
}

export enum INPUT_NAMES {
  QUANTITY_FROM = 'quantityFrom',
  QUANTITY_TO = 'quantityTo',
}

export type TAppLanguage = 'eng' | 'rus';
