import { TRejectResponseData } from '@models/index';
import { dateSort, simpleSort } from '@helpers/index';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REJECT_RESPONSE_KEY, REJECT_RESPONSE_2_KEY } from '@api/types';
import { IInitialState, initialState } from '@redux/partnersFullTable/initialState';
import { getPartnerFullTableData } from '@redux/partnersFullTable/partnerFullTableThunks';

import {
  TPartnersFullTableData,
  TPartnersFullTableStoreData,
  PARTNERS_FULL_TABLE_KEY_NAMES,
} from './models';

const partnerFullTableSlice = createSlice({
  name: 'partnerFullTableSlice',
  initialState,
  reducers: {
    setTableDataPFT(state: IInitialState, action: PayloadAction<TPartnersFullTableData[]>) {
      state.data = action.payload;
    },
    setSortTypeDataPFT(
      state: IInitialState,
      action: PayloadAction<{ collumName: string; order: string }>,
    ) {
      const { collumName, order } = action.payload;

      const key = collumName as unknown as keyof TPartnersFullTableData;

      if (
        collumName === PARTNERS_FULL_TABLE_KEY_NAMES.CONNECT_DATE ||
        collumName === PARTNERS_FULL_TABLE_KEY_NAMES.FIRST_PAY_DATE
      ) {
        state.data = dateSort<TPartnersFullTableData>(state.data, key, order);
      } else {
        state.data = simpleSort<TPartnersFullTableData>(state.data, key, order);
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getPartnerFullTableData.pending, (state: IInitialState) => {
      state.isError = false;
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(
      getPartnerFullTableData.fulfilled,
      (state: IInitialState, action: PayloadAction<TPartnersFullTableStoreData | void>) => {
        if (action.payload) {
          const { data, qrCode, promoUrl, promoCode } = action.payload;
          state.data = data;
          state.qrCode = qrCode;
          state.promoUrl = promoUrl;
          state.promoCode = promoCode;
        }
        state.isSuccess = true;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getPartnerFullTableData.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isLoading = false;
            } else {
              return;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
          }
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      },
    );
  },
});

export const partnerFullTableReducer = partnerFullTableSlice.reducer;
export const { setTableDataPFT, setSortTypeDataPFT } = partnerFullTableSlice.actions;
