import { createAsyncThunk } from '@reduxjs/toolkit';
//
import { settingsApi } from '@api/settingsApi';
import { templateApi } from '@api/templateApi';
import { TRejectResponseData } from '@models/index';
import { TSettingsData } from '@redux/settings/models';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { getDataToPostSettings, mapResponseToState } from '@redux/settings/settingsMappers';
import {
  TStaffListItem,
  REJECT_RESPONSE_KEY,
  SETTINGS_RESPONSE_KEYS,
  T_STAFF_LIST_RESPONSE_KEYS,
} from '@api/types';

/**
 * Thunk-экшн для получения данных настроек.
 *
 * @returns {TSettingsData|void} - Данные настроек или void.
 * @throws {TRejectResponseData} - Объект с данными об ошибке.
 */
const getSettings = createAsyncThunk<
  TSettingsData | void,
  void,
  { rejectValue: TRejectResponseData }
>('settings/getSettingsData', (data, { dispatch, rejectWithValue }) => {
  return settingsApi
    .getSettings()
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (SETTINGS_RESPONSE_KEYS.BILL_DATA in response.data) {
        return mapResponseToState(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getSettings()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшн для отправки настроек на сервер.
 *
 * @param {TSettingsData} data - Данные настроек для отправки.
 * @returns {TSettingsData|void} - Данные настроек или void в случае ошибки.
 * @throws {TRejectResponseData} - Объект с данными об ошибке.
 */
const postSettings = createAsyncThunk<
  TSettingsData | void,
  { data: TSettingsData; phonesDontShowInBotNewValue: string },
  { rejectValue: TRejectResponseData }
>('settings/postSettings', (data, { dispatch, rejectWithValue }) => {
  const postData = getDataToPostSettings({
    ...data.data,
    phonesDontShowInBotNewValue: data.phonesDontShowInBotNewValue,
  });
  return settingsApi
    .postSettings(postData)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (SETTINGS_RESPONSE_KEYS.BILL_DATA in response.data) {
        return mapResponseToState(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(postSettings(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшн для получения списка категорий сотрудников.
 *
 * @param {Object} data - Объект с данными запроса.
 * @param {string} data.categoryName - Название категории.
 * @returns {{ data: TStaffListItem[]; categoryName: string }|void} - Данные списка категорий или void в случае ошибки.
 * @throws {TRejectResponseData} - Объект с данными об ошибке.
 */
const getListOfCategoriesSet = createAsyncThunk<
  { data: TStaffListItem[]; categoryName: string } | void,
  { categoryName: string },
  { rejectValue: TRejectResponseData }
>('templateSlice/getListOfCategories', (data, { dispatch, rejectWithValue }) => {
  return templateApi
    .getCategoryList(data.categoryName)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (T_STAFF_LIST_RESPONSE_KEYS.COUNT in response.data) {
        return { data: response.data.data, categoryName: data.categoryName };
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getListOfCategoriesSet(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

export { getSettings, postSettings, getListOfCategoriesSet };
