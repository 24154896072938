import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { COMMON_TOAST } from '@const/common';
import { TRejectResponseData } from '@models/index';
import { dateSort, simpleSort } from '@helpers/index';
import { PAY_PAGE_SUCCESS_TOAST_MESSAGES } from '@const/pay';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { IInitialState, initialState } from '@redux/pay/initialState';
import { REJECT_RESPONSE_KEY, REJECT_RESPONSE_2_KEY } from '@api/types';
import { TCreateInvoiceResponse, TPayData, TPaymentData } from '@redux/pay/models';
import {
  changeCartData,
  getPayPageData,
  sendInvoiceToTg,
  createInvoiceThunk,
  getAutoPayLinkData,
  turnOffAutoPayment,
} from '@redux/pay/payThunks';

export const paySlice = createSlice({
  name: 'pay',
  initialState,
  reducers: {
    setIsAccessOnPayPageDenied(state: IInitialState) {
      state.isAccessOnPayPageDenied = true;
    },
    showConnectAutoPayModal(state: IInitialState) {
      state.isConnectAutoPayModalOpen = true;
    },
    hideConnectAutoPayModal(state: IInitialState) {
      state.isConnectAutoPayModalOpen = false;
    },
    showChangeCardModal(state: IInitialState) {
      state.isChangeModalOpen = true;
    },
    hideChangeCardModal(state: IInitialState) {
      state.isChangeModalOpen = false;
    },
    showAutoPayDisableModal(state: IInitialState) {
      state.isAutoPayDisableModalOpen = true;
    },
    hideAutoPayDisableModal(state: IInitialState) {
      state.isAutoPayDisableModalOpen = false;
    },
    showGetBillModal(state: IInitialState) {
      state.isBillDownloadModalOpen = true;
    },
    hideGetBillModal(state: IInitialState) {
      state.invoiceData = null;
      state.isBillDownloadModalOpen = false;
    },
    setSelectedCardNumber(
      state: IInitialState,
      action: PayloadAction<{ totalAmount: number; cardNumber: number; monthAmount: number }>,
    ) {
      const { cardNumber, totalAmount, monthAmount } = action.payload;
      state.selectedCardNumber = cardNumber;
      state.selectedCardTotalAmount = totalAmount;
      state.selectedCardMonthAmount = monthAmount;
    },
    setSortTypeDataPP(
      state: IInitialState,
      action: PayloadAction<{ collumName: string; order: string }>,
    ) {
      const { collumName, order } = action.payload;
      const key = collumName as unknown as keyof TPaymentData;

      if (collumName === 'date' || collumName === 'period') {
        state.filteredData = dateSort<TPaymentData>(state.filteredData, key, order);
      } else {
        state.filteredData = simpleSort<TPaymentData>(state.filteredData, key, order);
      }
    },
    setPromoCode(state: IInitialState, action: PayloadAction<string>) {
      state.promoCode = action.payload;
    },
    clearStatuses(state: IInitialState) {
      state.isFetchingAutoPayLink = false;
      state.isFetchingAutoPayLinkError = false;
      state.isFetchingAutoPayLinkSuccess = false;
      state.isFetchingInvoiceLink = false;
      state.isFetchingInvoiceLinkError = false;
      state.isSendingInvoiceToTgError = false;
      state.isSendingInvoiceToTg = false;
      state.errorMessage = '';
      state.successMessage = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(getPayPageData.pending, (state: IInitialState) => {
      state.isLoading = true;
      state.isError = false;
      state.autoPayLink = '';
      state.isFetchingAutoPayLink = false;
      state.isFetchingAutoPayLinkError = false;
      state.isFetchingAutoPayLinkSuccess = false;
      state.isFetchingInvoiceLink = false;
      state.isFetchingInvoiceLinkError = false;
      state.isSendingInvoiceToTgError = false;
      state.isSendingInvoiceToTg = false;
      state.changeCardDataLink = '';
      state.changeCardIsLoading = false;
      state.changeCardIsError = false;
      state.changeCardIsSuccess = false;
      state.turnOffAutoPaymentIsLoading = false;
      state.turnOffAutoPaymentIsError = false;
      state.turnOffAutoPaymentIsSuccess = false;
      state.isAccessOnPayPageDenied = false;
      state.isChangeModalOpen = false;
      state.isConnectAutoPayModalOpen = false;
      state.isAutoPayDisableModalOpen = false;
    });
    builder.addCase(
      getPayPageData.fulfilled,
      (state: IInitialState, action: PayloadAction<TPayData | void>) => {
        if (action.payload) {
          state.data = action.payload;
          state.filteredData = action.payload.paymentData;
        }
        if (state.isRetries) {
          state.retries = 1;
          state.isRetries = false;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(
      getPayPageData.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessOnPayPageDenied = true;
            } else {
              state.isError = true;
            }
            state.isLoading = false;
          }
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      },
    );
    builder.addCase(getAutoPayLinkData.pending, (state: IInitialState) => {
      state.isFetchingAutoPayLink = true;
      state.isFetchingAutoPayLinkError = false;
      state.isFetchingAutoPayLinkSuccess = false;
    });
    builder.addCase(
      getAutoPayLinkData.fulfilled,
      (state: IInitialState, action: PayloadAction<string | void>) => {
        if (action.payload) {
          state.autoPayLink = action.payload;
          state.isConnectAutoPayModalOpen = true;
          state.isFetchingAutoPayLinkSuccess = true;
        }
        state.isFetchingAutoPayLink = false;
      },
    );
    builder.addCase(
      getAutoPayLinkData.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessOnPayPageDenied = true;
          } else {
            state.isFetchingAutoPayLinkError = true;
          }
          state.isFetchingAutoPayLink = false;
        } else if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isFetchingAutoPayLink = false;
          } else {
            state.isFetchingAutoPayLink = false;
            state.isFetchingAutoPayLinkError = true;
          }
        } else {
          state.isFetchingAutoPayLink = false;
          state.isFetchingAutoPayLinkError = true;
        }
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
    builder.addCase(changeCartData.pending, (state: IInitialState) => {
      state.changeCardIsLoading = true;
      state.changeCardIsSuccess = false;
      state.changeCardIsError = false;
    });
    builder.addCase(
      changeCartData.fulfilled,
      (state: IInitialState, action: PayloadAction<string | void>) => {
        if (action.payload) {
          state.isChangeModalOpen = true;
          state.changeCardIsSuccess = true;
          state.changeCardDataLink = action.payload;
        }
        state.changeCardIsLoading = false;
      },
    );
    builder.addCase(
      changeCartData.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessOnPayPageDenied = true;
          } else {
            state.changeCardIsError = true;
          }
          state.changeCardIsLoading = false;
        } else if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.changeCardIsLoading = false;
          } else {
            state.changeCardIsLoading = false;
            state.changeCardIsError = true;
          }
        } else {
          state.changeCardIsLoading = false;
          state.changeCardIsError = true;
        }
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
    builder.addCase(turnOffAutoPayment.pending, (state: IInitialState) => {
      state.turnOffAutoPaymentIsError = false;
      state.turnOffAutoPaymentIsLoading = true;
      state.turnOffAutoPaymentIsSuccess = false;
    });
    builder.addCase(turnOffAutoPayment.fulfilled, (state: IInitialState) => {
      state.turnOffAutoPaymentIsSuccess = true;
      state.turnOffAutoPaymentIsLoading = false;
    });
    builder.addCase(
      turnOffAutoPayment.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessOnPayPageDenied = true;
          } else {
            state.turnOffAutoPaymentIsError = true;
          }
          state.turnOffAutoPaymentIsLoading = false;
        } else if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.changeCardIsLoading = false;
          } else {
            state.turnOffAutoPaymentIsLoading = false;
            state.turnOffAutoPaymentIsError = true;
          }
        } else {
          state.turnOffAutoPaymentIsLoading = false;
          state.turnOffAutoPaymentIsError = true;
        }
        state.isAutoPayDisableModalOpen = false;
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
    builder.addCase(createInvoiceThunk.pending, (state: IInitialState) => {
      state.isFetchingInvoiceLink = true;
      state.isFetchingInvoiceLinkError = false;
    });
    builder.addCase(
      createInvoiceThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<TCreateInvoiceResponse | void>) => {
        if (action.payload) {
          state.invoiceData = action.payload;
          state.isBillDownloadModalOpen = true;
        }
        state.isFetchingInvoiceLink = false;
      },
    );
    builder.addCase(
      createInvoiceThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessOnPayPageDenied = true;
          } else {
            state.isFetchingInvoiceLinkError = true;
          }
          state.isFetchingInvoiceLink = false;
        } else if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isFetchingInvoiceLink = false;
          } else {
            state.isFetchingInvoiceLink = false;
            state.isFetchingInvoiceLinkError = true;
          }
        } else {
          state.isFetchingInvoiceLink = false;
          state.isFetchingInvoiceLinkError = true;
        }
        state.isFetchingInvoiceLink = false;
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
    builder.addCase(sendInvoiceToTg.pending, (state: IInitialState) => {
      state.isSendingInvoiceToTg = true;
      state.isSendingInvoiceToTgError = false;
    });
    builder.addCase(sendInvoiceToTg.fulfilled, (state: IInitialState) => {
      state.isSendingInvoiceToTg = false;
      state.successMessage = PAY_PAGE_SUCCESS_TOAST_MESSAGES.SEND_INVOICE_IN_TG;
    });
    builder.addCase(
      sendInvoiceToTg.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessOnPayPageDenied = true;
          } else {
            state.isSendingInvoiceToTgError = true;
          }
          state.turnOffAutoPaymentIsLoading = false;
        } else if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isSendingInvoiceToTg = false;
          } else {
            state.isSendingInvoiceToTg = false;
            state.isSendingInvoiceToTgError = true;
          }
        } else {
          state.isSendingInvoiceToTg = false;
          state.isSendingInvoiceToTgError = true;
        }
        state.isSendingInvoiceToTg = false;
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
  },
});

export const payReducer = paySlice.reducer;
export const {
  setPromoCode,
  clearStatuses,
  hideGetBillModal,
  showGetBillModal,
  setSortTypeDataPP,
  showChangeCardModal,
  hideChangeCardModal,
  setSelectedCardNumber,
  showConnectAutoPayModal,
  hideConnectAutoPayModal,
  showAutoPayDisableModal,
  hideAutoPayDisableModal,
  setIsAccessOnPayPageDenied,
} = paySlice.actions;
