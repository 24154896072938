import { createAsyncThunk } from '@reduxjs/toolkit';
import { connectionApi } from '@api/connectionApi';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { qrMapper } from '@redux/connection/connectionMappers';
import { setSelectedFilialInstanceStatus } from '@redux/accounts/accountsSlice';
import { CONNECTION_STATUSES, TQRCodeData, TRejectResponseData } from '@models/index';
import {
  REJECT_RESPONSE_KEY,
  CONNECTION_ACTION_RESULTS,
  TGetConnectionStatusResponse,
  GET_QR_CODE_RESPONSE_KEY_NAMES,
  GET_CONNECTION_STATUS_KEY_NAMES,
} from '@api/types';

/**
 * Thunk для получения статуса подключения.
 *
 * @param {void} data - Данные запроса (не используются).
 * @returns {TGetConnectionStatusResponse | void} - Данные статуса подключения или undefined.
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const getConnectionPageStatus = createAsyncThunk<
  TGetConnectionStatusResponse | void,
  void,
  { rejectValue: TRejectResponseData }
>('connection/getConnectionPageStatus', (data, { dispatch, rejectWithValue }) => {
  return connectionApi
    .getConnectionStatus()
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        dispatch(setSelectedFilialInstanceStatus(CONNECTION_STATUSES.BLOCKED));
        throw new Error(String(response.data));
      }

      if (GET_CONNECTION_STATUS_KEY_NAMES.MESSAGES in response.data) {
        dispatch(setSelectedFilialInstanceStatus(response.data.status));
        return response.data;
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getConnectionPageStatus()), 200);
      }
      dispatch(setSelectedFilialInstanceStatus(CONNECTION_STATUSES.BLOCKED));
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk для получения данных QR-кода.
 *
 * @param {void} data - Данные запроса (не используются).
 * @returns {TQRCodeData | void} - Данные QR-кода или undefined.
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const getQrData = createAsyncThunk<TQRCodeData | void, void, { rejectValue: TRejectResponseData }>(
  'connection/getQrData',
  (data, { dispatch, rejectWithValue }) => {
    return connectionApi
      .getQRCodeString()
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data));
        }

        if (
          GET_QR_CODE_RESPONSE_KEY_NAMES.RESULT in response.data &&
          response.data.result !== CONNECTION_ACTION_RESULTS.QR_CREATE
        ) {
          throw new Error(String(response.data));
        }

        if (GET_QR_CODE_RESPONSE_KEY_NAMES.QR_CODE in response.data) {
          return qrMapper(response.data);
        }
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(getQrData()), 200);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

/**
 * Thunk для очистки сообщений.
 *
 * @param {void} data - Данные запроса (не используются).
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const clearMessages = createAsyncThunk<void, void, { rejectValue: TRejectResponseData }>(
  'connection/clearMessages',
  (data, { dispatch, rejectWithValue }) => {
    return connectionApi
      .clearQuery()
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data));
        }
        if (
          GET_QR_CODE_RESPONSE_KEY_NAMES.RESULT in response.data &&
          response.data.result !== CONNECTION_ACTION_RESULTS.CLEAR_MESSAGE_Q
        ) {
          throw new Error(String(response.data));
        }
        dispatch(getConnectionPageStatus());
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(clearMessages()), 200);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

/**
 * СThunk для остановки бота.
 *
 * @param {void} data - Данные запроса (не используются).
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const stopBot = createAsyncThunk<void, void, { rejectValue: TRejectResponseData }>(
  'connection/stopBot',
  (data, { dispatch, rejectWithValue }) => {
    return connectionApi
      .logOutInstance()
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data.ok));
        }
        if (
          GET_QR_CODE_RESPONSE_KEY_NAMES.RESULT in response.data &&
          response.data.result !== CONNECTION_ACTION_RESULTS.LOGOUT
        ) {
          throw new Error(String(response.data));
        }
        dispatch(getConnectionPageStatus());
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(stopBot()), 200);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

/**
 * Thunk для перезапуска бота.
 *
 * @param {void} data - Данные запроса (не используются).
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const restartBot = createAsyncThunk<void, void, { rejectValue: TRejectResponseData }>(
  'connection/restartBot',
  (data, { dispatch, rejectWithValue }) => {
    return connectionApi
      .reloadBot()
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data.ok));
        }
        if (
          GET_QR_CODE_RESPONSE_KEY_NAMES.RESULT in response.data &&
          response.data.result !== CONNECTION_ACTION_RESULTS.REBOOT
        ) {
          throw new Error(String(response.data));
        }
        dispatch(getConnectionPageStatus());
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(restartBot()), 200);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

export { getConnectionPageStatus, getQrData, clearMessages, stopBot, restartBot };
