import { TMediaFileType } from '@models/index';
import { TActiveSendOutsStatuses } from '@api/types';

export type TMailingTableData = {
  id: number;
  date: string;
  time: string;
  text: string;
  process: string;
  status: TActiveSendOutsStatuses;
  do: string;
};

export type TMailingPageText = {
  mailingTextInfo: string;
  mailingFileInfo: string;
};

export type TMailingPageData = {
  text: string;
  error: boolean;
  mediaFileType: TMediaFileType;
  mediaFileName: string;
  mediaFile: FormData;
  clientFile: FormData;
  clientFileName: string;
  mediaFileURL: string | null;
  isLoading: boolean;
  showParsedInfo: boolean;
  xlsxFileInfoMessages: string[];
  messageSend: boolean;
};

export enum MAILING_PAGE_DATA_KEY_NAME {
  TEXT = 'text',
  MEDIA_FILE = 'mediaFile',
  CLIENT_FILE = 'clientFile',
}

export type TCustomerKeys = Record<string, string>;

export type TParsedXlsxFileData = {
  count: number;
  sendDays: number;
  duration: number;
  sendOutBatchLen: number;
  visitsMedian: number;
  lastVisitTimeMedian: number;
  allowedStart: string;
  immediateStart: boolean;
  instanceId: string;
  timeToMsk: number;
  keys: TCustomerKeys;
};

export type TCreateParsedFileInfo = (fileData: TParsedXlsxFileData | undefined) => Array<string>;

export type TGetParsedXlsxFileInfo = (
  data: TParsedXlsxFileData,
  appLanguage?: 'eng' | 'rus',
) => {
  message1: string;
  message2: string;
  message3: string;
  message4: string;
  message5: string;
  message6: string;
};

export type TMailingTableResponse = {
  totalCount: number;
  data: TMailingTableData[];
};

export type TMailingDrawerTabNames = 'text' | 'images';

export enum MAILING_DRAWER_TAB_NAMES {
  TEXT = 'text',
  IMAGES = 'images',
}

export type TMailingInstruction = { name: string; iconName: string; href: string };

export type TMailingInstructions = {
  video: TMailingInstruction;
  text: TMailingInstruction;
  examples: TMailingInstruction;
};

export type TCalendarDays = {
  date: Date;
  day: string;
  dayNumber: number;
  dayNumberInWeek: number;
  dayShort: string;
  month: string;
  monthIndex: number;
  monthNumber: number;
  monthShort: string;
  timestamp: number;
  week: number;
  year: number;
  yearShort: string;
};

export type TSendOutElement = {
  isHead: boolean;
  isTail: boolean;
  isNewSendOut: boolean;
  isActive: boolean;
  sendOutNumber: string;
  remainder: number;
  widthInPercents: number;
  endDate: Date;
};

export type TSendOutsData = {
  isNew: boolean;
  sendOutId: string;
  startDate: Date | null;
  endDate: Date | null;
  duration: number;
  isActiveSendOut: boolean;
  totalCount: number;
  daySendOutLimit: number;
  remainder: number;
};

export type TSendOutsDetailData = {
  sendOutId: string;
  startDate: Date | null;
  totalCount: number;
  visitsMedian: number;
  lastVisitTimeMedian: number;
  sendOutText: string;
};
