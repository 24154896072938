import React, { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { PATH_NAMES } from '@data/dict';
import { Icon } from '@blueprintjs/core';
import { QUERY_PARAMS } from '@const/apiConstants';
import { useCopyToClipboard } from '@hooks/useClipboard';
import { TStatisticsData } from '@redux/statistics/models';
import { ReactComponent as Segment } from '@img/mailing/segment.svg';
import { ReactComponent as Conversion } from '@img/mailing/conversion.svg';
import { getConversionColor } from '@helpers/index';
import { ConversionColors } from '@const/common';

type TMailingStatisticsCardProps = {
  /**
   * Данные для карточки
   * @param {TStatisticsData}
   */
  data: TStatisticsData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const MailingStatisticsCard = memo(
  ({ data, className = '' }: TMailingStatisticsCardProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [, copy] = useCopyToClipboard();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const {
      id,
      sum,
      date,
      text,
      time,
      visits,
      signUp,
      answers,
      sumOneMsg,
      lastVisitDays,
      sendoutStopFlag,
      conversionSignUp,
      conversionAnswers,
      dateFinishSendout,
      timeFinishSendout,
    } = data;

    // Обрабатывает копирование текста в буфер обмена.
    const copyToClipboardHandler = (textToCopy: string) => () => {
      copy(textToCopy)
        .then(() => {
          setIsSuccess(true);
          setTimeout(() => setIsSuccess(false), 2000);
        })
        .catch(() => setIsSuccess(false));
    };

    // Переход на страницу подробной статистики.
    const onClickNavigate = () => {
      navigate(`${PATH_NAMES.MAILING_DETAIL_STATS}?${QUERY_PARAMS.SENDOUT_ID}=${id}`);
    };

    const rub = Intl.NumberFormat('ru-RU', {
      currency: 'RUB',
      useGrouping: true,
    });

    const grayTextClass = 'font-medium text-stormGray text-inner_text tracking-[0.018em]';
    const statLabelClass = 'font-medium text-[1.25rem] leading-8 tracking-[0.004em]';
    const conversionValueClass = 'text-[1.25rem] leading-8 tracking-[0.0075em] mb-0.5';

    const isShowFinishDate = dateFinishSendout && timeFinishSendout;

    const answerConversionColor = getConversionColor(conversionAnswers, ConversionColors);
    const signupConversionColor = getConversionColor(conversionSignUp, ConversionColors);

    return (
      <div className={className}>
        <div className='bg-white border border-quartz rounded-lg px-4 pt-4 pb-6'>
          <div className='flex flex-col sm:flex-row sm:items-center justify-between mb-6'>
            <div className='flex flex-col flex-wrap mb-4 sm:mb-0 sm:flex-row sm:items-center'>
              <span className='font-medium text-blackText text-[1.25rem] leading-8 tracking-[0.004em] mr-6'>
                №{id}
              </span>
              <div className='flex flex-wrap'>
                <span className='text-darkGray leading-6 tracking-[0.0275em] mr-3'>
                  {t('MAILING_PAGE_TEXT.statisticsTable.started', { date, time })}
                </span>
                {isShowFinishDate ? (
                  <span className='text-darkGray leading-6 tracking-[0.0275em]'>
                    <span className='pr-3'>|</span>
                    {sendoutStopFlag
                      ? t('MAILING_PAGE_TEXT.statisticsTable.stopped', {
                          date: dateFinishSendout,
                          time: timeFinishSendout,
                        })
                      : t('MAILING_PAGE_TEXT.statisticsTable.finished', {
                          date: dateFinishSendout,
                          time: timeFinishSendout,
                        })}
                  </span>
                ) : null}
              </div>
            </div>
            <Button
              dense
              type='action'
              view='outlined'
              color='default'
              icon='timeline-bar-chart'
              onClick={onClickNavigate}
              text={t('MAILING_PAGE_TEXT.statisticsTable.moreDetailsButton')}
            />
          </div>
          <div className='flex flex-wrap justify-between'>
            <div className='flex flex-col w-full lg:max-w-[25.375rem]'>
              <div className='relative bg-gray1 border border-gray1 rounded-[0.75rem] px-4 py-2 overflow-hidden h-[8.5rem] mb-2 mr-3'>
                <span className='block text-black leading-6 tracking-[0.0275em] whitespace-pre-line break-words h-[7.5rem] overflow-y-auto dropdown-list-scrollbar dropdown-list-scrollbar--dark'>
                  {text}
                </span>
              </div>
              <button
                onClick={copyToClipboardHandler(text)}
                className='bg-none border-none cursor-pointer flex items-center px-4 py-2'>
                <Icon
                  size={24}
                  color={isSuccess ? 'green' : '#4A4B57'}
                  icon={isSuccess ? 'tick' : 'duplicate'}
                  className='mr-2'
                />
                <span className='font-medium text-darkGray leading-6 tracking-[0.022em]'>
                  {isSuccess
                    ? t('MAILING_PAGE_TEXT.statisticsTable.textCopied')
                    : t('MAILING_PAGE_TEXT.statisticsTable.copyText')}
                </span>
              </button>
            </div>
            <div className='flex flex-col sm:flex-row grow lg:ml-6 mt-6 lg:mt-0'>
              <div className='flex flex-col grow basis-[33%] mb-6 sm:mb-0'>
                <div className='flex flex-col mb-4'>
                  <span className='font-bold text-[2.125rem] text-blackText leading-[2.75rem] mb-2'>
                    {rub.format(sum)}
                    <span className='text-[2.125rem] text-blackText leading-[2.75rem] tracking-[0.0075em] pl-2'>
                      ₽
                    </span>
                  </span>
                  <span className={grayTextClass}>
                    {t('MAILING_PAGE_TEXT.statisticsTable.sumOfRecords')}
                  </span>
                </div>
                <div className='flex flex-col'>
                  <span className={`${statLabelClass} mb-2`}>
                    {rub.format(sumOneMsg)}
                    <span className='text-[1.25rem] text-grayText leading-8 tracking-[0.0075em] pl-2'>
                      ₽
                    </span>
                  </span>
                  <span className={grayTextClass}>
                    {t('MAILING_PAGE_TEXT.statisticsTable.earningsFromOneSentMessage')}
                  </span>
                </div>
              </div>
              <div className='flex flex-col grow basis-[33%] mb-6 sm:mb-0'>
                <div className='flex items-start mb-2'>
                  <Conversion className='mr-4' />
                  <span className={statLabelClass}>
                    {t('MAILING_PAGE_TEXT.statisticsTable.conversion')}
                  </span>
                </div>
                <div className='flex flex-col mb-4'>
                  <div className='flex items-center'>
                    <span className={`${statLabelClass} text-blackText mr-1 mb-0.5`}>
                      {answers}
                    </span>
                    <span
                      className={`${statLabelClass} text-stormGray mr-1 mb-0.5`}>{`\u00B7`}</span>
                    <span
                      style={{ color: answerConversionColor }}
                      className={`${conversionValueClass}`}>
                      {conversionAnswers}%
                    </span>
                  </div>
                  <span className={grayTextClass}>
                    {t('MAILING_PAGE_TEXT.statisticsTable.answered')}
                  </span>
                </div>
                <div className='flex flex-col'>
                  <div className='flex items-center'>
                    <span className={`${statLabelClass} text-blackText mr-1 mb-0.5`}>{signUp}</span>
                    <span
                      className={`${statLabelClass} text-stormGray mr-1 mb-0.5`}>{`\u00B7`}</span>
                    <span
                      style={{ color: signupConversionColor }}
                      className={`${conversionValueClass}`}>
                      {conversionSignUp}%
                    </span>
                  </div>
                  <span className={grayTextClass}>
                    {t('MAILING_PAGE_TEXT.statisticsTable.signUp')}
                  </span>
                </div>
              </div>
              <div className='flex flex-col grow basis-[33%]'>
                <div className='flex items-start mb-2'>
                  <Segment className='mr-4' />
                  <span className={statLabelClass}>
                    {t('MAILING_PAGE_TEXT.statisticsTable.segment')}
                  </span>
                </div>
                <div className='flex flex-col mb-4'>
                  <span className={`font-medium text-blackText ${statLabelClass} mb-0.5`}>
                    {visits}
                  </span>
                  <span className={grayTextClass}>
                    {t('MAILING_PAGE_TEXT.statisticsTable.numberOfVisits')}
                  </span>
                </div>
                <div className='flex flex-col'>
                  <span className={`font-medium text-blackText ${statLabelClass} mb-0.5`}>
                    {lastVisitDays}
                  </span>
                  <span className={grayTextClass}>
                    {t('MAILING_PAGE_TEXT.statisticsTable.numberOfDaysSinceLastVisit')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
