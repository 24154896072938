import { TRejectResponseData } from '@models/index';
import { dateSort, simpleSort } from '@helpers/index';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REJECT_RESPONSE_KEY, REJECT_RESPONSE_2_KEY } from '@api/types';
import { IInitialState, initialState } from '@redux/consultantFullTable/initialState';
import { getConsultantFullTableData } from '@redux/consultantFullTable/consultantFullTableThunks';
import {
  TConsultantFullTableData,
  TConsultantFullTableStoreData,
  CONSULTANT_FULL_TABLE_DATA_KEY_NAMES,
} from '@redux/consultantFullTable/models';

const consultantFullTableSlice = createSlice({
  name: 'consultantFullTableSlice',
  initialState,
  reducers: {
    setTableDataCFT(state: IInitialState, action: PayloadAction<TConsultantFullTableData[]>) {
      state.data = action.payload;
    },
    setSortTypeDataCFT(
      state: IInitialState,
      action: PayloadAction<{ collumName: string; order: string }>,
    ) {
      const { collumName, order } = action.payload;

      const key = collumName as unknown as keyof TConsultantFullTableData;

      if (collumName === CONSULTANT_FULL_TABLE_DATA_KEY_NAMES.LAST_PAY_DATE) {
        state.data = dateSort<TConsultantFullTableData>(state.data, key, order);
      } else if (collumName === CONSULTANT_FULL_TABLE_DATA_KEY_NAMES.PAY_TILL_DATE) {
        state.data = dateSort<TConsultantFullTableData>(state.data, key, order, 3);
      } else {
        state.data = simpleSort<TConsultantFullTableData>(state.data, key, order);
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getConsultantFullTableData.pending, (state: IInitialState) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = true;
    });
    builder.addCase(
      getConsultantFullTableData.fulfilled,
      (state: IInitialState, action: PayloadAction<TConsultantFullTableStoreData | void>) => {
        if (action.payload) {
          const { data, qrCode, promoUrl, promoCode } = action.payload;
          state.data = data;
          state.qrCode = qrCode;
          state.promoUrl = promoUrl;
          state.promoCode = promoCode;
        }
        state.isSuccess = true;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getConsultantFullTableData.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isLoading = false;
            } else {
              return;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
          }
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      },
    );
  },
});

export const consultantFullTableReducer = consultantFullTableSlice.reducer;
export const { setTableDataCFT, setSortTypeDataCFT } = consultantFullTableSlice.actions;
