import { TSimpleStringObj } from '@models/index';
import {
  reportPreviewDict,
  TEXT_STYLE_SYMBOLS,
  KEY_WRAPPER_SYMBOLS,
  HTML_TEXT_STYLE_TAG,
} from '@const/preview';

export interface IPreviewMaker {
  getPreviewMarkUp: (description: string) => string;
}

export class PreviewMaker implements IPreviewMaker {
  private readonly keyWrapperSymbols;

  private readonly htmlTextStyleTag;

  private readonly reportPreviewDictionary;

  private readonly textStyleKeys;

  private description: string;

  constructor({
    textStileKeys,
    htmlTextStyleTag,
    keyWrapperSymbol,
    reportPreviewDictionary,
  }: {
    textStileKeys: string[];
    htmlTextStyleTag: TSimpleStringObj;
    reportPreviewDictionary: TSimpleStringObj;
    keyWrapperSymbol: typeof KEY_WRAPPER_SYMBOLS;
  }) {
    this.description = '';
    this.textStyleKeys = textStileKeys;
    this.htmlTextStyleTag = htmlTextStyleTag;
    this.keyWrapperSymbols = keyWrapperSymbol;
    this.reportPreviewDictionary = reportPreviewDictionary;
  }

  private getDividedText(
    startIndex: number,
    endIndex: number,
    keyLength?: number,
  ): {
    textBeforeKey: string;
    textInKey: string;
    textAfterKey: string;
  } {
    const textBeforeKey = this.description.slice(0, startIndex);
    const textInKey = this.description.slice(startIndex + (keyLength || 1), endIndex);
    const textAfterKey = this.description.slice(endIndex + (keyLength || 1));
    return {
      textAfterKey,
      textBeforeKey,
      textInKey,
    };
  }

  private replaceStatisticKeyByMockData() {
    let openSymbolIndex = 0;
    let isOpenIndexFound = false;
    let closedSymbolIndex = 0;
    let isCloseIndexFound = false;

    for (let i = 0; i < this.description.length; i++) {
      if (this.description[i] === this.keyWrapperSymbols.OPEN_SYMBOL) {
        openSymbolIndex = i;
        isOpenIndexFound = true;
      }

      if (this.description[i] === this.keyWrapperSymbols.CLOSE_SYMBOL) {
        closedSymbolIndex = i;
        isCloseIndexFound = true;
      }

      if (isOpenIndexFound && isCloseIndexFound) {
        const { textBeforeKey, textInKey, textAfterKey } = this.getDividedText(
          openSymbolIndex,
          closedSymbolIndex,
        );

        const replacedKeyLength = closedSymbolIndex - openSymbolIndex;

        const mockData = this.reportPreviewDictionary[textInKey];

        if (mockData) {
          const mockDataLength = mockData.length;

          const iCorrectValue = mockDataLength - replacedKeyLength;

          this.description = `${textBeforeKey}${mockData}${textAfterKey}`;

          i += iCorrectValue - 2;
        }

        openSymbolIndex = 0;
        isOpenIndexFound = false;
        closedSymbolIndex = 0;
        isCloseIndexFound = false;
      }
    }
  }

  private findAllLinks(): { startIndex: number; endIndex: number }[] {
    const linkRegex = /http[^\s\n]+/g;
    const linkIndexes: { startIndex: number; endIndex: number }[] = [];
    let match;

    // eslint-disable-next-line
    while ((match = linkRegex.exec(this.description)) !== null) {
      linkIndexes.push({ startIndex: match.index, endIndex: match.index + match[0].length });
    }

    return linkIndexes;
  }

  private replaceTextFormattingKeysByHTLMTag() {
    let startIndexOfStyledText = 0;
    let startStyledTextSymbol = '';
    let isStartIndexFound = false;
    let endIndexOfStyledText = 0;
    let endStyledTextSymbol = '';
    let isEndIndexFound = false;

    let linksIndex = this.findAllLinks();

    const clearAllFindSymbol = () => {
      startIndexOfStyledText = 0;
      startStyledTextSymbol = '';
      isStartIndexFound = false;
      endIndexOfStyledText = 0;
      endStyledTextSymbol = '';
      isEndIndexFound = false;
    };

    for (let i = 0; i < this.description.length; i++) {
      const charInLink = linksIndex
        .map(item => {
          const isInRange = i >= item.startIndex && i <= item.endIndex;
          if (isInRange) {
            return true;
          }
          return null;
        })
        .filter(item => !!item);

      if (charInLink.length) {
        // eslint-disable-next-line
        continue;
      }

      let currentSymbol = this.description[i];
      let isCurrentSymbolIsKey = this.textStyleKeys.includes(currentSymbol);

      if (
        (isCurrentSymbolIsKey && !isStartIndexFound) ||
        (isCurrentSymbolIsKey && isStartIndexFound && startStyledTextSymbol !== currentSymbol)
      ) {
        const previousSymbolIndex = i - 1;

        const previousSymbol = this.description[previousSymbolIndex];

        const canIAddStartIndexOfStyledText =
          previousSymbol === ' ' || !previousSymbol || previousSymbol === '\n';

        if (canIAddStartIndexOfStyledText) {
          startStyledTextSymbol = currentSymbol;
          startIndexOfStyledText = i;
          isStartIndexFound = true;

          if (currentSymbol === '`') {
            i += 3;
          } else {
            i++;
          }
        }
      }

      if (this.description[i] === '\n' && isStartIndexFound) {
        startIndexOfStyledText = 0;
        startStyledTextSymbol = '';
        isStartIndexFound = false;
      }

      currentSymbol = this.description[i];
      isCurrentSymbolIsKey = this.textStyleKeys.includes(currentSymbol);

      if (isCurrentSymbolIsKey && !isEndIndexFound) {
        let keyLength = 1;

        if (currentSymbol === '`') {
          keyLength = 3;
        }

        const nextSymbolIndex = i + keyLength;

        const nextSymbol = this.description[nextSymbolIndex];

        const canIAddEndIndexOfStyledText =
          nextSymbol === ' ' || !nextSymbol || nextSymbol === '\n';

        if (canIAddEndIndexOfStyledText) {
          endStyledTextSymbol = currentSymbol;
          endIndexOfStyledText = i;
          isEndIndexFound = true;

          if (currentSymbol === '`') {
            i += 3;
          }
        }
      }

      if (isStartIndexFound && isEndIndexFound && startStyledTextSymbol === endStyledTextSymbol) {
        const keyLength = startStyledTextSymbol === '`' ? 3 : 1;

        const { textBeforeKey, textInKey, textAfterKey } = this.getDividedText(
          startIndexOfStyledText,
          endIndexOfStyledText,
          keyLength,
        );

        const htmlTagText = this.htmlTextStyleTag[startStyledTextSymbol];

        const htmlMarkUp = `<${htmlTagText} class="inline">${textInKey}</${htmlTagText}>`;

        this.description = `${textBeforeKey}${htmlMarkUp}${textAfterKey}`;

        clearAllFindSymbol();

        linksIndex = this.findAllLinks();
      }

      if (isStartIndexFound && isEndIndexFound && startStyledTextSymbol !== endStyledTextSymbol) {
        clearAllFindSymbol();
      }

      if (i === this.description.length) {
        clearAllFindSymbol();
      }
    }
  }

  getPreviewMarkUp(description: string): string {
    this.description = description;
    this.replaceStatisticKeyByMockData();
    this.replaceTextFormattingKeysByHTLMTag();
    return this.description;
  }
}

export const preview = new PreviewMaker({
  keyWrapperSymbol: KEY_WRAPPER_SYMBOLS,
  reportPreviewDictionary: reportPreviewDict,
  textStileKeys: Object.values(TEXT_STYLE_SYMBOLS),
  htmlTextStyleTag: HTML_TEXT_STYLE_TAG,
});

Object.seal<IPreviewMaker>(preview);
