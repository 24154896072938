import { apiService } from '@api/ApiService';
import { ConfigENV } from '@const/httpConst';
import { END_POINTS_URL } from '@const/apiConstants';
import {
  TRequestStatus,
  TGetResponseWithData,
  TStatisticsTableData,
  TTemplateStatisticDataItem,
  TTemplateDetailStatsResponse,
  TStatisticsTableDataQueryParams,
  TTemplatesStatisticsRequestData,
  TTemplatesStatisticsResponseData,
  TStatisticsTableDataCountQueryParams,
  TTemplateStatisticDataResponseByTime,
} from '@api/types';

/**
 * API страницы статистики рассылок
 * @module statisticsApi
 */

export const statisticsApi = {
  /**
   * Метод запрашивает данные о количестве записей в статистики рассылок
   * @method getStatisticDataCount
   * @return {Promise<AxiosResponse<number | TRequestStatus, any>>}
   */
  getStatisticDataCount(queryParams: TStatisticsTableDataCountQueryParams) {
    return apiService.get<number | TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.STATS_SENDOUT_COUNT}`,
      { ...queryParams },
    );
  },
  /**
   * Метод запрашивает данные завершенных рассылок
   * @method getStatisticsData
   * @return {Promise<AxiosResponse<TGetResponseWithData<TStatisticsTableData> | TRequestStatus, any>>}
   */
  getStatisticsData(queryParams: TStatisticsTableDataQueryParams) {
    return apiService.get<TGetResponseWithData<TStatisticsTableData> | TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.STATS_SENDOUT}`,
      { ...queryParams },
    );
  },
  /**
   * Метод запраивет статистику всех шаблонов филиала
   * @method getTemplatesStatisticsData
   * @return {Promise<AxiosResponse<TGetResponseWithData<TTemplatesStatisticsResponseData[]> | TRequestStatus, any>>}
   */
  getTemplatesStatisticsData({ date_from, date_to }: Omit<TTemplatesStatisticsRequestData, 'id'>) {
    return apiService.get<TTemplatesStatisticsResponseData | TRequestStatus>(
      END_POINTS_URL.TEMPLATE_LIST_STATISTICS,
      {
        date_from,
        date_to,
      },
    );
  },

  /**
   * Метод запрашивает статистику конкретного шаблона по версиям
   * @method getTemplateStatisticDataByVersion
   * @param {TTemplatesStatisticsRequestData}
   * @return {Promise<AxiosResponse<TGetResponseWithData<TTemplateStatisticDataItem[]> | TRequestStatus, any>>}
   */
  getTemplateStatisticDataByVersion({ id }: TTemplatesStatisticsRequestData) {
    return apiService.get<TGetResponseWithData<TTemplateStatisticDataItem[]> | TRequestStatus>(
      END_POINTS_URL.TEMPLATE_STAT,
      { templ_id: id },
    );
  },
  /**
   * Метод запрашивает статистику конкретного шаблона по версиям
   * @method getTemplateStatisticDataByTime
   * @param {TTemplatesStatisticsRequestData}
   * @return {Promise<AxiosResponse<TTemplateStatisticDataResponseByTime | TRequestStatus, any>>}
   */
  getTemplateStatisticDataByTime({
    id,
    date_to,
    group_by,
    date_from,
  }: TTemplatesStatisticsRequestData) {
    return apiService.get<TTemplateStatisticDataResponseByTime | TRequestStatus>(
      END_POINTS_URL.TEMPLATE_STAT_BY_TIME,
      { templ_id: id, date_from, date_to, group_by },
    );
  },
  getTemplateDetailStats(id: string) {
    return apiService.get<TTemplateDetailStatsResponse | TRequestStatus>(
      END_POINTS_URL.TEMPLATE_DETAIL_STATS,
      {
        templ_id: id,
      },
    );
  },
  getTemplateDetailStatsExcel(id: string) {
    return apiService.get<string | TRequestStatus>(END_POINTS_URL.TEMPLATE_DETAIL_STATS_EXCEL, {
      templ_id: id,
    });
  },
};
