import { TOperationListTableData } from './models';

export interface IInitialState {
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  sortCollumName: string;
  isAccessDenied: boolean;
  totalAccrued: string;
  data: TOperationListTableData[];
}

export const initialState: IInitialState = {
  isError: false,
  isLoading: false,
  isFetching: false,
  isSuccess: false,
  isAccessDenied: false,
  sortCollumName: '',
  totalAccrued: '',
  data: [],
};
