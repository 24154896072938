import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Menu } from '@uikit/Menu';
import { Callout } from '@uikit/Callout';
import { Dropdown } from '@uikit/Dropdown';
import { TAppLanguage } from '@models/index';
import { useAppDispatch } from '@store/store';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { TOnChangeHandler } from '@shared/types';
import { TextWithInfoIcon } from '@blocks/textWithInfoIcon';
import { SETTINGS_DATA_KEY_NAMES } from '@redux/settings/models';
import { changeSMSProviderName } from '@redux/settings/settingsSlice';
import { SettingInputWithLabel } from '@blocks/settingInputWithLabel';
import { PROVIDER_NAMES, smsOperatorIndexName } from '@const/settings';

interface ISmsOperatorSelectProps {
  /**
   * Пароль
   * @param {string}
   */
  smsPass: string;
  /**
   * Логин
   * @param {string}
   */
  smsLogin: string;
  /**
   * Значение ключа API SMS оператора
   * @param {string}
   */
  smsApikey: string;
  /**
   * Имя провайдера
   * @param {string}
   */
  smsProvider: string;
  /**
   * Параметр показывает инпут с токеном
   * @param {boolean}
   */
  isShowToken: boolean;
  /**
   * Имя отправителя SMS
   * @param {string}
   */
  smsSenderName: string;
  /**
   * Параметр показывает инпут с логином
   * @param {boolean}
   */
  isShowLogin: boolean;
  /**
   * Параметр показывает инпут с паролем
   * @param {boolean}
   */
  isShowPass: boolean;
  /**
   * Параметр обязательности имени отправителя
   * @param {boolean}
   */
  isShowSenderName: boolean;
  /**
   * Параметр показывает callout с дополнительной информацией
   * @param {boolean}
   */
  isShowAdditionalInfo: boolean;
  /**
   * Callback для изменения данных
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const SmsOperatorSelect = memo(
  ({
    smsPass,
    smsLogin,
    smsApikey,
    isShowPass,
    appLanguage,
    isShowLogin,
    isShowToken,
    smsProvider,
    smsSenderName,
    onChangeHandler,
    isShowSenderName,
    isShowAdditionalInfo,
    className = '',
  }: ISmsOperatorSelectProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    // Обрабатывает событие смены СМС провайдера, в случае отключения провайдера записывает в стэйт пустую строку.
    const setSMSProvider = useCallback(
      (name: string, value: string) => () => {
        dispatch(changeSMSProviderName({ name, value }));
      },
      [dispatch],
    );

    // Формирует список СМС операторов для рендеринга
    const renderSMSOperatorList: React.ReactNode[] = useMemo(
      () =>
        Object.entries(smsOperatorIndexName[appLanguage]).map(([index, operatorName]) => (
          <MenuItem
            key={index}
            text={<span>{operatorName}</span>}
            onClick={setSMSProvider(operatorName, operatorName)}
            className={smsProvider === operatorName ? '!bg-ivory' : ''}
          />
        )),
      [appLanguage, smsProvider, setSMSProvider],
    );

    // Функция возвращает булево значение указывающее на обязательность заполнения поля
    const getIsRequire = useCallback(
      ({
        login,
        apiKey,
        password,
        inputName,
        smsOperatorName,
      }: {
        login: string;
        apiKey: string;
        password: string;
        inputName: string;
        smsOperatorName: string;
      }): boolean => {
        if (smsOperatorName === PROVIDER_NAMES.SMS_PROSTO) {
          if (inputName === SETTINGS_DATA_KEY_NAMES.SMS_API_KEY) {
            return !password && !login;
          }

          if (
            inputName === SETTINGS_DATA_KEY_NAMES.SMS_LOGIN ||
            inputName === SETTINGS_DATA_KEY_NAMES.SMS_PASSWORD
          ) {
            return !apiKey;
          }
        }

        return true;
      },
      [],
    );

    return (
      <div className={className}>
        <TextWithInfoIcon
          className='mb-4'
          marginBottom='0.5'
          variant={SETTINGS_DATA_KEY_NAMES.SMS_PROVIDER}
          fontSize='text-[1.25rem]'
        />
        {isShowAdditionalInfo ? (
          <div className='my-4'>
            <Callout type='def' view='smooth' color='primary' icon='info-sign'>
              <>
                <p className='font-bold mb-2'>
                  {t('SETTINGS_PAGE_TEXT.smsOperatorSelect.additionalInfo')}
                </p>
                <p>
                  {t(`SETTINGS_PAGE_TEXT.PROVIDER_NAME_FOR_SHOW_ADDITIONAL_INFO.${smsProvider}`)}
                </p>
              </>
            </Callout>
          </div>
        ) : null}
        <Dropdown
          view='outlined'
          color='default'
          position='bottom-left'
          text={<span>{smsProvider || t('SETTINGS_PAGE_TEXT.smsOperatorSelect.disabled')}</span>}
          content={
            <Menu view='raised' className='dropdown-list-scrollbar max-h-[50vh] overflow-y-auto'>
              {renderSMSOperatorList}
            </Menu>
          }
          className='!p-2 max-w-[21.25rem] m-0 w-full'
        />
        <div className='flex flex-col mt-8'>
          {isShowToken ? (
            <SettingInputWithLabel
              value={smsApikey}
              onChangeHandler={onChangeHandler}
              htmlFor={SETTINGS_DATA_KEY_NAMES.SMS_API_KEY}
              isRequire={getIsRequire({
                login: smsLogin,
                password: smsPass,
                apiKey: smsApikey,
                smsOperatorName: smsProvider,
                inputName: SETTINGS_DATA_KEY_NAMES.SMS_API_KEY,
              })}
              className='mb-8'>
              {t('SETTINGS_PAGE_TEXT.smsOperatorSelect.apiKey')}
            </SettingInputWithLabel>
          ) : null}
          {isShowLogin ? (
            <SettingInputWithLabel
              value={smsLogin}
              onChangeHandler={onChangeHandler}
              htmlFor={SETTINGS_DATA_KEY_NAMES.SMS_LOGIN}
              isRequire={getIsRequire({
                login: smsLogin,
                password: smsPass,
                apiKey: smsApikey,
                smsOperatorName: smsProvider,
                inputName: SETTINGS_DATA_KEY_NAMES.SMS_LOGIN,
              })}
              className='mb-8'>
              {t('SETTINGS_PAGE_TEXT.smsOperatorSelect.login')}
            </SettingInputWithLabel>
          ) : null}
          {isShowPass ? (
            <SettingInputWithLabel
              value={smsPass}
              onChangeHandler={onChangeHandler}
              htmlFor={SETTINGS_DATA_KEY_NAMES.SMS_PASSWORD}
              isRequire={getIsRequire({
                login: smsLogin,
                password: smsPass,
                apiKey: smsApikey,
                smsOperatorName: smsProvider,
                inputName: SETTINGS_DATA_KEY_NAMES.SMS_LOGIN,
              })}
              className='mb-8'>
              {t('SETTINGS_PAGE_TEXT.smsOperatorSelect.passWord')}
            </SettingInputWithLabel>
          ) : null}
          {isShowSenderName ? (
            <SettingInputWithLabel
              value={smsSenderName}
              onChangeHandler={onChangeHandler}
              htmlFor={SETTINGS_DATA_KEY_NAMES.SMS_SENDER_NAME}
              isRequire={getIsRequire({
                login: smsLogin,
                password: smsPass,
                apiKey: smsApikey,
                smsOperatorName: smsProvider,
                inputName: SETTINGS_DATA_KEY_NAMES.SMS_LOGIN,
              })}
              className='mb-8'>
              {t('SETTINGS_PAGE_TEXT.smsOperatorSelect.senderName')}
            </SettingInputWithLabel>
          ) : null}
        </div>
      </div>
    );
  },
);

SmsOperatorSelect.displayName = 'SmsOperatorSelect';
