import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
//
import { Button } from '@uikit/Button';
import { Loader } from '@blocks/loader';
import { PATH_NAMES } from '@data/dict';
import { getLinkHref } from '@helpers/index';
import { getAppData } from '@redux/app/selectors';
import { NavComponent } from '@components/navComponent';
import { getAccountsData } from '@redux/accounts/selectors';
import { TOnChangeHandlerInputTextarea } from '@shared/types';
import { useAppDispatch, useAppSelector } from '@store/store';
import { HeaderWithButton } from '@components/headerWithButton';
import { sendFeedbackData } from '@redux/feedback/feedbackThunk';
import { TInviteFriendListData } from '@redux/inviteFriendList/types';
import { InviteFriedActionList } from '@templates/inviteFriedActionList';
import { InviteFriendsFeedback } from '@components/inviteFriendsFeedback';
import { InviteFriendsStatistics } from '@templates/inviteFriendsStatistics';
import { setFeedbackTextIF } from '@redux/inviteFriendList/inviteFriendListSlice';
import { TInviteFriendsStatisticsItemData } from '@redux/inviteFrendsStatistics/types';
import {
  FAST_FEEDBACK_BUTTON_TEXT,
  INVITE_FRIENDS_PAGES_NAME_DICT,
  YCLIENTS_CARD_CREATION_INSTRUCTION,
} from '@const/inviteFriend';

type InviteFriendsTemplateProps = {
  /**
   * Флаг состояния запроса
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Флаг состояния запроса на удаление
   * @param {boolean}
   */
  isDeleting: boolean;
  /**
   * Флаг успешного удаления шаблона
   * @param {boolean}
   */
  isDeletingSuccess: boolean;
  /**
   * Флаг ошибки при удалении шаблона
   * @param {boolean}
   */
  isDeletingError: boolean;
  /**
   * Флаг переключения активности шаблона
   * @param {boolean}
   */
  isSwitching: boolean;
  /**
   * Флаг отправки отзыва
   * @param {boolean}
   */
  isFeedbackSending: boolean;
  /**
   * Текст отзыва
   * @param {string}
   */
  feedbackText: string;
  /**
   * Выбранный вид быстрого отзыва
   * @param {string}
   */
  fastReview: string;
  /**
   * Данные в карточку
   * @param {TInviteFriendsStatisticsItemData[]}
   */
  statisticsData: TInviteFriendsStatisticsItemData[];
  /**
   * Данные для отображения карточек
   * @param {TInviteFriendListData[]}
   */
  data: TInviteFriendListData[];
};

export const InviteFriendsTemplate = memo(
  ({
    isLoading,
    fastReview,
    isDeleting,
    isSwitching,
    feedbackText,
    statisticsData,
    isDeletingError,
    isFeedbackSending,
    isDeletingSuccess,
    data,
  }: InviteFriendsTemplateProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const [showDescription2, setShowDescription2] = useState(false);

    const { appLanguage } = useAppSelector(getAppData);

    // получаем объект selectedFilial
    const { selectedFilial } = useAppSelector(getAccountsData);

    const isShowCardList = pathname.includes(PATH_NAMES.INVITE_FRIEND_LIST);
    const isShowStatistics = pathname.includes(PATH_NAMES.INVITE_FRIEND_STATISTICS);

    // Обработчик нажатия на кнопку создать акцию
    const addAction = useCallback(() => {
      navigate(
        getLinkHref({
          accId: selectedFilial?.accId || '',
          route: PATH_NAMES.INVITE_FRIEND_NEW_TEMPLATE,
        }),
      );
    }, [selectedFilial?.accId, navigate]);

    // обрабатывает изменения значения в textarea
    const setFeedbackHandler: TOnChangeHandlerInputTextarea = useCallback(
      event => {
        dispatch(setFeedbackTextIF(event.currentTarget.value));
      },
      [dispatch],
    );

    // отправляет отзыв
    const sendFeedback = useCallback(
      (text: string) => (rate: number, showSuccessModalCallback: () => void) => {
        const textWithRate = `${text}\n\nRate: ${rate}`;

        dispatch(
          sendFeedbackData({
            file: null,
            topic: 'reference',
            text: textWithRate,
            showSuccessModalCallback,
            filialName: selectedFilial?.address || '',
          }),
        );
      },
      [selectedFilial?.address, dispatch],
    );

    return (
      <div className='px-4 sm:px-0'>
        <HeaderWithButton headerText={t('INVITE_FRIEND_PAGE_TEXT.header')} className='mb-6 mt-0' />
        {isLoading ? <Loader className='h-[50vh]' /> : null}
        {isShowCardList && !isLoading && selectedFilial?.accId ? (
          <div className='xl:grid xl:grid-cols-2IfListPage xl:gap-[1.5rem]'>
            <div className='flex flex-col items-start justify-between'>
              <p className='text-blackText leading-6 tracking-[0.0275em] mb-6 lg:max-w-[54.5rem] w-full'>
                {t('INVITE_FRIEND_PAGE_TEXT.descriptionPath1')}
                {!showDescription2 ? (
                  <button
                    onClick={() => setShowDescription2(true)}
                    className='bg-none border-none text-btnGreen hover:text-green1 underline ml-1'>
                    {t('INVITE_FRIEND_PAGE_TEXT.readMore')}
                  </button>
                ) : null}
              </p>
              {showDescription2 ? (
                <div className='mb-6'>
                  <ol className='text-blackText leading-6 tracking-[0.0275em] list-decimal pl-[1.3rem] mb-6'>
                    <li>
                      <span className='mr-1'>
                        {t('INVITE_FRIEND_PAGE_TEXT.descriptionPath2.numericList.first')}
                      </span>
                      <a
                        target='_blank'
                        rel='noreferrer noopener'
                        href={YCLIENTS_CARD_CREATION_INSTRUCTION}
                        className='text-btnGreen hover:text-green1 underline'>
                        {t(
                          'INVITE_FRIEND_PAGE_TEXT.descriptionPath2.numericList.linkToInstruction',
                        )}
                      </a>
                    </li>
                    <li>{t('INVITE_FRIEND_PAGE_TEXT.descriptionPath2.numericList.second')}</li>
                    <ul className='list-disc pl-2'>
                      <li>
                        {t(
                          'INVITE_FRIEND_PAGE_TEXT.descriptionPath2.numericList.unorderedList.first',
                        )}
                      </li>
                      <li>
                        {t(
                          'INVITE_FRIEND_PAGE_TEXT.descriptionPath2.numericList.unorderedList.second',
                        )}
                      </li>
                    </ul>
                    <li>{t('INVITE_FRIEND_PAGE_TEXT.descriptionPath2.numericList.third')}</li>
                    <li>{t('INVITE_FRIEND_PAGE_TEXT.descriptionPath2.numericList.forth')}</li>
                  </ol>
                </div>
              ) : null}
              <div className='flex flex-wrap items-center justify-between sm:mb-6 w-full'>
                <NavComponent
                  path={pathname}
                  isDisable={false}
                  addNewActionTemplate={addAction}
                  selectedFilialAccId={selectedFilial?.accId || ''}
                  pagesNames={INVITE_FRIENDS_PAGES_NAME_DICT[appLanguage]}
                  className='sm:max-w-[20rem] w-full'
                />
                <div className='flex flex-col-reverse sm:flex-row flex-wrap  sm:items-center w-full sm:w-auto'>
                  {/* <div className='rounded-lg flex px-4 py-2 h-fit lg:max-w-[26.5rem] mb-4 mr-[0.625rem]'>
                    <IconWithDrawer
                      tooltips='faq'
                      tooltipTitle={t('INVITE_FRIEND_PAGE_TEXT.faq')}
                      className=''
                    />
                  </div> */}
                  <Button
                    dense
                    icon='plus'
                    type='action'
                    view='filled'
                    color='success'
                    onClick={addAction}
                    text={t('INVITE_FRIEND_PAGE_TEXT.createAction')}
                    className='mb-4 min-w-[12rem] w-full sm:w-fit'
                  />
                </div>
              </div>
              <div className='grid xxl:grid-cols-2tempIF gap-[1.5rem] w-full'>
                <InviteFriendsFeedback
                  fastReview={fastReview}
                  feedbackText={feedbackText}
                  onChangeHandler={setFeedbackHandler}
                  isFeedbackSending={isFeedbackSending}
                  sendFeedback={sendFeedback(feedbackText)}
                  fastFeedbackButtons={FAST_FEEDBACK_BUTTON_TEXT[appLanguage]}
                  className='xl:hidden sm:mb-6'
                />
                <div className='hidden m:block' />
              </div>
              <InviteFriedActionList
                isDeleting={isDeleting}
                appLanguage={appLanguage}
                isSwitching={isSwitching}
                isDeletingError={isDeletingError}
                isDeletingSuccess={isDeletingSuccess}
                data={data}
              />
            </div>
            <InviteFriendsFeedback
              fastReview={fastReview}
              feedbackText={feedbackText}
              onChangeHandler={setFeedbackHandler}
              isFeedbackSending={isFeedbackSending}
              sendFeedback={sendFeedback(feedbackText)}
              fastFeedbackButtons={FAST_FEEDBACK_BUTTON_TEXT[appLanguage]}
              className='hidden xl:block'
            />
          </div>
        ) : null}
        {isShowStatistics && !isLoading ? (
          <InviteFriendsStatistics appLanguage={appLanguage} data={statisticsData} />
        ) : null}
      </div>
    );
  },
);

InviteFriendsTemplate.displayName = 'InviteFriendsTemplate';
