import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { H2 } from '@atoms/h2';
import { Table } from '@uikit/Table/Table';
import { useAppDispatch } from '@store/store';
import { TPaymentData } from '@redux/pay/models';
import { setSortTypeDataPP } from '@redux/pay/paySlice';

type TPayHistoryTable = {
  /**
   * Данные для таблицы
   * @param {TPaymentData[]}
   */
  filteredData: TPaymentData[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const PayHistoryTable = memo(({ filteredData, className = '' }: TPayHistoryTable) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // функция сортировки, получает название столбца отправляет в редакс
  const setSortTypeHandler = useCallback(
    (name: string, order: string) => {
      dispatch(setSortTypeDataPP({ collumName: name || '', order }));
    },
    [dispatch],
  );

  const Head = useMemo(
    () => [
      { id: 'date', label: t('PAY_PAGE_TEXT.tableDate') },
      { id: 'period', label: t('PAY_PAGE_TEXT.tablePeriod') },
      { id: 'sum', label: t('PAY_PAGE_TEXT.tableSum') },
    ],
    [t],
  );

  const getTableData = useCallback(
    (tableData: TPaymentData[]) => {
      const emptyData = [
        {
          date: ' ',
          period: ' ',
          sum: ' ',
        },
      ];

      if (!tableData.length) {
        return emptyData;
      }

      return tableData.map(item => {
        return { ...item, period: `${t('PAY_PAGE_TEXT.tableBeforePeriod')} ${item.period}` };
      });
    },
    [t],
  );

  return (
    <div className={className}>
      <H2 className='font-bold text-h6Mobile text-black mt-8 mb-6'>
        {t('PAY_PAGE_TEXT.tableHeader')}
      </H2>
      <div className='max-w-[57.5rem]'>
        <Table
          pages
          type='def'
          view='flat'
          name={Head}
          defRows={8}
          color='default'
          checkboxes={false}
          paperClassName='no_shadow'
          rowsPerPageOptions={[5, 8, 20]}
          data={getTableData(filteredData)}
          onSortCallback={setSortTypeHandler}
          position={['left', 'left', 'right']}
          style={{ boxShadow: 'none', marginLeft: '6px' }}
          labelRowsPerPage={t('PAY_PAGE_TEXT.paginationText')}
        />
      </div>
    </div>
  );
});

PayHistoryTable.displayName = 'PayHistoryTable';
