import React, { memo } from 'react';
import { EventTemplateListTitle } from '@blocks/eventTemplateListTitle';

type TEventTemplateListWrapper = {
  /**
   * Текст в заголовок
   * @param {string}
   */
  text: string;
  /**
   * Флаг открытия/закрытия списка
   * @param {string}
   */
  isOpen: boolean;
  /**
   * Callback для открытия/закрытия списка шаблонов
   * @param {() => void}
   */
  onClickCallback: () => void;
  /**
   * Массив с карточками шаблонов
   * @param {React.ReactNode}
   */
  cartList: React.ReactNode;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const EventTemplateListWrapper = memo(
  ({ isOpen, text, onClickCallback, cartList, className = '' }: TEventTemplateListWrapper) => {
    return (
      <div className={className}>
        <EventTemplateListTitle text={text} isOpen={isOpen} onClickCallback={onClickCallback} />
        <div
          className={`grid grid-cols-3temp xxl:grid-cols-3tempXXL gap-[1.5rem] ${
            isOpen ? 'max-h-auto' : 'max-h-0'
          } overflow-hidden`}>
          {cartList}
        </div>
      </div>
    );
  },
);

EventTemplateListWrapper.displayName = 'EventTemplateListWrapper';
