import React from 'react';

type TEventTemplateListTitle = {
  /**
   * Текст в заголовок
   * @param {string}
   */
  text: string;
  /**
   * Флаг открытия/закрытия списка
   * @param {string}
   */
  isOpen: boolean;
  /**
   * Callback для открытия/закрытия списка шаблонов
   * @param {() => void}
   */
  onClickCallback: () => void;
};

export const EventTemplateListTitle = ({
  text,
  isOpen,
  onClickCallback,
}: TEventTemplateListTitle) => {
  return (
    <h2
      onClick={onClickCallback}
      className='flex items-center cursor-pointer font-bold mb-8 text-h6Mobile leading-6 tracking-[0.0075em] text-blackText'>
      <div
        className={`pl-2.5 pr-[0.875rem] mb-1
                  ${isOpen ? '' : 'opacity-50 -rotate-90'} cursor-pointer`}>
        <svg
          width='20'
          height='20'
          fill='none'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            fill='#4A4B57'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16 7C16 6.45 15.55 6 15 6H5C4.45 6 4 6.45 4 7C4 7.24 4.1 7.46 4.24 7.63L4.23 7.64L9.23 13.64L9.24 13.63C9.43 13.85 9.69 14 10 14C10.31 14 10.57 13.85 10.76 13.63L10.77 13.64L15.77 7.64L15.76 7.63C15.9 7.46 16 7.24 16 7Z'
          />
        </svg>
      </div>
      {text}
    </h2>
  );
};
