import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Loader } from '@blocks/loader';
import { BotButton } from '@blocks/botButton';
import { useAppSelector } from '@store/store';
import { getAppData } from '@redux/app/selectors';
import { VIDEO_INSTRUCTIONS } from '@const/common';
import { CONNECTION_STATUSES } from '@models/index';
import { ConnectionInfo } from '@blocks/connectionInfo';
import { ConnectionTable } from '@blocks/connectionTable';
import { ConnectionGetQr } from '@blocks/connectionGetQr';
import { getAccountsData } from '@redux/accounts/selectors';
import { ToastsMessages } from '@components/toastsMessages';
import { HeaderWithButton } from '@components/headerWithButton';
import { IconWithTooltips } from '@components/iconWithTooltips';
import { TConnectionStatusData } from '@redux/connection/models';

interface IQrCodeData {
  ok: boolean;
  qr: string;
}

interface IConnectionProps {
  /**
   * Callback для получения QR кода
   * @param {() => void}
   */
  getQr: () => void;
  /**
   * Данные QR кода
   * @param {IQrCodeData | undefined}
   */
  qrData: IQrCodeData | undefined;
  /**
   * Флаг процесса получения QR кода
   * @param {boolean}
   */
  isFetchingQR: boolean;
  /**
   * Флаг ошибки получения QR кода
   * @param {boolean}
   */
  isGetQRError: boolean;
  /**
   * Callback для очистки очереди сообщений
   * @param {() => void}
   */
  clearQuery: () => void;
  /**
   * Флаг процесса очистки очереди сообщений
   * @param {boolean}
   */
  isFetchingCleanQuery: boolean;
  /**
   * Флаг ошибки очистки очереди сообщений
   * @param {boolean}
   */
  isCleanQueryError: boolean;
  /**
   * Callback для остановки бота
   * @param {() => void}
   */
  stopTelegramBot: () => void;
  /**
   * Флаг процесса остановки бота
   * @param {boolean}
   */
  isBotStoppingQuery: boolean;
  /**
   * Флаг ошибки остановки бота
   * @param {boolean}
   */
  isBotStoppingError: boolean;
  /**
   * Callback для перезапуска бота
   * @param {() => void}
   */
  restartTelegramBot: () => void;
  /**
   * Флаг процесса перезапуска бота
   * @param {boolean}
   */
  isRestartingBot: boolean;
  /**
   * Флаг ошибки перезапуска бота
   * @param {boolean}
   */
  isBotRestartError: boolean;
  /**
   * Сообщение об ошибке
   * @param {string}
   */
  errorMessage: string;
  /**
   * Флаг состояния загрузки
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Данные страницы подключения
   * @param {TConnectionStatusData}
   */
  data: TConnectionStatusData;
}

export const ConnectionTemplate = memo(
  ({
    getQr,
    qrData,
    isLoading,
    clearQuery,
    errorMessage,
    isFetchingQR,
    isGetQRError,
    isRestartingBot,
    stopTelegramBot,
    isBotRestartError,
    isCleanQueryError,
    isBotStoppingQuery,
    isBotStoppingError,
    restartTelegramBot,
    isFetchingCleanQuery,
    data,
  }: IConnectionProps) => {
    const { t } = useTranslation();
    const { appLanguage } = useAppSelector(getAppData);

    // деструктурезируем state
    const { status, messages } = data;

    // Данные выбранного филиала
    const { selectedFilial } = useAppSelector(getAccountsData);

    const isShowErrorToast =
      isGetQRError || isCleanQueryError || isBotStoppingError || isBotRestartError;

    return (
      <div className='flex flex-col'>
        <HeaderWithButton
          headerText={t('CONNECTION_PAGE_TEXT.header')}
          instructionData={VIDEO_INSTRUCTIONS.connection}
          className='px-4 sm:px-0 mt-0 mb-10 lg:mb-8'
        />
        <div>
          {isLoading ? (
            <Loader className='h-[50vh]' />
          ) : (
            <>
              <div className='bg-gray1 sm:rounded-lg pt-[1.6875rem] px-4 sm:px-6 py-6 max-w-[48.75rem]'>
                <ConnectionInfo
                  appLanguage={appLanguage}
                  selectedFilialAddress={selectedFilial?.address || ''}
                  data={data}
                />
                {status === CONNECTION_STATUSES.GOT_QR_CODE ||
                status === CONNECTION_STATUSES.LOADING ? (
                  <ConnectionGetQr
                    getQr={getQr}
                    qrData={qrData}
                    clearQuery={clearQuery}
                    isGetQRError={isGetQRError}
                    isFetchingQR={isFetchingQR}
                    data={data}
                  />
                ) : null}
                {status === CONNECTION_STATUSES.AUTHENTICATED ? (
                  <div className='flex flex-col sm:flex-row items-center my-2.5'>
                    <BotButton
                      variant='stop'
                      callBack={stopTelegramBot}
                      isLoading={isBotStoppingQuery}
                      className='!bg-white !min-w-[12.6875rem] w-full sm:w-fit mb-2.5 sm:mb-0 mr-0 sm:mr-2.5'
                    />
                    <BotButton
                      variant='restart'
                      isLoading={isRestartingBot}
                      callBack={restartTelegramBot}
                      className='!bg-white !min-w-[14.375rem] w-full sm:w-fit'
                    />
                  </div>
                ) : null}
              </div>
              {status !== CONNECTION_STATUSES.AUTHENTICATED &&
              status !== CONNECTION_STATUSES.BLOCKED ? (
                <div className='px-4 sm:px-0'>
                  <div className='flex items-center mt-[2.2rem] mb-4'>
                    <h2 className='font-inter font-medium text-[1.25rem] tracking-[0.025rem] mr-[0.3125rem]'>
                      {t('CONNECTION_PAGE_TEXT.table.header')}
                    </h2>
                    <IconWithTooltips tooltips='messageQuery' />
                  </div>
                  <ConnectionTable
                    messages={messages}
                    cleanMessagesCallBack={clearQuery}
                    isFetchingCleanQuery={isFetchingCleanQuery}
                  />
                </div>
              ) : null}
              <ToastsMessages
                successMessage=''
                isShowSuccessToast={false}
                errorMessage={errorMessage}
                isShowErrorToast={isShowErrorToast}
              />
            </>
          )}
        </div>
      </div>
    );
  },
);

ConnectionTemplate.displayName = 'ConnectionTemplate';
