import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { ToggleSwitch } from '@blocks/toggleSwitch';

type TAutoPay = {
  /**
   * Флаг подключения автоплатежа
   * @param {boolean}
   */
  isAutoPayEnable: boolean;
  /**
   * Последние 4 цифры карты
   * @param {string}
   */
  cardLastFourDigits: string;
  /**
   * Флаг состояния процесса загрузки ссылки для смены карты
   * @param {boolean}
   */
  changeCardIsLoading: boolean;
  /**
   * Callback для изменения карты для автоплатежа
   * @param {() => void}
   */
  changeCardHandler: () => void;
  /**
   * Флаг состояния процесса загрузки ссылки для подключения автоплатежа
   * @param {boolean}
   */
  isFetchingAutoPayLink: boolean;
  /**
   * Номер выбранной карточки оплаты
   * @param {number}
   */
  selectedCardNumber: number;
  /**
   * Callback для подключения автоплатежа
   * @param {() => void}
   */
  getAutoPayLinkHandler: () => void;
  /**
   * Флаг состояния модалки для отключения автоплатежа
   * @param {() => void}
   */
  autoPayDisableModalOpen: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const AutoPay = memo(
  ({
    isAutoPayEnable,
    changeCardHandler,
    cardLastFourDigits,
    selectedCardNumber,
    changeCardIsLoading,
    getAutoPayLinkHandler,
    isFetchingAutoPayLink,
    autoPayDisableModalOpen,
    className = '',
  }: TAutoPay) => {
    const { t } = useTranslation();

    const [isButtonPressed, setIsButtonPressed] = useState(false);

    useEffect(() => {
      if (!isFetchingAutoPayLink && isButtonPressed) {
        setIsButtonPressed(false);
      }
    }, [isFetchingAutoPayLink, isButtonPressed]);

    const getAutoPayLinkInnerHandler = useCallback(() => {
      setIsButtonPressed(true);
      getAutoPayLinkHandler();
    }, [getAutoPayLinkHandler]);

    const autoPaymentStatus = t('PAY_PAGE_TEXT.autoPaymentStatus', {
      status: isAutoPayEnable
        ? t('PAY_PAGE_TEXT.autoPaymentEnabled')
        : t('PAY_PAGE_TEXT.autoPaymentDisabled'),
    });

    const autoPaymentStatus2 = `${
      isAutoPayEnable
        ? t('PAY_PAGE_TEXT.card', { cardLastFourDigits })
        : t('PAY_PAGE_TEXT.enableAutoPayment')
    }`;

    const isChangeButtonView = selectedCardNumber === 1 && !isAutoPayEnable;

    return (
      <div className={className}>
        <h2 className='font-medium text-h6Mobile mb-4'>{t('PAY_PAGE_TEXT.header')}</h2>
        <hr className='border-none bg-quartz1 h-0.5 mb-4' />
        <div className='flex flex-col sm:flex-row flex-wrap sm:justify-between'>
          <div className='flex items-center mb-6 sm:mb-4'>
            <ToggleSwitch
              name=''
              checked={isAutoPayEnable}
              onChangeHandler={isAutoPayEnable ? autoPayDisableModalOpen : getAutoPayLinkHandler}
            />
            <div className='flex flex-col ml-6'>
              <p className='font-medium m-0'>{autoPaymentStatus}</p>
              <p className='text-textareaBlack text-captionSmall m-0'>{autoPaymentStatus2}</p>
            </div>
          </div>
          <div className='mb-4'>
            {!isAutoPayEnable ? (
              <Button
                dense
                icon='plus'
                type='action'
                onClick={getAutoPayLinkInnerHandler}
                text={t('PAY_PAGE_TEXT.enableAutoPayment')}
                view={isChangeButtonView ? 'filled' : 'outlined'}
                loading={isButtonPressed && isFetchingAutoPayLink}
                color={isChangeButtonView ? 'success' : 'default'}
                className='sm:!min-w-[16.875rem] w-fit !h-fit'
              />
            ) : (
              <Button
                dense
                icon='edit'
                type='action'
                view='outlined'
                color='default'
                onClick={changeCardHandler}
                loading={changeCardIsLoading}
                text={t('PAY_PAGE_TEXT.changeCard')}
                className='sm:!min-w-[12.25rem] w-fit !h-fit'
              />
            )}
          </div>
        </div>
        <hr className='border-none bg-quartz1 h-0.5 mb-4' />
      </div>
    );
  },
);

AutoPay.displayName = 'AutoPay';
