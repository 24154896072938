import React, { memo, useCallback, useEffect } from 'react';
//
import { PayTemplate } from '@templates/pay';
import { NoAccess } from '@components/noAccess';
import { ErrorMessage } from '@blocks/errorMessage';
import { getPayPageState } from '@redux/pay/selectors';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  changeCartData,
  getPayPageData,
  getAutoPayLinkData,
  turnOffAutoPayment,
} from '@redux/pay/payThunks';

const PayPage = memo(() => {
  const dispatch = useAppDispatch();

  const {
    data,
    isError,
    isRetries,
    autoPayLink,
    errorMessage,
    changeCardIsError,
    changeCardDataLink,
    changeCardIsLoading,
    isFetchingAutoPayLink,
    isAccessOnPayPageDenied,
    turnOffAutoPaymentIsError,
    isFetchingAutoPayLinkError,
    turnOffAutoPaymentIsLoading,
    turnOffAutoPaymentIsSuccess,
  } = useAppSelector(getPayPageState);

  const { selectedFilial } = useAppSelector(getAccountsData);

  useEffect(() => {
    if (!isRetries && selectedFilial?.accId) {
      dispatch(getPayPageData());
    }
  }, [dispatch, isRetries, selectedFilial?.accId]);

  useEffect(() => {
    if (isRetries) {
      setTimeout(() => dispatch(getPayPageData()), 100);
    }
  }, [dispatch, isRetries]);

  // Callback вызывает запрос за данными страницы
  const refetch = useCallback(() => {
    dispatch(getPayPageData());
  }, [dispatch]);

  // Создаем callback для получения ссылки на подключение автоплатежа
  const getAutoPayLinkHandler = useCallback(() => {
    dispatch(getAutoPayLinkData());
  }, [dispatch]);

  // Создаем callback для получения ссылки на изменение карты подключенной в автоплатеже
  const changeCardHandler = useCallback(() => {
    dispatch(changeCartData());
  }, [dispatch]);

  // Создаем callback для отключения автоплатежа
  const turnOffAutoPaymentHandler = useCallback(() => {
    dispatch(turnOffAutoPayment());
  }, [dispatch]);

  return (
    <>
      {isError && !isAccessOnPayPageDenied ? <ErrorMessage /> : null}
      {isAccessOnPayPageDenied && !isError ? <NoAccess /> : null}
      {!isError && !isAccessOnPayPageDenied ? (
        <PayTemplate
          refetch={refetch}
          autoPayLink={autoPayLink}
          errorMessage={errorMessage}
          changeCardIsError={changeCardIsError}
          changeCardHandler={changeCardHandler}
          changeCardDataLink={changeCardDataLink}
          changeCardIsLoading={changeCardIsLoading}
          getAutoPayLinkHandler={getAutoPayLinkHandler}
          isFetchingAutoPayLink={isFetchingAutoPayLink}
          turnOffAutoPaymentHandler={turnOffAutoPaymentHandler}
          turnOffAutoPaymentIsError={turnOffAutoPaymentIsError}
          isFetchingAutoPayLinkError={isFetchingAutoPayLinkError}
          turnOffAutoPaymentIsLoading={turnOffAutoPaymentIsLoading}
          turnOffAutoPaymentIsSuccess={turnOffAutoPaymentIsSuccess}
          data={data}
        />
      ) : null}
    </>
  );
});

PayPage.displayName = 'PayPage';

export default PayPage;
