import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Loader } from '@blocks/loader';
import { useAppSelector } from '@store/store';
import { getAccountsData } from '@redux/accounts/selectors';
import { PartnersFullTable } from '@components/partnersFullTable';
import { ConsultantFullTable } from '@components/consultantFullTable';
import { PartnersPageLinkBlock } from '@blocks/partnersPageLinkBlock';
import { getPartnersFullTablePageData } from '@redux/partnersFullTable/selectors';
import { getConsultantFullTablePageData } from '@redux/consultantFullTable/selectors';
import { Button } from '@uikit/Button';
import { Icon } from '@atoms/icon';
import { downloadByLink } from '@helpers/index';
import { TAppLanguage } from '@models/index';

interface IPartnerProgramTemplateProps {
  /**
   * Флаг определяет что аккаунт является салоном
   * @param {boolean}
   */
  isSalon: boolean;
  /**
   * Флаг определяет что аккаунт является салоном и консультантом
   * @param {boolean}
   */
  isSalonConsultant: boolean;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const PartnerProgramTemplate = memo(
  ({ isSalon, appLanguage, isSalonConsultant, className = '' }: IPartnerProgramTemplateProps) => {
    const { t } = useTranslation();
    const { consultantData } = useAppSelector(getAccountsData);

    const {
      promoUrl: consultantURL,
      qrCode: consultantQrCode,
      data: consultantFullTable,
      isLoading: consultantIsLoading,
      promoCode: consultantPromoCode,
    } = useAppSelector(getConsultantFullTablePageData);

    const {
      isLoading,
      qrCode: partnersQrCode,
      data: partnersFullTable,
      promoUrl: salonPromoUrl,
      promoCode: salonPromoCode,
    } = useAppSelector(getPartnersFullTablePageData);

    const getBonusPercentString = useCallback((number: number): string => {
      return String(number * 100);
    }, []);

    const downloadQrHandler = useCallback(
      (link: string) => () => {
        downloadByLink(link);
      },
      [],
    );

    const textStyle = 'leading-6 text-blackText tracking-[0.0275em]';

    return (
      <>
        {!isLoading && !consultantIsLoading ? (
          <div className='flex flex-wrap md:flex-nowrap justify-center md:justify-start my-6 lg:my-10 w-full'>
            <div
              className={`border border-quartz rounded-lg px-4 py-3 mb-6 md:mb-0 max-w-[47rem] w-full ${className}`}>
              <h2 className='leading-[1.875rem] text-h3Mobile text-blackText tracking-[0.0075em]'>
                {t('PARTNER_PAGE_TEXT.partnerProgramTemplate.header')}
              </h2>
              {isSalon ? (
                <p className={`${textStyle} mb-5`}>
                  {t('PARTNER_PAGE_TEXT.partnerProgramTemplate.bonus')}
                </p>
              ) : null}
              {!isSalon || isSalonConsultant ? (
                <p className={`${textStyle} mb-4`}>
                  <span className='font-bold'>
                    {t('PARTNER_PAGE_TEXT.partnerProgramTemplate.yourBonus')}
                  </span>
                  {t('PARTNER_PAGE_TEXT.partnerProgramTemplate.yourBonus2')}
                </p>
              ) : null}
              <div className='mb-3'>
                {consultantData?.oneTimePayRate ? (
                  <p className={`${textStyle} mb-2`}>
                    {t('PARTNER_PAGE_TEXT.partnerProgramTemplate.firstPayBonus', {
                      percent: getBonusPercentString(consultantData.oneTimePayRate),
                    })}
                  </p>
                ) : null}
                {consultantData?.regularPayRate ? (
                  <p className={`${textStyle} mb-2`}>
                    {t('PARTNER_PAGE_TEXT.partnerProgramTemplate.everyPayBonus', {
                      percent: getBonusPercentString(consultantData.regularPayRate),
                    })}
                  </p>
                ) : null}
                {consultantData?.oneTimePayment ? (
                  <p className={`${textStyle} mb-2`}>
                    {t('PARTNER_PAGE_TEXT.partnerProgramTemplate.oneTimePayment', {
                      oneTimePayment: consultantData?.oneTimePayment,
                    })}
                  </p>
                ) : null}
              </div>
              <PartnersPageLinkBlock
                labelText={t('PARTNER_PAGE_TEXT.partnerProgramTemplate.partnerLink')}
                link={salonPromoUrl || consultantURL}
                className='mb-8 w-full'
              />
              <PartnersPageLinkBlock
                link={salonPromoCode || consultantPromoCode}
                labelText={t('PARTNER_PAGE_TEXT.partnerProgramTemplate.partnerPromo')}
                className='w-full'
              />
            </div>
            {consultantQrCode || partnersQrCode ? (
              <div className='md:ml-6 min-w-[15rem]'>
                <div className='border border-quartz p-2 mb-2'>
                  <img
                    src={consultantQrCode || partnersQrCode}
                    alt='qr-code'
                    height={224}
                    width={224}
                    className='h-[14rem] w-[14rem]'
                  />
                </div>
                <Button
                  fill
                  dense
                  type='action'
                  view='outlined'
                  color='default'
                  icon={<Icon variant='download' />}
                  disabled={!(consultantQrCode || partnersQrCode)}
                  onClick={downloadQrHandler(consultantQrCode || partnersQrCode)}
                  text={t('PARTNER_PAGE_TEXT.partnerProgramTemplate.downloadQRButton')}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        {isSalon ? (
          <div>
            {isLoading ? (
              <Loader className='h-[50vh]' />
            ) : (
              <PartnersFullTable
                appLanguage={appLanguage}
                data={partnersFullTable}
                className='overflow-x-auto'
              />
            )}
          </div>
        ) : (
          <div>
            {consultantIsLoading ? (
              <Loader className='h-[50vh]' />
            ) : (
              <ConsultantFullTable
                appLanguage={appLanguage}
                data={consultantFullTable}
                className='overflow-x-auto'
              />
            )}
          </div>
        )}
      </>
    );
  },
);

PartnerProgramTemplate.displayName = 'PartnerProgramTemplate';
