import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@atoms/icon';

interface IFileNameBlockProps {
  /**
   * Полная ссылка на файл
   * @param {string}
   */
  fullFileName: string;
  /**
   * Имя загруженного файла
   * @param {string}
   */
  clientFileName: string;
  /**
   * Callback для очистки загруженного файла
   * @param {() => void}
   */
  clearClientFileHandler: () => void;
  /**
   * Флаг изменяет внешний вид блока для отображения в виде сообщения
   * @param {boolean}
   */
  isMessageStyle?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const FileNameBlock = memo(
  ({
    fullFileName,
    clientFileName,
    isMessageStyle,
    clearClientFileHandler,
    className = '',
  }: IFileNameBlockProps) => {
    const { t } = useTranslation();

    return (
      <div
        className={`flex items-center ${
          isMessageStyle ? 'bg-quartz1 rounded-lg p-6' : ''
        } ${className}`}>
        {!isMessageStyle ? <Icon variant='tickCircle' className='mr-3' /> : null}
        <p className='font-medium text-black leading-6 tracking-[0.022em]'>
          {!isMessageStyle ? (
            <span className='mr-[0.4rem]'>{t('MAILING_PAGE_TEXT.file')}</span>
          ) : null}
          {fullFileName ? (
            <a
              href={fullFileName}
              download={!isMessageStyle}
              className='underline text-green1'>{`${clientFileName}`}</a>
          ) : (
            `${clientFileName}`
          )}
        </p>
        {!isMessageStyle ? (
          <Icon
            variant='deleteFile'
            onClick={clearClientFileHandler}
            className='ml-3 cursor-pointer rounded-full hover:shadow-[0_0_0.375rem_rgb(128,128,128)]'
          />
        ) : null}
      </div>
    );
  },
);

FileNameBlock.displayName = 'FileNameBlock';
