import React, { memo } from 'react';
//
import { Label } from '@atoms/label';
import { TWithChildren } from '@models/index';
import { TOnChangeHandler } from '@shared/types';
import { TextInput } from '@uikit/Inputs/DefaultInput';

interface ISettingInputWithLabel extends TWithChildren {
  /**
   * Значение инпута
   * @param {string}
   */
  value: string;
  /**
   * Параметр htmlFor для label
   * @param {string}
   */
  htmlFor: string;
  /**
   * Опциональный параметр добавляет к тексту label знак *
   * @param {boolean}
   * @default
   */
  isRequire?: boolean;
  /**
   * Callback для обработки изменения значения в инпуте
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const SettingInputWithLabel = memo(
  ({
    value,
    htmlFor,
    children,
    className = '',
    isRequire,
    onChangeHandler,
  }: ISettingInputWithLabel) => (
    <div className={`flex flex-col ${className}`}>
      <Label
        htmlFor={htmlFor}
        className='font-inter text-blackHeader text-body_text font-medium mb-1'>
        {children}
        {isRequire ? '*' : ''}
      </Label>
      <TextInput
        fill
        type='text'
        view='outlined'
        color='default'
        id={htmlFor}
        value={value}
        name={htmlFor}
        required={isRequire}
        onChange={onChangeHandler}
      />
    </div>
  ),
);

SettingInputWithLabel.displayName = 'SettingInputWithLabel';
