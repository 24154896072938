import { TQRCodeData } from '@models/index';
import { TGetQRCodeResponse } from '@api/types';

/**
 * Преобразует QR-код и статус "ok" из объекта ответа в объект TQRCodeData.
 *
 * @param {TGetQRCodeResponse} param - Объект ответа, содержащий QR-код и статус "ok".
 * @returns {TQRCodeData} - Преображенные данные QR-кода.
 */
const qrMapper = ({ QR_code, ok }: TGetQRCodeResponse): TQRCodeData => ({
  qr: QR_code,
  ok,
});

export { qrMapper };
