import { apiService } from '@api/ApiService';
import { TGetFormData } from '@redux/autoConnect/models';
import { END_POINTS_URL, QUERY_PARAMS } from '@const/apiConstants';
import {
  TRequestStatus,
  TAcceptFormStepData,
  TGetCallbackCallData,
  TSendDeepLinkToWaData,
  TAutoConnectionFormData,
} from '@api/types';
import { ConfigENV } from '@const/httpConst';

/**
 * API страниц авто подключения
 * @module autoConnectionApi
 */

export const autoConnectionApi = {
  /**
   * Метод отправляет ссылку на бота в WA
   * @param data {string}
   */
  sendLinkInWA(data: TSendDeepLinkToWaData) {
    return apiService.get<TRequestStatus>(END_POINTS_URL.SEND_LINK_IN_WA, {
      [QUERY_PARAMS.PHONE]: data.phoneNumber,
      ...data.params,
    });
  },
  /**
   * Метод отправляет запрос с данными для последующего обратного звонка
   * @param data {string}
   */
  getCallbackCall(data: TGetCallbackCallData) {
    return apiService.get<TRequestStatus>(
      `${ConfigENV.AUTO_CONNECT_BASE_URL}${END_POINTS_URL.REQUEST_CALL_BACK}`,
      {
        [QUERY_PARAMS.PHONE]: data.phoneNumber,
        ...data.params.params,
      },
    );
  },
  /**
   * Метод получает данные формы
   * @param data {TGetFormData}
   */
  getFormData(data: TGetFormData) {
    return apiService.get<TAutoConnectionFormData | TRequestStatus>(
      `${ConfigENV.AUTO_CONNECT_BASE_URL}${END_POINTS_URL.GET_AUTO_CONNECTION_FORM}`,
      { ...data.params },
    );
  },
  /**
   * Метод сохраняет данные текущего шага
   * @param data {TAcceptFormStepData}
   */
  acceptStep(data: TAcceptFormStepData) {
    return apiService.post<TAutoConnectionFormData | TRequestStatus>(
      `${ConfigENV.AUTO_CONNECT_BASE_URL}${END_POINTS_URL.ACCEPT_AUTO_CONNECTION_STEP}`,
      data.data,
      { ...data.params },
    );
  },
};
