import React, { memo } from 'react';
//
import { H1 } from '@atoms/h1';
import { Button } from '@uikit/Button';
import { TOnclickHandler } from '@shared/types';
import { VideoInstructionWithDropdown } from '@components/videoInstructionWithDropdown';
import { TInstructionData } from '@const/common';

interface IHeaderWithButtonProps {
  /**
   * Текст заголовка
   * @param {status}
   */
  headerText: string;
  /**
   * Тест кнопки
   * @param {string}
   */
  buttonText?: string;
  /**
   * Флаг отключения кнопки
   * @param {disabled}
   */
  disabled?: boolean;
  /**
   * Опциональный флаг показывает или скрывает кнопку создания нового шаблона
   * @param {boolean}
   */
  isTemplateList?: boolean;
  /**
   * Callback на кнопку
   * @param {TOnclickHandler}
   */
  callBack?: TOnclickHandler;
  /**
   * Данные для отображения инструкций
   * @param {TInstructionData[]}
   */
  instructionData?: TInstructionData[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const HeaderWithButton = memo(
  ({
    callBack,
    headerText,
    isTemplateList,
    instructionData,
    disabled = false,
    buttonText = 'no text found',
    className = '',
  }: IHeaderWithButtonProps) => (
    <div className={`flex flex-wrap items-center justify-between ${className}`}>
      <H1>{headerText}</H1>
      <div className='flex'>
        {isTemplateList && callBack ? (
          <Button
            dense
            icon='plus'
            type='action'
            view='filled'
            color='success'
            text={buttonText}
            onClick={callBack}
            disabled={disabled}
            className='mr-4'
          />
        ) : null}
        {instructionData ? (
          <VideoInstructionWithDropdown instructionData={instructionData} />
        ) : null}
      </div>
    </div>
  ),
);

HeaderWithButton.displayName = 'HeaderWithButton';
