import React, { memo, useCallback } from 'react';
//
import { TDeleteStep } from '@shared/types';
import { ReviewsCard } from '@components/reviewsCard';
import { ToastsMessages } from '@components/toastsMessages';
import { TReviewInListMapData } from '@redux/reviews/models';
import { getReviewsPageData } from '@redux/reviews/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { deleteReview, reviewStepDelete } from '@redux/reviews/reviewsThunks';
import { clearChainErrors, clearSuccessStatus } from '@redux/reviews/reviewsSlice';

interface IReviewsListProps {
  /**
   * Данные списка цепочек отзывов
   * @param {TReviewInListMapData[]}
   */
  data: TReviewInListMapData[];
  /**
   * Флаг доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const ReviewsList = memo(
  ({ data, className = '', isEditTemplatesAccess }: IReviewsListProps) => {
    const dispatch = useAppDispatch();

    const {
      isSaveError,
      errorMessage,
      successMessage,
      isDeletingError,
      isSwitchingError,
      isDeleteStepSuccess,
      isChainDeletingSuccess,
    } = useAppSelector(getReviewsPageData);

    const deleteChainHandler = useCallback(
      (chainId: string) => () => {
        dispatch(deleteReview({ chainId }));
      },
      [dispatch],
    );

    const deleteStepHandler: TDeleteStep = useCallback(
      ({ stepId, templateId }) => {
        dispatch(reviewStepDelete({ templateId, stepId }));
      },
      [dispatch],
    );

    const clearAllErrorHandler = useCallback(() => {
      dispatch(clearChainErrors());
    }, [dispatch]);

    const clearAllSuccessStatusHandler = useCallback(() => {
      dispatch(clearSuccessStatus());
    }, [dispatch]);

    const renderChain = data.map((item, index) => {
      const key = `${index}${item.firstStep.body.slice(0, 10)}`;
      const isNotLastElement = index < data.length - 1;

      return (
        <ReviewsCard
          key={key}
          data={item}
          deleteStep={deleteStepHandler}
          reviewsChanLength={data.length}
          templateCardId={String(item.firstStep.id)}
          isEditTemplatesAccess={isEditTemplatesAccess}
          deleteChain={deleteChainHandler(item.firstStep.id)}
          className={isNotLastElement ? 'mb-6' : ''}
        />
      );
    });

    const isShowSuccessToast = isChainDeletingSuccess || isDeleteStepSuccess;

    const isShowErrorToast = isDeletingError || isSwitchingError || isSaveError;

    return (
      <div className={`flex flex-col ${className}`}>
        {renderChain}
        <ToastsMessages
          errorMessage={errorMessage}
          successMessage={successMessage}
          isShowErrorToast={isShowErrorToast}
          isShowSuccessToast={isShowSuccessToast}
          clearErrorCallback={clearAllErrorHandler}
          clearSuccessStatusCallback={clearAllSuccessStatusHandler}
        />
      </div>
    );
  },
);

ReviewsList.displayName = 'ReviewsList';
