export const BASE_TEMPLATE_PAGE_TEXT = {
  header: {
    productName: 'Бьюти Бот',
    homeLink: {
      imgAlt: 'Домой',
      imgTitle: 'На главную',
    },
    avatarAlt: 'Аватар пользователя',
    logoutButton: 'Выйти',
  },
  support: {
    header: 'Поддержка:',
    telegramButton: 'Telegram',
  },
  menu: {
    filials: 'Филиалы',
    connection: 'Подключение',
    templates: 'Шаблоны',
    eventTemplates: 'При событии',
    timeTemplates: 'По времени',
    reviews: 'Цепочка отзывов',
    templatesStatistics: 'Статистика',
    sendOut: 'Рассылки',
    newSendOut: 'Новая рассылка',
    startedSendOut: 'Запущенные рассылки',
    sendOutStatistics: 'Статистика',
    inviteFriend: 'Приведи друга',
    inviteFriendTemplates: 'Акции',
    inviteFriendStatistics: 'Статистика',
    autoResponse: 'Автоответы',
    blacklist: 'Черный список',
    settings: 'Настройки',
    access: 'Доступы',
    partnersProgram: 'Партнерская программа',
    conditionsAndPartners: 'Условия и партнёры',
    operationList: 'Список операций',
    payHistory: 'История выплат',
    addLeads: 'Добавление лидов',
    chatHistory: 'История переписки',
    payment: 'Оплата',
  },
  abNewCommercial: {
    button: 'Подробнее',
  },
  abCommercial: {
    header: 'Админ Бот',
    description: 'Автоматизация постановки задач для сотрудников',
    button: 'Подключить',
  },
  feedback: {
    button: 'Оставить отзыв',
  },
  feedbackModal: {
    title: 'Оставьте отзыв о личном кабинете',
    textareaPlaceholder: 'Введите текст',
    addFile: 'Загрузить картинку или видео',
    cancelButton: 'Отмена',
    sendButton: 'Отправить',
  },
  footer: {
    link: '© бьютибот.рф',
    knowledgeBase: 'База знаний',
  },
  instructionButton: 'Видео-инструкция',
  lkLoginInstruction: 'Как войти в личный кабинет ',
};

export const BASE_TEMPLATE_PAGE_TEXT_ENG = {
  header: {
    productName: 'Beauty Bot',
    homeLink: {
      imgAlt: 'Home',
      imgTitle: 'Home',
    },
    avatarAlt: 'User avatar',
    logoutButton: 'Logout',
  },
  support: {
    header: 'Support:',
    telegramButton: 'Telegram',
  },
  menu: {
    filials: 'Branches',
    connection: 'Connection',
    templates: 'Templates',
    eventTemplates: 'On event',
    timeTemplates: 'By time',
    reviews: 'Review chain',
    templatesStatistics: 'Statistics',
    sendOut: 'Mailings',
    newSendOut: 'New mailing',
    startedSendOut: 'Launched mailings',
    sendOutStatistics: 'Statistics',
    inviteFriend: 'Bring a friend',
    inviteFriendTemplates: 'Promotions',
    inviteFriendStatistics: 'Statistics',
    autoResponse: 'Auto responses',
    blacklist: 'Black list',
    settings: 'Settings',
    access: 'Accesses',
    partnersProgram: 'Partnership program',
    conditionsAndPartners: 'Conditions and partners',
    operationList: 'List of operations',
    payHistory: 'Payment history',
    addLeads: 'Adding leads',
    chatHistory: 'Chat history',
    payment: 'Payment',
  },
  abNewCommercial: {
    button: 'More details',
  },
  abCommercial: {
    header: 'Admin Bot',
    description: 'Automation of setting tasks for employees',
    button: 'Connect',
  },
  feedback: {
    button: 'Leave feedback',
  },
  feedbackModal: {
    title: 'Leave a review about your personal account',
    textareaPlaceholder: 'Enter text',
    addFile: 'Upload media file',
    cancelButton: 'Cancel',
    sendButton: 'Send',
  },
  footer: {
    link: '© бьютибот.рф',
    knowledgeBase: 'Knowledge Base',
  },
  instructionButton: 'Video instruction',
  lkLoginInstruction: 'How to login',
};
