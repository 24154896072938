import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { IconWithTooltips } from '@components/iconWithTooltips';
import { THelpKeys, TWithOptionalChildren } from '@models/index';

interface ITextWithInfoIconProps extends TWithOptionalChildren {
  /**
   * Ключ для выбора текста в label текста берется из словаря по переданному ключу
   * @param {THelpKeys}
   */
  variant: THelpKeys;
  /**
   * Опциональный параметр, размер текста в label
   * @param {string}
   * @default
   */
  fontSize?: string;
  /**
   * Опциональный параметр, размер нижнего отступа для иконки (добавляется к mb-)
   * @param {string}
   * @default
   */
  marginBottom?: string;
  /**
   * Опциональный параметр отображает обучающую подсказку
   * @param {boolean}
   */
  isFirstTooltip?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const TextWithInfoIcon = memo(
  ({
    variant,
    children,
    marginBottom,
    fontSize = '',
    isFirstTooltip,
    className = '',
  }: ITextWithInfoIconProps) => {
    const { t } = useTranslation();

    return (
      <div className={`flex items-center font-medium font-inter text-body_text ${className}`}>
        <p className={`m-0 mr-2 ${fontSize}`}>{t(`LABEL_TEXT.${variant}`)}</p>
        <div className={children ? 'mr-2' : ''}>{children}</div>
        <IconWithTooltips
          tooltips={variant}
          isFirstTooltip={isFirstTooltip}
          className={marginBottom ? `mb-${marginBottom}` : 'mb-0'}
        />
      </div>
    );
  },
);

TextWithInfoIcon.displayName = 'TextWithInfoIcon';
