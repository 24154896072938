import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { EMPLOYEES } from '@const/pay';
import { numWord } from '@helpers/index';
import { TAppLanguage } from '@models/index';
import { Status } from '@blocks/colorStatus';
import { TextWithInfoIcon } from '@blocks/textWithInfoIcon';

type TFilialPayData = {
  /**
   * Сумма ежемесячной оплаты
   * @param {string}
   */
  amount: string;
  /**
   * ID филиала
   * @param {string}
   */
  branch: string;
  /**
   * Название филиала
   * @param {string}
   */
  filialName: string;
  /**
   * Статус оплаты
   * @param {boolean}
   */
  payStatus: boolean;
  /**
   * До какой даты оплачена подписка
   * @param {string}
   */
  payTillDate: string;
  /**
   * Количество сотрудников в филиале
   * @param {string}
   */
  branchEmployees: string;
  /**
   * Флаг подключения автоплатежа
   * @param {boolean}
   */
  isAutoPayEnable: boolean;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Текст скидки
   * @param {string}
   */
  discountText: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const FilialPayData = memo(
  ({
    amount,
    branch,
    payStatus,
    filialName,
    appLanguage,
    payTillDate,
    discountText,
    isAutoPayEnable,
    branchEmployees,
    className = '',
  }: TFilialPayData) => {
    const { t } = useTranslation();

    const payStatusText = payStatus
      ? t('PAY_PAGE_TEXT.payTill', { payTillDate })
      : t('PAY_PAGE_TEXT.notPaid');

    const autoPayText = t('PAY_PAGE_TEXT.payEvery', { payTillDate: payTillDate.slice(0, 2) });

    const filialNameAndBranch = t('PAY_PAGE_TEXT.filial', { filialName, branch });

    const tariffData = t('PAY_PAGE_TEXT.tariffData', {
      branchEmployees,
      employersText: numWord(+branchEmployees, EMPLOYEES[appLanguage]),
      amount,
    });

    return (
      <div className={className}>
        <div className='flex flex-wrap justify-between'>
          <TextWithInfoIcon
            isFirstTooltip
            fontSize='text-[1.5rem] font-bold leading-9'
            variant='tariff'
            className='mb-2'
          />
          <div className='flex items-center mb-4 font-medium tracking-[0.0275em]'>
            <Status isPayStatus status={payStatus} title={t('PAY_PAGE_TEXT.payStatusTitle')} />
            <span className='ml-3'>{isAutoPayEnable ? autoPayText : payStatusText}</span>
          </div>
        </div>
        <p className='text-grayText mb-4 tracking-[0.0275em]'>{filialNameAndBranch}</p>
        <p className='whitespace-pre-wrap break-words text-black tracking-[0.0275em]'>
          {tariffData}
        </p>
        <p className='whitespace-pre-wrap break-words text-black tracking-[0.0275em]'>
          {discountText}
        </p>
      </div>
    );
  },
);

FilialPayData.displayName = 'FilialPayData';
