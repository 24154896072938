import React, { memo, useCallback } from 'react';
//
import { TimePicker } from '@uikit/DateTime/TimePicker';
import { getRoundedTime, getTime, selectTextContentOnFocus } from '@helpers/index';

interface IWhenTimeProps {
  /**
   * Время в формате HH:MM
   * @param {string}
   */
  whenTime: string;
  /**
   * Опциональный параметр при передаче этого флага можно изменять только часы
   * @param {string}
   */
  isSetOnlyHour?: boolean;
  /**
   * Опциональный параметр, округляет минуты кратно переданному значению
   * @param {number}
   */
  roundTo?: number;
  /**
   * Callback обрабатывает изменение данных в input
   * @param {(value: string) => void}
   */
  onChangeHandler: (value: string) => void;
  /**
   * Включает округление вверх
   * @param {boolean}
   */
  isRoundCeil?: boolean;
  /**
   * Флаг отключения инпута
   * @param {boolean}
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const WhenTime = memo(
  ({
    roundTo,
    whenTime,
    disabled,
    isRoundCeil,
    isSetOnlyHour,
    onChangeHandler,
    className = '',
  }: IWhenTimeProps) => {
    // срабатывает при вводе нового времени, округляет минуты до четверти часа, формирует строку времени
    // и записывает в редакс вызовом callback
    const setTimeHandler = useCallback(
      (date: Date) => {
        onChangeHandler(getRoundedTime(date, !!isSetOnlyHour, roundTo, isRoundCeil));
      },
      [onChangeHandler, isSetOnlyHour, roundTo, isRoundCeil],
    );

    return (
      <TimePicker
        color='primary'
        useAmPm={false}
        precision='MINUTE'
        disabled={disabled}
        showArrowButtons={false}
        onChange={setTimeHandler}
        value={getTime(whenTime)}
        onFocus={selectTextContentOnFocus}
        className={`border border-dGray rounded ${
          isSetOnlyHour ? 'disable_minutes_input' : ''
        } ${className}`}
      />
    );
  },
);

WhenTime.displayName = 'WhenTime';
