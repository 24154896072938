export const REG_EXP = {
  onlyNumbers: /\D/g,
  // eslint-disable-next-line
  onlyCutEmoji: /([а-яА-яёЁ]|[\d]|[\w]|[\s]|[!\]\[@#$%^&*()_+=`~<>,./?"№;:|])/gm,
  openKey: /{/gm,
  closeKey: /}/gm,
  openKeyWithSpace: /\s{/gm,
  closeKeyWithSpace: /}\s/gm,
};

/**
 * Сообщения для toast
 * @const COMMON_TOAST_MESSAGES
 */
export const COMMON_TOAST_MESSAGES = {
  SAVE_SUCCESS: 'Сохранено',
  COMMON_ERROR_MESSAGE: 'Ошибка\n перезагрузите страницу \n и попробуйте еще раз',
  FILE_TO_BIG: 'Размер файла \n превышает 20 мегабайт, \n выберите файл меньшего размера',
  UNSUPPORTED_FILE_TYPE: 'Тип файла \n не поддерживается, \n выберите другой файл',
  COMMON_SAVE_ERROR_MESSAGE: 'Ошибка сохранения, \n перезагрузите страницу \n и попробуйте еще раз',
  UNSUPPORTED_FILE: 'Тип файла \n не поддерживается, \n выберите другой файл',
  UNIQUE_TG_ID_ERROR: 'Ошибка сохранения, \n пользователь с таким telegram id \n уже существует',
  EMPTY_TG_INPUT_ERROR: 'Поле telegram id не может быть пустым',
  MAILING_ACCOUNT_NOT_PAYED: 'Отправка рассылок\n доступна только на платном тарифе',
  VISITS_QUANTITY_ERROR:
    "Нельзя устанавливать этот параметр выше,\n чем параметр 'До' в блоке 'Клиентам с количеством визитов'",
};

/**
 * Сообщения для toast
 * @const COMMON_TOAST_MESSAGES_ENG
 */
export const COMMON_TOAST_MESSAGES_ENG = {
  SAVE_SUCCESS: 'Saved',
  COMMON_ERROR_MESSAGE: 'Error\n please reload the page\n and try again',
  FILE_TO_BIG: 'The file size \n is greater than 20 megabytes, \n please select a smaller file',
  UNSUPPORTED_FILE_TYPE: 'The file type \n is not supported, \n please select another file',
  COMMON_SAVE_ERROR_MESSAGE: 'Saving error, \n please reload the page \n and try again',
  UNSUPPORTED_FILE: 'File type \n is not supported, \n please select another file',
  UNIQUE_TG_ID_ERROR: 'Saving error, \n a user with the same telegram id \n already exists',
  EMPTY_TG_INPUT_ERROR: 'The telegram id field cannot be empty',
  MAILING_ACCOUNT_NOT_PAYED: 'Sending mailings\n is only available on a paid plan',
  VISITS_QUANTITY_ERROR:
    "You cannot set this parameter higher\nthan the 'To' parameter in the 'Clients with number of visits' block",
};

export const COMMON_TOAST = {
  SAVE_SUCCESS: 'COMMON_TOAST_MESSAGES.SAVE_SUCCESS',
  COMMON_ERROR_MESSAGE: 'COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE',
  FILE_TO_BIG: 'COMMON_TOAST_MESSAGES.FILE_TO_BIG',
  UNSUPPORTED_FILE_TYPE: 'COMMON_TOAST_MESSAGES.UNSUPPORTED_FILE_TYPE',
  COMMON_SAVE_ERROR_MESSAGE: 'COMMON_TOAST_MESSAGES.COMMON_SAVE_ERROR_MESSAGE',
  UNSUPPORTED_FILE: 'COMMON_TOAST_MESSAGES.UNSUPPORTED_FILE',
  UNIQUE_TG_ID_ERROR: 'COMMON_TOAST_MESSAGES.UNIQUE_TG_ID_ERROR',
  EMPTY_TG_INPUT_ERROR: 'COMMON_TOAST_MESSAGES.EMPTY_TG_INPUT_ERROR',
  MAILING_ACCOUNT_NOT_PAYED: 'COMMON_TOAST_MESSAGES.MAILING_ACCOUNT_NOT_PAYED',
};

export const TEMPLATE_WARNING_MESSAGE = {
  DISABLE_CLIENT_DID_NOT_COME_CHECKBOX: 'Нельзя использовать со статусом не пришел',
};

export const TEMPLATE_WARNING_MESSAGE_ENG = {
  DISABLE_CLIENT_DID_NOT_COME_CHECKBOX: 'Cannot be used with not arrived status',
};

export const TEMPLATE_WARNING = {
  DISABLE_CLIENT_DID_NOT_COME_CHECKBOX:
    'TEMPLATE_WARNING_MESSAGE.DISABLE_CLIENT_DID_NOT_COME_CHECKBOX',
};

export const CURRENCY = '₽';
export const CURRENCY_ENG = '$';

export enum PROMISE_STATUS {
  REJECTED = 'rejected',
}

export enum SORTING_TYPES {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export const ONE_DAY_IN_MS = 86395000;

export const TWO_DAY_IN_MS = ONE_DAY_IN_MS * 2;

export const DAYS_IN_WEEK = 7;

export const ONE_WEEK_IN_MS = 518400000;

export const TEXTAREA_TEXT_MAX_LENGTH = 2000;

export enum BALLOONS_ACTIONS {
  ADD = 'add',
}

export const DAYS = {
  eng: ['day', 'days', 'days'],
  rus: ['день', 'дня', 'дней'],
};

export const FIRST_TOOLTIP = {
  text: 'Нажмите на значок «i», чтобы прочитать подсказку',
};

export const FIRST_TOOLTIP_ENG = {
  text: 'Click on the "i" icon to read the hint',
};

export const HOUR = {
  eng: ['hour', 'hours', 'hours'],
  rus: ['час', 'часа', 'часов'],
};

export const ERROR_MESSAGES_TEXT = {
  nothingFound: 'Ничего не найдено',
};

export const ERROR_MESSAGES_TEXT_ENG = {
  nothingFound: 'Nothing found',
};

export type TInstructionData = {
  headerText: string;
  videoLink: string;
  startTime: string;
};

const VIDEO_INSTRUCTION_LINKS = {
  LOGIN: 'https://www.youtube.com/embed/rTmWOxqtNWs?si=hZ280vDFt28MRviN',
  CONNECTION: 'https://www.youtube.com/embed/PnjYbTxSN_g?si=iQwnp1U-DNvwoL5I',
  TEMPLATES: 'https://www.youtube.com/embed/ZB_HHlOaXQg?si=h14hm74soCpPZpwM&amp',
  MAILING: 'https://www.youtube.com/embed/u64lNL_jNaw?si=ZGNVmnishhTXNKD6&amp',
  AUTO_RESPONSE: 'https://www.youtube.com/embed/fCEhwt0zZ_s?si=6XiVT7mOY4EyEuV3&amp',
  // Исправить ссылку после записи видео
  INVITE_FRIENDS: 'https://www.youtube.com/embed/fesh3Jj5Pbc?si=Tc4n-YRMWKKL80M-&amp',
  BLACK_LIST: 'https://www.youtube.com/embed/fesh3Jj5Pbc?si=Tc4n-YRMWKKL80M-&amp',
  SETTINGS: 'https://www.youtube.com/embed/5YKrPJNi9CM?si=9c9LfgG4S2ED_lz4&amp',
  PARTNERS: 'https://www.youtube.com/embed/7Mo8rF1xbJE?si=qt6DHPjbKeGp0tlD&amp',
  CHAT_HISTORY: 'https://www.youtube.com/embed/zA1HTF9GRLs?si=VQnToJcs7R4bJfjQ&amp',
  PAY: 'https://www.youtube.com/embed/dVAqszlgIP4?si=MPL9biJA1_l0ixfJ&amp',
};

export const VIDEO_INSTRUCTIONS: Record<string, TInstructionData[]> = {
  login: [
    {
      headerText: 'Как войти в личный кабинет',
      videoLink: VIDEO_INSTRUCTION_LINKS.LOGIN,
      startTime: '1',
    },
  ],
  connection: [
    {
      headerText: 'Подключение Бьюти Бота к рабочему номеру салона красоты',
      videoLink: VIDEO_INSTRUCTION_LINKS.CONNECTION,
      startTime: '1',
    },
  ],
  templates: [
    {
      headerText: 'Типы шаблонов',
      videoLink: VIDEO_INSTRUCTION_LINKS.TEMPLATES,
      startTime: '1',
    },
    {
      headerText: 'Включение и отключение рассылки шаблона',
      videoLink: VIDEO_INSTRUCTION_LINKS.TEMPLATES,
      startTime: '72',
    },
    {
      headerText: 'Удаление шаблона',
      videoLink: VIDEO_INSTRUCTION_LINKS.TEMPLATES,
      startTime: '84',
    },
    {
      headerText: 'Создание и редактирование шаблона',
      videoLink: VIDEO_INSTRUCTION_LINKS.TEMPLATES,
      startTime: '91',
    },
    {
      headerText: 'Отправка рассылки потерявшимся клиентам',
      videoLink: VIDEO_INSTRUCTION_LINKS.TEMPLATES,
      startTime: '554',
    },
    {
      headerText: 'Цепочка отзывов',
      videoLink: VIDEO_INSTRUCTION_LINKS.TEMPLATES,
      startTime: '663',
    },
    {
      headerText: 'Настройка шаблона для получения отзывов',
      videoLink: VIDEO_INSTRUCTION_LINKS.TEMPLATES,
      startTime: '643',
    },
    {
      headerText: 'Градация отзывов и принцип их публикации в гео сервисах',
      videoLink: VIDEO_INSTRUCTION_LINKS.TEMPLATES,
      startTime: '735',
    },
    {
      headerText: 'Получение негативных отзывов на телефон',
      videoLink: VIDEO_INSTRUCTION_LINKS.TEMPLATES,
      startTime: '760',
    },
    {
      headerText: 'Статистика по шаблонам',
      videoLink: VIDEO_INSTRUCTION_LINKS.TEMPLATES,
      startTime: '769',
    },
    {
      headerText: 'Копирование шаблонов и их настроек',
      videoLink: VIDEO_INSTRUCTION_LINKS.TEMPLATES,
      startTime: '785',
    },
    {
      headerText: 'Редактирование шаблонов из тг бота',
      videoLink: VIDEO_INSTRUCTION_LINKS.TEMPLATES,
      startTime: '814',
    },
  ],
  mailing: [
    {
      headerText: 'Создание новых рассылок',
      videoLink: VIDEO_INSTRUCTION_LINKS.MAILING,
      startTime: '1',
    },
    {
      headerText: 'Работа с файлом клиентов',
      videoLink: VIDEO_INSTRUCTION_LINKS.MAILING,
      startTime: '43',
    },
    {
      headerText: 'Добавляем описание и картинку к тексту',
      videoLink: VIDEO_INSTRUCTION_LINKS.MAILING,
      startTime: '75',
    },
    {
      headerText: 'Установление даты и времени рассылки',
      videoLink: VIDEO_INSTRUCTION_LINKS.MAILING,
      startTime: '119',
    },
    {
      headerText: 'Особенности отправки тестового сообщения',
      videoLink: VIDEO_INSTRUCTION_LINKS.MAILING,
      startTime: '161',
    },
    {
      headerText: 'В каком случае может не прийти тестовое сообщение',
      videoLink: VIDEO_INSTRUCTION_LINKS.MAILING,
      startTime: '183',
    },
    {
      headerText: 'Отслеживание запущенных рассылок',
      videoLink: VIDEO_INSTRUCTION_LINKS.MAILING,
      startTime: '210',
    },
    {
      headerText: 'Повторная отправка рассылки',
      videoLink: VIDEO_INSTRUCTION_LINKS.MAILING,
      startTime: '225',
    },
    {
      headerText: 'Статистика по завершенным рассылками',
      videoLink: VIDEO_INSTRUCTION_LINKS.MAILING,
      startTime: '256',
    },
    {
      headerText: 'Отправка рассылки через телеграм по шагам',
      videoLink: VIDEO_INSTRUCTION_LINKS.MAILING,
      startTime: '261',
    },
    {
      headerText: 'Как выгрузить файл для рассылки из Yclients',
      videoLink: VIDEO_INSTRUCTION_LINKS.MAILING,
      startTime: '305',
    },
    {
      headerText: 'Как привести файл к корректному виду для рассылки',
      videoLink: VIDEO_INSTRUCTION_LINKS.MAILING,
      startTime: '345',
    },
  ],
  autoResponse: [
    {
      headerText: 'Автоматические ответы бота',
      videoLink: VIDEO_INSTRUCTION_LINKS.AUTO_RESPONSE,
      startTime: '1',
    },
    {
      headerText: 'Инструкция по настройке автоответов',
      videoLink: VIDEO_INSTRUCTION_LINKS.AUTO_RESPONSE,
      startTime: '17',
    },
    {
      headerText: 'Создание нового автоответа',
      videoLink: VIDEO_INSTRUCTION_LINKS.AUTO_RESPONSE,
      startTime: '23',
    },
    {
      headerText: 'Отключение автоответа',
      videoLink: VIDEO_INSTRUCTION_LINKS.AUTO_RESPONSE,
      startTime: '49',
    },
    {
      headerText: 'Редактирование автоответа',
      videoLink: VIDEO_INSTRUCTION_LINKS.AUTO_RESPONSE,
      startTime: '55',
    },
    {
      headerText: 'Как удалить автоответ',
      videoLink: VIDEO_INSTRUCTION_LINKS.AUTO_RESPONSE,
      startTime: '59',
    },
    {
      headerText: 'Добавление дополнительных ключевых слов',
      videoLink: VIDEO_INSTRUCTION_LINKS.AUTO_RESPONSE,
      startTime: '62',
    },
    {
      headerText: 'Важно: про ключевые слова',
      videoLink: VIDEO_INSTRUCTION_LINKS.AUTO_RESPONSE,
      startTime: '74',
    },
  ],
  inviteFriends: [
    {
      headerText: 'Типы акций',
      videoLink: VIDEO_INSTRUCTION_LINKS.INVITE_FRIENDS,
      startTime: '1',
    },
  ],
  blacklist: [
    {
      headerText: 'Черный список клиентов салона красоты',
      videoLink: VIDEO_INSTRUCTION_LINKS.BLACK_LIST,
      startTime: '1',
    },
  ],
  settings: [
    {
      headerText: '«Проверка на ответ»',
      videoLink: VIDEO_INSTRUCTION_LINKS.SETTINGS,
      startTime: '1',
    },
    {
      headerText: 'Опция, которая позволяет отвечать в WhatsApp из телеграма',
      videoLink: VIDEO_INSTRUCTION_LINKS.SETTINGS,
      startTime: '42',
    },
    {
      headerText: 'Функция «включить трансляцию»',
      videoLink: VIDEO_INSTRUCTION_LINKS.SETTINGS,
      startTime: '70',
    },
    {
      headerText: 'Ответ в WhatsApp из телеграм',
      videoLink: VIDEO_INSTRUCTION_LINKS.SETTINGS,
      startTime: '110',
    },
    {
      headerText: 'Как получать коды подтверждения в смс',
      videoLink: VIDEO_INSTRUCTION_LINKS.SETTINGS,
      startTime: '144',
    },
    {
      headerText: 'Выбор сотрудников, по которым не нужно отправлять шаблоны и уведомления',
      videoLink: VIDEO_INSTRUCTION_LINKS.SETTINGS,
      startTime: '235',
    },
    {
      headerText: 'Скрытие номеров клиентов',
      videoLink: VIDEO_INSTRUCTION_LINKS.SETTINGS,
      startTime: '265',
    },
    {
      headerText: 'Временные лимиты для отправки сообщений',
      videoLink: VIDEO_INSTRUCTION_LINKS.SETTINGS,
      startTime: '308',
    },
    {
      headerText: 'Получение счета в личный кабинет или телеграм',
      videoLink: VIDEO_INSTRUCTION_LINKS.SETTINGS,
      startTime: '410',
    },
    {
      headerText: 'Выбор смс провайдера',
      videoLink: VIDEO_INSTRUCTION_LINKS.SETTINGS,
      startTime: '425',
    },
    {
      headerText: 'Раздел с доступами администраторов для владельца аккаунта',
      videoLink: VIDEO_INSTRUCTION_LINKS.SETTINGS,
      startTime: '485',
    },
    {
      headerText: 'Добавление администратора',
      videoLink: VIDEO_INSTRUCTION_LINKS.SETTINGS,
      startTime: '505',
    },
    {
      headerText: 'Получение телеграм ID для администратора',
      videoLink: VIDEO_INSTRUCTION_LINKS.SETTINGS,
      startTime: '550',
    },
  ],
  partners: [
    {
      headerText: 'Партнерская программа бота для салонов красоты',
      videoLink: VIDEO_INSTRUCTION_LINKS.PARTNERS,
      startTime: '1',
    },
  ],
  chatHistory: [
    {
      headerText: 'История переписки в приложении для парикмахерских и салонов красоты Бьюти Бот',
      videoLink: VIDEO_INSTRUCTION_LINKS.CHAT_HISTORY,
      startTime: '1',
    },
  ],
  // pay: [
  //   {
  //     headerText: 'Подключение автоплатежа и оплата выбранного тарифа',
  //     videoLink: VIDEO_INSTRUCTION_LINKS.PAY,
  //     startTime: '1',
  //   },
  //   {
  //     headerText: 'Оплата картой',
  //     videoLink: VIDEO_INSTRUCTION_LINKS.PAY,
  //     startTime: '30',
  //   },
  //   {
  //     headerText: 'Оплата по счёту',
  //     videoLink: VIDEO_INSTRUCTION_LINKS.PAY,
  //     startTime: '40',
  //   },
  //   {
  //     headerText: 'Продление работы бота до получения оплаты по счёту',
  //     videoLink: VIDEO_INSTRUCTION_LINKS.PAY,
  //     startTime: '75',
  //   },
  //   {
  //     headerText: 'Оплата и продление бота из телеграм',
  //     videoLink: VIDEO_INSTRUCTION_LINKS.PAY,
  //     startTime: '90',
  //   },
  //   {
  //     headerText: 'Оплата нескольких филиалов',
  //     videoLink: VIDEO_INSTRUCTION_LINKS.PAY,
  //     startTime: '114',
  //   },
  // ],
};

export const ConversionColors = {
  2: '#ff0000',
  2.1: '#ffa500',
  4.6: '#e7e713',
  7.6: '#008000',
};
