import React from 'react';
import { useTranslation } from 'react-i18next';
//
import { Table } from '@uikit/Table/Table';
import { useAppDispatch } from '@store/store';
import { TSendOutDetailsStatisticsData } from '@models/index';
import { setSortTypeDataSDS } from '@redux/sendOutStatisticsTable/sendOutStatisticsTableSlice';

type TMailingDetailStatsTable = {
  /**
   * Данные для таблицы
   * @param {TSendOutDetailsStatisticsData[]}
   */
  tableData: TSendOutDetailsStatisticsData[];
};

export const MailingDetailStatsTable = ({ tableData }: TMailingDetailStatsTable) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // Создаем массив для отображения шапки таблицы
  const Head = [
    { id: 'phone', label: t('MAILING_PAGE_TEXT.mailingDetailStat.phone') },
    { id: 'name', label: t('MAILING_PAGE_TEXT.mailingDetailStat.name') },
    { id: 'replyTime', label: t('MAILING_PAGE_TEXT.mailingDetailStat.replyTime') },
    { id: 'recordTime', label: t('MAILING_PAGE_TEXT.mailingDetailStat.recordTime') },
    { id: 'service', label: t('MAILING_PAGE_TEXT.mailingDetailStat.service') },
    { id: 'summaryCost', label: t('MAILING_PAGE_TEXT.mailingDetailStat.summaryCost') },
  ];

  // функция сортировки, получает название столбца отправляет в редакс
  const setSortTypeHandler = (collumName: string, order: string) => {
    dispatch(setSortTypeDataSDS({ collumName, order }));
  };

  // Создаем массив элементов для тела таблицы, если нет данных, возвращаем массив с пустым объектом
  const getTableData = (data: TSendOutDetailsStatisticsData[]) => {
    const textClass = 'text-blackText leading-6 tracking-[0.0275em]';
    const emptyData = [
      {
        phone: ' ',
        name: ' ',
        replyTime: ' ',
        recordTime: ' ',
        service: ' ',
        summaryCost: ' ',
      },
    ];
    if (!data.length) {
      return emptyData;
    }
    return data.map(item => {
      const recordTime = item.recordTime.split(' ');
      const replyTime = item.replyTime.split(' ');

      return {
        phone: <span className={textClass}>{item.phone}</span>,
        name: <span className={textClass}>{item.name}</span>,
        replyTime: (
          <div>
            <span className='text-grayText leading-6 tracking-[0.0275em] mr-1'>{replyTime[0]}</span>
            {replyTime[1] ? <span>{replyTime[1]}</span> : null}
          </div>
        ),
        recordTime: (
          <div>
            <span className='text-grayText leading-6 tracking-[0.0275em] mr-1'>
              {recordTime[0]}
            </span>
            {recordTime[1] ? <span>{recordTime[1]}</span> : null}
          </div>
        ),
        service: (
          <span className={`flex !min-w-[12.5rem] whitespace-pre-line ${textClass}`}>
            {item.service}
          </span>
        ),
        summaryCost: <span className={textClass}>{item.summaryCost || '-'}</span>,
      };
    });
  };

  return (
    <div className='overflow-x-auto'>
      <Table
        name={Head}
        data={getTableData(tableData)}
        position={['left', 'left', 'left', 'left', 'left', 'right']}
        type='def'
        view='flat'
        color='default'
        pages
        labelRowsPerPage={t('MAILING_PAGE_TEXT.mailingDetailStat.pagination')}
        rowsPerPageOptions={[5, 8, 20]}
        defRows={20}
        checkboxes={false}
        paperClassName='no_shadow'
        onSortCallback={setSortTypeHandler}
        style={{ boxShadow: 'none', marginLeft: '6px' }}
        className='w-full'
      />
    </div>
  );
};
