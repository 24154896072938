import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { REG_EXP } from '@const/common';
import { BLACKLIST_TOAST } from '@const/blacklist';
import { TRejectResponseData } from '@models/index';
import { TBlackListData } from '@redux/blackList/models';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { addNewLines, validatePhoneNumber } from '@helpers/index';
import { REJECT_RESPONSE_KEY, REJECT_RESPONSE_2_KEY } from '@api/types';
import { IInitialState, initialState } from '@redux/blackList/initialState';
import {
  getBlackList,
  addNumberToBlacklist,
  deleteNumberFromBlacklist,
} from '@redux/blackList/blackListThunks';

export const blackListSlice = createSlice({
  name: 'blackList',
  initialState,
  reducers: {
    setNewNumber(state: IInitialState, action: PayloadAction<{ value: string }>) {
      const newNumber = validatePhoneNumber(action.payload.value.replace(REG_EXP.onlyNumbers, ''));

      if (newNumber.length < 14) {
        state.newNumber = action.payload.value.replace(REG_EXP.onlyNumbers, '');
      }
    },
    setNumberToDelete(state: IInitialState, action: PayloadAction<{ number: string }>) {
      state.numberToDelete = action.payload.number;
    },
    setSearchString(state: IInitialState, action: PayloadAction<{ value: string }>) {
      const searchString = action.payload.value;
      state.searchString = searchString;
      if (!searchString) {
        state.filteredState = state.data;
      } else {
        state.filteredState = state.data.filter(item => item.number.includes(state.searchString));
      }
    },
    setFilteredList(state: IInitialState) {
      state.filteredState = state.data.filter(item => item.number.includes(state.searchString));
    },
    setFullBlackList(state: IInitialState) {
      state.filteredState = state.data;
    },
    showAddNumberSuccessMessage(state: IInitialState) {
      state.isAddSuccess = true;
    },
    hideAddNumberSuccessMessage(state: IInitialState) {
      state.isAddSuccess = false;
    },
    showDeletingSuccessMessage(state: IInitialState) {
      state.isDeleteSuccess = true;
    },
    hideDeletingSuccessMessage(state: IInitialState) {
      state.isDeleteSuccess = false;
    },
    hideAllErrorToast(state: IInitialState) {
      state.errorMessage = '';
      state.isAddingNumberError = false;
      state.isDeleteNumberError = false;
    },
    hideAllSuccessToast(state: IInitialState) {
      state.successMessage = '';
      state.isAddSuccess = false;
      state.isDeleteSuccess = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getBlackList.pending, (state: IInitialState) => {
      if (!state.isRefresh) {
        state.isLoading = true;
      }
      state.isAddSuccess = false;
      state.isAddingNumber = false;
      state.isDeleteSuccess = false;
      state.isDeleteNumberError = false;
      state.isAddingNumberError = false;
    });
    builder.addCase(
      getBlackList.fulfilled,
      (state: IInitialState, action: PayloadAction<TBlackListData[] | void>) => {
        const data = action.payload;
        if (state.isRefresh) {
          state.isRefresh = false;
        }
        if (state.isRetries) {
          state.retries = 1;
          state.isRetries = false;
        }
        state.isLoading = false;
        state.data = data || [];
        state.filteredState = data || [];
      },
    );
    builder.addCase(
      getBlackList.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
            state.isLoading = false;
            state.isRefresh = false;
          }
        } else {
          state.isError = true;
          state.isLoading = false;
          state.isRefresh = false;
        }
      },
    );
    builder.addCase(addNumberToBlacklist.pending, (state: IInitialState) => {
      state.isError = false;
      state.isLoading = false;
      state.isAddSuccess = false;
      state.isAddingNumber = true;
      state.isDeleteSuccess = false;
      state.isDeleteNumberError = false;
      state.isAddingNumberError = false;
      state.errorMessage = '';
    });
    builder.addCase(addNumberToBlacklist.fulfilled, (state: IInitialState) => {
      state.newNumber = '';
      state.isRefresh = true;
      state.isAddSuccess = true;
      state.successMessage = BLACKLIST_TOAST.SUCCESS_ADD_NUMBER;
      state.isAddingNumber = false;
    });
    builder.addCase(
      addNumberToBlacklist.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.isAddingNumberError = true;
          }
          state.isAddingNumber = false;
        }
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isAddingNumber = false;
          } else {
            state.isAddingNumber = false;
            state.isAddingNumberError = true;
          }
        }
        if (action.payload && REJECT_RESPONSE_KEY.OK in action.payload) {
          state.isAddingNumber = false;
          state.isAddingNumberError = true;
          state.errorMessage = addNewLines(action.payload.status);
          return;
        }
        if (!action.payload) {
          state.isAddingNumber = false;
          state.isAddingNumberError = true;
        }
        state.errorMessage = BLACKLIST_TOAST.ERROR_ADD_NUMBER;
      },
    );
    builder.addCase(deleteNumberFromBlacklist.pending, (state: IInitialState) => {
      state.isLoading = false;
      state.isAddSuccess = false;
      state.isAddingNumber = false;
      state.isDeleteSuccess = false;
      state.isDeleteNumberError = false;
      state.isAddingNumberError = false;
      state.errorMessage = '';
    });
    builder.addCase(deleteNumberFromBlacklist.fulfilled, (state: IInitialState) => {
      state.numberToDelete = '';
      state.isDeleteSuccess = true;
      state.successMessage = BLACKLIST_TOAST.SUCCESS_DELETE_NUMBER;
      state.isRefresh = true;
    });
    builder.addCase(
      deleteNumberFromBlacklist.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.isDeleteNumberError = true;
          }
        }
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isAddingNumber = false;
          } else {
            state.isDeleteNumberError = true;
          }
        }
        if (action.payload && REJECT_RESPONSE_KEY.OK in action.payload) {
          state.isDeleteNumberError = true;
          state.errorMessage = addNewLines(action.payload.status);
          return;
        }
        if (!action.payload) {
          state.isDeleteNumberError = true;
        }
        state.errorMessage = BLACKLIST_TOAST.ERROR_DELETE_NUMBER;
      },
    );
  },
});

export const {
  setNewNumber,
  setSearchString,
  setFilteredList,
  setFullBlackList,
  setNumberToDelete,
  hideAllErrorToast,
  hideAllSuccessToast,
  showDeletingSuccessMessage,
  hideDeletingSuccessMessage,
  showAddNumberSuccessMessage,
  hideAddNumberSuccessMessage,
} = blackListSlice.actions;
export const blackListReducer = blackListSlice.reducer;
