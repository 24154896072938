import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
//
import { Loader } from '@blocks/loader';
import { TClickHandler } from '@shared/types';
import { MailingNav } from '@blocks/mailingNav';
import { getAppData } from '@redux/app/selectors';
import { QUERY_PARAMS } from '@const/apiConstants';
import { VIDEO_INSTRUCTIONS } from '@const/common';
import { ACTIVE_STEP_NUMBER } from '@const/mailing';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { MailingSteps } from '@components/mailingSteps';
import { StatisticTemplate } from '@templates/statistic';
import { PATH_NAMES, templateKeysDict } from '@data/dict';
import { StartedMailing } from '@templates/startedMailing';
import { getAccountsData } from '@redux/accounts/selectors';
import { getLinkHref, parseDivValue } from '@helpers/index';
import { MailingStepIcons } from '@blocks/mailingStepIcons';
import { ToastsMessages } from '@components/toastsMessages';
import { useAppDispatch, useAppSelector } from '@store/store';
import { HeaderWithButton } from '@components/headerWithButton';
import { MailingDetailStat } from '@templates/mailingDetailStat';
import { ImportantMailingInfo } from '@blocks/importantMailingInfo';
import { getPaymentStatusThunk, getStartedMailingTableData } from '@redux/mailing/mailingThunks';
import { getDetailSendOutStatistics } from '@redux/sendOutStatisticsTable/sendOutStatisticsTableThunks';
import {
  getStatisticTableData,
  getStatisticDataCountThunk,
} from '@redux/statistics/statisticsThunks';
import {
  getMailingData,
  getMailingTableData,
  getStatisticsPageData,
} from '@redux/mailing/selectors';
import {
  clearForm,
  wrapTextMP,
  clearAllErrors,
  addKeyInTextareaMP,
  clearAllSuccessStatuses,
  showMailingExamplesDrawer,
} from '@redux/mailing/mailingSlice';

interface IMailingProps {
  /**
   * Флаг состояния запроса
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const MailingTemplate = memo(({ isLoading, className = '' }: IMailingProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const path = useLocation().pathname;
  const windowWidth = useWindowWidth();
  const [searchParams] = useSearchParams();
  const { appLanguage } = useAppSelector(getAppData);
  const sendOutId = searchParams.get(QUERY_PARAMS.SENDOUT_ID);

  const { selectedFilial } = useAppSelector(getAccountsData);
  const div = useRef<(EventTarget & HTMLDivElement) | null>(null);
  const { data: mailingTableData } = useAppSelector(getMailingTableData);
  const {
    endDate,
    sortedBy,
    startDate,
    rowsPerPage,
    currentPage,
    searchString,
    sortDirection,
    data: statisticTableData,
  } = useAppSelector(getStatisticsPageData);
  const {
    text,
    isRefresh,
    activeStep,
    errorMessage,
    customerKeys,
    mediaFileName,
    clientFileName,
    successMessage,
    isPayedAccount,
    isShowCalendarError,
    sendOutErrorMessage,
    blurFromTextareaName,
    mediaFileTooBigError,
    unsupportedFileError,
    clientFileTooBigError,
    isParsingXLSXFileError,
    isStartingSendOutError,
    isTestMessageSendError,
    isStoppingSendOutError,
    isTestMessageSendSuccess,
    isStartingSendOutSuccess,
    isStoppingSendOutSuccess,
  } = useAppSelector(getMailingData);

  useEffect(() => {
    if (path === PATH_NAMES.MAILING && selectedFilial?.accId) {
      dispatch(clearForm());
      dispatch(getPaymentStatusThunk());
    }
  }, [path, selectedFilial?.accId, dispatch]);

  useEffect(() => {
    if (path.includes(PATH_NAMES.STARTED_MAILING) && selectedFilial?.accId) {
      dispatch(getStartedMailingTableData());
    }
  }, [dispatch, path, selectedFilial?.accId]);

  useEffect(() => {
    if (path.includes(PATH_NAMES.STARTED_MAILING) && isRefresh) {
      dispatch(getStartedMailingTableData());
    }
  }, [dispatch, path, isRefresh]);

  useEffect(() => {
    if (path.includes(PATH_NAMES.STATISTICS) && selectedFilial?.accId) {
      dispatch(
        getStatisticDataCountThunk({
          endDate,
          startDate,
          searchString,
        }),
      );
    }
  }, [path, endDate, startDate, searchString, selectedFilial?.accId, dispatch]);

  useEffect(() => {
    if (path.includes(PATH_NAMES.STATISTICS) && selectedFilial?.accId) {
      dispatch(
        getStatisticTableData({
          endDate,
          sortedBy,
          startDate,
          currentPage,
          rowsPerPage,
          searchString,
          sortDirection,
        }),
      );
    }
    // eslint-disable-next-line
  }, [
    path,
    endDate,
    sortedBy,
    startDate,
    currentPage,
    rowsPerPage,
    searchString,
    sortDirection,
    selectedFilial?.accId,
    dispatch,
  ]);

  useEffect(() => {
    if (path.includes(PATH_NAMES.MAILING_DETAIL_STATS) && selectedFilial?.accId) {
      if (sendOutId) {
        dispatch(getDetailSendOutStatistics({ sendOutId, method: 'all', getExcelFile: false }));
      }
    }
  }, [dispatch, path, selectedFilial?.accId, sendOutId]);

  // делает редирект на страницу рассылки
  const addNewMailing = useCallback(() => {
    navigate(getLinkHref({ route: PATH_NAMES.MAILING, accId: selectedFilial?.accId || '' }));
  }, [navigate, selectedFilial?.accId]);

  const focusOnTextarea = useCallback(() => {
    if (div.current) {
      div?.current.focus();
    }
  }, [div]);

  // Callback передается в компонент <TemplateAdditionalInfo />
  // добавляет спец ключ в текс 'выбранной' textarea
  const setKeyInTextarea = useCallback(
    (key: string) => () => {
      if (blurFromTextareaName && div.current) {
        dispatch(addKeyInTextareaMP({ innerText: key, callback: parseDivValue(div.current) }));
        focusOnTextarea();
      }
    },
    [blurFromTextareaName, div, focusOnTextarea, dispatch],
  );

  // Оборачивает выделенный текст в выбранные спецсимволы
  const wrapSelectedTextInTextarea: TClickHandler = useCallback(
    event => {
      if (blurFromTextareaName && div.current) {
        // получаем выбранный спецсимвол вместе с примером текстом в функцию
        // для формирования новой строки передается первый символ
        const { innerText } = event.currentTarget;

        dispatch(wrapTextMP({ innerText, callback: parseDivValue(div.current) }));
        focusOnTextarea();
      }
    },
    [blurFromTextareaName, div, focusOnTextarea, dispatch],
  );

  const openMailingExamplesDrawer = useCallback(() => {
    dispatch(showMailingExamplesDrawer());
  }, [dispatch]);

  const headerText =
    path === PATH_NAMES.MAILING
      ? t('MAILING_PAGE_TEXT.newSendOut')
      : t('MAILING_PAGE_TEXT.sendOut');

  const clearAllErrorHandler = useCallback(() => {
    dispatch(clearAllErrors());
  }, [dispatch]);

  const clearAllSuccessStatusesHandler = useCallback(() => {
    dispatch(clearAllSuccessStatuses());
  }, [dispatch]);

  const showMailingNav = path !== PATH_NAMES.MAILING || windowWidth < 981;

  const hideMailingSteps = path !== PATH_NAMES.MAILING;

  const isSwitchToStepTwo = !!clientFileName;

  const isSwitchToStepThree = !!(mediaFileName || text);

  const isShowErrorToast =
    clientFileTooBigError ||
    mediaFileTooBigError ||
    unsupportedFileError ||
    ((isTestMessageSendError || isStartingSendOutError) && !!sendOutErrorMessage) ||
    isParsingXLSXFileError ||
    isTestMessageSendError ||
    isStoppingSendOutError ||
    isStartingSendOutError ||
    isShowCalendarError;

  const isShowSuccessToast =
    (isStoppingSendOutSuccess || isStartingSendOutSuccess || isTestMessageSendSuccess) &&
    !!successMessage;

  const keys = { ...customerKeys };

  const keysToRender = keys ? Object.assign(keys, templateKeysDict[appLanguage].mailing) : keys;

  return (
    <div className={`flex flex-col font-inter w-full ${className}`}>
      <div>
        <HeaderWithButton
          headerText={headerText}
          instructionData={VIDEO_INSTRUCTIONS.mailing}
          className='mb-6 lg:mb-10'
        />
        {showMailingNav ? (
          <MailingNav
            path={path}
            callBack={addNewMailing}
            appLanguage={appLanguage}
            selectedFilialAccId={selectedFilial?.accId || ''}
            numberOfMailingsLaunched={mailingTableData.length}
            className='mb-6'
          />
        ) : null}
        {!hideMailingSteps ? (
          <MailingStepIcons
            activeStep={activeStep}
            appLanguage={appLanguage}
            disabled={!isPayedAccount}
            isSwitchToStepTwo={isSwitchToStepTwo}
            isSwitchToStepThree={isSwitchToStepThree}
            className='absolute mb-8'
          />
        ) : null}
      </div>
      <div className='flex lg:flex-row-reverse items-start w-full'>
        {path.includes(PATH_NAMES.MAILING) ? (
          <ImportantMailingInfo
            isMobileView={false}
            appLanguage={appLanguage}
            isShowExampleBlock={false}
            customerKeys={keysToRender}
            setKeyInTextarea={setKeyInTextarea}
            openDrawer={openMailingExamplesDrawer}
            wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
            isShowAdditionalInfo={activeStep === ACTIVE_STEP_NUMBER.SECOND_STEP}
            disableOpenDrawerButton={activeStep !== ACTIVE_STEP_NUMBER.SECOND_STEP}
            className='hidden xl:flex ml-5 min-w-[21.56rem] w-[26.5rem]'
          />
        ) : null}
        <div
          className={`relative flex flex-col ${
            path.includes(PATH_NAMES.MAILING) ? 'lg:max-w-[54.74rem] mr-auto' : ''
          } w-full`}>
          {isLoading ? <Loader className='h-[50vh]' /> : null}
          {!isLoading && path.includes(PATH_NAMES.MAILING) ? (
            <MailingSteps
              div={div}
              appLanguage={appLanguage}
              disabled={!isPayedAccount}
              setKeyInTextarea={setKeyInTextarea}
              wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
            />
          ) : null}
          {!isLoading && path.includes(PATH_NAMES.STARTED_MAILING) ? <StartedMailing /> : null}
          {!isLoading && path.includes(PATH_NAMES.STATISTICS) ? (
            <StatisticTemplate appLanguage={appLanguage} data={statisticTableData} />
          ) : null}
          {!isLoading && path.includes(PATH_NAMES.MAILING_DETAIL_STATS) ? (
            <MailingDetailStat />
          ) : null}
        </div>
      </div>
      <ToastsMessages
        successMessage={successMessage}
        isShowErrorToast={isShowErrorToast}
        isShowSuccessToast={isShowSuccessToast}
        clearErrorCallback={clearAllErrorHandler}
        errorMessage={errorMessage || sendOutErrorMessage}
        clearSuccessStatusCallback={clearAllSuccessStatusesHandler}
      />
    </div>
  );
});

MailingTemplate.displayName = 'MailingTemplate';
